import React, { ReactNode, useRef } from "react";
import { useTranslation } from "next-i18next";
import { motion, useInView } from "framer-motion";
import cn from "classnames";
import RichText from "../../Common/RichText";
import s from "../InteractiveGrid.module.scss";
import Button from "../../Button";
import { StoryblokRichTextFieldtype } from "../../../lib/storyblok/types/fieldtypes/richText";
import BlockBackground from "../../Common/BlockBackground/BlockBackground";
import IconWithColor from "../../Common/IconWithColor";
import VideoPlayer from "./VideoPlayer";

interface ItemNonTouchProps {
  style?: string;
  icon?: string;
  title: string;
  description: StoryblokRichTextFieldtype;
  link?: string;
  image?: ReactNode;
  video?: {
    filename: string;
    id: string | number;
  };
  rowIndex: number;
  columnIndex: number;
  isHovered: boolean;
  hasHoveredItemInRow: boolean;
  onHoverChange: (isHovered: boolean) => void;
}

const ItemNonTouch: React.FC<ItemNonTouchProps> = ({
  style = "tobii-violet",
  icon,
  title,
  description,
  link,
  image,
  video,
  rowIndex,
  columnIndex,
  isHovered,
  hasHoveredItemInRow,
  onHoverChange,
}) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const isInView = useInView(ref, {
    once: true,
  });

  const contentInverted =
    style !== "white" &&
    style !== "gray" &&
    style !== "none" &&
    style !== "" &&
    style !== undefined
      ? true
      : false;

  return (
    <motion.div
      ref={ref}
      className={cn(s.card, s.cardNonTouch, { [s.inverted]: !contentInverted })}
      onHoverStart={() => onHoverChange(true)}
      onHoverEnd={() => onHoverChange(false)}
      data-is-hovered={isHovered}
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{
        opacity: isInView ? 1 : 0,
        scale: isInView ? 1 : 0.8,
      }}
      transition={{
        duration: 0.3,
        delay: (rowIndex * 2 + columnIndex) * 0.05,
        ease: [0.2, 0, 0.1, 1],
      }}
    >
      <motion.div
        className={s.defaultContent}
        animate={{
          opacity: isHovered ? 0 : 1,
        }}
        transition={{
          duration: isHovered ? 0.15 : 0.4,
          delay: isHovered ? 0 : 0.2,
          ease: isHovered ? "easeOut" : "easeInOut",
        }}
      >
        <BlockBackground style={style} opacity={100} />
        <div className={s.contentContainer}>
          {icon && (
            <div className={s.icon}>
              <IconWithColor
                icon={icon}
                width={48}
                height={48}
                alt={title}
                color={contentInverted ? "white" : "black"}
              />
            </div>
          )}
          <motion.span
            className={s.title}
            animate={{
              opacity: hasHoveredItemInRow && !isHovered ? 0 : 1,
              height: hasHoveredItemInRow && !isHovered ? "0" : "auto",
            }}
            transition={{
              duration: 0.2,
            }}
          >
            {title}
          </motion.span>
        </div>
      </motion.div>

      {(video || image) && (
        <motion.div
          className={s.visualContainer}
          initial={false}
          animate={{
            opacity: isHovered ? 1 : 0.5,
            scale: isHovered ? 1.1 : 1,
          }}
          transition={{
            duration: 0.7,
            ease: [0.4, 0, 0.2, 1],
            opacity: { duration: 0.3 },
          }}
        >
          {video?.filename ? (
            <VideoPlayer
              key={video.id}
              videoUrl={video.filename}
              title={title}
              isHovered={isHovered}
            />
          ) : (
            image
          )}
        </motion.div>
      )}

      <motion.div
        className={s.expandedContent}
        initial={false}
        animate={{
          opacity: isHovered ? 1 : 0,
        }}
        transition={{
          duration: isHovered ? 0.3 : 0.1,
          delay: isHovered ? 0.6 : 0,
        }}
      >
        <motion.div
          className={s.contentContainer}
          animate={{
            opacity: isHovered ? 1 : 0,
            y: isHovered ? 0 : 20,
          }}
          transition={{
            duration: isHovered ? 0.3 : 0.1,
            delay: isHovered ? 0.6 : 0,
          }}
        >
          <span className={s.title}>{title}</span>
          {description && <RichText content={description} inverted />}
          {link && link !== "/" && link.trim() !== "" && (
            <Button
              variant="inverted"
              size="medium"
              href={link}
              title={title}
              icon="arrow-right"
            >
              {t("learn-more")}
            </Button>
          )}
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default ItemNonTouch;
