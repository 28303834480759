import { ReactNode } from "react";
import s from "../ContentGrid.module.scss";

type Props = {
  title: string;
  browseAllHref?: string;
  browseAllLabel: string;
  children: ReactNode;
};

const List = ({ title, browseAllHref, browseAllLabel, children }: Props) => {
  return (
    <article>
      <div className={s.listHeading}>
        <h3>{title}</h3>
        {browseAllHref && (
          <a className={s.browseAll} href={browseAllHref} title={title}>
            {browseAllLabel}
          </a>
        )}
      </div>
      <ul className={s.list}>{children}</ul>
    </article>
  );
};

export default List;
