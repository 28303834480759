import React, { ElementType } from "react";
import cn from "classnames";
import c from "../../styles/container.module.scss";
import s from "./Preamble.module.scss";

type Props = {
  title?: string;
  labelAs?: ElementType;
  text: string;
  noTopPadding?: boolean;
  noBottomPadding?: boolean;
};

const Preamble = ({
  title,
  labelAs = "h2",
  text,
  noTopPadding,
  noBottomPadding,
}: Props) => {
  const Heading = labelAs;

  return (
    <div
      className={cn(s.preamble, {
        [s.noTopPadding]: noTopPadding,
        [s.noBottomPadding]: noBottomPadding,
      })}
    >
      <Heading className={cn(s.title, c.narrow)}>{title}</Heading>
      <p className={cn(s.text, c.narrow, { [s.noTitle]: !title })}>{text}</p>
    </div>
  );
};

export default Preamble;
