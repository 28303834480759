import React, { useState, useEffect } from "react";
import { AnimatePresence } from "framer-motion";
import { MobileSubNavigationTab as TabType } from "../../../lib/storyblok/types/blocks/header/HeaderSubNavigationTab";
import { MobileSubNavTitle, MobileNavList } from "../../../ui/Header";
import { useLinks } from "../../../lib/storyblok/useLinks";
import { MobileNavItemItem } from "../../../ui/Header/components/mobile/MobileNavItemItem";
import { MobileNavItem } from "../../../ui/Header/components/mobile/MobileNavItem";
import { HeaderSubNavigationItem } from "../../../lib/storyblok/types/blocks/header/HeaderSubNavigationItem";

type Props = {
  item: TabType;
  title: string;
  href: string;
  setIsMobileSubNavOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const MobileSubNavigationTab: React.FC<Props> = ({
  item,
  title,
  href,
  setIsMobileSubNavOpen,
}) => {
  const resolveLink = useLinks();

  const [isMobileTabOpen, setIsMobileTabOpen] = useState(false);
  const [isMobileTabSubOpen, setIsMobileTabSubOpen] = useState(false);
  const [activeMobileTabSubIndex, setActiveMobileTabSubIndex] = useState<
    number | null
  >(null);

  useEffect(() => {
    setIsMobileTabOpen(true);
  }, []);

  const activeTabIndex = activeMobileTabSubIndex ?? 0;
  return (
    <>
      <AnimatePresence>
        {isMobileTabOpen && (
          <MobileNavList isOpen={isMobileTabOpen} level={1} key={item._uid}>
            <MobileSubNavTitle
              title={title}
              href={href}
              onBackClick={() => {
                setIsMobileTabOpen(false);
                setIsMobileSubNavOpen(false);
              }}
              index={0}
            />
            {item.items?.map((subItem, i) => (
              <MobileNavItem
                key={subItem._uid}
                text={subItem.title}
                href={"#"}
                index={i}
                tab={true}
                onClick={() => {
                  setActiveMobileTabSubIndex(i);
                  setIsMobileTabSubOpen(true);
                }}
              />
            ))}
          </MobileNavList>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isMobileTabSubOpen && activeMobileTabSubIndex !== null && (
          <MobileNavList isOpen={isMobileTabSubOpen} level={2}>
            <MobileSubNavTitle
              title={title}
              href={href}
              onBackClick={() => {
                setIsMobileSubNavOpen(false);
                setIsMobileTabOpen(false);
                setIsMobileTabSubOpen(false);
              }}
              index={0}
            />
            <MobileSubNavTitle
              title={item.items[activeTabIndex].title}
              href="#"
              onBackClick={() => {
                setIsMobileTabSubOpen(false);
              }}
              index={1}
            />
            {item.items[activeTabIndex]?.items?.map((subNavItem, j) => {
              const navItemAllSubItems: HeaderSubNavigationItem[] = [];
              subNavItem.body.forEach((e) => {
                navItemAllSubItems.push(...e.items);
              });
              return (
                <MobileNavItemItem
                  key={subNavItem._uid}
                  text={subNavItem.title}
                  href={resolveLink(subNavItem.link)}
                  directLink={resolveLink(subNavItem.link) !== "/"}
                  index={j}
                  subItems={navItemAllSubItems}
                />
              );
            })}
          </MobileNavList>
        )}
      </AnimatePresence>
    </>
  );
};

export default MobileSubNavigationTab;
