import React from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import ComponentTestUi from "../../ui/ComponentTest";
import { ComponentTestBlock } from "../../lib/storyblok/types/blocks/ComponentTestBlock";

const ComponentTest: StoryblokBlockComponent<ComponentTestBlock> = ({
  title,
  content,
}) => <ComponentTestUi content={content} title={title} />;

export default ComponentTest;
