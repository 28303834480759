import React, { ReactNode } from "react";
import cn from "classnames";
import s from "./Downloads.module.scss";
import c from "../../styles/container.module.scss";

import List from "./components/List";
import LinkItem from "./components/LinkItem";
import ExpandableItem from "./components/ExpandableItem";
import GatedDownload from "./components/GatedDownload";
import Markdown from "../Markdown";

type Props = {
  title: string;
  text: string;
  children: ReactNode;
};

const subComponents = {
  List,
  LinkItem,
  ExpandableItem,
  GatedDownload,
};
type SubComponents = typeof subComponents;

const Downloads: CWS<Props, SubComponents> = ({ title, text, children }) => (
  <section className={cn(s.downloads, c.fluid)}>
    <div className={c.xWide}>
      <h2>{title}</h2>
      <Markdown>{text}</Markdown>
    </div>
    {children}
  </section>
);

Downloads.List = List;
Downloads.LinkItem = LinkItem;
Downloads.ExpandableItem = ExpandableItem;
Downloads.GatedDownload = GatedDownload;

export default Downloads;
