import { ReactNode } from "react";
import cn from "classnames";
import s from "../Footer.module.scss";
import b from "../../../ui/Button/Button.module.scss";

type Props = {
  title?: string;
  children: ReactNode;
  contactPageLink?: string;
  contactPageText: string;
};

const SocialList = ({
  title,
  children,
  contactPageLink,
  contactPageText,
}: Props) => {
  return (
    <div className={cn(s.footerList, s.socialList)}>
      {title && <span className={s.title}>{title}</span>}
      <ul>
        {contactPageLink && (
          <li>
            <a
              className={cn(s.button, b.button, b.small)}
              href={contactPageLink}
              title={contactPageText}
            >
              {contactPageText}
            </a>
          </li>
        )}
        {children}
      </ul>
    </div>
  );
};

export default SocialList;
