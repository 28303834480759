import React, { useMemo, useState, useEffect, useRef } from "react";
import cn from "classnames";
import s from "../Slider.module.scss";

type Props = {
  videoUrl: string | undefined;
  delay?: boolean;
  noOverlay?: boolean;
  parallax?: boolean;
  playCount;
  setPlayCount;
  activeSlideId?: string;
  id?: string;
};

const VideoPlayer = ({
  videoUrl,
  delay,
  noOverlay,
  parallax,
  playCount,
  setPlayCount,
  activeSlideId,
  id,
}: Props) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [loaded, setLoaded] = useState(false);
  const [video, setVideo] = useState<string>();
  const [isPlaying, setIsPlaying] = useState(false);

  const opacity = useMemo(() => {
    return loaded ? 0.7 : 1;
  }, [loaded]);

  useEffect(() => {
    setVideo(videoUrl);
  }, [videoUrl]);

  useEffect(() => {
    if (id === activeSlideId) {
      if (!isPlaying) {
        videoRef.current?.play().then(() => {
          setIsPlaying(true);
        });
        /*
          .catch((error) => {
            // eslint-disable-next-line
            console.error("Error playing video:", error);
          });
          */
      }
    } else {
      if (isPlaying) {
        videoRef.current?.pause();
        setIsPlaying(false);
      }
    }
  }, [id, activeSlideId, isPlaying]);

  return (
    <>
      <video
        ref={videoRef}
        className={cn(s.videoPlayer, { [s.parallax]: parallax })}
        width="100%"
        height="100%"
        preload="auto"
        muted
        autoPlay
        playsInline
        onEnded={() => {
          if (playCount < 2 && videoRef.current) {
            videoRef.current.play();
          } else {
            videoRef.current?.pause();
          }

          setPlayCount((prevCount) => {
            return prevCount + 1;
          });
        }}
        onPlay={() => {
          setTimeout(
            () => {
              setLoaded(true);
            },
            delay ? 3000 : 0
          );
        }}
        src={video}
      />
      <div className={cn({ [s.overlay]: !noOverlay })} style={{ opacity }} />
    </>
  );
};

export default VideoPlayer;
