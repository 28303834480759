import { ImageVaultFieldType } from "../lib/storyblok/types/fieldtypes/imagevaultFieldtype";
import Image, { ImageLoader, ImageProps } from "next/image";
import { NextRequest } from "next/server";
import { ImageSizes } from "../lib/utils/imageSizes";

if (process.env.IMAGEVAULT_URL === "undefined")
  throw new Error("Tobii ImageVault URL .env is missing");

type Props = {
  image: ImageVaultFieldType;
  sizes?: ImageSizes;
  layout?: string;
  contain?: boolean;
  width?: number;
  height?: number;
};

type RemainingImageProps = Omit<
  ImageProps,
  "width" | "height" | "src" | "sizes"
>;

const ImageVaultImage = ({
  image,
  layout,
  sizes,
  contain,
  ...imageProps
}: RemainingImageProps & Props) => {
  const item = image?.item?.MediaConversions?.[0];

  if (!item) return null;

  const altText = image?.item?.Metadata?.find(
    (meta) => meta.MetadataDefinitionId === 1082
  )?.Value;

  const loader: ImageLoader = imageVaultLoader;

  const src = item.Url.split("/").slice(3).join("/");

  return (
    <Image
      lazyBoundary="600px"
      loader={loader}
      alt={altText}
      src={"/" + src}
      layout={layout}
      objectFit={contain ? "contain" : "cover"}
      width={layout !== "fill" ? item.Width : undefined}
      height={layout !== "fill" ? item.Height : undefined}
      sizes={sizes}
      quality={80}
      {...imageProps}
    />
  );
};

export function rewriteImageVaultImageUrl(url: NextRequest["nextUrl"]) {
  const [, , , hash, file] = url.pathname.split("/");
  const { w, q } = Object.fromEntries(
    url.search
      .slice(1)
      .split("&")
      .map((p) => p.split("="))
  );
  url.pathname = "/_next/image";
  url.search = `?url=${
    process.env.IMAGEVAULT_URL
  }/publishedmedia/${hash}/${file}&w=${w ?? 3840}&q=${q ?? 100}`;
}

export const imageVaultLoader = ({ src, width, quality }): string => {
  // This url is rewritten to "_next/image/..." in middleware.ts
  // with the rewriteImageVaultImageUrl function
  return `/imagevault${src}?w=${width}&q=${quality || 75}`;
};

export default ImageVaultImage;
