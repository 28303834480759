import { ReactNode } from "react";
import cn from "classnames";
import s from "./Authors.module.scss";
import c from "../../styles/container.module.scss";

import Author from "././components/Author";

type Props = {
  dark?: boolean;
  title: string;
  children: ReactNode;
};

const subComponents = { Author };
type SubComponents = typeof subComponents;

const Authors: CWS<Props, SubComponents> = ({
  dark,
  title,
  children,
}: Props) => (
  <section className={cn(s.authors, c.narrow, cn({ [s.dark]: dark }))}>
    <p className={cn(s.title)}>{title}</p>
    <ul>{children}</ul>
  </section>
);

Authors.Author = Author;

export default Authors;
