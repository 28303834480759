import Head from "next/head";
import { useTranslation } from "next-i18next";
import { ViewModel } from "../../lib/viewModel/createViewModel";
import { InvestorEventPageStory } from "../../lib/storyblok/types/stories/InvestorEventPageStory";
import { StoryblokStoryComponent } from "../../lib/storyblok/types/StoryblokComponent";
import Agenda from "../../ui/Agenda";
import Details from "../../ui/Details";
import Editorial from "../../ui/Editorial";
import WithSidebar from "../../ui/PageTemplates/WithSidebar";
import Share from "../../ui/Share";
import Tags from "../../ui/Tags";
import { DynamicBlocks } from "../DynamicBlock";
import ImageVaultImage, { imageVaultLoader } from "../ImageVaultImage";
import FeaturedHero from "../../ui/FeaturedHero";
import Button from "../../ui/Button";
import { useEffect, useState } from "react";
import { useLinks } from "../../lib/storyblok/useLinks";
import Tag from "../Tag";
import ShareItems from "../../ui/Share/components/ShareItems";
import { useViewmodel } from "../../lib/storyblok/ViewmodelContext";
import Image from "next/image";
import WeChat from "../../public/images/wechat.jpg";
import Body from "../../ui/PageTemplates/WithSidebar/components/Body";
import { MediaQueryHider } from "../../ui/MediaQueryHider/MediaQueryHider";
import { FormatDate, FormatDateRange } from "../../lib/utils/FormatDateRange";
import { storyblokEditable } from "@storyblok/react";
import { deduceDomain } from "../../lib/utils/deduceDomain";

type VM = ViewModel<InvestorEventPageStory>;

const { Sidebar } = WithSidebar;

const InvestorEventPage: StoryblokStoryComponent<VM> = ({ story }) => {
  const {
    heroImage,
    heroTitle,
    heroPreamble,
    heroButtons,
    callToActionText,
    noHeroOverlay,
    eventDate,
    startDate,
    eventLocation,
    eventAvailability,
    eventLanguages,
    details,
    relatedDocuments,
    contacts,
    editorial,
    agendaTitle,
    agenda,
    resourcesFromEvent,
    speakers,
    callToActionSection,
    contentGrid,
    eventType,
    taggedProducts,
    taggedSolutions,
  } = story.content;

  const resolveLink = useLinks();

  const { t } = useTranslation(["eventpage", "tags", "common"]);

  const { globalLinks, locale, site } = useViewmodel();

  const [eventHasExpired, setEventHasExpired] = useState(false);

  const hasEventExpired = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return today > new Date(date);
  };

  useEffect(() => {
    if (eventDate) {
      setEventHasExpired(hasEventExpired(eventDate));
    }
  }, [eventDate]);

  // Event schema
  // Source: https://developers.google.com/search/docs/appearance/structured-data/event
  const eventInvestorSchema = {
    "@context": "https://schema.org",
    "@type": "Event",
    name: heroTitle || "",
    startDate: startDate || "",
    endDate: eventDate || "",
    eventAttendanceMode: "https://schema.org/MixedEventAttendanceMode",
    eventStatus: "https://schema.org/EventScheduled",
    location: {
      "@type": "Place",
      name: eventLocation || "",
    },
    image:
      deduceDomain(locale, site) +
      imageVaultLoader({
        src:
          "/" +
          story.content.seoImage?.item?.MediaConversions?.[0].Url.split("/")
            .slice(3)
            .join("/"),
        width: 1200,
        quality: 100,
      }),
    description: heroPreamble || story.content.seoMeta?.description,
    organizer: {
      "@type": "Organization",
      name: "Tobii",
      url: deduceDomain(locale, site),
    },
  };

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(eventInvestorSchema),
          }}
        />
      </Head>
      <FeaturedHero
        backgroundImage={
          heroImage?.item && (
            <ImageVaultImage
              priority
              image={heroImage}
              layout="fill"
              sizes="100vw"
            />
          )
        }
        label={
          eventType?.length > 0 ? t(eventType[0], { ns: "tags" }) : undefined
        }
        title={heroTitle}
        preamble={heroPreamble}
        date={eventDate}
        startDate={startDate}
        location={eventLocation}
        buttons={
          <>
            {callToActionText && (
              <Button
                variant="inverted"
                disabled={eventHasExpired}
                {...(!eventHasExpired ? { href: "#cta-section" } : {})}
              >
                {eventHasExpired ? t("event-has-expired") : callToActionText}
              </Button>
            )}
            {heroButtons?.length > 0 && <DynamicBlocks blocks={heroButtons} />}
          </>
        }
        noOverlay={noHeroOverlay}
      />
      <WithSidebar>
        <Body>
          <MediaQueryHider hideAboveDesktop>
            <Details title={t("event-details")}>
              {eventDate && (
                <Details.Detail
                  icon="symbolicon-calendar"
                  title={
                    startDate
                      ? FormatDateRange(startDate, eventDate)
                      : FormatDate(eventDate)
                  }
                />
              )}

              {eventLocation && (
                <Details.Detail
                  icon="location"
                  title={eventLocation}
                  subtitle={t(eventAvailability, { ns: "tags" })}
                />
              )}
              {eventLanguages && eventLanguages.length > 0 && (
                <Details.Detail
                  icon="world"
                  title={eventLanguages
                    .map((tag) => t(tag, { ns: "common" }))
                    .join(", ")}
                />
              )}
              {details &&
                details.length > 0 &&
                details.map((d) => (
                  <div key={d._uid} {...storyblokEditable(d)}>
                    <Details.Detail
                      icon={d.icon}
                      title={d.title}
                      subtitle={d.subtitle}
                    />
                  </div>
                ))}
            </Details>
          </MediaQueryHider>

          {editorial && (
            <Editorial.WithoutSpacing>
              <DynamicBlocks blocks={editorial} />
            </Editorial.WithoutSpacing>
          )}
          {agenda?.length >= 1 && (
            <Agenda title={agendaTitle}>
              {agenda.map((i) => (
                <div key={i._uid} {...storyblokEditable(i)}>
                  <Agenda.Item time={i.time} description={i.description} />
                </div>
              ))}
            </Agenda>
          )}
        </Body>
        <Sidebar>
          <MediaQueryHider hideBelowDesktop>
            <Details title={t("event-details")}>
              {eventDate && (
                <Details.Detail
                  icon="symbolicon-calendar-date"
                  title={
                    startDate
                      ? FormatDateRange(startDate, eventDate)
                      : FormatDate(eventDate)
                  }
                />
              )}
              {eventLocation && (
                <Details.Detail
                  icon="location"
                  title={eventLocation}
                  subtitle={t(eventAvailability, { ns: "tags" })}
                />
              )}
              {eventLanguages && eventLanguages.length > 0 && (
                <Details.Detail
                  icon="world"
                  title={eventLanguages
                    .map((tag) => t(tag, { ns: "common" }))
                    .join(", ")}
                />
              )}

              {details &&
                details.length > 0 &&
                details.map((d) => (
                  <div key={d._uid} {...storyblokEditable(d)}>
                    <Details.Detail
                      key={d._uid}
                      icon={d.icon}
                      title={d.title}
                      subtitle={d.subtitle}
                    />
                  </div>
                ))}
            </Details>
          </MediaQueryHider>

          {relatedDocuments && relatedDocuments.length > 0 && (
            <Details
              title={t("related-documents", { ns: "common" })}
              isRelatedDocuments
            >
              {relatedDocuments.map((d) => (
                <div key={d._uid} {...storyblokEditable(d)}>
                  <Details.Document
                    icon={d.icon}
                    title={d.title}
                    subtitle={d.subtitle}
                    href={resolveLink(d.link)}
                  />
                </div>
              ))}
            </Details>
          )}
          {(eventType || taggedProducts || taggedSolutions) && (
            <Tags>
              {eventType && eventType.length > 0 && (
                <Tags.TagList title={t("event-type")}>
                  {eventType?.map((tag) => {
                    const baseUrl = globalLinks.investorEventFilterPage
                      ? resolveLink(globalLinks.investorEventFilterPage)
                      : "";
                    const url = `${baseUrl}?types=${tag}`;
                    return (
                      <Tag key={tag} tag={t(tag, { ns: "tags" })} url={url} />
                    );
                  })}
                </Tags.TagList>
              )}
            </Tags>
          )}
          <DynamicBlocks blocks={contacts} />
          <Share
            title={t("share", { ns: "common" })}
            weChat={
              locale === "zh" && (
                <Image src={WeChat} quality={100} sizes="100vw" alt="WeChat" />
              )
            }
          >
            <ShareItems />
          </Share>
        </Sidebar>
      </WithSidebar>
      {resourcesFromEvent && <DynamicBlocks blocks={resourcesFromEvent} />}
      {speakers && <DynamicBlocks blocks={speakers} />}
      {callToActionSection && (
        <div id="cta-section">
          <DynamicBlocks blocks={callToActionSection} />
        </div>
      )}
      {contentGrid && <DynamicBlocks blocks={contentGrid} />}
    </>
  );
};

export default InvestorEventPage;
