import cn from "classnames";
import { ReactNode } from "react";
import useMediaQuery from "../../hooks/useMatchMedia";
import s from "./WaveDivider.module.scss";

type Props = {
  image: ReactNode;
  flipped?: boolean;
};

const WaveDivider = ({ image, flipped }: Props) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <div
      className={cn(s.waveDivider, {
        [s.top]: !flipped,
        [s.bottom]: flipped,
        [s.mobile]: isMobile,
      })}
    >
      {image && image}
    </div>
  );
};

export default WaveDivider;
