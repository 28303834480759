import { resolveSlug } from "../utils/resolveSlug";
import { LinkFieldtype } from "./types/fieldtypes/linkFieldtype";
import { useViewmodel } from "./ViewmodelContext";

export const useLinks = () => {
  const { links } = useViewmodel();

  const resolveLink = (linkField: LinkFieldtype): string => {
    const { id, linktype, url } = linkField ?? {};
    if (linktype === "story") {
      const link = links.find((l) => l.uuid === id);
      if (link) {
        return resolveSlug(link.full_slug);
      }
    }

    return url || "/";
  };

  return resolveLink;
};
