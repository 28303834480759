import { useTranslation } from "next-i18next";
import React from "react";
import {
  useClearRefinements,
  useCurrentRefinements,
} from "react-instantsearch";
import FilterList from "../../ui/FilterList";
import RefinementFilterList from "./RefinementFilterList";

const StoryRefinementList = ({ refinementAttributes, hiddenFacets }) => {
  const { t } = useTranslation("searchpage");

  const { refine: clearRefinements } = useClearRefinements();
  const { items: currentRefinements } = useCurrentRefinements();

  const onClearClick = () => {
    clearRefinements();
  };
  return (
    <>
      <FilterList.Heading
        clearLabel={currentRefinements.length > 0 ? t("clear-all") : ""}
        onClearClick={onClearClick}
      >
        {t("filter-by")}
      </FilterList.Heading>

      {refinementAttributes.map(({ id, attribute, sort }) => (
        <RefinementFilterList
          key={id}
          id={id}
          attribute={attribute}
          sort={sort}
          hiddenFacets={hiddenFacets}
        />
      ))}
    </>
  );
};

export default StoryRefinementList;
