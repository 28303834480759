import { EditorialQuoteBlock } from "../../lib/storyblok/types/blocks/EditorialQuoteBlock";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import Editorial from "../../ui/Editorial/Editorial";

const EditorialQuote: StoryblokBlockComponent<EditorialQuoteBlock> = ({
  quote,
  quotee,
}) => <Editorial.Quote quote={quote} quotee={quotee} />;

export default EditorialQuote;
