import { createElement, ReactNode } from "react";
import Marquee from "react-fast-marquee";
import cn from "classnames";
import s from "../LogoList.module.scss";
import c from "../../../styles/container.module.scss";

type Props = {
  featured?: boolean;
  shouldScroll?: boolean;
  children: ReactNode;
};

const List = ({ featured, shouldScroll, children }: Props) => {
  return (
    <>
      <div className={cn(s.listWrapper)}>
        {createElement(
          shouldScroll ? Marquee : "div",
          {
            ...(shouldScroll
              ? {
                  className: cn(s.marquee, {
                    [c.xxWide]: featured,
                    [c.xWide]: !featured,
                  }),
                  gradient: false,
                }
              : {
                  className: cn(s.list, {
                    [c.xxWide]: featured,
                    [c.xWide]: !featured,
                  }),
                }),
          },
          children
        )}
      </div>
    </>
  );
};

export default List;
