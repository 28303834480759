import React, { ReactNode } from "react";
import useExpandable from "../../../hooks/useExpandable";
import cn from "classnames";
import s from "../TechSpec.module.scss";

import Icon from "../../Icon/Icon";

type Props = {
  title: string;
  isExpanded: boolean;
  toggleExpanded?: () => void;
  isSingle?: boolean;
  children: ReactNode;
};

const Expandable = ({
  title,
  isExpanded,
  toggleExpanded,
  isSingle,
  children,
}: Props) => {
  const contentRef = useExpandable<HTMLDivElement>(isExpanded);

  return (
    <div className={s.expandable} data-testid="techspec-expandable">
      {!isSingle ? (
        <button
          onClick={toggleExpanded}
          className={cn(s.titleContainer, { [s.open]: isExpanded })}
        >
          {title}
          <Icon
            icon="caret-down"
            width={16}
            color="white"
            className={cn(s.icon, { [s.rotated]: isExpanded })}
          />
        </button>
      ) : (
        <div className={cn(s.titleContainer, s.single)}>{title}</div>
      )}
      <div
        ref={isSingle ? null : contentRef}
        className={cn(s.content, {
          [s.isExpanded]: isExpanded,
          [s.isSingle]: isSingle,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default Expandable;
