import { createContext, useContext, useState } from "react";

type AnchorMenuContextProps = ReturnType<typeof useMe>;
const anchorMenuContext = createContext<AnchorMenuContextProps>(
  {} as AnchorMenuContextProps
);

export const useAnchorMenuContext = () => useContext(anchorMenuContext);

const useMe = () => {
  const [visibleSections, setVisibleSections] = useState<string[]>([]);
  return { visibleSections, setVisibleSections };
};

export const AnchorMenuContextProvider = ({ children }) => {
  return (
    <anchorMenuContext.Provider value={useMe()}>
      {children}
    </anchorMenuContext.Provider>
  );
};
