import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { FeaturedContentGridBlock } from "../../lib/storyblok/types/blocks/FeaturedContentGridBlock";
import FeaturedContentGridUi from "../../ui/FeaturedContentGrid";
import FeaturedContentItem from "../../ui/FeaturedContentGrid/components/FeaturedContentItem";
import { useRelations } from "../../lib/storyblok/useRelations";
import ImageVaultImage from "../ImageVaultImage";
import Button from "../../ui/Button";
import { useTranslation } from "next-i18next";
import {
  resolveStoryImage,
  isForegroundImage,
} from "../../lib/utils/resolveStoryImage";
import { resolveSlug } from "../../lib/utils/resolveSlug";
import Link from "next/link";

const FeaturedContentGrid: StoryblokBlockComponent<
  FeaturedContentGridBlock
> = ({ items }) => {
  const { getRelation } = useRelations();
  const { t } = useTranslation();

  if (items) {
    return (
      <FeaturedContentGridUi>
        {items &&
          items.map((item, i) => {
            const story = getRelation(item.item);
            if (story) {
              const { uuid, content, name, full_slug } = story;
              return (
                <FeaturedContentItem
                  key={uuid}
                  productName={name}
                  title={name}
                  preamble={content.description}
                  backgroundImage={
                    <ImageVaultImage
                      sizes="33vw"
                      image={resolveStoryImage(content)}
                      layout="fill"
                    />
                  }
                  foregroundImage={
                    isForegroundImage(content) && (
                      <ImageVaultImage
                        sizes="33vw"
                        image={resolveStoryImage(content)}
                        layout="fill"
                      />
                    )
                  }
                  inverted={item.inverted}
                  overlay={item.overlay}
                  buttons={
                    <Link
                      href={resolveSlug(full_slug)}
                      passHref
                      prefetch={false}
                    >
                      <Button variant="cta" href={resolveSlug(full_slug)}>
                        {t("learn-more")}
                      </Button>
                    </Link>
                  }
                  fullWidth={i === 0}
                />
              );
            }
          })}
      </FeaturedContentGridUi>
    );
  } else {
    return <></>;
  }
};

export default FeaturedContentGrid;
