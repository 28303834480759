import { useTranslation } from "next-i18next";
import FooterUi from "../../ui/Footer";
import { FooterBlock } from "../../lib/storyblok/types/blocks/FooterBlock";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { useLinks } from "../../lib/storyblok/useLinks";
import { useViewmodel } from "../../lib/storyblok/ViewmodelContext";

const {
  FooterHeader,
  FooterFooter,
  FooterList,
  FooterLink,
  SocialList,
  SocialItem,
} = FooterUi;

type FooterProps = FooterBlock & {
  onLangClick: () => void;
};

const Footer: StoryblokBlockComponent<FooterProps> = ({
  searchPageLink,
  contactPageLink,
  termsOfUseText,
  termsOfUseLink,
  privacyPolicyText,
  privacyPolicyLink,
  linkListATitle,
  linkListA,
  linkListBTitle,
  linkListB,
  socialMediaTitle,
  socialMediaList,
  onLangClick,
}) => {
  const resolveLink = useLinks();
  const { t } = useTranslation();
  const { locale, site } = useViewmodel();

  return (
    <FooterUi>
      <FooterHeader
        search={resolveLink(searchPageLink)}
        onLangClick={onLangClick}
        site={site}
      ></FooterHeader>

      <FooterList title={linkListATitle}>
        {linkListA.map((item) => (
          <FooterLink
            key={item._uid}
            text={item.text}
            href={resolveLink(item.link)}
          />
        ))}
      </FooterList>

      <FooterList title={linkListBTitle}>
        {linkListB.map((item) => (
          <FooterLink
            key={item._uid}
            text={item.text}
            href={resolveLink(item.link)}
          />
        ))}
      </FooterList>

      <SocialList
        title={socialMediaTitle}
        contactPageLink={resolveLink(contactPageLink)}
        contactPageText={t("contact")}
      >
        {socialMediaList.map((soMeItem) => (
          <SocialItem
            key={soMeItem._uid}
            icon={soMeItem.icon}
            href={resolveLink(soMeItem.link)}
          />
        ))}
      </SocialList>

      <FooterFooter
        locale={locale}
        privacyPolicyText={privacyPolicyText}
        privacyPolicyLink={resolveLink(privacyPolicyLink)}
        termsOfUseText={termsOfUseText}
        termsOfUseLink={resolveLink(termsOfUseLink)}
        copyright={t("copyright")}
        allRightsReserved={t("all-rights-reserved")}
        icpLicense={t("icp-license")}
      />
    </FooterUi>
  );
};

export default Footer;
