import { ReactNode } from "react";
import cn from "classnames";
import s from "../WithSidebar.module.scss";

type Props = {
  children: ReactNode;
};

const Sidebar = ({ children }: Props) => (
  <section className={cn(s.sidebar, s.column)}>{children}</section>
);

export default Sidebar;
