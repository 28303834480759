import Image from "next/image";
import { IconType } from "../../Icon/Icon";
import s from "../Details.module.scss";

type Props = {
  icon: IconType;
  title: string;
  subtitle?: string;
  href: string;
  customClassName?: string;
};

const Detail = ({ icon, title, subtitle, href, customClassName }: Props) => (
  <li className={customClassName}>
    <a
      target="_blank"
      rel="noreferrer"
      title={title}
      href={href}
      className={s.document}
    >
      {icon && (
        <Image src={`/svg/icons/${icon}.svg`} width={24} height={24} alt="" />
      )}
      <div className={s.wrapper}>
        <p className={s.title}>{title}</p>
        {subtitle && <p className={s.subtitle}>{subtitle}</p>}
      </div>
    </a>
  </li>
);

export default Detail;
