const TobiiLogo = ({ width, color }) => (
  <svg
    width={width}
    viewBox="0 0 63 25"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M58.0349 24.5954V9.29545H62.1349V24.5954H58.0349Z" fill={color} />
    <path d="M50.8349 24.5954V9.29545H54.8349V24.5954H50.8349Z" fill={color} />
    <path
      d="M23.1349 12.7955C20.9349 12.7955 19.1349 14.5954 19.1349 16.7954C19.1349 18.9954 20.9349 20.7954 23.1349 20.7954C25.3349 20.7954 27.1349 18.9954 27.1349 16.7954C27.1349 14.5954 25.3349 12.7955 23.1349 12.7955ZM23.1349 24.9955C18.6349 24.9955 15.0349 21.3954 15.0349 16.8954C15.0349 12.3954 18.6349 8.79545 23.1349 8.79545C27.6349 8.79545 31.2349 12.3954 31.2349 16.8954C31.2349 21.3954 27.6349 24.9955 23.1349 24.9955Z"
      fill={color}
    />
    <path
      d="M40.9349 20.7954H39.5349C39.5349 20.7954 39.5349 20.7954 39.4349 20.7954C38.8349 20.6954 38.2349 20.6954 37.8349 20.2954C37.5349 19.8954 37.4349 19.3954 37.4349 18.4954C37.4349 18.4954 37.4349 17.6954 37.4349 16.5954V16.4954C37.4349 14.1954 38.9349 13.0955 41.1349 13.0955C43.3349 13.0955 44.8349 14.7954 44.8349 17.0954C44.7349 19.1954 43.1349 20.7954 40.9349 20.7954ZM40.9349 9.09545C40.7349 9.09545 40.5349 9.09545 40.3349 9.09545C39.2349 9.19545 38.2349 9.49545 37.3349 9.99545V4.69545H33.3349V19.7954C33.3349 24.3954 35.8349 24.9954 38.5349 24.9954C38.6349 24.9954 40.9349 24.9954 41.0349 24.9954C45.5349 24.9954 48.8349 21.5954 48.8349 17.0954C48.7349 12.4954 45.5349 9.09545 40.9349 9.09545Z"
      fill={color}
    />
    <path
      d="M11.5349 24.6954C9.73492 24.6954 6.63492 24.8954 5.13492 23.5954C3.83492 22.4954 3.43492 21.1954 3.43492 19.5954V13.4954H0.234924V9.49545H3.43492V5.09545H7.53492V9.59545H13.1349V13.5954H7.53492V15.7955C7.53492 16.3955 7.53492 16.5954 7.53492 17.1954C7.53492 18.5954 7.23492 20.8954 9.33492 20.9954C9.63492 20.9954 13.2349 20.9954 13.2349 20.9954V24.6954H11.5349Z"
      fill={color}
    />
    <path
      d="M62.6349 1.99545C62.2349 0.59545 60.7349 -0.30455 59.3349 0.0954496C58.3349 0.39545 57.8349 1.09545 57.3349 1.69545C56.9349 2.19545 56.6349 2.59545 56.1349 2.79545C55.5349 2.89545 55.1349 2.69545 54.5349 2.49545C53.8349 2.19545 53.0349 1.79545 52.0349 2.09545C50.6349 2.39545 49.7349 3.89545 50.0349 5.29545V5.49545C50.4349 6.89545 51.9349 7.79545 53.3349 7.39545C54.4349 7.09545 54.9349 6.39545 55.3349 5.79545C55.7349 5.29545 55.9349 4.89545 56.5349 4.69545C57.1349 4.59545 57.5349 4.79545 58.1349 4.99545C58.8349 5.29545 59.5349 5.69545 60.6349 5.39545C62.0349 5.09545 62.9349 3.59545 62.6349 2.19545C62.7349 2.09545 62.7349 2.09545 62.6349 1.99545Z"
      fill={color}
    />
  </svg>
);

export default TobiiLogo;
