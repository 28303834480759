import { useEffect, useState } from "react";

export const useDeviceDetect = () => {
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  useEffect(() => {
    const detectTouch = () => {
      const hasTouch = "ontouchstart" in window || navigator.maxTouchPoints > 0;
      const hasFinePointer = window.matchMedia("(pointer: fine)").matches;
      const isSmallScreen = window.matchMedia("(max-width: 900px)").matches;

      setIsTouchDevice(hasTouch && (!hasFinePointer || isSmallScreen));
    };

    detectTouch();
    window.addEventListener("resize", detectTouch);

    return () => window.removeEventListener("resize", detectTouch);
  }, []);

  return { isTouchDevice };
};

export const useMediaQuery = (query: string): boolean => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    const handleChange = () => setMatches(matchMedia.matches);
    handleChange();

    matchMedia.addEventListener("change", handleChange);
    return () => matchMedia.removeEventListener("change", handleChange);
  }, [query]);

  return matches;
};
