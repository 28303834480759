import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { TechSpecBlock } from "../../lib/storyblok/types/blocks/TechSpecBlock";
import { default as TechSpecComponent } from "../../ui/TechSpec";
import React, { Fragment, useState } from "react";
import ConditionalWrapper from "../ConditionalWrapper";
import { storyblokEditable } from "@storyblok/react";
import Markdown from "../../ui/Markdown";
import { useTranslation } from "next-i18next";

const TechSpec: StoryblokBlockComponent<TechSpecBlock> = ({
  title,
  preamble,
  sections,
  spacing,
}) => {
  const [expandedIndexes, setExpandedIndexes] = useState(
    sections.map(() => false)
  );

  const { t } = useTranslation();

  const toggleExpanded = (i: number) => {
    const indexes = [...expandedIndexes];
    indexes[i] = !indexes[i];
    setExpandedIndexes(indexes);
  };

  const toggleExpandAll = () => {
    setExpandedIndexes(expandedIndexes.map(() => !isAllExpanded()));
  };

  const isAllExpanded = () => expandedIndexes.every((val) => val);

  const rowHasValues = (row) => {
    return row.some((cell) => cell.value !== "");
  };

  const {
    ExpandButton,
    Expandable,
    Tables,
    Table,
    TableNote,
    TableNotes,
    TableRow,
  } = TechSpecComponent;

  return (
    <TechSpecComponent
      title={title}
      text={preamble}
      spacing={spacing}
      expandButton={
        sections?.length > 1 ? (
          <ExpandButton
            onClick={() => toggleExpandAll()}
            isAllExpanded={isAllExpanded()}
          >
            {isAllExpanded() ? t("close-all") : t("expand-all")}
          </ExpandButton>
        ) : null
      }
    >
      {sections.map((section, i) => {
        const {
          tableData,
          boldColumns,
          featuresLeft,
          featuresRight,
          footnotes,
          title,
          _uid,
        } = section;

        return (
          <div key={section._uid} {...storyblokEditable(section)}>
            <ConditionalWrapper
              wrapper={(children) => (
                <Expandable
                  key={_uid}
                  title={title}
                  isExpanded={sections.length === 1 || expandedIndexes[i]}
                  toggleExpanded={() => toggleExpanded(i)}
                  isSingle={sections.length === 1}
                >
                  {children}
                </Expandable>
              )}
              shouldWrap
            >
              <>
                {featuresLeft?.length === 0 &&
                  featuresRight?.length === 0 &&
                  tableData && (
                    <Table>
                      {tableData.thead &&
                        tableData.thead.length > 0 &&
                        rowHasValues(tableData.thead) && (
                          <thead>
                            <TableRow>
                              {tableData.thead.map((th) => (
                                <th key={th._uid}>{th.value}</th>
                              ))}
                            </TableRow>
                          </thead>
                        )}
                      {tableData.tbody && tableData.tbody.length > 0 && (
                        <tbody>
                          {tableData.tbody.map((tr) => (
                            <TableRow key={tr._uid}>
                              {tr.body?.map((td, i) => {
                                const Component = i === 0 ? "th" : "td";

                                const isBold =
                                  boldColumns &&
                                  boldColumns.find(
                                    (column) => column === String(i + 1)
                                  );

                                const tdStyle = isBold
                                  ? {
                                      fontWeight: "bold",
                                      color: "var(--foreground-i1)",
                                    }
                                  : {};

                                return (
                                  <Component key={td._uid} style={tdStyle}>
                                    {td.value}
                                  </Component>
                                );
                              })}
                            </TableRow>
                          ))}
                        </tbody>
                      )}
                    </Table>
                  )}
                {((featuresLeft && featuresLeft.length > 0) ||
                  (featuresRight && featuresRight.length > 0)) && (
                  <Tables>
                    {featuresLeft && (
                      <Table key={i}>
                        <tbody>
                          {featuresLeft?.map((row) => (
                            <TableRow key={row._uid}>
                              <th>
                                <Markdown>{row.feature}</Markdown>
                              </th>
                              <td>
                                <Markdown>{row.specification}</Markdown>
                              </td>
                            </TableRow>
                          ))}
                        </tbody>
                      </Table>
                    )}
                    {featuresRight && featuresRight.length > 0 && (
                      <Table>
                        <tbody>
                          {featuresRight.map(
                            ({ feature, specification, _uid }) => (
                              <TableRow key={_uid}>
                                <th>
                                  <Markdown>{feature}</Markdown>
                                </th>
                                <td>
                                  <Markdown>{specification}</Markdown>
                                </td>
                              </TableRow>
                            )
                          )}
                        </tbody>
                      </Table>
                    )}
                  </Tables>
                )}
                1
                {footnotes && footnotes.length > 0 && (
                  <TableNotes>
                    {footnotes.map(({ text, _uid }) => (
                      <TableNote key={_uid}>
                        <Markdown components={{ p: Fragment }}>{text}</Markdown>
                      </TableNote>
                    ))}
                  </TableNotes>
                )}
              </>
            </ConditionalWrapper>
          </div>
        );
      })}
    </TechSpecComponent>
  );
};

export default TechSpec;
