import { useRef, useEffect } from "react";
import { DynamicBlocks } from "../../DynamicBlock";
import { useAnchorMenuContext } from "./AnchorMenuContext";
import { Section } from "./splitIntoSections";

type Props = { section: Section };

const AnchorMenuSection = ({ section }: Props) => {
  const { title, href, blocks } = section;

  const ref = useRef<HTMLElement>(null);

  const { setVisibleSections } = useAnchorMenuContext();

  useEffect(() => {
    if (href === "") return;

    const observer = new IntersectionObserver(
      (entries) => {
        const { isIntersecting } = entries[0];
        setVisibleSections((prev) =>
          isIntersecting ? [...prev, href] : prev.filter((s) => s !== href)
        );
      },
      {
        rootMargin: "-150px 0px 0px 0px",
      }
    );

    const currentRef = ref.current;

    if (currentRef) observer.observe(currentRef);
    return () => {
      if (currentRef) observer.unobserve(currentRef);
    };
  }, [href, setVisibleSections]);

  return (
    <section ref={ref} key={title} id={href}>
      <h2 className="visuallyHidden">{title}</h2>
      <DynamicBlocks blocks={blocks} />
    </section>
  );
};

export default AnchorMenuSection;
