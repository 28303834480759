import { ReactNode, useState } from "react";
import Head from "next/head";
import cn from "classnames";
import Icon from "../Icon";
import s from "./Video.module.scss";

type Props = {
  url: string;
  thumbnailImage?: ReactNode;
  title?: string;
  description?: string;
  duration?: string;
  autoplay?: boolean;
  startTime?: string;
  thumbnailOverlay: boolean;
  provider: string;
  featured?: boolean;
  className?: string;
  schemaImage?: string;
  schemaTitle?: string;
  schemaDescription?: string;
  schemaDate?: string;
  schemaDuration?: string;
};

const Video = ({
  url,
  thumbnailImage,
  title,
  description,
  duration,
  autoplay,
  startTime,
  thumbnailOverlay,
  provider,
  className,
  schemaImage,
  schemaTitle,
  schemaDescription,
  schemaDate,
  schemaDuration,
}: Props) => {
  const [isClicked, setIsClicked] = useState(false);

  const getVideoUrl = (url) => {
    const newUrl = new URL(url);

    switch (provider) {
      case "youtube":
        if (thumbnailImage) {
          newUrl.searchParams.append("autoplay", "1");
          newUrl.searchParams.append("mute", "1");
        }
        if (startTime) {
          newUrl.searchParams.append("start", startTime);
        }
        return newUrl.toString();
      case "twentyThree":
        if (thumbnailImage) {
          newUrl.searchParams.append("autoplay", "1");
          newUrl.searchParams.append("mute", "1");
          newUrl.searchParams.append("mutedAutoPlay", "1");
          newUrl.searchParams.append("autoMute", "1");
          newUrl.searchParams.append("ambient", "1");
          newUrl.searchParams.append("showTray", "1");
        }
        if (startTime) {
          newUrl.searchParams.append("start", startTime);
        }
        return newUrl.toString();
      default:
        return url;
    }
  };

  const videoSchema = {
    "@context": "https://schema.org",
    "@type": "VideoObject",
    name: schemaTitle || "",
    description: schemaDescription || "",
    thumbnailUrl: schemaImage || "",
    uploadDate: schemaDate || "",
    contentUrl: url,
    embedUrl: getVideoUrl(url),
    duration: schemaDuration || "",
  };

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(videoSchema) }}
        />
      </Head>

      <div className={s.video}>
        {title && <h2 className={s.title}>{title}</h2>}
        <div className={cn(s.wrapper, className)}>
          {isClicked || !thumbnailImage || autoplay ? (
            <iframe
              src={url && getVideoUrl(url)}
              width="100%"
              height="100%"
              title="Embed video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
            />
          ) : (
            <>
              {thumbnailImage && (
                <>
                  {thumbnailImage}
                  <div className={thumbnailOverlay ? s.overlay : ""} />
                </>
              )}
              <button onClick={() => setIsClicked(true)}>
                <span className={s.circle}>
                  <Icon icon="play" width={24} color={s.iconcolor} />
                </span>
              </button>
              {duration && <span className={s.duration}>{duration}</span>}
            </>
          )}
        </div>
        {description && <p className={s.description}>{description}</p>}
      </div>
    </>
  );
};

export default Video;
