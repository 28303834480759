import React from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import TestimonialsUi from "../../ui/Testimonials";
import { TestimonialsBlock } from "../../lib/storyblok/types/blocks/TestimonialsBlock";
import { storyblokEditable } from "@storyblok/react";
import ImageVaultImage from "../ImageVaultImage";

const Testimonials: StoryblokBlockComponent<TestimonialsBlock> = ({
  title,
  spacing,
  testimonials,
}) => {
  const { Testimonial } = TestimonialsUi;

  return (
    <TestimonialsUi title={title} spacing={spacing}>
      {testimonials?.map((testimonial) => (
        <div
          style={{ display: "contents" }}
          key={testimonial._uid}
          {...storyblokEditable(testimonial)}
        >
          <Testimonial
            quote={testimonial.quote}
            name={testimonial.name}
            roleAndCompany={testimonial.roleAndCompany}
            logo={
              testimonial.logo.item && (
                <ImageVaultImage
                  sizes="33vw"
                  image={testimonial.logo}
                  layout="intrinsic"
                />
              )
            }
          />
        </div>
      ))}
    </TestimonialsUi>
  );
};

export default Testimonials;
