import React from "react";
import { VideoPlayerBlock } from "../../lib/storyblok/types/blocks/VideoPlayerBlock";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { DynamicBlock } from "../DynamicBlock";

import { Container } from "../../ui/Video";

const VideoPlayer: StoryblokBlockComponent<VideoPlayerBlock> = ({
  video,
  featured,
  spacing,
}) => (
  <Container featured={featured} spacing={spacing}>
    {video[0] && <DynamicBlock content={video[0]} overrides={""} />}
  </Container>
);

export default VideoPlayer;
