import algoliasearch, { SearchClient } from "algoliasearch";

export const initAlgoliaSearch = (): SearchClient => {
  const ID = process.env.NEXT_PUBLIC_ALGOLIA_ID;
  const KEY = process.env.NEXT_PUBLIC_ALGOLIA_KEY;

  if (typeof ID === "undefined") {
    throw new Error("Missing Algolia ID");
  }
  if (typeof KEY === "undefined") {
    throw new Error("Missing Algolia Key");
  }

  return algoliasearch(ID, KEY);
};

export const initAlgoliaIndecies = () => {
  const EN_INDEX = process.env.NEXT_PUBLIC_ALGOLIA_GLOBAL_SEARCH_INDEX_EN;
  const ZH_INDEX = process.env.NEXT_PUBLIC_ALGOLIA_GLOBAL_SEARCH_INDEX_ZH;
  const JA_INDEX = process.env.NEXT_PUBLIC_ALGOLIA_GLOBAL_SEARCH_INDEX_JA;

  if (typeof EN_INDEX === "undefined") {
    throw new Error("Missing Algolia Global Search Index for English");
  }
  if (typeof ZH_INDEX === "undefined") {
    throw new Error("Missing Algolia Global Search Index for Chinese");
  }
  if (typeof JA_INDEX === "undefined") {
    throw new Error("Missing Algolia Global Search Index for Japanese");
  }
  return {
    en: EN_INDEX,
    zh: ZH_INDEX,
    ja: JA_INDEX,
  } as const;
};
export const initAlgoliaInvestorIndecies = () => {
  const EN_INDEX = process.env.NEXT_PUBLIC_ALGOLIA_INVESTOR_SEARCH_INDEX_EN;
  const SV_INDEX = process.env.NEXT_PUBLIC_ALGOLIA_INVESTOR_SEARCH_INDEX_SV;

  if (typeof EN_INDEX === "undefined") {
    throw new Error("Missing Algolia Pressrelease Search Index for English");
  }
  if (typeof SV_INDEX === "undefined") {
    throw new Error("Missing Algolia Pressrelease Search Index for Swedish");
  }
  return {
    en: EN_INDEX,
    sv: SV_INDEX,
  } as const;
};
