import React, { useState } from "react";
import Image from "next/image";
import VideoUi from "../../ui/Video";
import { YoutubeVideoBlock } from "../../lib/storyblok/types/blocks/YoutubeVideoBlock";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import ImageVaultImage from "../ImageVaultImage";
import { imageSizes } from "../../lib/utils/imageSizes";
import { useViewmodel } from "../../lib/storyblok/ViewmodelContext";

import {
  getSchemaImageYoutube,
  getSchemaDuration,
  getSchemaTitle,
  getSchemaDescription,
} from "../../lib/utils/schema/video";

const YouTubeVideo: StoryblokBlockComponent<YoutubeVideoBlock> = ({
  url,
  thumbnail,
  thumbnailOverlay,
  startTime,
  title,
  description,
  duration,
  autoplay,
  component,
  schemaTitle,
  schemaDescription,
  schemaDate,
  schemaDuration,
}) => {
  const { locale, site } = useViewmodel();
  const [useYtThumbFallback, setUseYtThumbFallback] = useState(false);

  const resolveYouTubeUrl = (url) => {
    const newUrl = new URL(url);
    const id = new URLSearchParams(newUrl.search).get("v");

    return new URL(
      newUrl.hostname.includes("embed")
        ? url
        : `https://www.youtube.com/embed/${id}`
    );
  };

  const resolveYouTubeId = (url) => {
    const newUrl = new URL(url);

    if (newUrl.pathname.includes("embed")) {
      return url.split("embed/")[1];
    } else {
      return new URLSearchParams(newUrl.search).get("v");
    }
  };

  const getUrl = () => {
    const newUrl = resolveYouTubeUrl(url);

    if (thumbnail) {
      newUrl?.searchParams.append("autoplay", "1");
      newUrl?.searchParams.append("mute", "1");
    }
    if (startTime) {
      newUrl?.searchParams.append("start", startTime);
    }
    return newUrl?.toString();
  };

  const getYouTubeThumbnail = () => {
    const videoId = resolveYouTubeId(url);
    const maxResThumbnail = `https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`;
    const hqDefaultThumbnail = `https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`;

    return (
      <Image
        layout="fill"
        objectFit="cover"
        src={useYtThumbFallback ? hqDefaultThumbnail : maxResThumbnail}
        onError={() => {
          // Fallback to HQ default if maxresdefault is not available
          setUseYtThumbFallback(true);
        }}
        alt={title}
      />
    );
  };

  return (
    <VideoUi
      url={url && getUrl()}
      thumbnailImage={
        !thumbnail?.item ? (
          getYouTubeThumbnail()
        ) : (
          <ImageVaultImage
            sizes={imageSizes("100vw", { xl: "1200px" })}
            image={thumbnail}
            layout="fill"
          />
        )
      }
      thumbnailOverlay={thumbnailOverlay}
      title={title}
      description={description}
      duration={duration}
      autoplay={autoplay}
      startTime={startTime}
      provider={component}
      schemaImage={getSchemaImageYoutube(
        thumbnail,
        resolveYouTubeId(url),
        useYtThumbFallback,
        site,
        locale
      )}
      schemaTitle={getSchemaTitle(title, schemaTitle)}
      schemaDescription={getSchemaDescription(description, schemaDescription)}
      schemaDate={schemaDate}
      schemaDuration={getSchemaDuration(schemaDuration)}
    />
  );
};

export default YouTubeVideo;
