import { forwardRef, ReactNode, useEffect, useState } from "react";
import cn from "classnames";

import s from "./GlobalAlert.module.scss";
import c from "../../styles/container.module.scss";
import Icon from "../Icon";
import useExpandable from "../../hooks/useExpandable";
import useMediaQuery from "../../hooks/useMatchMedia";

type Props = {
  onCloseClick: () => void;
  icon: ReactNode;
  title: string;
  text: string;
  showMoreLabel: string;
  showLessLabel: string;
  button: ReactNode;
  close: string;
  style: "white" | "red" | "black" | "signature";
};

const GlobalAlert = forwardRef<HTMLDivElement, Props>(
  (
    {
      style = "white",
      onCloseClick,
      icon,
      title,
      close,
      text,
      button,
      showLessLabel,
      showMoreLabel,
    },
    ref
  ) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const contentRef = useExpandable<HTMLDivElement>(isExpanded);

    const isMobile = useMediaQuery("(max-width: 768px)");

    useEffect(() => {
      if (!isMobile && !isExpanded) {
        setIsExpanded(true);
      }
    }, [isMobile, isExpanded]);

    return (
      <div
        ref={ref}
        className={cn(s.alert, {
          [s.red]: style === "red",
          [s.black]: style === "black",
          [s.signature]: style === "signature",
        })}
      >
        <div className={cn(s.wrapper, c.wide)}>
          <div className={s.content}>
            <div className={s.titleWrapper}>
              {icon && <span className={s.icon}>{icon}</span>}
              {title && <p>{title}</p>}
            </div>
            <div
              ref={contentRef}
              className={cn({ [s.expandableContent]: isMobile })}
            >
              <p className={cn({ [s.marginBottom]: button && isMobile })}>
                {text}
              </p>
              {isMobile && button && button}
            </div>
            {isMobile && (
              <button
                className={s.expandButton}
                onClick={() => setIsExpanded(!isExpanded)}
              >
                {isExpanded ? showLessLabel : showMoreLabel}
                <Icon
                  icon="caret-down"
                  width={12}
                  color={style === "white" ? "black" : "white"}
                  className={cn(s.caret, { [s.rotated]: isExpanded })}
                />
              </button>
            )}
          </div>
          {!isMobile && button && button}
          <button
            onClick={onCloseClick}
            className={s.closeButton}
            title={close}
          >
            <Icon icon="cancel" color={style === "white" ? "black" : "white"} />
          </button>
        </div>
      </div>
    );
  }
);

GlobalAlert.displayName = "GlobalAlert";

export default GlobalAlert;
