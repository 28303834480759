const resetProgressBar = (index, intervalId) => {
  const progressBar = document.getElementById(`progress-${index}`);
  if (progressBar) {
    clearInterval(intervalId[index]);
    progressBar.style.width = "0%";
  }
};

const startProgressBar = (index, setIntervalId) => {
  const progressBar = document.getElementById(`progress-${index}`);

  if (progressBar) {
    let width = 0;

    const interval = 10;

    const totalDuration = 5000;

    const increment = (interval / totalDuration) * 100;

    const progressBarInterval = setInterval(() => {
      width += increment;
      progressBar.style.width = `${width}%`;

      if (width >= 100) {
        clearInterval(progressBarInterval);
      }
    }, interval);
    setIntervalId((prev) => {
      return { ...prev, [index]: progressBarInterval };
    });
  }
};

export { resetProgressBar, startProgressBar };
