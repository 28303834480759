import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { HeaderSubNavigationTab as TabType } from "../../../lib/storyblok/types/blocks/header/HeaderSubNavigationTab";
import {
  DesktopSubNavigationPanel,
  DesktopSubNavigationPanelList,
  DesktopSubNavigationPanelDiv,
  DesktopSubNavigationPanelLink,
  DesktopSubNavigationPanelItemWrapper,
  DesktopSubNavigationPanelItemWrapperInner,
  DesktopSubNavigationGroup,
  DesktopSubNavigationCTA,
  DesktopSubNavigationSidebar,
  DesktopSubNavigationTab,
  DesktopSubNavigationTabList,
  DesktopSubNavigationTabItem,
} from "../../../ui/Header";
import Image from "next/image";
import ImageVaultImage from "../../../components/ImageVaultImage";
import { useLinks } from "../../../lib/storyblok/useLinks";
import SubNavigationItemList from "./components/SubNavigationItemList";
import s from "../../../ui/Header/components/desktop/DesktopMenu.module.scss";

type Props = {
  item: TabType;
  onMouseLeave?: () => void;
};

const HeaderSubNavigationTab: React.FC<Props> = ({ item }) => {
  const resolveLink = useLinks();

  // Tabs
  const [activeDesktopSubNavTabItemIndex, setActiveDesktopSubNavTabItemIndex] =
    useState<number>(0);
  const tabRefs = useRef<HTMLDivElement[]>([]);
  const panelRefs = useRef<HTMLDivElement[]>([]);
  const [shouldAnimateSidebar, setShouldAnimateSidebar] = useState(false);
  const groupAnimationCount = useRef(0);

  const handleTabItemClick = (index: number) => {
    setActiveDesktopSubNavTabItemIndex(index);
    setActiveDesktopSubNavPanelItemIndex(0);
    setShouldAnimateSidebar(false);
    groupAnimationCount.current = 0;
  };

  const handleGroupAnimationComplete = () => {
    groupAnimationCount.current += 1;
    const activeTabItem = item.items[activeDesktopSubNavTabItemIndex];
    const activePanelItem =
      activeTabItem?.items[activeDesktopSubNavPanelItemIndex];
    const totalGroups = activePanelItem?.body?.length || 0;

    if (groupAnimationCount.current === totalGroups) {
      setShouldAnimateSidebar(true);
    }
  };

  // Panels
  const [
    activeDesktopSubNavPanelItemIndex,
    setActiveDesktopSubNavPanelItemIndex,
  ] = useState<number>(0);

  const handlePanelItemClick = (index: number) => {
    if (index !== activeDesktopSubNavPanelItemIndex) {
      const subPanelItem =
        item.items[activeDesktopSubNavTabItemIndex]?.items[index];
      const hasContent =
        !!subPanelItem?.body?.length ||
        !!subPanelItem?.cta?.length ||
        !!subPanelItem?.sidebar?.length;

      if (hasContent) {
        setActiveDesktopSubNavPanelItemIndex(index);
        setShouldAnimateSidebar(false);
        groupAnimationCount.current = 0;
      }
    }
  };

  useEffect(() => {
    setActiveDesktopSubNavPanelItemIndex(0);
    setActiveDesktopSubNavTabItemIndex(0);
    setShouldAnimateSidebar(false);
    groupAnimationCount.current = 0;
  }, [item]);

  const [tabActiveStyle, settabActiveStyle] = useState<React.CSSProperties>({
    left: 0,
    width: 0,
  });

  useLayoutEffect(() => {
    const currentTab = tabRefs.current[activeDesktopSubNavTabItemIndex];
    if (currentTab) {
      const { offsetLeft, offsetWidth } = currentTab;
      settabActiveStyle({ left: offsetLeft, width: offsetWidth });
    }
  }, [activeDesktopSubNavTabItemIndex]);

  const [panelActiveStyle, setPanelActiveStyle] = useState<React.CSSProperties>(
    {
      top: 0,
      height: 0,
    }
  );

  useLayoutEffect(() => {
    const currentTab = panelRefs.current[activeDesktopSubNavPanelItemIndex];
    if (currentTab) {
      const { offsetTop, offsetHeight } = currentTab;
      setPanelActiveStyle({ top: offsetTop, height: offsetHeight });
    }
  }, [activeDesktopSubNavPanelItemIndex]);

  return (
    <DesktopSubNavigationTab>
      <DesktopSubNavigationTabList activeStyle={tabActiveStyle}>
        {item.items?.map((tabNavListItem, k) => (
          <DesktopSubNavigationTabItem
            key={tabNavListItem._uid}
            title={tabNavListItem.title}
            onClick={() => handleTabItemClick(k)}
            isActive={activeDesktopSubNavTabItemIndex === k}
            ref={(el) => (tabRefs.current[k] = el!)}
          />
        ))}
      </DesktopSubNavigationTabList>
      {item.items?.map((tabItemList, i) =>
        activeDesktopSubNavTabItemIndex === i ? (
          <DesktopSubNavigationPanel key={tabItemList._uid}>
            <DesktopSubNavigationPanelList activeStyle={panelActiveStyle}>
              {tabItemList.items?.map((subItemList, m) => {
                const hasContent =
                  !!subItemList.body?.length ||
                  !!subItemList.cta?.length ||
                  !!subItemList.sidebar?.length;
                return hasContent ? (
                  <DesktopSubNavigationPanelDiv
                    key={subItemList._uid}
                    title={subItemList.title}
                    onClick={() => handlePanelItemClick(m)}
                    isActive={activeDesktopSubNavPanelItemIndex === m}
                    index={m}
                    ref={(el) => (panelRefs.current[m] = el!)}
                  />
                ) : (
                  <DesktopSubNavigationPanelLink
                    key={subItemList._uid}
                    title={subItemList.title}
                    link={resolveLink(subItemList.link)}
                    index={m}
                  />
                );
              })}
            </DesktopSubNavigationPanelList>
            <DesktopSubNavigationPanelItemWrapper>
              {tabItemList.items?.map((subItem, j) => {
                const hasContent =
                  !!subItem.body?.length ||
                  !!subItem.cta?.length ||
                  !!subItem.sidebar?.length;

                return hasContent && activeDesktopSubNavPanelItemIndex === j ? (
                  <DesktopSubNavigationPanelItemWrapperInner
                    key={subItem._uid}
                    hasSidebar={
                      (subItem.cta?.length || subItem.sidebar?.length) > 0
                    }
                  >
                    <div>
                      {subItem.body?.map((bodyItem, k) => (
                        <DesktopSubNavigationGroup
                          key={bodyItem._uid}
                          title={bodyItem.title}
                          description={bodyItem.description}
                          link={resolveLink(bodyItem.link)}
                          index={k}
                          icon={
                            bodyItem.icon ? (
                              <Image
                                src={`/svg/icons/${bodyItem.icon}.svg`}
                                width={16}
                                height={16}
                                alt={bodyItem.title}
                              />
                            ) : null
                          }
                          onGroupAnimationComplete={
                            handleGroupAnimationComplete
                          }
                        >
                          <SubNavigationItemList items={bodyItem.items || []} />
                        </DesktopSubNavigationGroup>
                      ))}
                    </div>
                    <div className={s.sidebarWrapper}>
                      {subItem.cta?.map((ctaItem) => (
                        <DesktopSubNavigationCTA
                          key={ctaItem._uid}
                          title={ctaItem.title}
                          description={ctaItem.description}
                          link={resolveLink(ctaItem.link)}
                          image={
                            <ImageVaultImage
                              sizes="360px"
                              image={ctaItem.image}
                              layout="fill"
                            />
                          }
                          animateSidebar={shouldAnimateSidebar}
                        />
                      ))}
                      {subItem.sidebar?.map((sidebarItem, m) => (
                        <DesktopSubNavigationSidebar
                          key={sidebarItem._uid}
                          title={sidebarItem.title}
                          icon={
                            sidebarItem.icon ? (
                              <Image
                                src={`/svg/icons/${sidebarItem.icon}.svg`}
                                width={16}
                                height={16}
                                alt={sidebarItem.title}
                              />
                            ) : null
                          }
                          index={m}
                          animateSidebar={shouldAnimateSidebar}
                        >
                          <SubNavigationItemList
                            items={sidebarItem.items || []}
                          />
                        </DesktopSubNavigationSidebar>
                      ))}
                    </div>
                  </DesktopSubNavigationPanelItemWrapperInner>
                ) : null;
              })}
            </DesktopSubNavigationPanelItemWrapper>
          </DesktopSubNavigationPanel>
        ) : null
      )}
    </DesktopSubNavigationTab>
  );
};

export default HeaderSubNavigationTab;
