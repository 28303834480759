import React, { forwardRef, HTMLProps } from "react";
import cn from "classnames";
import s from "./DesktopMenu.module.scss";

type Props = {
  title: string;
  isActive: boolean;
  onClick: () => void;
} & HTMLProps<HTMLDivElement>;

export const DesktopSubNavigationTabItem = forwardRef<HTMLDivElement, Props>(
  ({ title, isActive, onClick, ...divProps }, ref) => {
    const handleClick = () => {
      if (!isActive) {
        onClick();
      }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (!isActive && (e.key === "Enter" || e.key === " ")) {
        onClick();
        e.preventDefault();
      }
    };

    return (
      <li>
        <div
          className={cn(s.DesktopSubNavigationTabItem, {
            [s.active]: isActive,
            [s.inactive]: !isActive,
          })}
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          role={isActive ? "none" : "button"}
          tabIndex={isActive ? -1 : 0}
          aria-disabled={isActive}
          ref={ref}
          {...divProps}
        >
          {title && <span>{title}</span>}
        </div>
      </li>
    );
  }
);

DesktopSubNavigationTabItem.displayName = "DesktopSubNavigationTabItem";
