import React from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import DatablockUi from "../../ui/Datablock";
import { DatablockBlock } from "../../lib/storyblok/types/blocks/DatablockBlock";
import { useLinks } from "../../lib/storyblok/useLinks";
import { useViewmodel } from "../../lib/storyblok/ViewmodelContext";

const Datablock: StoryblokBlockComponent<DatablockBlock> = ({
  title,
  description,
  widgets,
  outerSpacing,
}) => {
  const { locale, globalLinks } = useViewmodel();
  const resolveLink = useLinks();

  return (
    <DatablockUi
      title={title}
      description={description}
      widgets={widgets}
      locale={locale}
      outerSpacing={outerSpacing}
      eventsFilterPage={
        globalLinks.investorEventFilterPage &&
        resolveLink(globalLinks.investorEventFilterPage)
      }
      pressReleaseFilterPage={
        globalLinks.pressreleaseFilterPage &&
        resolveLink(globalLinks.pressreleaseFilterPage)
      }
    />
  );
};

export default Datablock;
