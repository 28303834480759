import React, { ReactNode } from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { HeroBlock } from "../../lib/storyblok/types/blocks/HeroBlock";
import Hero from "../../ui/Hero";
import Button from "../../ui/Button";
import ImageVaultImage from "../ImageVaultImage";
import Link from "next/link";
import Image from "next/image";
import WaveDivider from "../../ui/WaveDivider";
import { useLinks } from "../../lib/storyblok/useLinks";

import Wave from "../../public/images/divider_top.png";

type Props = {
  anchorLinksMenu?: ReactNode;
};

const HeroBlock: StoryblokBlockComponent<HeroBlock & Props> = ({
  component,
  title,
  description,
  label,
  foregroundImage,
  backgroundImage,
  video,
  mobileVideo,
  fallbackImage,
  buttons,
  hideContentOnDesktop,
  hideContentOnMobile,
  anchorLinksMenu,
  inverted,
  noOverlay,
  divider,
  isStartPage,
}) => {
  const resolveLink = useLinks();

  const buttonCollection = buttons?.map((button) => (
    <Link
      key={button._uid}
      href={button.buttonLink ? resolveLink(button.buttonLink) : ""}
      passHref
    >
      <Button
        variant={button.buttonType ?? "default"}
        href={resolveLink(button.buttonLink)}
      >
        {button.buttonText}
      </Button>
    </Link>
  ));

  return (
    <>
      <Hero
        title={title}
        description={description}
        label={label}
        foregroundImage={
          foregroundImage?.item && (
            <ImageVaultImage
              priority
              image={foregroundImage}
              layout="responsive"
              sizes="100vw"
            />
          )
        }
        backgroundImage={
          backgroundImage?.item && (
            <ImageVaultImage
              image={backgroundImage}
              layout="fill"
              sizes="500vw" //todo optimize
              priority
            />
          )
        }
        video={
          video?.filename && (
            <Hero.VideoPlayer
              key="desktop"
              videoUrl={video?.filename}
              noOverlay={noOverlay}
            />
          )
        }
        mobileVideo={
          mobileVideo?.filename && (
            <Hero.VideoPlayer
              key="mobile"
              videoUrl={mobileVideo?.filename}
              noOverlay={noOverlay}
            />
          )
        }
        fallbackImage={
          fallbackImage?.item && (
            <ImageVaultImage
              image={fallbackImage}
              layout="fill"
              sizes="500vw" //todo optimize
              priority
            />
          )
        }
        small={component.includes("Small")}
        buttons={buttonCollection}
        hideContentOnDesktop={hideContentOnDesktop}
        hideContentOnMobile={hideContentOnMobile}
        hasAnchorMenu={!!anchorLinksMenu}
        inverted={inverted}
        noOverlay={noOverlay}
        divider={
          divider && (
            <WaveDivider
              image={<Image alt="" src={Wave} quality={100} sizes="100vw" />}
            />
          )
        }
        isStartPage={isStartPage}
      />
      {anchorLinksMenu && anchorLinksMenu}
    </>
  );
};

export default HeroBlock;
