import React, { forwardRef, HTMLProps, ReactNode } from "react";
import cn from "classnames";
import s from "./DesktopMenu.module.scss";

type Props = {
  children: ReactNode;
} & HTMLProps<HTMLDivElement>;

export const DesktopSubNavigationPanelItemWrapper = forwardRef<
  HTMLDivElement,
  Props
>(({ children, ...props }, ref) => {
  return (
    <div
      className={cn(s.DesktopSubNavigationPanelWrapper)}
      ref={ref}
      {...props}
    >
      {children}
    </div>
  );
});

DesktopSubNavigationPanelItemWrapper.displayName =
  "DesktopSubNavigationPanelItemWrapper";
