import { useEffect, useState } from "react";
import Item from "./Item";
import { useTranslation } from "next-i18next";

const ShareItems = () => {
  const { t } = useTranslation();

  const [pageUrl, setPageUrl] = useState<string>("");

  useEffect(() => {
    setPageUrl(window.location.href);
  }, []);

  return (
    <>
      <Item
        icon="facebook"
        href={"https://www.facebook.com/sharer/sharer.php?u=" + pageUrl}
        title={t("share-on-facebook")}
      />
      <Item
        icon="linkedin"
        href={"https://www.linkedin.com/shareArticle?mini=true&url=" + pageUrl}
        title={t("share-on-linkedin")}
      />
      <Item
        icon="twitter"
        href={"https://twitter.com/intent/tweet?url=" + pageUrl}
        title={t("share-on-x")}
      />
    </>
  );
};

export default ShareItems;
