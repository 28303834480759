import { ReactNode, Children, useEffect, useState } from "react";
import cn from "classnames";
import s from "./StartPageCTA.module.scss";
import c from "../../styles/container.module.scss";
import useIsInViewport from "use-is-in-viewport";

type Props = {
  title: string;
  preamble?: string;
  buttons?: ReactNode;
  image: ReactNode;
  noFadeIn?: boolean;
};

const StartPageCTA = ({ title, preamble, buttons, image, noFadeIn }: Props) => {
  const [isInViewport, targetRef] = useIsInViewport({ threshold: 50 });
  const [hasBeenInViewport, setHasBeenInViewport] = useState(false);

  useEffect(() => {
    if (isInViewport && !hasBeenInViewport) {
      setTimeout(() => {
        setHasBeenInViewport(true);
      }, 1000);
    }
  }, [isInViewport, hasBeenInViewport]);

  return (
    <section className={s.startCta}>
      <div
        ref={targetRef}
        className={cn(s.wrapper, {
          [s.noFadeIn]: noFadeIn || hasBeenInViewport,
          [s.inViewport]: isInViewport,
        })}
      >
        {title && <h2 className={cn(s.title, c.medium)}>{title}</h2>}
        {preamble && <p className={cn(s.preamble, c.medium)}>{preamble}</p>}
        {Children.count(buttons) > 0 && (
          <div className={s.buttons}>{buttons}</div>
        )}
        {image && <div className={cn(s.image, c.narrow)}>{image}</div>}
      </div>
    </section>
  );
};

export default StartPageCTA;
