import React from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import ColumnGridUi from "../../ui/ColumnGrid";
import { ColumnGridBlock } from "../../lib/storyblok/types/blocks/ColumnGridBlock";
import { DynamicBlock } from "../DynamicBlock";
import { useLinks } from "../../lib/storyblok/useLinks";
import Button from "../../ui/Button";
import Link from "next/link";

const ColumnGrid: StoryblokBlockComponent<ColumnGridBlock> = ({
  title,
  description,
  items,
  buttons,
  centered,
  twoColumns,
  spacing,
  outerSpacing,
  style,
  width,
}) => {
  const resolveLink = useLinks();

  const buttonCollection = buttons?.map((button) => (
    <Link
      key={button._uid}
      href={button.buttonLink ? resolveLink(button.buttonLink) : ""}
      passHref
    >
      <Button
        variant={button.buttonType ?? "default"}
        href={resolveLink(button.buttonLink)}
      >
        {button.buttonText}
      </Button>
    </Link>
  ));

  return (
    <ColumnGridUi
      title={title}
      description={description}
      twoColumns={twoColumns}
      buttons={buttonCollection}
      centered={centered}
      spacing={spacing}
      outerSpacing={outerSpacing}
      style={style}
      width={width}
    >
      {items.map((item) => (
        <DynamicBlock
          key={item._uid}
          content={{ ...item, style: style }}
          overrides={""}
        />
      ))}
    </ColumnGridUi>
  );
};

export default ColumnGrid;
