import { useCallback, useEffect, useRef, useState } from "react";
import { DownloadsGatedDownloadItemBlock } from "../../lib/storyblok/types/blocks/DownloadsGatedDownloadItem";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import Downloads from "../../ui/Downloads/Downloads";

const { GatedDownload } = Downloads;

const DownloadsGatedDownloadItem: StoryblokBlockComponent<
  DownloadsGatedDownloadItemBlock
> = ({ _uid, icon, title, subtitle, form }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [height, setHeight] = useState(800);

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  const onMessage = useCallback(
    (message) => {
      if (
        (form[0] &&
          message.data.type ===
            "pardotFormResize-" + form[0].formUrl + `#${_uid}` &&
          message.data.height) ||
        (message.data.type ===
          "ctaFormResize-" + form[0].formUrl + `#${_uid}` &&
          message.data.height)
      ) {
        setTimeout(() => {
          setHeight(message.data.height + 2);
        }, 0);
      }

      if (message.data.type === "pardotFormSubmit") {
        modalRef.current &&
          modalRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
      }
    },
    [_uid, form]
  );

  useEffect(() => {
    window.addEventListener("message", (message) => onMessage(message));

    return () => {
      window.removeEventListener("message", (message) => onMessage(message));
    };
  }, [onMessage]);

  return (
    <GatedDownload
      modalRef={modalRef}
      toggleModal={() => setIsModalOpen(!isModalOpen)}
      isModalOpen={isModalOpen}
      icon={icon}
      title={title}
      subtitle={subtitle}
      formTitle={form[0] && form[0].title}
      formText={form[0] && form[0].text}
      form={
        form[0] && (
          <iframe
            id={_uid}
            ref={iframeRef}
            title={form[0].title}
            width="100%"
            height={height}
            src={form[0].formUrl + `#${_uid}`}
          />
        )
      }
    />
  );
};

export default DownloadsGatedDownloadItem;
