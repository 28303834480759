import React, {
  ReactNode,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import cn from "classnames";
import { motion, AnimatePresence } from "framer-motion";
import useMediaQuery from "../../../hooks/useMatchMedia";
import Icon from "../../Icon";
import s from "./Tabs.module.scss";

type Props = {
  children: ReactNode;
  activeIndex: number;
  alignment?: "alignLeft" | "alignCenter" | "alignRight";
};

const dropdownVariants = {
  hidden: {
    opacity: 0,
    y: -8,
    transition: {
      duration: 0.2,
      ease: [0.4, 0.0, 0.2, 1],
    },
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
      ease: [0.0, 0.0, 0.2, 1],
    },
  },
};

const iconVariants = {
  closed: {
    rotate: 0,
    transition: { duration: 0.3, ease: [0.4, 0.0, 0.2, 1] },
  },
  open: {
    rotate: 90,
    transition: { duration: 0.3, ease: [0.4, 0.0, 0.2, 1] },
  },
};

const Tabs: React.FC<Props> = ({
  children,
  activeIndex,
  alignment = "alignCenter",
}) => {
  const [dimensions, setDimensions] = useState({ width: 0, left: 0 });
  const [isOpen, setIsOpen] = useState(false);
  const tabRef = useRef<HTMLUListElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const toggleRef = useRef<HTMLButtonElement>(null);
  const isDesktopDown = useMediaQuery("(max-width: 1024px)");

  const handleToggle = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.currentTarget === toggleRef.current) {
      setIsOpen((prev) => !prev);
    }
  }, []);

  const updateDimensions = useCallback(() => {
    const activeTab = tabRef.current?.children[activeIndex] as HTMLElement;
    if (activeTab && !isDesktopDown) {
      const { offsetLeft, offsetWidth } = activeTab;
      setDimensions({ width: offsetWidth, left: offsetLeft });
    }
  }, [activeIndex, isDesktopDown]);

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, [updateDimensions]);

  useEffect(() => {
    if (!isDesktopDown) return;

    const handleClickOutside = (e: MouseEvent) => {
      if (
        !dropdownRef.current?.contains(e.target as Node) &&
        !toggleRef.current?.contains(e.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isDesktopDown]);

  const activeChild = React.Children.toArray(children)[
    activeIndex
  ] as React.ReactElement;
  const activeTitle = activeChild?.props?.title || "";

  return (
    <div className={s.tabsContent}>
      <div
        className={cn(s.tabsMobileWrapper, s[alignment], {
          [s.isOpen]: isOpen,
        })}
        ref={dropdownRef}
      >
        <div className={s.header}>
          <span className={s.title}>{activeTitle}</span>
          <button
            ref={toggleRef}
            className={s.toggle}
            onClick={handleToggle}
            aria-label={`Selected tab: ${activeTitle}`}
            aria-expanded={isOpen}
            type="button"
          >
            <motion.div
              variants={iconVariants}
              animate={isOpen ? "open" : "closed"}
              initial="closed"
            >
              <Icon
                icon="ellipsis-filled"
                className={s.icon}
                color={s.iconcolor}
              />
            </motion.div>
          </button>
        </div>

        <AnimatePresence>
          {isOpen && (
            <motion.ul
              role="listbox"
              className={s.dropdown}
              variants={dropdownVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              aria-label="Tabs dropdown"
            >
              {children}
            </motion.ul>
          )}
        </AnimatePresence>
      </div>

      <div className={cn(s.tabsDeskWrapper, s[alignment])}>
        <ul
          className={s.list}
          ref={tabRef}
          role="tablist"
          aria-orientation="horizontal"
        >
          {children}
          <AnimatePresence initial={false}>
            <motion.li
              key={activeIndex}
              className={s.highlight}
              initial={false}
              animate={{
                width: dimensions.width,
                x: dimensions.left,
                opacity: 1,
              }}
              transition={{
                type: "spring",
                stiffness: 400,
                damping: 30,
              }}
            />
          </AnimatePresence>
        </ul>
      </div>
    </div>
  );
};

export default Tabs;
