import { createElement, ReactNode } from "react";
import useMediaQuery from "../../../hooks/useMatchMedia";
import useExpandable from "../../../hooks/useExpandable";
import cn from "classnames";
import s from "../Downloads.module.scss";

import Icon from "../../Icon";
import Markdown from "../../Markdown";

type Props = {
  image: ReactNode;
  title: string;
  text: string;
  listTitle: string;
  isExpanded: boolean;
  toggleExpanded: () => void;
  children: ReactNode;
};

const ExpandableItem = ({
  image,
  title,
  text,
  listTitle,
  isExpanded,
  toggleExpanded,
  children,
}: Props) => {
  const isMobile = useMediaQuery("(max-width: 900px)");
  const contentRef = useExpandable<HTMLDivElement>(!isMobile || isExpanded);

  return (
    <li className={cn(s.expandableItem, { [s.expandedItem]: isExpanded })}>
      {createElement(
        isMobile ? "button" : "div",
        isMobile ? { onClick: toggleExpanded } : {},
        <>
          <div className={s.imageWrapper}>{image}</div>
          {title && <h3>{title}</h3>}
          {isMobile && (
            <Icon
              className={cn(s.buttonIcon, {
                [s.expanded]: isExpanded,
              })}
              icon={"caret-down"}
              width={16}
            />
          )}
        </>
      )}
      <div ref={contentRef} className={cn({ [s.content]: isMobile })}>
        <Markdown>{text}</Markdown>
        <h4>{listTitle}</h4>
        <ul>{children}</ul>
      </div>
    </li>
  );
};

export default ExpandableItem;
