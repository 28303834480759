import { isSite } from "./../routing/site";
import { isLocale } from "../routing/locales";

export const resolveSlug = (slug: string, sitemap = false) => {
  const [locale, site, ...rest] = slug ? slug.split("/") : [];

  if (!slug || slug.startsWith("#")) return slug;

  if (!isLocale(locale))
    throw new Error(`${locale} is not a valid locale - ${slug}`);
  if (!isSite(site) && !sitemap)
    throw new Error(`${site} is not a valid site - ${slug}`);

  // Since NEXT_PUBLIC_USE_DOMAIN_SPLIT environment variable is True only in production
  // then in all none-production enviroments we will get an extra /zh/ in the URL
  // which is fine and it will be removed in production
  const localePrefix =
    locale === "ja" ||
    locale === "sv" ||
    (process.env.NEXT_PUBLIC_USE_DOMAIN_SPLIT !== "TRUE" && locale === "zh")
      ? `/${locale}/`
      : "/";

  return localePrefix + rest.join("/");
};
