import React, { HTMLAttributes } from "react";
import cn from "classnames";
import s from "../TechSpec.module.scss";
import Icon from "../../Icon";

type Props = HTMLAttributes<HTMLButtonElement> & {
  isAllExpanded: boolean;
};

const ExpandButton = ({ isAllExpanded, children, ...props }: Props) => (
  <button {...props} className={s.expandButton} data-testid="expand-button">
    {children}
    <Icon
      icon="add"
      width={10}
      color="white"
      className={cn(s.buttonIcon, { [s.expanded]: isAllExpanded })}
    />
  </button>
);

export default ExpandButton;
