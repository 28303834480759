import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";
import s from "../TabsGrid.module.scss";

type Props = {
  videoUrl: string | undefined;
  isActiveTab: boolean;
};

const VideoPlayer = ({ videoUrl, isActiveTab }: Props) => {
  const [playCount, setPlayCount] = useState(0);
  const videoRef = useRef<HTMLVideoElement>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);

  const handleVideoEnded = () => {
    setPlayCount((prev) => prev + 1);
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [videoUrl]);

  useEffect(() => {
    if (videoRef.current) {
      if (!isActiveTab || playCount >= 3) {
        videoRef.current.pause();
      } else if (isActiveTab && playCount < 3) {
        videoRef.current.play();
        //.catch((e) => console.error("Video playback failed:", e));
      }
    }
  }, [isActiveTab, playCount]);

  useEffect(() => {
    if (videoRef.current) {
      observerRef.current = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting && isActiveTab && playCount < 3) {
            videoRef.current!.play();
            //.catch((e) => console.error("Video playback failed:", e));
          } else {
            videoRef.current!.pause();
          }
        },
        { threshold: 0.5 }
      );

      observerRef.current.observe(videoRef.current);

      return () => {
        observerRef.current?.disconnect();
      };
    }
  }, [isActiveTab, playCount]);

  return (
    <video
      ref={videoRef}
      className={cn(s.videoPlayer)}
      width="100%"
      height="100%"
      preload="metadata"
      muted
      onEnded={handleVideoEnded}
      src={videoUrl}
    />
  );
};

export default VideoPlayer;
