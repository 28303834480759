import React from "react";
import { imageSizes } from "../../lib/utils/imageSizes";
import cn from "classnames";
import s from "./DualMediaBlock.module.scss";
import { MediaAndImageSmall } from "../MediaAndImage";
import ImageVaultImage from "../../components/ImageVaultImage";
import { useRelations } from "../../lib/storyblok/useRelations";
import { resolveSlug } from "../../lib/utils/resolveSlug";
import { resolveStoryImage } from "../../lib/utils/resolveStoryImage";
import { useTranslation } from "next-i18next";
import { Story } from "../../lib/storyblok/types/story";
import LinkWrapper from "../../components/Nestable/LinkWrapper/LinkWrapper";
import { useViewmodel } from "../../lib/storyblok/ViewmodelContext";
import { resolveDate } from "../../lib/utils/resolveDate";
import c from "../../styles/container.module.scss";

const ArticleItem = ({ article, subTitle }) => {
  const { locale } = useViewmodel();
  const { t } = useTranslation("articlepage");

  return (
    <a href={resolveSlug(article?.full_slug || "")} className={cn(s.imageLink)}>
      <div className={cn(s.card)}>
        <div className={cn(s.imageWrapper)}>
          <div>
            <ImageVaultImage
              width={720}
              height={405}
              quality={50}
              image={resolveStoryImage(article?.content || {})}
              alt={article?.content.title || ""}
            />
          </div>
        </div>
        <div className={cn(s.cardContent)}>
          <p className={cn(s.oneThirdSubTitle)}>{subTitle}</p>
          <LinkWrapper
            className={cn(s.oneThirdDescription)}
            href={resolveSlug(article?.full_slug || "")}
            text={article?.content.title || ""}
          />
          <div className={s.detailsWrapper}>
            <ul className={s.details}>
              {article?.content.author && (
                <li className={cn(s.filterCardLabel)}>
                  {article.content.author}
                </li>
              )}

              {article?.content.createdAt && (
                <li className={cn("date")}>
                  {resolveDate(article.content.createdAt, locale)}
                </li>
              )}

              {article?.content.readTime && (
                <li>
                  {article.content.readTime} {t("minutesShort")}
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </a>
  );
};

type Props = {
  featured: boolean;
  reversed: boolean;
  latestArticle?: Story;
  featuredArticle: string;
  topArticles: string[];
  editorsPick: string;
  mostPopular: string;
  highlight: string;
  imageKeepProportions: boolean;
  style: "white" | "gray" | "signature" | "black";
  outerSpacing: "outerOnlyTop" | "outerOnlyBottom" | "outerBoth" | "outerNone";
  mediaBackground:
    | "mediaBgWhite"
    | "mediaBgGray"
    | "mediaBgSignature"
    | "mediaBgBlack"
    | "mediaBgLitterbox";
  layout:
    | "layout11"
    | "layout1g1"
    | "layout12"
    | "layout1g2"
    | "layout21"
    | "layout2g1";
  typeformCopy: string;
  typeformID: string;
  typeformSize: "50" | "60" | "70" | "80" | "90" | "100";
  typeformButtonStyle: "default" | "cta" | "inverted";
};

const DualMediaBlock = ({
  featured,
  reversed,
  style,
  latestArticle,
  featuredArticle,
  editorsPick,
  mostPopular,
  highlight,
  outerSpacing,
  mediaBackground,
  imageKeepProportions,
  layout,
}: Props) => {
  const { getRelation } = useRelations();

  const resolveArticle = (articleId?: string | null): Story | undefined => {
    if (articleId !== null && articleId !== undefined) {
      const resolved = getRelation(articleId);
      if (resolved !== null) {
        return resolved;
      }
    }
    return latestArticle;
  };

  const featuredArticleStory = resolveArticle(featuredArticle);
  const editorsPickStory = resolveArticle(editorsPick);
  const mostPopularStory = resolveArticle(mostPopular);
  const highlightStory = resolveArticle(highlight);
  const { t } = useTranslation("articlepage");
  return (
    <>
      <section className={cn(s.dualMedia, c.xWide)}>
        {featuredArticle && (
          <div className={cn(s.twoThirds)}>
            <a
              className={cn(s.twoThirdsLink)}
              href={resolveSlug(featuredArticleStory?.full_slug || "")}
            >
              <MediaAndImageSmall
                style={style}
                title={featuredArticleStory?.content.title || ""}
                preamble={featuredArticleStory?.content.description || ""}
                image={
                  <ImageVaultImage
                    sizes={imageSizes("100vw", { desktop: "50vw" })}
                    image={resolveStoryImage(
                      featuredArticleStory?.content || {}
                    )}
                    layout="fill"
                  />
                }
                author={featuredArticleStory?.content.author}
                publishedAt={featuredArticleStory?.content.createdAt}
                readTime={featuredArticleStory?.content.readTime}
                featured={featured}
                reversed={reversed}
                outerSpacing={outerSpacing}
                mediaBackground={mediaBackground}
                imageKeepProportions={imageKeepProportions}
                layout={layout}
                typeformCopy={""}
                typeformID={""}
                typeformSize={"70"}
                typeformButtonStyle={"default"}
              />
            </a>
          </div>
        )}

        <div className={cn(s.oneThird)}>
          <div className={cn(s.cards)}>
            <ArticleItem
              article={editorsPickStory}
              subTitle={t("editors-pick")}
            />
            <ArticleItem
              article={mostPopularStory}
              subTitle={t("most-popular")}
            />
            <ArticleItem article={highlightStory} subTitle={t("highlight")} />
          </div>
        </div>
      </section>
    </>
  );
};

export default DualMediaBlock;
