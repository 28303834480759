import { EditorialVideoBlock } from "../../lib/storyblok/types/blocks/EditorialVideoBlock";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import Editorial from "../../ui/Editorial/Editorial";
import { DynamicBlocks } from "../DynamicBlock";

const EditorialVideo: StoryblokBlockComponent<EditorialVideoBlock> = ({
  video,
}) => (
  <Editorial.Copy>
    <DynamicBlocks blocks={video} />
  </Editorial.Copy>
);

export default EditorialVideo;
