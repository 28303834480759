import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";
import s from "../Overlay.module.scss";

type Props = {
  videoUrl: string | undefined;
};

const VideoPlayer = ({ videoUrl }: Props) => {
  const [playCount, setPlayCount] = useState(0);
  const videoRef = useRef<HTMLVideoElement>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);

  const handleVideoEnded = () => {
    setPlayCount((prev) => prev + 1);
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [videoUrl]);

  useEffect(() => {
    if (videoRef.current && playCount >= 3) {
      videoRef.current.pause();
    }
  }, [playCount]);

  useEffect(() => {
    if (videoRef.current) {
      observerRef.current = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            if (playCount < 3) {
              videoRef.current!.play();
            }
          } else {
            videoRef.current!.pause();
          }
        },
        {
          threshold: 0.5,
        }
      );

      observerRef.current.observe(videoRef.current);

      return () => {
        observerRef.current?.disconnect();
      };
    }
  }, [playCount]);

  return (
    <video
      ref={videoRef}
      className={cn(s.videoPlayer)}
      width="100%"
      height="100%"
      preload="auto"
      muted
      playsInline
      onEnded={handleVideoEnded}
      src={videoUrl}
    />
  );
};

export default VideoPlayer;
