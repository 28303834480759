import { SimpleContentPageStory } from "../../lib/storyblok/types/stories/SimpleContentPageStory";
import { StoryblokStoryComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { ViewModel } from "../../lib/viewModel/createViewModel";
import HeroBlock from "../Nestable/Hero";
import AnchorMenu from "../Layout/AnchorMenu/AnchorMenu";
import { AnchorMenuContextProvider } from "../Layout/AnchorMenu/AnchorMenuContext";
import AnchorMenuSection from "../Layout/AnchorMenu/AnchorMenuSection";
import { splitIntoSections } from "../Layout/AnchorMenu/splitIntoSections";
import DisclaimerBlock from "../../ui/DisclaimerBlock";
import { useState } from "react";

type VM = ViewModel<SimpleContentPageStory>;

const SimpleContentPage: StoryblokStoryComponent<VM> = ({ story }) => {
  const {
    hero,
    body,
    anchorMenuButton,
    hideStickyMenu,
    hasDisclaimer,
    disclaimerTitle,
    disclaimerDescription,
    disclaimerOptionsList,
    disclaimerAuthorizedOptions,
    disclaimerSecondPageButtonText,
    disclaimerSecondPageSecondaryButtonText,
    disclaimerMainButtonText,
    disclaimerSecondaryButtonLink,
    disclaimerSecondaryButtonText,
    disclaimerRestrictedAccessTitle,
    disclaimerAuthorizedContentDescription,
    disclaimerRestrictedAccessContentDescription,
    disclaimerAuthorizedContentTitle,
    disclaimerHasSecondPage,
    disclaimerFirstDropDownTitle,
  } = story.content;

  const sections = splitIntoSections(body);
  const [showPressRelease, setShowPressRelease] = useState(false);
  return (
    <>
      {hasDisclaimer && !showPressRelease && (
        <DisclaimerBlock
          disclaimerTitle={disclaimerTitle}
          disclaimerDescription={disclaimerDescription}
          disclaimerOptionsList={disclaimerOptionsList}
          disclaimerAuthorizedOptions={disclaimerAuthorizedOptions}
          disclaimerAuthorizedContentDescription={
            disclaimerAuthorizedContentDescription
          }
          disclaimerAuthorizedContentTitle={disclaimerAuthorizedContentTitle}
          disclaimerRestrictedAccessTitle={disclaimerRestrictedAccessTitle}
          disclaimerRestrictedAccessContentDescription={
            disclaimerRestrictedAccessContentDescription
          }
          setShowContent={setShowPressRelease}
          disclaimerSecondaryButtonLink={disclaimerSecondaryButtonLink}
          disclaimerSecondaryButtonText={disclaimerSecondaryButtonText}
          disclaimerMainButtonText={disclaimerMainButtonText}
          disclaimerFirstDropDownTitle={disclaimerFirstDropDownTitle}
          disclaimerSecondPageSecondaryButtonText={
            disclaimerSecondPageSecondaryButtonText
          }
          disclaimerSecondPageButtonText={disclaimerSecondPageButtonText}
          disclaimerHasSecondPage={disclaimerHasSecondPage}
        />
      )}
      {(showPressRelease || !hasDisclaimer) && (
        <AnchorMenuContextProvider>
          {hero?.[0] && (
            <HeroBlock
              {...hero?.[0]}
              anchorLinksMenu={
                sections.length > 1 &&
                !hideStickyMenu && (
                  <AnchorMenu
                    sections={sections}
                    button={anchorMenuButton?.[0]}
                  />
                )
              }
            />
          )}

          {sections?.map((section) => (
            <AnchorMenuSection
              key={`${section.href ? section.href : "index"}-${story.uuid}`}
              section={section}
            />
          ))}
        </AnchorMenuContextProvider>
      )}
    </>
  );
};

export default SimpleContentPage;
