import React, { forwardRef, HTMLProps } from "react";
import cn from "classnames";
import s from "./DesktopMenu.module.scss";
import { useIsExternalLink } from "../../../../hooks/useIsExternalLink";
import Icon, { IconType } from "../../../Icon/Icon";
import FlexibleLinkWrapper from "../../../../components/Nestable/LinkWrapper/FlexibleLinkWrapper";

type Props = {
  title: string;
  icon?: IconType;
  description: string;
  link: string;
} & HTMLProps<HTMLAnchorElement>;

export const DesktopSubNavigationItem = forwardRef<HTMLAnchorElement, Props>(
  ({ title, icon, description, link, ...anchorProps }, ref) => {
    const { isExternalLink } = useIsExternalLink(link);

    return (
      <li>
        <FlexibleLinkWrapper
          className={cn(s.DesktopSubNavigationItem, {
            [s.externalLink]: isExternalLink,
            [s.noIcon]: !icon,
          })}
          ref={ref}
          href={link}
          title={title}
          aria-label={description ? `${title}: ${description}` : title}
          {...anchorProps}
        >
          {icon && <Icon icon={icon} width={16} aria-hidden="true" />}
          <div className={s.content}>
            {title && (
              <span
                className={cn(s.title, {
                  [s.noDescription]: !description,
                })}
              >
                {title}
              </span>
            )}
            {description && <p className={s.description}> {description}</p>}
          </div>
          {isExternalLink && (
            <Icon
              icon={"arrow-up-right"}
              color={s.iconcolor}
              className={s.icon}
              width={12}
              aria-hidden="true"
            />
          )}
        </FlexibleLinkWrapper>
      </li>
    );
  }
);

DesktopSubNavigationItem.displayName = "DesktopSubNavigationItem";
