import { ReactNode } from "react";
import cn from "classnames";
import s from "../ArticleHeading.module.scss";

type Props = {
  horizontal?: boolean;
  children: ReactNode;
};

const DetailsList = ({ horizontal, children }: Props) => {
  return (
    <ul className={cn(s.detailsList, { [s.horizontal]: horizontal })}>
      {children}
    </ul>
  );
};

export default DetailsList;
