import { ReactNode } from "react";
import cn from "classnames";
import s from "../Testimonials.module.scss";
import Icon from "../../Icon";

type Props = {
  quote: string;
  name: string;
  roleAndCompany: string;
  logo?: ReactNode;
};

const Testimonial = ({ quote, name, roleAndCompany, logo }: Props) => (
  <li className={cn(s.testimonial, s.slider)}>
    <div className={s.quoteWrapper}>
      <Icon width={16} icon="quote" className={s.icon} />
      <q className={s.quote}>{quote}</q>
    </div>
    <div className={s.companyWrapper}>
      <p className={s.name}>{name}</p>
      <p className={cn("label", s.roleAndCompany)}>{roleAndCompany}</p>
      {logo && <div className={s.logoWrapper}>{logo}</div>}
    </div>
  </li>
);

export default Testimonial;
