import React, { ReactNode } from "react";
import cn from "classnames";
import s from "./TagsAndShareWrapper.module.scss";
import c from "../../styles/container.module.scss";

import TagsWrapper from "./components/TagsWrapper";
import ShareWrapper from "./components/ShareWrapper";

type Props = {
  children: ReactNode;
};

const subComponents = { TagsWrapper, ShareWrapper };
type SubComponents = typeof subComponents;

const TagsAndShareWrapper: CWS<Props, SubComponents> = ({
  children,
}: Props) => <div className={cn(s.wrapper, c.narrow)}>{children}</div>;
TagsAndShareWrapper.TagsWrapper = TagsWrapper;
TagsAndShareWrapper.ShareWrapper = ShareWrapper;

export default TagsAndShareWrapper;
