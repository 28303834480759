import { createContext, useContext, useRef, useState } from "react";
import useEventListener from "../../../lib/utils/useEventListener";

type HeaderContext = ReturnType<typeof useMe>;
const headerContext = createContext<HeaderContext>({} as HeaderContext);

export const HeaderContextProvider = ({ children }) => (
  <headerContext.Provider value={useMe()}>{children}</headerContext.Provider>
);

const useMe = () => {
  const [lockedHeader, setLockedHeader] = useState(false);

  //when following hash-anchorlinks this can be called to temporarily block the header from appearing under the cursor
  const timerRef = useRef<NodeJS.Timeout>();
  const temporarilyLockHeader = () => {
    setShouldHideHeader(true);
    setLockedHeader(true);
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => setLockedHeader(false), 1000);
  };

  const [shouldHideHeader, setShouldHideHeader] = useState(false);
  const lastScrollY = useRef(-1);

  useEventListener("scroll", () => {
    if (typeof window !== "undefined") {
      if (!lockedHeader)
        setShouldHideHeader(window.scrollY > lastScrollY.current);

      if (window.scrollY > lastScrollY.current && window.scrollY < 90)
        setShouldHideHeader(false);

      lastScrollY.current = window.scrollY;
    }
  });

  return {
    shouldHideHeader,
    temporarilyLockHeader,
  };
};

export const useHeaderContext = () => useContext(headerContext);
