import React from "react";
import { motion } from "framer-motion";
import Icon from "../../../Icon";
import s from "./MobileMenu.module.scss";
import cn from "classnames";
import FlexibleLinkWrapper from "../../../../components/Nestable/LinkWrapper/FlexibleLinkWrapper";

type Props = {
  title: string;
  href: string;
  index: number;
  onBackClick: () => void;
};

export const MobileSubNavTitle = ({
  title,
  href,
  index,
  onBackClick,
}: Props) => {
  return (
    <>
      <motion.li
        initial={{
          opacity: 0,
          x: 30,
        }}
        animate={{ opacity: 1, x: 0 }}
        transition={{
          duration: 0.2,
          delay: (index + 1) * 0.03,
        }}
        className={cn(s.subNavTitle, {
          [s[`level-${index}`]]: true,
        })}
      >
        <div className={s.subNavTitleContent}>
          {href !== "#" ? (
            <FlexibleLinkWrapper
              href={href}
              title={title}
              aria-label={title}
              tabIndex={index}
            >
              {title && <span>{title}</span>}
            </FlexibleLinkWrapper>
          ) : (
            title && <span>{title}</span>
          )}
          <button onClick={onBackClick}>
            <Icon
              icon={"caret-down"}
              width={20}
              color={s.iconColorInverted}
              className={s.iconcolor}
            />
          </button>
        </div>
      </motion.li>
    </>
  );
};
