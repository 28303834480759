import React, { useEffect, useState, useMemo } from "react";
import { useDatablockWidget } from "../../../hooks/mfn/useDatablockWidget";
import { Tabs, Tab } from "../../Common/Tabs";
import cn from "classnames";
import c from "../../../styles/container.module.scss";
import s from "./Datablock.Sharegraph.module.scss";
import ss from "./Datablock.SharedStyles.module.scss";
import tokens from "../tokens.json";

type Props = {
  locale: string;
  demo: boolean;
};

const Sharegraph: React.FC<Props> = ({ locale, demo }) => {
  const [activeTab, setActiveTab] = useState(1);
  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  const { initializeWidgets, cleanupWidgets } = useDatablockWidget();

  const widgetConfigs = useMemo(
    () => [
      {
        widget: "stock-chart",
        widgetId: "stock-chart",
        token: tokens.stockChart,

        label: locale === "en" ? "Stock graph" : "Aktiegraf",
      },
      {
        widget: "stock-table",
        widgetId: "stock-table",
        token: tokens.shareTicker,
        label: locale === "en" ? "Performance" : "Utveckling",
      },
      {
        widget: "stock-calculator",
        widgetId: "stock-calculator",
        token: tokens.stockCalculator,
        label: locale === "en" ? "Share calculator" : "Aktiekalkylator",
      },
    ],
    [locale]
  );

  useEffect(() => {
    initializeWidgets(widgetConfigs, locale, demo);
    return () => cleanupWidgets(widgetConfigs, locale);
  }, [locale, demo, widgetConfigs, initializeWidgets, cleanupWidgets]);

  const chartConfig = widgetConfigs[0];
  const tabConfigs = widgetConfigs.slice(1);

  return (
    <div className={s.sharegraphContainer}>
      <div className={cn(s.contentWrapper, ss.contentWrapper)}>
        <div
          key={chartConfig.widgetId}
          id={`${chartConfig.widgetId}-${locale}`}
          className={cn(c.xWide, s.sharegraph)}
        />
      </div>

      <Tabs activeIndex={activeTab} alignment={"alignLeft"}>
        {tabConfigs.map((tab, i) => (
          <Tab
            key={tab.widgetId}
            index={i}
            title={tab.label}
            isActive={activeTab === i}
            onClick={() => handleTabClick(i)}
          />
        ))}
      </Tabs>

      <div className={cn(ss.tabsWrapper, ss.contentWrapper)}>
        {tabConfigs.map((tab, j) => (
          <div
            key={tab.widgetId}
            className={cn(ss.tab, { [ss.activeTab]: activeTab === j })}
          >
            <div
              id={`${tab.widgetId}-${locale}`}
              className={cn(c.xWide, s.shareholders)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sharegraph;
