/* eslint-disable */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { GatedFormBlock } from "../../lib/storyblok/types/blocks/GatedFormBlock";
import cn from "classnames";
import Image from "next/image";
import s from "../../ui/CTAForm/CTAForm.module.scss";
import c from "../../styles/container.module.scss";
import { DynamicBlocks } from "../DynamicBlock";
import Markdown from "../../ui/Markdown";
import s2 from "../../ui/Editorial/components/EditorialCopy.module.scss";
import swooshBlueTop from "../../public/images/divider-block-swoosh-blue-top.png";
import swooshBlueBottom from "../../public/images/divider-block-swoosh-blue-bottom.png";
import { imageSizes } from "../../lib/utils/imageSizes";
import ImageVaultImage from "../ImageVaultImage";

const GatedForm: StoryblokBlockComponent<GatedFormBlock> = ({
  _uid,
  block,
  text,
  title,
  copy,
  image,
  formUrl,
  spacing,
  style,
  divider,
}) => {
  const [submitted, setSubmitted] = useState(false);
  const [presubmittedId, setPresubmittedId] = useState<string | null>(null);

  const ref = useRef<HTMLIFrameElement>(null);

  let originUrl = "";
  if (formUrl) {
    try {
      originUrl = new URL(formUrl).origin;
    } catch (error) {
      console.error("Invalid formUrl:", formUrl);
    }
  }

  const onMessage = useCallback(
    (message) => {
      if (message.data.type === "pardotFormPreSubmit" && message.data.id) {
        if (!presubmittedId) {
          setPresubmittedId(message.data.id);
        }
      }

      if (message.data.type === "pardotFormSubmit" && presubmittedId) {
        setSubmitted(true);
        setPresubmittedId(null);
      }

      if (
        (message.data.type === "pardotFormResize-" + formUrl + `#${_uid}` &&
          message.data.height) ||
        (message.data.type === "ctaFormResize-" + formUrl + `#${_uid}` &&
          message.data.height &&
          message.origin === originUrl)
      ) {
        setTimeout(() => {
          setHeight(message.data.height + 2);
        }, 0);
      }
    },
    [presubmittedId, _uid, formUrl, originUrl]
  );

  useEffect(() => {
    window.addEventListener("message", onMessage);

    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, [onMessage]);

  const [height, setHeight] = useState(1000);

  return !submitted ? (
    <div className={cn(s.ctaForm, s[spacing], s[style])}>
      {divider === "swooshBlueTop" || divider === "swooshBlueBoth" ? (
        <div className={s.topImage}>
          <Image
            src={swooshBlueTop}
            alt="Swoosh Blue Top"
            objectFit="contain"
            layout="intrinsic"
            quality="100"
          />
        </div>
      ) : null}
      <div className={cn(c.wide, s.wrapper)}>
        <div className={cn(s.content)}>
          {title && <h2>{title}</h2>}
          {text && (
            <div className={s.intro}>
              <Markdown>{text}</Markdown>
            </div>
          )}
          {copy && (
            <div className={cn(s.copy, s2.copy)}>
              <Markdown>{copy}</Markdown>
            </div>
          )}
          {image ? (
            <div className={cn(s.imageContainer)}>
              <ImageVaultImage
                sizes={imageSizes("720px")}
                image={image}
                layout="intrinsic"
                contain
              />
            </div>
          ) : null}
        </div>
        {formUrl && (
          <div className={cn(s.form)}>
            <div>
              <iframe
                title={formUrl}
                ref={ref}
                width="100%"
                height={height}
                src={formUrl + `#${_uid}`}
              />
            </div>
          </div>
        )}
      </div>

      {divider === "swooshBlueBottom" || divider === "swooshBlueBoth" ? (
        <div className={s.bottomImage}>
          <Image
            src={swooshBlueBottom}
            alt="Swoosh Blue Bottom"
            objectFit="contain"
            layout="intrinsic"
            quality="100"
          />
        </div>
      ) : null}
    </div>
  ) : (
    <DynamicBlocks blocks={block ?? []} />
  );
};

export default GatedForm;
