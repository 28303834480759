import { ReactNode } from "react";
import s from "../Footer.module.scss";

type Props = {
  title?: string;
  children: ReactNode;
};

const FooterList = ({ title, children }: Props) => (
  <div className={s.footerList}>
    {title && <span className={s.title}>{title}</span>}
    <ul className={s.list}>{children}</ul>
  </div>
);

export default FooterList;
