import Link from "next/link";
import React from "react";
import { TextAndImageParallaxBlock } from "../../lib/storyblok/types/blocks/textAndImageParallaxBlock";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { useLinks } from "../../lib/storyblok/useLinks";
import { imageSizes } from "../../lib/utils/imageSizes";
import Button from "../../ui/Button";
import Markdown from "../../ui/Markdown";
import MediaAndImageUi from "../../ui/MediaAndImage";
import ImageVaultImage from "../ImageVaultImage";

const TextAndImageParallax: StoryblokBlockComponent<
  TextAndImageParallaxBlock
> = ({
  text,
  title,
  image,
  buttonText,
  buttonLink,
  secondButtonText,
  secondButtonLink,
  typeformCopy,
  typeformID,
  typeformSize,
  typeformButtonStyle,
  parallaxVideo,
  outerSpacing,
  mediaBackground,
  imageKeepProportions,
  layout,
  style,
}) => {
  const resolveLink = useLinks();

  const preamble = (
    <Markdown
      components={{
        li: (child) => (
          <li>
            <p>{child.children}</p>
          </li>
        ),
      }}
    >
      {text}
    </Markdown>
  );
  return (
    <MediaAndImageUi
      style={style}
      reversed={true}
      title={title}
      preamble={preamble}
      image={
        <ImageVaultImage
          priority
          sizes={imageSizes("100vw", { tablet: "50vw" })}
          image={image}
          layout="fill"
        />
      }
      button={
        buttonText && (
          <Link href={resolveLink(buttonLink)} passHref prefetch={false}>
            <Button href={resolveLink(buttonLink)} variant="inverted">
              {buttonText}
            </Button>
          </Link>
        )
      }
      secondButton={
        secondButtonText && (
          <Link passHref href={resolveLink(secondButtonLink)} prefetch={false}>
            <Button href={resolveLink(secondButtonLink)} variant="inverted">
              {secondButtonText}
            </Button>
          </Link>
        )
      }
      typeformCopy={typeformCopy}
      typeformID={typeformID}
      typeformSize={typeformSize}
      typeformButtonStyle={typeformButtonStyle}
      parallaxVideo={parallaxVideo}
      outerSpacing={outerSpacing}
      mediaBackground={mediaBackground}
      imageKeepProportions={imageKeepProportions}
      layout={layout}
    />
  );
};

export default TextAndImageParallax;
