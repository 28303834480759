import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { ColumnGridVideoItemBlock } from "../../lib/storyblok/types/blocks/ColumnGridVideoItemBlock";
import ColumnGrid from "../../ui/ColumnGrid";
import Button from "../../ui/Button";
import { DynamicBlocks } from "../DynamicBlock";
import { useLinks } from "../../lib/storyblok/useLinks";

const { Item } = ColumnGrid;

const ColumnGridVideoItem: StoryblokBlockComponent<
  ColumnGridVideoItemBlock
> = ({
  _uid,
  video,
  title,
  markdown,
  buttonText,
  buttonLink,
  secondButtonText,
  secondButtonLink,
  style,
}) => {
  const resolveLink = useLinks();

  const isStyleInverted = style === "black" || style === "signature"; // Check if the style should be inverted

  return (
    <Item
      key={_uid}
      video={<DynamicBlocks blocks={video} />}
      title={title}
      markdown={markdown}
    >
      {buttonText && buttonLink && (
        <Button
          href={resolveLink(buttonLink)}
          variant={isStyleInverted ? "inverted" : "default"} // Use the derived isStyleInverted value
          openInNewTab={buttonLink.linktype === "asset"}
        >
          {buttonText}
        </Button>
      )}
      {secondButtonText && secondButtonLink && (
        <Button
          href={resolveLink(secondButtonLink)}
          variant={isStyleInverted ? "inverted" : "default"} // Use the derived isStyleInverted value
          openInNewTab={secondButtonLink.linktype === "asset"}
        >
          {secondButtonText}
        </Button>
      )}
    </Item>
  );
};

export default ColumnGridVideoItem;
