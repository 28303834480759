import React, { ElementType, HTMLProps } from "react";
import s from "../SearchHits.module.scss";
import cn from "classnames";

type HitBodyProps = HTMLProps<HTMLElement> & {
  as?: ElementType;
};

const HitBody = ({ as: Body = "p", className, ...props }: HitBodyProps) => (
  <Body {...props} className={cn(className, s.body)} />
);

export default HitBody;
