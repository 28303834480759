import cn from "classnames";
import s from "../ContentGridSlider.module.scss";
type Props = {
  totalSlides: number;
  percent: number;
  currentSlide: number;
  className?: string;
};

const ProgressBar = ({
  totalSlides,
  percent,
  currentSlide,
  className,
}: Props) => {
  const roundedTotal = Math.ceil(totalSlides);
  const roundedCurrent = Math.ceil(currentSlide);
  const Filler = () => (
    <div
      className={cn(s.filler)}
      style={{
        width: `${(1 / totalSlides) * 100}%`,
        left: `${percent * (1 - 1 / totalSlides)}%`,
      }}
    ></div>
  );

  return (
    <div className={cn(s.progressBarContainer, className)}>
      <div className={cn(s.progressBar)}>
        <Filler />
      </div>
      <div className={cn(s.slideIndex)}>
        <p>
          {roundedCurrent}/{roundedTotal}
        </p>
      </div>
    </div>
  );
};

export default ProgressBar;
