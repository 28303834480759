import React from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import ShortFeaturesUi from "../../ui/ShortFeatures";
import { ShortFeaturesBlock } from "../../lib/storyblok/types/blocks/ShortFeaturesBlock";
import { storyblokEditable } from "@storyblok/react";
import Button from "../../ui/Button";
import Link from "next/link";
import Image from "next/image";
import { useLinks } from "../../lib/storyblok/useLinks";
import ImageVaultImage from "../ImageVaultImage";

const ShortFeatures: StoryblokBlockComponent<ShortFeaturesBlock> = ({
  title,
  text,
  buttons,
  features,
  style,
  featureStyle,
  featureBoxed,
  spacing,
  outerSpacing,
  columns,
  contentAlignment,
  width,
  backgroundImage,
  backgroundOpacity = { value: 100 },
}) => {
  const resolveLink = useLinks();

  const buttonCollection = buttons?.map((button) => (
    <Link
      key={button._uid}
      href={button.buttonLink ? resolveLink(button.buttonLink) : ""}
      passHref
    >
      <Button
        variant={button.buttonType ?? "default"}
        href={resolveLink(button.buttonLink)}
      >
        {button.buttonText}
      </Button>
    </Link>
  ));

  const determineVariant = (style, featureStyle) => {
    const invertedStyles = [
      "signature",
      "black",
      "blue",
      "oceanBlue",
      "peach",
      "violet",
      "gradientRadialTopBlueSignature",
      "gradientRadialCenterBlueSignature",
      "gradientLinearBlueSignature",
    ];
    if (
      invertedStyles.includes(style) &&
      (!featureStyle ||
        featureStyle === "featureSignature" ||
        featureStyle === "featureBlack")
    ) {
      return "inverted";
    }
    return "default";
  };

  return (
    <>
      <ShortFeaturesUi
        title={title}
        text={text}
        buttons={buttonCollection}
        style={style}
        featureStyle={featureStyle}
        featureBoxed={featureBoxed}
        spacing={spacing}
        outerSpacing={outerSpacing}
        columns={columns}
        contentAlignment={contentAlignment}
        width={width}
        backgroundImage={
          backgroundImage?.item && (
            <ImageVaultImage
              image={backgroundImage}
              layout="fill"
              sizes="50vw" //todo optimize
              priority
            />
          )
        }
        backgroundOpacity={backgroundOpacity.value}
      >
        {features.map((feature) => (
          <div
            key={feature._uid}
            {...storyblokEditable(feature)}
            style={{
              display: "grid",
            }}
          >
            <ShortFeaturesUi.Feature
              text={feature.text}
              description={feature.description}
              showLabel={feature.showLabel}
              label={feature.label}
              labelPrefix={feature.labelPrefix}
              labelSuffix={feature.labelSuffix}
              button={
                feature.buttonText &&
                feature.buttonLink && (
                  <Button
                    href={resolveLink(feature.buttonLink)}
                    title={feature.buttonText}
                    size="small"
                    variant={determineVariant(style, featureStyle)}
                  >
                    {feature.buttonText}
                  </Button>
                )
              }
            >
              <Image
                src={`/svg/icons/${feature.icon}.svg`}
                width={40}
                height={40}
                alt=""
              />
            </ShortFeaturesUi.Feature>
          </div>
        ))}
      </ShortFeaturesUi>
    </>
  );
};

export default ShortFeatures;
