import React, {
  forwardRef,
  ReactNode,
  useRef,
  useImperativeHandle,
} from "react";
import { motion, HTMLMotionProps } from "framer-motion";
import cn from "classnames";
import s from "./DesktopMenu.module.scss";

type Props = {
  children: ReactNode;
  isOpen: boolean;
} & HTMLMotionProps<"div"> &
  React.HTMLAttributes<HTMLDivElement>;

export type DesktopSubNavigationWrapperRef = {
  subNavRef: React.RefObject<HTMLDivElement>;
};

export const DesktopSubNavigationWrapper = forwardRef<
  DesktopSubNavigationWrapperRef,
  Props
>(({ children, isOpen, style, ...divProps }, ref) => {
  const subNavRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => ({
    subNavRef,
  }));

  const variants = {
    open: {
      opacity: 1,
      height: "auto",
      transition: {
        height: { type: "spring", stiffness: 300, damping: 30 },
        opacity: { duration: 0.3 },
      },
    },
    closed: {
      opacity: 0,
      height: 0,
      transition: {
        height: { type: "spring", stiffness: 300, damping: 30 },
        opacity: { duration: 0.3 },
      },
    },
  };

  return (
    <motion.div
      ref={subNavRef}
      initial="closed"
      animate={isOpen ? "open" : "closed"}
      variants={variants}
      className={cn(s.DesktopSubNavigationWrapper)}
      style={{ overflow: "hidden", ...style } as React.CSSProperties}
      aria-hidden={!isOpen}
      aria-expanded={isOpen}
      aria-label="Desktop sub-navigation"
      {...divProps}
    >
      {children}
    </motion.div>
  );
});

DesktopSubNavigationWrapper.displayName = "DesktopSubNavigationWrapper";
