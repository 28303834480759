import { useState } from "react";
import cn from "classnames";
import s from "./CTAModal.module.scss";

import Button from "../Button";
import Icon from "../Icon";
import Modal from "../Modal";
import ModalPortal from "../ModalPortal/ModalPortal";

type Props = {
  buttonText: string;
  iframeUrl: string;
  spacing: "none" | "onlyTop" | "onlyBottom" | "both";
};

const CTAModal = ({ buttonText, iframeUrl, spacing }: Props) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className={cn(s.ctaModal, s[spacing])}>
        <Button variant="cta" onClick={() => setShowModal(!showModal)}>
          {buttonText}
        </Button>
      </div>
      {iframeUrl && (
        <ModalPortal show={showModal}>
          <Modal className={s.modal}>
            <button className={s.close} onClick={() => setShowModal(false)}>
              <Icon icon="cancel" color="black" />
            </button>
            <iframe title="modal" src={iframeUrl} width="100%" height="99%" />
          </Modal>
        </ModalPortal>
      )}
    </>
  );
};

export default CTAModal;
