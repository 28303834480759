import { ReactNode } from "react";
import s from "../AnchorMenu.module.scss";

type Props = {
  children: ReactNode;
};

const List = ({ children }: Props) => {
  return <ul className={s.list}>{children}</ul>;
};

export default List;
