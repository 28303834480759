import Link from "next/link";
import React from "react";
import { TextAndImageBlock } from "../../lib/storyblok/types/blocks/TextAndImageBlock";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { useLinks } from "../../lib/storyblok/useLinks";
import { imageSizes } from "../../lib/utils/imageSizes";
import Button from "../../ui/Button";
import Markdown from "../../ui/Markdown";
import MediaAndImageUi from "../../ui/MediaAndImage";
import ImageVaultImage from "../ImageVaultImage";

const TextAndImage: StoryblokBlockComponent<TextAndImageBlock> = ({
  text,
  title,
  image,
  featured,
  reversed,
  buttonText,
  buttonLink,
  secondButtonText,
  secondButtonLink,
  style,
  typeformCopy,
  typeformID,
  typeformSize,
  typeformButtonStyle,
  outerSpacing,
  mediaBackground,
  imageKeepProportions,
  layout,
}) => {
  const resolveLink = useLinks();

  const isStyleInverted = style === "black" || style === "signature"; // Check if the style should be inverted

  const preamble = (
    <Markdown
      components={{
        li: (child) => (
          <li>
            <p>{child.children}</p>
          </li>
        ),
      }}
    >
      {text}
    </Markdown>
  );
  return (
    <MediaAndImageUi
      style={style}
      outerSpacing={outerSpacing}
      mediaBackground={mediaBackground}
      imageKeepProportions={imageKeepProportions}
      layout={layout}
      featured={featured}
      reversed={reversed}
      title={title}
      preamble={preamble}
      image={
        <ImageVaultImage
          sizes={imageSizes("100vw", { desktop: "50vw" })}
          image={image}
          layout="fill"
        />
      }
      button={
        buttonText && (
          <Button
            href={resolveLink(buttonLink)}
            variant={isStyleInverted ? "inverted" : "default"}
          >
            {buttonText}
          </Button>
        )
      }
      secondButton={
        secondButtonText && (
          <Link passHref href={resolveLink(secondButtonLink)} prefetch={false}>
            <Button
              href={resolveLink(secondButtonLink)}
              variant={isStyleInverted ? "inverted" : "default"}
            >
              {secondButtonText}
            </Button>
          </Link>
        )
      }
      typeformCopy={typeformCopy}
      typeformID={typeformID}
      typeformSize={typeformSize}
      typeformButtonStyle={typeformButtonStyle}
    />
  );
};

export default TextAndImage;
