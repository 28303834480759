import React from "react";

type Props = {
  wrapper: (children: React.ReactElement) => JSX.Element;
  children: React.ReactElement;
  shouldWrap: boolean;
};

const ConditionalWrapper = ({ wrapper, shouldWrap, children }: Props) =>
  shouldWrap ? wrapper(children) : children;

export default ConditionalWrapper;
