import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "next-i18next";
import React from "react";
import { Language } from "../../hooks/useLanguageSelect";
import { useViewmodel } from "../../lib/storyblok/ViewmodelContext";
import LanguageSelectModal from "../../ui/LanguageSelectModal";
import ModalPortal from "../../ui/ModalPortal/ModalPortal";

type LanguageSelectProps = {
  show: boolean;
  languages: Language[];
  handleLanguageSelect: (show: boolean) => () => void;
};

const LanguageSelect = ({
  show,
  languages,
  handleLanguageSelect,
}: LanguageSelectProps) => {
  const { t } = useTranslation();
  const { locale: currentLocale } = useViewmodel();

  return (
    <ModalPortal show={show}>
      <AnimatePresence>
        <motion.div
          key={"modal"}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.15 }}
        >
          <LanguageSelectModal
            title={t("select-language-title")}
            preamble={t("select-language-preamble")}
            closeButtonLabel={t("close")}
            closeModal={handleLanguageSelect(false)}
          >
            {languages.map(({ locale, href, label }) => (
              <LanguageSelectModal.LanguageItem
                key={locale}
                selected={locale === currentLocale}
                country={locale}
                href={href}
                language={label}
              />
            ))}
          </LanguageSelectModal>
        </motion.div>
      </AnimatePresence>
    </ModalPortal>
  );
};

export default LanguageSelect;
