import { useTranslation } from "next-i18next";
import { useRelations } from "../../lib/storyblok/useRelations";
import { ContentGridSliderBlock } from "../../lib/storyblok/types/blocks/ContentGridSliderBlock";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import Button from "../../ui/Button";
import ImageVaultImage from "../ImageVaultImage";
import ContentGridSliderUi from "../../ui/ContentGridSlider";
import { resolveStoryImage } from "../../lib/utils/resolveStoryImage";
import { resolveSlug } from "../../lib/utils/resolveSlug";
import { imageSizes } from "../../lib/utils/imageSizes";

const ContentGridSlider: StoryblokBlockComponent<ContentGridSliderBlock> = ({
  title,
  description,
  items,
  verticalSpacing,
  isStartpage,
}) => {
  const { getRelation } = useRelations();
  const { t } = useTranslation();

  return (
    <>
      <ContentGridSliderUi
        title={title}
        description={description}
        spacing={verticalSpacing}
        isStartpage={isStartpage}
      >
        {items.map((item) => {
          const story = getRelation(item);

          if (story) {
            const { uuid, content, name, full_slug } = story;
            const category = content.category;
            const label = category || full_slug.split("/").reverse()[1];
            const slug = resolveSlug(full_slug);
            return (
              <ContentGridSliderUi.Item
                key={uuid}
                href={slug}
                image={
                  <ImageVaultImage
                    sizes={imageSizes("750px")}
                    image={resolveStoryImage(content)}
                    layout="fill"
                  />
                }
                label={!isStartpage ? t(label, { ns: "tags" }) : ""}
                title={content.title || name}
                text={content.description || ""}
                link={
                  isStartpage ? (
                    <Button
                      variant="default"
                      href={slug}
                      title={content.title || name}
                      icon="arrow-right"
                    >
                      {t("learn-more")}
                    </Button>
                  ) : null
                }
              />
            );
          }
        })}
      </ContentGridSliderUi>
    </>
  );
};

export default ContentGridSlider;
