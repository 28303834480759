import React from "react";
import cn from "classnames";
import s from "./PastEvent.module.scss";

export const PastEvent = ({ children, variant }) => {
  const pastEventClass = cn(s.pastEvent, {
    [s.investor]: variant === "investor",
  });

  return <span className={pastEventClass}>{children}</span>;
};
