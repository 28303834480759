import React from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import AgendaUi from "../../ui/Agenda";
import { AgendaBlock } from "../../lib/storyblok/types/blocks/AgendaBlock";

const Agenda: StoryblokBlockComponent<AgendaBlock> = ({ title, items }) => {
  const { Item } = AgendaUi;

  return (
    <AgendaUi title={title}>
      {items?.map((item) => (
        <Item key={item._uid} time={item.time} description={item.description} />
      ))}
    </AgendaUi>
  );
};

export default Agenda;
