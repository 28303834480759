import React from "react";
import cn from "classnames";
import s from "../Speakers.module.scss";
import Icon from "../../Icon";

type Props = {
  text: string;
  isExpanded: boolean;
  toggleExpanded: () => void;
};

const ExpandButton = ({ text, isExpanded, toggleExpanded }: Props) => (
  <button className={cn(s.expandButton)} onClick={toggleExpanded}>
    {text}
    <Icon
      icon="caret-down"
      width={12}
      className={cn(s.icon, { [s.open]: isExpanded })}
    />
  </button>
);

export default ExpandButton;
