import React, { ReactNode } from "react";
import cn from "classnames";
import s from "./FilterPage.module.scss";
import c from "../../../styles/container.module.scss";
import Filter from "./components/Filter";
import Body from "./components/Body";
import Input from "./components/Input";
import Toolbar from "./components/Toolbar";
import NumberOfHits from "./components/NumberOfHits";
import Sort from "./components/Sort";
import Preamble from "./components/Preamble";
import Toggle from "./components/Toggle";

type FilterPageProps = {
  children: JSX.Element | JSX.Element[] | ReactNode | ReactNode[];
  type?: string;
  style?: React.CSSProperties;
};

const subComponents = {
  Filter,
  Body,
  Input,
  Toolbar,
  NumberOfHits,
  Sort,
  Preamble,
  Toggle,
};
type SubComponents = typeof subComponents;

const FilterPage: CWS<FilterPageProps, SubComponents> = ({
  children,
  type,
  style,
}) => (
  <section className={cn(s.filterPage, c.xWide, type && s[type])} style={style}>
    {children}
  </section>
);

FilterPage.Filter = Filter;
FilterPage.Body = Body;
FilterPage.Input = Input;
FilterPage.Toolbar = Toolbar;
FilterPage.NumberOfHits = NumberOfHits;
FilterPage.Sort = Sort;
FilterPage.Preamble = Preamble;
FilterPage.Toggle = Toggle;

export default FilterPage;
