import Image from "next/image";
import { IconType } from "../../Icon/Icon";
import s from "../Downloads.module.scss";

type Props = {
  href: string;
  icon: IconType;
  title: string;
  subtitle: string;
};

const LinkItem = ({ href, icon, title, subtitle }: Props) => {
  const onClick = () => {
    const link = document.createElement("a");
    link.href = href;
    link.download = href;
    link.target = "_blank";
    link.click();
  };

  return (
    <li className={s.item}>
      <button onClick={onClick} title={title}>
        {icon && (
          <Image src={`/svg/icons/${icon}.svg`} width={24} height={24} alt="" />
        )}
        <div className={s.itemWrapper}>
          <p className={s.title}>{title}</p>
          <p className={s.subtitle}>{subtitle}</p>
        </div>
      </button>
    </li>
  );
};

export default LinkItem;
