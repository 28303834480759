export const mediaqueries = {
  mobile: "(max-width: 480px)",
  tablet: "(min-width: 481px)",
  laptop: "(min-width: 769px)",
  laptopLarge: "(min-width: 901px)",
  desktop: "(min-width: 1025px)",
  xl: "(min-width: 1201px)",
  xxl: "(min-width: 1401px)",
  xxxl: "(min-width: 2560px)",
  touchscreen: "(hover: none)",
  notTouchscreen: "(hover: hover)",
} as const;

export type MediaQueryBreakpoints = keyof typeof mediaqueries;
