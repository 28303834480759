import s from "../Footer.module.scss";
import Icon, { IconType } from "../../Icon/Icon";
import FlexibleLinkWrapper from "../../../components/Nestable/LinkWrapper/FlexibleLinkWrapper";

type Props = {
  icon: IconType;
  href: string;
};

const SocialItem = ({ icon, href }: Props) => {
  let hostName = "";

  try {
    const url = new URL(href);
    hostName = getFriendlyName(url.host);
  } catch (error) {
    hostName = "Unknown";
  }

  function getFriendlyName(hostname: string): string {
    const parts = hostname.split(".").reverse();
    const domainPart = parts.length > 1 ? parts[1] : parts[0];

    return domainPart.charAt(0).toUpperCase() + domainPart.slice(1);
  }

  return (
    <li>
      <FlexibleLinkWrapper
        className={s.socialItem}
        href={href}
        title={hostName}
      >
        <span className="visuallyHidden">Tobii on {hostName}</span>
        <Icon icon={icon} color={s.iconcolor} width={20} />
      </FlexibleLinkWrapper>
    </li>
  );
};

export default SocialItem;
