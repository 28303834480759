import React from "react";
import cn from "classnames";
import s from "./Tabs.module.scss";

interface TabProps {
  index: number;
  title: string;
  isActive: boolean;
  onClick: () => void;
}

const Tab: React.FC<TabProps> = ({ index, title, isActive, onClick }) => (
  <li className={s.item} role="presentation">
    <button
      tabIndex={index}
      className={cn(s.tabButton, {
        [s.isActive]: isActive,
      })}
      onClick={onClick}
      role="tab"
      aria-selected={isActive}
      aria-controls={`tab-panel-${index}`}
    >
      {title}
    </button>
  </li>
);

export default React.memo(Tab);
