import React, { forwardRef, HTMLProps, ReactNode } from "react";
import cn from "classnames";
import s from "./DesktopMenu.module.scss";

type Props = {
  children: ReactNode;
  activeStyle: React.CSSProperties;
} & HTMLProps<HTMLUListElement>;

export const DesktopSubNavigationTabList = forwardRef<HTMLUListElement, Props>(
  ({ children, activeStyle, ...props }, ref) => {
    return (
      <>
        <div className={cn(s.DesktopSubNavigationTabListWrapper)}>
          <ul
            className={cn(s.DesktopSubNavigationTabList)}
            ref={ref}
            {...props}
          >
            {children}
            <div className={s.highlight} style={activeStyle} />
          </ul>
        </div>
      </>
    );
  }
);

DesktopSubNavigationTabList.displayName = "DesktopSubNavigationTabList";
