import s from "./Pagination.module.scss";
import Icon from "../Icon";
import cn from "classnames";

const Pagination = ({
  currentPage,
  pageCount,
  onPageChange,
  labelPrevious,
  labelNext,
  labelPage,
}) => {
  const handlePageChange = (newPage) => {
    onPageChange(newPage);
  };
  const totalPagesToShow = 3;

  const pageButtons: JSX.Element[] = [];

  const startPage = Math.max(1, currentPage - Math.floor(totalPagesToShow / 2));
  const endPage = Math.min(pageCount, startPage + totalPagesToShow - 1);

  if (currentPage > 2) {
    const isActive = currentPage === startPage;
    pageButtons.push(
      <>
        <li>
          <button
            key="first"
            onClick={() => handlePageChange(1)}
            className={isActive ? `${s.active} ${s.button}` : s.button}
            title={`${labelPage} 1`}
          >
            1
          </button>
        </li>
        <li>{currentPage > 2 && <span key="ellipsis-first">...</span>}</li>
      </>
    );
  }

  for (let page = startPage; page <= endPage; page++) {
    const isActive = currentPage === page;
    pageButtons.push(
      <li>
        <button
          key={page}
          onClick={() => handlePageChange(page)}
          className={isActive ? `${s.active} ${s.button}` : s.button}
          title={`${labelPage} ${page}`}
        >
          {page}
        </button>
      </li>
    );
  }

  if (currentPage < pageCount - 1) {
    if (currentPage < pageCount - 2) {
      pageButtons.push(
        <li>
          <span key="ellipsis-last">...</span>
        </li>
      );
    }
    const isActive = currentPage === pageCount;
    pageButtons.push(
      <li>
        <button
          key="last"
          onClick={() => handlePageChange(pageCount)}
          className={isActive ? `${s.active} ${s.button}` : s.button}
          title={`${labelPage} ${pageCount}`}
        >
          {pageCount}
        </button>
      </li>
    );
  }

  if (currentPage > 1) {
    const isActive = currentPage === startPage;
    pageButtons.unshift(
      <li>
        <button
          key="prev"
          onClick={() => handlePageChange(currentPage - 1)}
          className={isActive ? `${s.active} ${s.button}` : s.button}
          title={labelPrevious}
        >
          <Icon icon="caret-left" width={16} className={cn(s.icon)} />
        </button>
      </li>
    );
  }

  if (currentPage < pageCount) {
    const isActive = currentPage === pageCount;
    pageButtons.push(
      <li>
        <button
          key="next"
          onClick={() => handlePageChange(currentPage + 1)}
          className={isActive ? `${s.active} ${s.button}` : s.button}
          title={labelNext}
        >
          <Icon icon="caret-right" width={16} className={cn(s.icon)} />
        </button>
      </li>
    );
  }
  return <ul className={s.paginationContainer}>{pageButtons}</ul>;
};
export default Pagination;
