import React, { ReactNode } from "react";
import cn from "classnames";
import c from "../../styles/container.module.scss";
import s from "./InteractiveGrid.module.scss";
import bs from "../../styles/block.spacing.module.scss";
import ItemNonTouch from "./components/ItemNonTouch";
import ItemTouch from "./components/ItemTouch";
import VideoPlayer from "./components/VideoPlayer";
import BlockIntro from "../Common/BlockIntro";
import BlockBackground from "../Common/BlockBackground";
import { StoryblokRichTextFieldtype } from "../../lib/storyblok/types/fieldtypes/richText";

type Props = {
  title: string;
  description: StoryblokRichTextFieldtype;
  buttons?: ReactNode;
  children: ReactNode;
  style?: string;
  blockStyle?: string;
  innerSpacing: string;
  outerSpacing: string;
};

const InteractiveGrid: React.FC<Props> & {
  ItemNonTouch: typeof ItemNonTouch;
  ItemTouch: typeof ItemTouch;
  VideoPlayer: typeof VideoPlayer;
} = ({
  title,
  description,
  buttons,
  children,
  style = "none",
  innerSpacing,
  outerSpacing,
}) => {
  return (
    <section
      className={cn(s.interactiveGrid, bs[innerSpacing], bs[outerSpacing])}
      aria-label={title}
    >
      <BlockBackground style={style} opacity={100} />
      <BlockIntro
        style={style}
        title={title}
        description={description}
        buttons={buttons}
        featured={""}
        flexibleWidth={""}
        alignment={"alignCenter"}
      />
      <div className={cn(s.interactiveGridInner, c.xxWide)}>{children}</div>
    </section>
  );
};

InteractiveGrid.ItemNonTouch = ItemNonTouch;
InteractiveGrid.ItemTouch = ItemTouch;
InteractiveGrid.VideoPlayer = VideoPlayer;

export default InteractiveGrid;
