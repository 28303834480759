import React from "react";
import cn from "classnames";
import c from "../../../styles/container.module.scss";

export type Props = {
  src: string;
  title?: string;
};

const EditorialImage = ({ src, title }: Props) => {
  return (
    <iframe
      className={cn(c.narrow)}
      width="100%"
      height="330"
      src={src}
      title={title ?? "Video player"}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    />
  );
};

export default EditorialImage;
