import React, { ReactNode } from "react";
import { motion, AnimatePresence, Variant } from "framer-motion";
import s from "../TabsGrid.module.scss";
import cn from "classnames";

type Props = {
  children: ReactNode;
  isActive: boolean;
};

const tabVariants: Record<string, Variant> = {
  enter: {
    opacity: 0,
    x: 20,
    position: "absolute" as const,
    width: "100%",
  },
  center: {
    opacity: 1,
    x: 0,
    position: "relative" as const,
    width: "100%",
  },
  exit: {
    opacity: 0,
    x: -20,
    position: "absolute" as const,
    width: "100%",
  },
};

const Tab = ({ children, isActive }: Props) => (
  <AnimatePresence>
    {isActive && (
      <motion.div
        className={cn(s.tab, { [s.activeTab]: isActive })}
        initial="enter"
        animate="center"
        exit="exit"
        variants={tabVariants}
        transition={{ duration: 0.3 }}
      >
        {children}
      </motion.div>
    )}
  </AnimatePresence>
);

export default Tab;
