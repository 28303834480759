import React, { ReactNode } from "react";
import cn from "classnames";
import s from "./ColumnGrid.module.scss";
import c from "../../styles/container.module.scss";
import s2 from "../Editorial/components/EditorialCopy.module.scss";

import Item from "./components/Item";
import Markdown from "../Markdown";

type Props = {
  title: string;
  description?: string;
  buttons?: ReactNode;
  children: ReactNode;
  centered?: boolean;
  twoColumns?: boolean;
  style: "white" | "gray" | "signature" | "black";
  spacing: "onlyTop" | "onlyBottom" | "both" | "none";
  outerSpacing: "outerOnlyTop" | "outerOnlyBottom" | "outerBoth" | "outerNone";
  width: "fluid" | "wide";
};

const subComponents = { Item };
type SubComponents = typeof subComponents;

const ColumnGrid: CWS<Props, SubComponents> = ({
  title,
  description,
  buttons,
  children,
  twoColumns,
  centered,
  spacing,
  style,
  outerSpacing,
  width,
}) => {
  return (
    <section
      className={cn(
        s.columnGrid,
        s[style],
        s[outerSpacing],
        s[spacing],
        width === "wide" ? [c.xWide, s.wide] : ""
      )}
    >
      <div className={c.xWide}>
        <div
          className={cn(s.intro, {
            [s.centered]: centered,
          })}
        >
          {title && <h2>{title}</h2>}
          {description && (
            <div className={cn(s.description, s2.copy)}>
              <Markdown>{description}</Markdown>
            </div>
          )}
          <div className={cn(s.buttons)}>{buttons}</div>
        </div>
        <div
          className={cn(s.grid, {
            [s.twoColumns]: twoColumns,
          })}
        >
          {children}
        </div>
      </div>
    </section>
  );
};

ColumnGrid.Item = Item;

export default ColumnGrid;
