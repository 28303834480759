import { useCallback } from "react";

export const useArchiveWidget = () => {
  const initializeArchiveWidget = useCallback(
    (token: string, locale: string, demo: boolean) => {
      const DB_URL = "https://widget.datablocks.se/api/rose";

      const existingMF = window._MF;

      window._MF = {
        widget: "archive",
        token: token,
        data: [],
        url: DB_URL,
        ready: false,
        render: function () {
          this.ready = true;

          // Ensure styles are applied after widget is rendered
          const archiveElement = document.getElementById("archive-module");
          if (archiveElement) {
            archiveElement.style.display = "none";
            archiveElement.offsetHeight;
            archiveElement.style.display = "";
          }
        },
        push: function (conf) {
          this.data.push(conf);
        },
      };

      window._MF.push({
        query: "#archive-module",
        widget: "archive",
        locale: locale,
        token: token,
        demo: demo,
      });

      if (
        !document.querySelector(
          `script[src="${DB_URL}/assets/js/loader-v4.js"]`
        )
      ) {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.src = `${DB_URL}/assets/js/loader-v4.js`;
        document.body.appendChild(script);
      }

      return { existingMF };
    },
    []
  );

  const cleanupArchiveWidget = useCallback(
    (existingMF: typeof window._MF | undefined) => {
      if (existingMF) {
        window._MF = existingMF;
      } else {
        delete window._MF;
      }
    },
    []
  );

  return { initializeArchiveWidget, cleanupArchiveWidget };
};
