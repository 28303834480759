import React from "react";
import Icon from "../../Icon";
import s from "../Breadcrumbs.module.scss";

const BreadcrumbLink = ({ children }: { children: React.ReactNode }) => {
  return (
    <li>
      <Icon icon="caret-right" width={14} color={s.iconcolor} />
      {children}
    </li>
  );
};
export default BreadcrumbLink;
