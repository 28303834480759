import { useRouter } from "next/router";
import React from "react";
import { Configure, InstantSearch } from "react-instantsearch";
import { ResourceFinderStory } from "../../lib/storyblok/types/stories/ResourceFinderStory";
import { StoryblokStoryComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { useRelations } from "../../lib/storyblok/useRelations";
import {
  initAlgoliaIndecies,
  initAlgoliaSearch,
} from "../../lib/utils/AlgoliaSearchHelpers";
import { resolveDate } from "../../lib/utils/resolveDate";
import { resolveSlug } from "../../lib/utils/resolveSlug";
import { ViewModel } from "../../lib/viewModel/createViewModel";
import Button from "../../ui/Button";
import StoryFinder from "../Nestable/StoryFinder";
import ImageVaultImage from "../ImageVaultImage";
import FeaturedHero from "../../ui/FeaturedHero";
import { useTranslation } from "next-i18next";
import { history } from "instantsearch.js/es/lib/routers";

type VM = ViewModel<ResourceFinderStory>;

const indecies = initAlgoliaIndecies();
const searchClient = initAlgoliaSearch();

const ResourceFinderPage: StoryblokStoryComponent<VM> = ({ story, locale }) => {
  const { t } = useTranslation();
  const { getRelation } = useRelations();
  const {
    featuredResource,
    latestResource,
    noHeroOverlay,
    body,
    activateSearchField,
  } = story.content;
  const { query } = useRouter();
  const initialValue = typeof query.q === "string" ? query.q : undefined;

  const refinements = [
    {
      id: "solution",
      attribute: "solutions",
    },
    {
      id: "resourceType",
      attribute: "types",
    },
    {
      id: "product",
      attribute: "categories",
    },
    ,
  ];

  const featuredResourceStory = featuredResource
    ? getRelation(featuredResource)
    : latestResource;

  interface queryParameters {
    types?: string[];
    categories?: string[];
    solutions?: string[];
    languages?: string[];
  }

  const resourceInstantSearchRouting = {
    router: history({
      createURL({ qsModule, routeState, location }) {
        const urlParts = location.href.match(/^(.*?)\/resource-center/);
        const baseUrl = `${urlParts ? urlParts[1] : ""}/`;

        const queryParameters: queryParameters = {};

        if (routeState.types) {
          queryParameters.types = (routeState.types as string[]).map(
            encodeURIComponent
          );
        }

        if (routeState.categories) {
          queryParameters.categories = (routeState.categories as string[]).map(
            encodeURIComponent
          );
        }

        if (routeState.solutions) {
          queryParameters.solutions = (routeState.solutions as string[]).map(
            encodeURIComponent
          );
        }

        if (routeState.languages) {
          queryParameters.languages = (routeState.languages as string[]).map(
            encodeURIComponent
          );
        }

        const queryString = decodeURIComponent(
          qsModule.stringify(queryParameters, {
            addQueryPrefix: true,
            arrayFormat: "repeat",
          })
        );

        return `${baseUrl}resource-center${queryString}`;
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      parseURL({ qsModule, location }) {
        const {
          types = [],
          categories = [],
          solutions = [],
          languages = [],
        } = qsModule.parse(location.search.slice(1));

        const allTypes = Array.isArray(types) ? types : [types].filter(Boolean);

        const allCategories = Array.isArray(categories)
          ? categories
          : [categories].filter(Boolean);

        const allSolutions = Array.isArray(solutions)
          ? solutions
          : [solutions].filter(Boolean);

        const allLanguages = Array.isArray(languages)
          ? languages
          : [languages].filter(Boolean);

        const uiState: queryParameters = {
          types: allTypes.map(decodeURIComponent),
          categories: allCategories.map(decodeURIComponent),
          solutions: allSolutions.map(decodeURIComponent),
          languages: allLanguages.map(decodeURIComponent),
        };

        return uiState;
      },
    }),

    stateMapping: {
      stateToRoute(uiState) {
        const indexUiState = uiState[indecies[locale]] || {};

        return {
          types: indexUiState.refinementList?.types,
          categories: indexUiState.refinementList?.categories,
          solutions: indexUiState.refinementList?.solutions,
          languages: indexUiState.refinementList?.languages,
        };
      },

      routeToState(routeState) {
        return {
          [indecies[locale]]: {
            refinementList: {
              types: routeState.types,
              categories: routeState.categories,
              solutions: routeState.solutions,
              languages: routeState.languages,
            },
          },
        };
      },
    },
  };
  return (
    <InstantSearch
      indexName={indecies[locale]}
      searchClient={searchClient}
      routing={resourceInstantSearchRouting}
    >
      <Configure
        hitsPerPage={9}
        attributesToSnippet={["title:40", "content:40", "metaDescription:40"]}
        query={initialValue}
        filters={"storyType:resourcePage"}
      />
      {featuredResourceStory && (
        <FeaturedHero
          label={
            featuredResourceStory.content.resourceType[0] &&
            featuredResourceStory.content.resourceType[0]
          }
          title={featuredResourceStory.content.heroTitle}
          preamble={featuredResourceStory.content.heroPreamble}
          date={
            featuredResourceStory.content.heroDate &&
            resolveDate(featuredResourceStory.content.heroDate)
          }
          location={featuredResourceStory.content.eventLocation}
          buttons={
            <Button
              variant="inverted"
              href={resolveSlug(featuredResourceStory.full_slug)}
            >
              {t("learn-more")}
            </Button>
          }
          backgroundImage={
            <ImageVaultImage
              sizes="100vw"
              image={featuredResourceStory.content.heroImage}
              layout="fill"
            />
          }
          noOverlay={noHeroOverlay}
        />
      )}
      <StoryFinder
        value={initialValue}
        preamble={body}
        activateSearchField={activateSearchField}
        refinements={refinements}
      />
    </InstantSearch>
  );
};

export default ResourceFinderPage;
