import React from "react";
import { VideoBlock } from "../../lib/storyblok/types/blocks/VideoBlock";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { DynamicBlocks } from "../DynamicBlock";

const Video: StoryblokBlockComponent<VideoBlock> = ({ video }) => (
  <DynamicBlocks blocks={video} />
);

export default Video;
