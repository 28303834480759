import { useRouter } from "next/router";
import React from "react";
import { Configure, InstantSearch } from "react-instantsearch";
import { ArticleFinderStoryV2 } from "../../lib/storyblok/types/stories/ArticleFinderStoryV2";
import { StoryblokStoryComponent } from "../../lib/storyblok/types/StoryblokComponent";
import {
  initAlgoliaIndecies,
  initAlgoliaSearch,
} from "../../lib/utils/AlgoliaSearchHelpers";
import { ViewModel } from "../../lib/viewModel/createViewModel";
import StorySearcher from "../Nestable/StorySearcher";
import HeroBlock from "../Nestable/Hero";
import AnchorMenu from "../Layout/AnchorMenu/AnchorMenu";
import { splitIntoSections } from "../Layout/AnchorMenu/splitIntoSections";
import DualMediaBlock from "../Nestable/DualMediaBlock";
import { history } from "instantsearch.js/es/lib/routers";

type VM = ViewModel<ArticleFinderStoryV2>;
const indecies = initAlgoliaIndecies();
const searchClient = initAlgoliaSearch();

const ArticleFinderPageV2: StoryblokStoryComponent<VM> = ({
  story,
  locale,
}) => {
  const {
    anchorMenuButton,
    body,
    activateSearchField,
    hero,
    featuredArticle,
    editorsPick,
    mostPopular,
    highlight,
    topArticles,
    latestArticle,
    hideStickyMenu,
    style,
    outerSpacing,
    mediaBackground,
    imageKeepProportions,
    layout,
  } = story.content;

  const sections = splitIntoSections(body);

  const { query } = useRouter();
  const initialValue = typeof query.q === "string" ? query.q : undefined;
  const refinements = [
    { id: "eventType", attribute: "types" },
    { id: "product", attribute: "categories" },
    { id: "solution", attribute: "solutions" },
    { id: "other", attribute: "otherTags" },
  ];
  interface queryParameters {
    types?: string[];
    categories?: string[];
    solutions?: string[];
    otherTags?: string[];
    languages?: string[];
  }

  const articleInstantSearchRouting = {
    router: history({
      createURL({ qsModule, routeState, location }) {
        const urlParts = location.href.match(/^(.*?)\/blog/);
        const baseUrl = `${urlParts ? urlParts[1] : ""}/`;

        const queryParameters: queryParameters = {};

        if (routeState.types) {
          queryParameters.types = (routeState.types as string[]).map(
            encodeURIComponent
          );
        }

        if (routeState.categories) {
          queryParameters.categories = (routeState.categories as string[]).map(
            encodeURIComponent
          );
        }

        if (routeState.solutions) {
          queryParameters.solutions = (routeState.solutions as string[]).map(
            encodeURIComponent
          );
        }
        if (routeState.otherTags) {
          queryParameters.otherTags = (routeState.otherTags as string[]).map(
            encodeURIComponent
          );
        }

        if (routeState.languages) {
          queryParameters.languages = (routeState.languages as string[]).map(
            encodeURIComponent
          );
        }

        const queryString = decodeURIComponent(
          qsModule.stringify(queryParameters, {
            addQueryPrefix: true,
            arrayFormat: "repeat",
          })
        );

        return `${baseUrl}blog${queryString}`;
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      parseURL({ qsModule, location }) {
        const {
          types = [],
          categories = [],
          solutions = [],
          otherTags = [],
          languages = [],
        } = qsModule.parse(location.search.slice(1));

        const allTypes = Array.isArray(types) ? types : [types].filter(Boolean);

        const allCategories = Array.isArray(categories)
          ? categories
          : [categories].filter(Boolean);

        const allSolutions = Array.isArray(solutions)
          ? solutions
          : [solutions].filter(Boolean);

        const allOtherTags = Array.isArray(otherTags)
          ? otherTags
          : [otherTags].filter(Boolean);

        const allLanguages = Array.isArray(languages)
          ? languages
          : [languages].filter(Boolean);

        const uiState: queryParameters = {
          types: allTypes.map(decodeURIComponent),
          categories: allCategories.map(decodeURIComponent),
          solutions: allSolutions.map(decodeURIComponent),
          otherTags: allOtherTags.map(decodeURIComponent),
          languages: allLanguages.map(decodeURIComponent),
        };

        return uiState;
      },
    }),

    stateMapping: {
      stateToRoute(uiState) {
        const indexUiState = uiState[indecies[locale]] || {};

        return {
          types: indexUiState.refinementList?.types,
          categories: indexUiState.refinementList?.categories,
          solutions: indexUiState.refinementList?.solutions,
          otherTags: indexUiState.refinementList?.otherTags,
          languages: indexUiState.refinementList?.languages,
        };
      },

      routeToState(routeState) {
        return {
          [indecies[locale]]: {
            refinementList: {
              types: routeState.types,
              categories: routeState.categories,
              solutions: routeState.solutions,
              otherTags: routeState.otherTags,
              languages: routeState.languages,
            },
          },
        };
      },
    },
  };
  return (
    <InstantSearch
      indexName={indecies[locale]}
      searchClient={searchClient}
      routing={articleInstantSearchRouting}
    >
      <Configure
        hitsPerPage={9}
        attributesToSnippet={["title:40", "content:40", "metaDescription:40"]}
        query={initialValue}
        filters={"storyType:articlePage"}
      />
      {hero?.[0] && (
        <HeroBlock
          {...hero?.[0]}
          anchorLinksMenu={
            sections.length > 1 &&
            !hideStickyMenu && (
              <AnchorMenu sections={sections} button={anchorMenuButton?.[0]} />
            )
          }
        />
      )}

      <DualMediaBlock
        style={style}
        featuredArticle={featuredArticle}
        topArticles={topArticles}
        latestArticle={latestArticle}
        editorsPick={editorsPick}
        mostPopular={mostPopular}
        highlight={highlight}
        _uid={""}
        component={"DualMediaBlockBlock"}
        _editable={""}
        featured={false}
        reversed={false}
        outerSpacing={outerSpacing}
        mediaBackground={mediaBackground}
        imageKeepProportions={imageKeepProportions}
        layout={layout}
      />

      {story.content && (
        <StorySearcher
          story={story}
          value={initialValue}
          activateSearchField={activateSearchField}
          refinements={refinements}
        />
      )}
    </InstantSearch>
  );
};

export default ArticleFinderPageV2;
