import { ReactNode } from "react";
import cn from "classnames";
import s from "../Downloads.module.scss";
import c from "../../../styles/container.module.scss";

type Props = {
  expandable?: boolean;
  children: ReactNode;
};

const List = ({ expandable, children }: Props) => (
  <ul className={cn(expandable ? s.expandableList : s.list, c.xWide)}>
    {children}
  </ul>
);

export default List;
