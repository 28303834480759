/* eslint-disable */
import { useViewmodel } from "../storyblok/ViewmodelContext";
import { resolveDate } from "./resolveDate";

const isValidDate = (date: Date) => !isNaN(date.getTime());
export const FormatDateRange = (startDate, endDate) => {
  const { locale } = useViewmodel();

  try {
    const startDateObj = startDate ? new Date(startDate) : null;
    const endDateObj = new Date(endDate);

    if (!isValidDate(endDateObj)) {
      throw new Error("Invalid End Date");
    }

    // Resolve date components only once
    const startMonth = startDateObj
      ? new Date(startDateObj).toLocaleString(locale, { month: "long" })
      : "";
    const endMonth = new Date(endDateObj).toLocaleString(locale, {
      month: "long",
    });
    const startDay = startDateObj
      ? new Date(startDateObj).toLocaleString(locale, { day: "numeric" })
      : "";
    const endDay = new Date(endDateObj).toLocaleString(locale, {
      day: "numeric",
    });
    const startYear = startDateObj
      ? new Date(startDateObj).toLocaleString(locale, { year: "numeric" })
      : "";
    const endYear = new Date(endDateObj).toLocaleString(locale, {
      year: "numeric",
    });

    let formattedDateRange = "";

    if (startDateObj) {
      if (startYear === endYear) {
        if (startMonth === endMonth) {
          if (startDay !== endDay) {
            formattedDateRange = `${startMonth} ${startDay} - ${endDay}, ${endYear}`;
          } else {
            formattedDateRange = `${startMonth} ${startDay}, ${endYear}`;
          }
        } else {
          formattedDateRange = `${startMonth} ${startDay} - ${endMonth} ${endDay}, ${endYear}`;
        }
      } else {
        formattedDateRange = `${startMonth} ${startDay}, ${startYear} - ${endMonth} ${endDay}, ${endYear}`;
      }
    } else {
      formattedDateRange = `${startMonth} ${startDay}, ${startYear} - ${endMonth} ${endDay}, ${endYear}`;
    }

    return formattedDateRange;
  } catch (error) {
    console.error("Error formatting date range:", error);
    return "Date Range Error";
  }
};

export const FormatDate = (date: string) => {
  const { locale } = useViewmodel();

  return resolveDate(date, locale);
};
