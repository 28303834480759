import React, { ElementType, HTMLProps } from "react";
import s from "../SearchHits.module.scss";
import cn from "classnames";

type HitHeadingProps = HTMLProps<HTMLHeadingElement> & {
  as?: ElementType;
};

const HitHeading = ({
  as: Heading = "h2",
  className,
  ...props
}: HitHeadingProps) => (
  <Heading {...props} className={cn(className, s.title)} />
);

export default HitHeading;
