import { ReactNode } from "react";
import cn from "classnames";

import s from "./FeaturedTabs.module.scss";
import c from "../../styles/container.module.scss";

import TabList from "./components/TabList";
import Tab from "./components/Tab";

type Props = {
  titleLabel: string;
  title: string;
  tabs: ReactNode;
  children: ReactNode;
};

const subComponents = {
  TabList,
  Tab,
};
type SubComponents = typeof subComponents;

const FeaturedTabs: CWS<Props, SubComponents> = ({
  titleLabel,
  tabs,
  children,
}) => {
  return (
    <section className={s.featuredTabs}>
      <h2 className={cn(c.wide, s.title)}>{titleLabel}</h2>
      <div className={s.tabs}>{tabs}</div>
      <div>{children}</div>
    </section>
  );
};

FeaturedTabs.TabList = TabList;
FeaturedTabs.Tab = Tab;

export default FeaturedTabs;
