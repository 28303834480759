import { createContext, useContext } from "react";

type FilterContextProps = {
  name: string;
};

export const FilterListContext = createContext<FilterContextProps>({
  name: "default",
});

export const useFilterListContext = () =>
  useContext<FilterContextProps>(FilterListContext);
