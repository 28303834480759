import React from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import SpeakersUi from "../../ui/Speakers";
import { SpeakersBlock } from "../../lib/storyblok/types/blocks/SpeakersBlock";
import ImageVaultImage from "../ImageVaultImage";
import { storyblokEditable } from "@storyblok/react";
import { useTranslation } from "next-i18next";

const Speakers: StoryblokBlockComponent<SpeakersBlock> = ({
  title,
  speakers,
}) => {
  const { Speaker } = SpeakersUi;
  const { t } = useTranslation();

  return (
    <SpeakersUi title={title} noOfSpeakers={speakers.length}>
      {speakers.map((speaker) => (
        <div key={speaker._uid} {...storyblokEditable(speaker)}>
          <Speaker
            image={
              <ImageVaultImage
                sizes="256px"
                image={speaker.image}
                layout="fill"
              />
            }
            name={speaker.name}
            title={speaker.title}
            description={speaker.description}
            linkedinLink={speaker.linkedinLink?.url}
            showMoreLabel={t("show-more")}
            showLessLabel={t("show-less")}
          />
        </div>
      ))}
    </SpeakersUi>
  );
};

export default Speakers;
