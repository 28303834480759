import React from "react";
import cn from "classnames";
import s from "../VideoCarousel.module.scss";
import Video from "../../../../components/Nestable/Video";

const CarouselItemVideo = ({ title, description, video, style }) => {
  return (
    <>
      <div className={cn(s.item, s[style])}>
        <div className={cn(s.videoContainer)}>
          <Video
            video={video}
            _uid={video[0]?._uid}
            _editable={video[0]?._editable}
            component={"video"}
          />
        </div>

        <div className={cn(s.inner)}>
          <div className={s.content}>
            {title && <h4>{title}</h4>}
            {description && <p>{description}</p>}
          </div>
        </div>
      </div>
    </>
  );
};

export default CarouselItemVideo;
