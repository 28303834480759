import React from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import ContactUi from "../../ui/Contact";
import { ContactBlock } from "../../lib/storyblok/types/blocks/ContactBlock";
import ImageVaultImage from "../ImageVaultImage";

const Contact: StoryblokBlockComponent<ContactBlock> = ({
  heading,
  image,
  name,
  title,
  email,
  phone,
}) => (
  <ContactUi
    heading={heading}
    image={
      <ImageVaultImage sizes="96px" quality={90} image={image} layout="fill" />
    }
    name={name}
    title={title}
    email={email}
    phone={phone}
  />
);

export default Contact;
