import React, { ReactNode, useEffect } from "react";
import cn from "classnames";
import s from "./MobileMenu.module.scss";

type Props = {
  children: ReactNode;
};

export const MobileInner = ({ children }: Props) => {
  useEffect(() => {
    const setDynamicHeight = () => {
      const dynamicHeight = window.innerHeight + "px";
      document.documentElement.style.setProperty(
        "--mobile-inner-height",
        dynamicHeight
      );
    };

    setDynamicHeight();
    window.addEventListener("resize", setDynamicHeight);
    window.addEventListener("orientationchange", setDynamicHeight);

    return () => {
      window.removeEventListener("resize", setDynamicHeight);
      window.removeEventListener("orientationchange", setDynamicHeight);
    };
  }, []);

  return (
    <div
      className={cn(s.mobileInner)}
      style={{
        height: "calc(var(--mobile-inner-height) - var(--header-height)",
      }}
    >
      {children}
    </div>
  );
};
