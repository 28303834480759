import cn from "classnames";
import { CSSProperties, forwardRef, HTMLProps } from "react";

import s from "../AnchorMenu.module.scss";

type Props = {
  text: string;
  isActive: boolean;
  isMobileItem?: boolean;
  style?: CSSProperties;
} & HTMLProps<HTMLAnchorElement>;

const Item = forwardRef<HTMLAnchorElement, Props>(
  ({ text, isActive, isMobileItem = false, style, ...rest }, ref) => (
    <li
      className={cn(s.item, {
        [s.active]: isActive,
        [s.mobileItem]: isMobileItem,
      })}
      style={style && style}
    >
      <a ref={ref} title={text} className={s.link} {...rest}>
        {text}
      </a>
    </li>
  )
);

Item.displayName = "Item";

export default Item;
