import { deduceDomain } from "../deduceDomain";

const getThumbnailUrl = (item, domain) => {
  if (!item) return "";
  const imageUrl = item.Url;
  return `${domain}/imagevault/${imageUrl.split("/").slice(3).join("/")}`;
};

export const getSchemaImageTwentyThree = (
  thumbnail,
  schemaImage,
  site,
  locale
) => {
  const domain = deduceDomain(locale, site);

  return (
    getThumbnailUrl(schemaImage?.item?.MediaConversions?.[0], domain) ||
    getThumbnailUrl(thumbnail?.item?.MediaConversions?.[0], domain) ||
    ""
  );
};

export const getSchemaImageYoutube = (
  thumbnail,
  id,
  useYtThumbFallback,
  site,
  locale
) => {
  const domain = deduceDomain(locale, site);

  const fallbackImageUrl = useYtThumbFallback
    ? `https://i.ytimg.com/vi/${id}/hqdefault.jpg`
    : `https://i.ytimg.com/vi/${id}/maxresdefault.jpg`;

  return (
    getThumbnailUrl(thumbnail?.item?.MediaConversions?.[0], domain) ||
    fallbackImageUrl
  );
};

export const getSchemaDuration = (seconds) => {
  if (!seconds) return "";

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  return `PT${hours}H${minutes}M${secs}S`;
};

export const getSchemaTitle = (title, schemaTitle) => {
  return title || schemaTitle || "";
};

export const getSchemaDescription = (description, schemaDescription) => {
  return description || schemaDescription || "";
};
