import React, { HTMLAttributes } from "react";
import cn from "classnames";

import s from "./Input.module.scss";
import f from "../CTAForm/CTAForm.module.scss";

type Props = HTMLAttributes<HTMLInputElement> & {
  type?:
    | "text"
    | "number"
    | "email"
    | "password"
    | "tel"
    | "checkbox"
    | "radio";
  label?: string;
  name: string;
  inverted?: boolean;
  black?: boolean;
  description?: string;
  placeholder?: string;
  columns?: "two";
  labelClassName?: string;
  inputClassName?: string;
  value?: string;
  required?: boolean;
  pattern?: string;
};

const Input = ({
  type = "text",
  columns,
  inverted,
  black,
  label,
  description,
  labelClassName,
  inputClassName,
  required,
  pattern,
  ...props
}: Props) => {
  return (
    <label
      className={cn(f.inputGroup, labelClassName, {
        [f.twoColumns]: columns === "two",
        [f.inverted]: inverted,
        [s.inverted]: inverted,
      })}
    >
      {label && <span className={black ? s.blackLabel : f.label}>{label}</span>}
      {description && <span className={f.description}>{description}</span>}
      <input
        type={type}
        {...props}
        className={cn(s.input, inputClassName, {
          [s.input]: type === "text",
          [s.inverted]: inverted,
          [s.black]: black,
        })}
        required={required}
        pattern={pattern}
      />
    </label>
  );
};

export default Input;
