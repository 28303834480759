import Link from "next/link";
import { EditorialImageBlock } from "../../lib/storyblok/types/blocks/EditorialImageBlock";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { useLinks } from "../../lib/storyblok/useLinks";
import { imageSizes } from "../../lib/utils/imageSizes";
import Editorial from "../../ui/Editorial/Editorial";
import ConditionalWrapper from "../ConditionalWrapper";
import ImageVaultImage from "../ImageVaultImage";

const EditorialImage: StoryblokBlockComponent<EditorialImageBlock> = ({
  image,
  caption,
  wide,
  link,
  linkTarget,
  linkTitle,
}) => {
  const resolveLink = useLinks();
  if (!image.item) return null;
  const hasLink = link ? link.id.length > 0 || link.url.length > 0: false;
  const target = linkTarget ? "_blank" : "_self";
  const href = link ? resolveLink(link) : "/";
  const linkProps = {
    title: linkTitle,
    href,
    target,
  };
  return (
    <Editorial.Image
      image={
        <ConditionalWrapper
          shouldWrap={hasLink}
          wrapper={(children) => (
            <Link {...linkProps}>
              <a {...linkProps}>{children}</a>
            </Link>
          )}
        >
          <ImageVaultImage
            sizes={imageSizes(
              "100vw",
              wide ? { xl: "1200px" } : { laptop: "720px" }
            )}
            image={image}
            layout="fill"
          />
        </ConditionalWrapper>
      }
      caption={caption}
      wide={wide}
    />
  );
};

export default EditorialImage;
