import { useRouter } from "next/router";
import React from "react";
import { Configure, InstantSearch } from "react-instantsearch";
import { InvestorEventFinderStory } from "../../lib/storyblok/types/stories/InvestorEventFinderStory";
import { StoryblokStoryComponent } from "../../lib/storyblok/types/StoryblokComponent";
import {
  initAlgoliaInvestorIndecies,
  initAlgoliaSearch,
} from "../../lib/utils/AlgoliaSearchHelpers";
import { ViewModel } from "../../lib/viewModel/createViewModel";
import { DynamicBlocks } from "../DynamicBlock";
import StoryFinder from "../Nestable/StoryFinder";
import { history } from "instantsearch.js/es/lib/routers";

type VM = ViewModel<InvestorEventFinderStory>;
const indecies = initAlgoliaInvestorIndecies();
const searchClient = initAlgoliaSearch();

const InvestorEventFinderPage: StoryblokStoryComponent<VM> = ({
  story,
  locale,
}) => {
  const { hero, body, activateSearchField } = story.content;
  const { query } = useRouter();
  const initialValue = typeof query.q === "string" ? query.q : undefined;
  const refinements = [{ id: "eventType", attribute: "types" }];
  interface queryParameters {
    types?: string[];
  }

  const investorEventFinderInstantSearchRouting = {
    router: history({
      createURL({ qsModule, routeState, location }) {
        const pageUrl =
          locale === "sv"
            ? "investerare/finansiell-kalender"
            : "investors/calendar";
        const urlParts =
          locale === "sv"
            ? location.href.match(/^(.*?)\/investerare\/finansiell-kalender/)
            : location.href.match(/^(.*?)\/investors\/calendar/);

        const baseUrl = `${urlParts ? urlParts[1] : ""}/`;

        const queryParameters: queryParameters = {};

        if (routeState.types) {
          queryParameters.types = (routeState.types as string[]).map(
            encodeURIComponent
          );
        }

        const queryString = decodeURIComponent(
          qsModule.stringify(queryParameters, {
            addQueryPrefix: true,
            arrayFormat: "repeat",
          })
        );

        return `${baseUrl}${pageUrl}${queryString}`;
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      parseURL({ qsModule, location }) {
        const { types = [] } = qsModule.parse(location.search.slice(1));

        const allTypes = Array.isArray(types) ? types : [types].filter(Boolean);

        const uiState: queryParameters = {
          types: allTypes.map(decodeURIComponent),
        };

        return uiState;
      },
    }),

    stateMapping: {
      stateToRoute(uiState) {
        const indexUiState = uiState[indecies[locale]] || {};

        return {
          types: indexUiState.refinementList?.types,
        };
      },

      routeToState(routeState) {
        return {
          [indecies[locale]]: {
            refinementList: {
              types: routeState.types,
            },
          },
        };
      },
    },
  };
  return (
    <InstantSearch
      indexName={indecies[locale]}
      searchClient={searchClient}
      routing={investorEventFinderInstantSearchRouting}
    >
      <Configure
        hitsPerPage={6}
        attributesToSnippet={["title:40", "content:40", "metaDescription:40"]}
        query={initialValue}
        filters={"storyType:eventPage"}
      />
      <DynamicBlocks blocks={hero} />
      <StoryFinder
        renderImages={false}
        pageType={"investorEventFinderPage"}
        value={initialValue}
        preamble={body}
        activateSearchField={activateSearchField}
        refinements={refinements}
      />
    </InstantSearch>
  );
};

export default InvestorEventFinderPage;
