import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { MarkdownBlock } from "../../lib/storyblok/types/blocks/MarkdownBlock";
import MarkdownUi from "../../ui/Markdown";

const Markdown: StoryblokBlockComponent<MarkdownBlock> = ({ body }) => {
  return (
    <section>
      <MarkdownUi
        components={{
          li: (child) => (
            <li>
              <p>{child}</p>
            </li>
          ),
        }}
      >
        {body}
      </MarkdownUi>
    </section>
  );
};

export default Markdown;
