import Script from "next/script";

type Props = { id: string | null };

const BaiduTracking = ({ id }: Props) =>
  id ? (
    <Script
      id={`baidu-tracking-${id}`}
      strategy="lazyOnload"
      dangerouslySetInnerHTML={{
        __html: `
          var _hmt = _hmt || [];
          (function() {
            var hm = document.createElement("script");
            hm.src = "https://hm.baidu.com/hm.js?${id}";
            var s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(hm, s);
          })();`,
      }}
    />
  ) : null;

export default BaiduTracking;
