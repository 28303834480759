import React, { forwardRef, ReactNode } from "react";
import { motion, HTMLMotionProps } from "framer-motion";
import s from "./DesktopMenu.module.scss";
import FlexibleLinkWrapper from "../../../../components/Nestable/LinkWrapper/FlexibleLinkWrapper";

type Props = {
  title: string;
  description: string;
  image: ReactNode;
  link: string;
  animateSidebar: boolean;
} & HTMLMotionProps<"div">;

export const DesktopSubNavigationCTA = forwardRef<HTMLDivElement, Props>(
  (
    { title, description, link, image, animateSidebar, ...anchorProps },
    ref
  ) => {
    return (
      <motion.div
        initial={{ opacity: 0, x: -16 }}
        animate={animateSidebar ? { opacity: 1, x: 0 } : { opacity: 0, x: -16 }}
        transition={{ duration: 0.3, delay: 0 }}
        ref={ref}
      >
        <FlexibleLinkWrapper
          className={s.DesktopSubNavigationCTA}
          href={link}
          title={title}
          {...anchorProps}
        >
          {image && (
            <div className={s.imageContainer}>
              <figure className={s.figure}>{image}</figure>
            </div>
          )}

          <div className={s.inner}>
            {title && <span className={s.title}>{title}</span>}
            {description && <p className={s.description}>{description}</p>}
          </div>
        </FlexibleLinkWrapper>
      </motion.div>
    );
  }
);

DesktopSubNavigationCTA.displayName = "DesktopSubNavigationCTA";
