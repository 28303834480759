// ui/Datablock/index.tsx
import React from "react";
import cn from "classnames";
import c from "../../styles/container.module.scss";
import bs from "../../styles/block.spacing.module.scss";
import s from "./Datablock.module.scss";
import type { StoryblokRichTextFieldtype } from "../../lib/storyblok/types/fieldtypes/richText";
import BlockIntro from "../Common/BlockIntro";
import ShareHolders from "./components/ShareHolders";
import InsidersTransactions from "./components/InsidersTransactions";
import Sharegraph from "./components/Sharegraph";
import Calendar from "./components/Calendar";
import Archive from "./components/Archive";
import Subscription from "./components/Subscription";
import KeyFigures from "./components/KeyFigures";
import InvestorGrid from "./components/InvestorGrid";

type Props = {
  title: string;
  description: StoryblokRichTextFieldtype;
  widgets: string;
  locale: string;
  outerSpacing: string;
  eventsFilterPage?: string;
  pressReleaseFilterPage?: string;
};

const DatablockUi: React.FC<Props> = ({
  title,
  description,
  widgets,
  locale,
  outerSpacing,
  eventsFilterPage,
  pressReleaseFilterPage,
}) => {
  const renderWidgetGroup = () => {
    switch (widgets) {
      case "investorGrid":
        return (
          <InvestorGrid
            locale={locale}
            eventsFilterPage={eventsFilterPage}
            pressReleaseFilterPage={pressReleaseFilterPage}
          />
        );
      case "shareholders":
        return <ShareHolders locale={locale} demo={false} />;
      case "insidersTransactions":
        return <InsidersTransactions locale={locale} demo={false} />;
      case "sharegraph":
        return <Sharegraph locale={locale} demo={false} />;
      case "financialCalendar":
        return <Calendar locale={locale} demo={false} />;
      case "archive":
        return <Archive locale={locale} demo={false} />;
      case "subscription":
        return <Subscription locale={locale} demo={false} />;
      case "keyFiguresQuarter":
        return <KeyFigures locale={locale} demo={false} type="quarter" />;
      case "keyFiguresOverview":
        return <KeyFigures locale={locale} demo={false} type="overview" />;
      case "keyFiguresSegments":
        return <KeyFigures locale={locale} demo={false} type="segments" />;
      case "keyFiguresPerformanceMetrics":
        return (
          <KeyFigures locale={locale} demo={false} type="performanceMetrics" />
        );

      default:
        return null;
    }
  };

  return (
    <section className={cn(s.datablock, c.xWide, bs[outerSpacing])}>
      <BlockIntro
        style={"none"}
        title={title}
        description={description}
        buttons={""}
        featured={""}
        flexibleWidth={""}
        alignment={"alignLeft"}
      />
      <div className={cn(s.content)}>{renderWidgetGroup()}</div>
    </section>
  );
};

export default DatablockUi;
