import { resolveSlug } from "../../../lib/utils/resolveSlug";
import s from "../ContentGrid.module.scss";

type Props = {
  title: string;
  href: string;
  date: string;
  dateTime: string;
};

const ListItem = ({ title, href, date, dateTime }: Props) => {
  return (
    <li className={s.listItem}>
      <time dateTime={dateTime}>{date}</time>
      <a href={resolveSlug(href)} title={title}>
        {title}
      </a>
    </li>
  );
};

export default ListItem;
