import { useTranslation } from "next-i18next";
import React from "react";
import { Highlight, Snippet, useInfiniteHits } from "react-instantsearch";
import Button from "../../ui/Button";
import ProgressIndicator from "../../ui/ProgressIndicator";
import SearchHits from "../../ui/SearchHits";
import { resolveSlug } from "../../lib/utils/resolveSlug";

type Hit = {
  objectID: string;
  fullSlug: string;
  storyType: string;
  title: string;
  publishedAt?: string;
  metaTitle?: string;
  metaDescription?: string;
  image?: string;
  content?: string;
  features?: Record<string, unknown>[];
};

const getBodyAttribute = (hit: Hit) =>
  typeof hit.metaDescription === "string" &&
  hit.metaDescription.trim().length > 0
    ? "metaDescription"
    : "content";

const InfiniteHits = () => {
  const { t } = useTranslation("searchpage");
  const { hits, results, showMore } = useInfiniteHits<Hit>();
  const total = results?.nbHits ?? 0;
  const current = hits.length;

  const showPagination = current < total;

  const totalLabel = t("number-of-hits", { count: total });
  const progressLabel = `${t("showing", { amount: current })}/${totalLabel}`;

  return (
    <>
      <SearchHits total={totalLabel}>
        {hits.map((hit) => (
          <SearchHits.Hit key={hit.objectID}>
            <SearchHits.Heading>
              <a href={resolveSlug(hit.fullSlug)}>
                <Highlight hit={hit} attribute="title" />
              </a>
            </SearchHits.Heading>
            <SearchHits.Body>
              <Snippet hit={hit} attribute={getBodyAttribute(hit)} />
            </SearchHits.Body>
            <SearchHits.Tags>
              <SearchHits.Tag tag={t(`pages.${hit.storyType}`)} />
            </SearchHits.Tags>
          </SearchHits.Hit>
        ))}
      </SearchHits>
      {showPagination && (
        <SearchHits.Pagination>
          <ProgressIndicator current={current} total={total}>
            {progressLabel}
          </ProgressIndicator>
          <Button href="" onClick={showMore}>
            {t("load-more")}
          </Button>
        </SearchHits.Pagination>
      )}
    </>
  );
};

export default InfiniteHits;
