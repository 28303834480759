import React from "react";
import FilterList from "../FilterList";

const FiltersSection = ({ label, allItems, activeItems, setState }) => {
  const sortedItems = allItems.slice().sort((a, b) => b.name - a.name);
  return (
    <FilterList label={label} layout="static">
      {sortedItems.map(({ id, name, count }) => {
        const checked = activeItems.includes(id.toString());
        return (
          <FilterList.Filter
            key={id}
            value={name}
            amount={Number(count)}
            checked={checked}
            onChange={() =>
              setState((prev) =>
                checked
                  ? prev.filter((c) => Number(c) !== id)
                  : [...prev, id.toString()]
              )
            }
          >
            {name}
          </FilterList.Filter>
        );
      })}
    </FilterList>
  );
};
export default FiltersSection;
