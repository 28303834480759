import React, { ReactNode, useEffect, useCallback, useRef } from "react";
import cn from "classnames";
import s from "./Header.module.scss";
import c from "../../styles/container.module.scss";

type Props = {
  isDesktopSubNavOpen: boolean;
  isTobiiGroup?: boolean;
  activeSubNav: ReactNode;
  children: ReactNode;
  onCloseSubNav: () => void;
};

const Header = ({
  isTobiiGroup,
  children,
  activeSubNav,
  isDesktopSubNavOpen,
  onCloseSubNav,
}: Props) => {
  const headerRef = useRef<HTMLElement>(null);

  const handleOverlayInteraction = useCallback(
    (event: React.MouseEvent | React.KeyboardEvent) => {
      if (
        event.type === "click" ||
        (event as React.KeyboardEvent).key === "Enter"
      ) {
        onCloseSubNav();
      }
    },
    [onCloseSubNav]
  );

  const handleScroll = useCallback(() => {
    if (isDesktopSubNavOpen && headerRef.current) {
      const headerBottom =
        headerRef.current.getBoundingClientRect().bottom + 58; // 58 is the height of the tabs
      if (headerBottom < 0) {
        onCloseSubNav();
      }
    }
  }, [isDesktopSubNavOpen, onCloseSubNav]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <>
      <header
        ref={headerRef}
        className={cn(s.header, { [s.tobiiGroup]: isTobiiGroup })}
        data-tobii-group={isTobiiGroup ? "true" : undefined}
      >
        <div className={cn(s.headerInner)}>
          <div className={cn(c.xWide)}>{children}</div>
        </div>
        {activeSubNav}
      </header>
      {isDesktopSubNavOpen && (
        <div
          className={s.subNavOverlay}
          onClick={handleOverlayInteraction}
          onKeyDown={handleOverlayInteraction}
          tabIndex={0}
          role="button"
          aria-label="Close"
        />
      )}
    </>
  );
};

export default Header;
