import React, { forwardRef } from "react";
import { motion } from "framer-motion";
import cn from "classnames";
import Icon from "../../../Icon/Icon";
import s from "./MobileMenu.module.scss";
import { useIsExternalLink } from "../../../../hooks/useIsExternalLink";
import { useTranslation } from "next-i18next";
import FlexibleLinkWrapper from "../../../../components/Nestable/LinkWrapper/FlexibleLinkWrapper";

type Props = {
  text: string;
  href: string;
  onClick?: () => void;
  directLink?: boolean;
  tab: boolean;
  index: number;
};

export const MobileNavItem = forwardRef<HTMLAnchorElement, Props>(
  ({ text, href, onClick, directLink, index, tab }, ref) => {
    const { isExternalLink } = useIsExternalLink(href);
    const { t } = useTranslation();

    return (
      <>
        <motion.li
          initial={{
            opacity: 0,
            x: 30,
          }}
          animate={{ opacity: 1, x: 0 }}
          transition={{
            duration: 0.3,
            delay: index * 0.06,
          }}
          className={cn(s.navItem, {
            [s.tabItem]: tab,
          })}
        >
          {tab ? (
            <div tabIndex={index}>{text && <span>{text}</span>}</div>
          ) : (
            <FlexibleLinkWrapper
              ref={ref}
              href={href}
              title={text}
              aria-label={text}
              tabIndex={index}
            >
              {text && <span>{text}</span>}
              {directLink && isExternalLink && (
                <Icon icon="arrow-up-right" color={s.icon} width={20} />
              )}
            </FlexibleLinkWrapper>
          )}
          {!directLink && onClick && (
            <button
              onClick={onClick}
              className={s.navItemButton}
              aria-label={t("open-submenu-for", { text: text })}
              tabIndex={0}
            >
              <Icon icon="caret-right" color={s.icon} width={20} />
            </button>
          )}
        </motion.li>
      </>
    );
  }
);

MobileNavItem.displayName = "MobileNavItem";
