const excludedProductTags = [
  "Tobii eye tracker 5",
  "Tobii 4C",
  "Tobii EyeX",
  "Tobii Pro SDK / API",
  "E-Prime",
  "iMotions",
  "ISVs",
  "Flag",
  "Eye tracking competitor",
  "Dynavox",
  "Cites Tobii Publication",
];

export default excludedProductTags;
