import React, { ReactNode } from "react";
import s from "../SearchHits.module.scss";

type FilterProps = {
  children: ReactNode;
  className?: string;
};

const Hit = ({ children, className, ...props }: FilterProps) => (
  <li className={className} {...props}>
    <article className={s.hit}>{children}</article>
  </li>
);

export default Hit;
