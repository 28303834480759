import React, { forwardRef, HTMLProps, useEffect, useRef } from "react";
import { useIsExternalLink } from "../../../../hooks/useIsExternalLink";
import cn from "classnames";
import s from "./DesktopMenu.module.scss";

type Props = {
  title: string;
  isActive: boolean;
  onClick: () => void;
  isTobiiGroup?: boolean;
  hasSubNav: boolean;
  href?: string;
} & HTMLProps<HTMLSpanElement>;

type RefType = HTMLSpanElement | HTMLAnchorElement;

export const DesktopNavItem = forwardRef<RefType, Props>(
  (
    { title, isActive, onClick, hasSubNav, isTobiiGroup, href, ...spanProps },
    forwardedRef
  ) => {
    const { isExternalLink } = useIsExternalLink(href);

    const linkRef = useRef<HTMLAnchorElement | HTMLSpanElement | null>(null);

    useEffect(() => {
      if (isActive && linkRef.current) {
        const linkRect = linkRef.current.getBoundingClientRect();
        const parentRect =
          linkRef.current.offsetParent?.getBoundingClientRect();
        const leftPosition =
          linkRect.left - (parentRect?.left || 0) + linkRect.width / 2;

        linkRef.current.style.setProperty("--arrow-left", `${leftPosition}px`);
      }
    }, [isActive]);

    const handleClick = () => {
      onClick();
    };

    const navItemClasses = cn(s.navItem, {
      [s.isActive]: isActive,
    });

    const navItemLinkClasses = cn(s.navItemLink, {
      [s.externalLink]: isExternalLink,
      [s.isActive]: isActive,
    });

    const LinkContent = (
      <>
        {title}
        {hasSubNav && <span className={s.navItemArrow} aria-hidden="true" />}
      </>
    );

    const setRefs = (node: HTMLAnchorElement | HTMLSpanElement | null) => {
      linkRef.current = node;
      if (typeof forwardedRef === "function") {
        forwardedRef(node);
      } else if (forwardedRef) {
        (forwardedRef as React.MutableRefObject<RefType | null>).current = node;
      }
    };

    const navItemLinkProps = {
      className: navItemLinkClasses,
      ref: setRefs,
      title,
      onClick: handleClick,
      onKeyDown: (e: React.KeyboardEvent) => {
        if (e.key === "Enter" || e.key === " ") {
          handleClick();
          e.preventDefault();
        }
      },
      role: hasSubNav ? "button" : undefined,
      tabIndex: 0,
      "data-tobii-group": isTobiiGroup ? "true" : undefined,
      ...spanProps,
    };

    if (!hasSubNav && href) {
      return (
        <li className={navItemClasses}>
          <a
            href={href}
            {...(navItemLinkProps as HTMLProps<HTMLAnchorElement>)}
          >
            {LinkContent}
          </a>
        </li>
      );
    }

    return (
      <li className={navItemClasses}>
        <span {...(navItemLinkProps as HTMLProps<HTMLSpanElement>)}>
          {LinkContent}
        </span>
      </li>
    );
  }
);

DesktopNavItem.displayName = "DesktopNavItem";
