import React from "react";

import cn from "classnames";
import s from "./Flag.module.scss";

import ja from "/public/svg/flags/ja.svg";
import en from "/public/svg/flags/en.svg";
import zh from "/public/svg/flags/zh.svg";
import sv from "/public/svg/flags/sv.svg";

export type FlagKey = keyof typeof CountryFlags;

export const CountryFlags = {
  ja,
  en,
  zh,
  sv,
} as const;

type Props = {
  country: FlagKey;
  width?: number | "none";
  className?: string;
};

const Flag = ({ country, className, width = "none" }: Props) => {
  const FlagIcon = CountryFlags[country];

  return (
    <FlagIcon
      style={{
        "--width": width === "none" ? "none" : width + "px",
      }}
      className={cn(s.flag, className)}
    />
  );
};

export default Flag;
