import React, { ReactNode } from "react";
import s from "../TechSpec.module.scss";

type Props = {
  children: ReactNode;
};

const TableRow = ({ children }: Props) => (
  <tr className={s.tableRow}>{children}</tr>
);

export default TableRow;
