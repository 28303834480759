import React from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import Overlay from "../../ui/Overlay";
import { OverlayBlock } from "../../lib/storyblok/types/blocks/OverlayBlock";
import ImageVaultImage from "../ImageVaultImage";
import Link from "next/link";
import Button from "../../ui/Button";
import { useLinks } from "../../lib/storyblok/useLinks";

const OverlayBlock: StoryblokBlockComponent<OverlayBlock> = ({
  title,
  description,
  buttons,
  image,
  video,
  overlayStyle,
  overlayOpacity,
  featured,
  contentAlignment,
  innerSpacing,
  outerSpacing,
  width,
}) => {
  const resolveLink = useLinks();

  const buttonCollection = buttons?.map((button) => (
    <Link
      key={button._uid}
      href={button.buttonLink ? resolveLink(button.buttonLink) : ""}
      passHref
    >
      <Button
        variant={button.buttonType ?? "default"}
        href={resolveLink(button.buttonLink)}
      >
        {button.buttonText}
      </Button>
    </Link>
  ));

  return (
    <Overlay
      title={title}
      description={description}
      buttons={buttonCollection}
      image={
        image?.item && (
          <ImageVaultImage image={image} layout="fill" sizes="50vw" priority />
        )
      }
      video={
        video?.filename && (
          <Overlay.VideoPlayer key="desktop" videoUrl={video?.filename} />
        )
      }
      overlayStyle={overlayStyle}
      overlayOpacity={overlayOpacity.value}
      featured={featured}
      contentAlignment={contentAlignment}
      innerSpacing={innerSpacing}
      outerSpacing={outerSpacing}
      width={width}
    />
  );
};

export default OverlayBlock;
