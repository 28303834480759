import cn from "classnames";
import { ReactNode } from "react";
import s from "./Hero.module.scss";
import c from "../../styles/container.module.scss";
import VideoPlayer from "./components/VideoPlayer";
import useMediaQuery from "../../hooks/useMatchMedia";

type Props = {
  description?: string;
  title: string;
  label?: string;
  buttons?: ReactNode;
  hideContentOnDesktop: boolean;
  hideContentOnMobile: boolean;
  small?: boolean;
  backgroundImage?: ReactNode;
  foregroundImage?: ReactNode;
  video?: ReactNode;
  mobileVideo?: ReactNode;
  fallbackImage?: ReactNode;
  hasAnchorMenu?: boolean;
  inverted?: boolean;
  noOverlay?: boolean;
  isStartPage?: boolean;
  divider?: ReactNode;
};

const subComponents = { VideoPlayer };
type SubComponents = typeof subComponents;

const Hero: CWS<Props, SubComponents> = ({
  description,
  title,
  label,
  buttons,
  hideContentOnDesktop,
  hideContentOnMobile,
  small,
  hasAnchorMenu,
  backgroundImage,
  foregroundImage,
  video,
  mobileVideo,
  fallbackImage,
  inverted,
  noOverlay,
  isStartPage,
  divider,
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <>
      <section
        className={cn(c.fluid, s.section, {
          [s.inverted]: inverted,
          [s.fullHeight]: !small && (backgroundImage || (video && !small)),
          [s.hasAnchorMenu]: hasAnchorMenu && !small && !foregroundImage,
          [s.startPage]: isStartPage,
        })}
      >
        {!foregroundImage && backgroundImage && !video && (
          <div className={cn(s.background, { [s.overlay]: !noOverlay })}>
            <div className={s.imageContainer}>{backgroundImage}</div>
          </div>
        )}

        <div
          className={cn(s.background, s.dark, { [s.startPage]: isStartPage })}
        >
          {isMobile && mobileVideo ? mobileVideo : video}
          {isMobile && !mobileVideo && fallbackImage}
          {!video && !mobileVideo && fallbackImage}
        </div>

        <div
          className={cn(s.container, c.xWide, {
            [s.small]: small,
            [s.centered]: !small,
            [s.fullHeight]: !small && backgroundImage,
            [s.extraPaddingTop]: !backgroundImage && foregroundImage,
          })}
        >
          {label && (
            <div
              className={cn(s.top, {
                [s.hideOnDesktop]: hideContentOnDesktop,
                [s.hideOnMobile]: hideContentOnMobile,
              })}
            >
              <span>{label}</span>
            </div>
          )}
          {title && (
            <h1
              className={cn({
                [s.startPage]: isStartPage,
                [s.hideOnDesktop]: hideContentOnDesktop,
                [s.hideOnMobile]: hideContentOnMobile,
              })}
            >
              {title}
            </h1>
          )}
          {description && description != "" && (
            <p
              className={cn(s.description, {
                [s.hideOnDesktop]: hideContentOnDesktop,
                [s.hideOnMobile]: hideContentOnMobile,
              })}
            >
              {description}
            </p>
          )}
          {buttons && buttons[0] && (
            <div
              className={cn(s.buttons, {
                [s.hideOnDesktop]: hideContentOnDesktop,
                [s.hideOnMobile]: hideContentOnMobile,
              })}
            >
              {buttons}
            </div>
          )}
          {foregroundImage && (
            <div className={s.foregroundImage}>{foregroundImage}</div>
          )}
        </div>
      </section>
      {divider && divider}
    </>
  );
};

Hero.VideoPlayer = VideoPlayer;

export default Hero;
