import { Block, Story } from "./types/story";
import { useViewmodel } from "./ViewmodelContext";

export const useRelations = () => {
  const { relations } = useViewmodel();

  const getRelation = <T extends Block>(uuid: Uuid) => {
    const relation = relations?.find((rel) => rel.uuid === uuid) as Story<T>;
    if (!relation) {
      return null;
    } else {
      return relation;
    }
  };

  return { getRelation, relations };
};
