export const resolveStoryImage = (content) => {
  if (content?.image?.item) {
    return content.image;
  }

  if (content?.hero?.length > 0) {
    if (content.hero[0].foregroundImage) {
      return content.hero[0]?.foregroundImage;
    }
    if (content.hero[0].backgroundImage) {
      return content.hero[0]?.backgroundImage;
    }
  }

  return content?.heroImage;
};

export const isForegroundImage = (content) => {
  if (content?.image?.item) {
    return false;
  }

  if (content.hero?.length > 0) {
    if (content.hero[0].foregroundImage) {
      return true;
    }

    if (content.hero[0].backgroundImage) {
      return false;
    }
  }
};
