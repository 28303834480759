import React from "react";
import s from "../ImageCarousel.module.scss";
import cn from "classnames";
import Image from "next/image";

const CarouselItemImage = ({
  title,
  description,
  style,
  overlayVisibility,
  overlayStyle,
  image,
}) => {
  const getOverlaySvg = (overlayStyle, style) => {
    if (overlayStyle === "peanut" || overlayStyle === "peanutInverted") {
      return `/svg/shapes/${overlayStyle}-${style}-cropped.svg`;
    }
    return "/svgs/shapes/peanut-signature-cropped.svg";
  };

  const innerClassName = cn(s.inner, {
    [s.shapeOverlay]:
      overlayVisibility && ["peanut", "peanutInverted"].includes(overlayStyle),
  });

  return (
    <>
      <div
        className={cn(s.item, s[style], {
          [s.overlayActive]: overlayVisibility,
        })}
      >
        {image && (
          <div className={s.imageContainer}>
            <figure className={s.figure}>{image}</figure>
          </div>
        )}

        <div className={innerClassName}>
          {overlayVisibility && (
            <>
              {overlayStyle === "gradient" && <div className={s.overlay}></div>}

              {["peanut", "peanutInverted"].includes(overlayStyle) && (
                <div className={cn(s.shape, s[overlayStyle])}>
                  <Image
                    src={getOverlaySvg(overlayStyle, style)}
                    alt={overlayStyle}
                    width={3140}
                    height={366}
                    objectFit="contain"
                  />
                </div>
              )}
            </>
          )}
          <div
            className={cn(s.content, {
              [s.inverted]: style !== "white" && style !== "gray",
            })}
          >
            {title && <h4>{title}</h4>}
            {description && <p>{description}</p>}
          </div>
        </div>
      </div>
    </>
  );
};

export default CarouselItemImage;
