import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { MiscTableBlock } from "../../lib/storyblok/types/blocks/MiscTableBlock";
import { default as MiscTableComponent } from "../../ui/MiscTable";
import React from "react";

const MiscTable: StoryblokBlockComponent<MiscTableBlock> = ({
  title,
  sections,
  spacing,
}) => {
  return (
    <MiscTableComponent title={title} spacing={spacing} sections={sections} />
  );
};

export default MiscTable;
