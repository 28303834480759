import { useCallback, useState, useEffect } from "react";

export interface CalendarEvent {
  id: string;
  title: string;
  starts: string;
  calendarLinks: {
    google: string;
    ical: string;
  };
}

interface CalendarResponse {
  events: {
    id: string;
    title: string;
    starts: string;
    inPast: boolean;
    calendarLinks: {
      google: string;
      ical: string;
    };
  }[];
}

export const useUpcomingEvents = (token: string, locale: string) => {
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchCalendarData = useCallback(async () => {
    try {
      const response = await fetch(
        `https://widget.datablocks.se/proxy/calendar/${token}/${locale}?time-location=Europe/Stockholm&languageFallback=false`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: CalendarResponse = await response.json();

      const upcomingEvents = data.events
        .filter((event) => !event.inPast)
        .sort(
          (a, b) => new Date(a.starts).getTime() - new Date(b.starts).getTime()
        )
        .slice(0, 2) // Show 2 events
        .map(({ id, title, starts, calendarLinks }) => ({
          id,
          title,
          starts,
          calendarLinks: {
            google: calendarLinks.google,
            ical: calendarLinks.ical,
          },
        }));

      setEvents(upcomingEvents);
      setError(null);
    } catch (err) {
      setError(
        err instanceof Error ? err.message : "Failed to fetch calendar data"
      );
      setEvents([]);
    } finally {
      setIsLoading(false);
    }
  }, [token, locale]);

  useEffect(() => {
    fetchCalendarData();
    const intervalId = setInterval(fetchCalendarData, 300000); // 5 minutes
    return () => clearInterval(intervalId);
  }, [fetchCalendarData]);

  return { events, isLoading, error };
};
