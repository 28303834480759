/* eslint-disable jsx-a11y/anchor-has-content */
import ReactMarkdown from "react-markdown";
import LinkWrapper from "../../components/Nestable/LinkWrapper/LinkWrapper";
import { isStoryblokSlug } from "../../lib/routing/isStoryblokSlug";
import { resolveSlug } from "../../lib/utils/resolveSlug";

const Markdown = ({ children, ...props }) => (
  <ReactMarkdown
    {...props}
    components={{
      ...props.components,

      a: ({ href, ...props }) => {
        if (!href) return null;
        const slug = href.slice(1);
        const isSbSlug = isStoryblokSlug(slug);
        const final = isSbSlug ? resolveSlug(slug) : href;
        return isSbSlug ? (
          <LinkWrapper href={final} text={props.children} {...props} />
        ) : (
          <LinkWrapper href={href} text={props.children} {...props} />
        );
      },
    }}
  >
    {children}
  </ReactMarkdown>
);

export default Markdown;
