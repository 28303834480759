import React, { forwardRef, HTMLProps, ReactNode } from "react";
import cn from "classnames";
import s from "./DesktopMenu.module.scss";

type Props = {
  children: ReactNode;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
} & HTMLProps<HTMLDivElement>;

export const DesktopSubNavigationTabItemWrapper = forwardRef<
  HTMLDivElement,
  Props
>(({ children, onMouseEnter, onMouseLeave, ...props }, ref) => {
  return (
    <div
      className={cn(s.DesktopSubNavigationPanelWrapper)}
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...props}
    >
      {children}
    </div>
  );
});

DesktopSubNavigationTabItemWrapper.displayName =
  "DesktopSubNavigationTabItemWrapper";
