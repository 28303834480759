import { useRouter } from "next/router";
import React from "react";
import { Configure, InstantSearch } from "react-instantsearch";
import { ArticleFinderStory } from "../../lib/storyblok/types/stories/ArticleFinderStory";
import { StoryblokStoryComponent } from "../../lib/storyblok/types/StoryblokComponent";
import {
  initAlgoliaIndecies,
  initAlgoliaSearch,
} from "../../lib/utils/AlgoliaSearchHelpers";
import { ViewModel } from "../../lib/viewModel/createViewModel";
import StoryFinder from "../Nestable/StoryFinder";
import FeaturedHero from "../../ui/FeaturedHero";
import { resolveDate } from "../../lib/utils/resolveDate";
import { resolveSlug } from "../../lib/utils/resolveSlug";
import { resolveStoryImage } from "../../lib/utils/resolveStoryImage";
import { useRelations } from "../../lib/storyblok/useRelations";
import ImageVaultImage from "../ImageVaultImage";
import { useTranslation } from "next-i18next";
import Button from "../../ui/Button";

type VM = ViewModel<ArticleFinderStory>;
const indecies = initAlgoliaIndecies();
const searchClient = initAlgoliaSearch();

const ArticleFinderPage: StoryblokStoryComponent<VM> = ({ story, locale }) => {
  const { getRelation } = useRelations();
  const { t } = useTranslation();
  const { featuredArticle, latestArticle, noHeroOverlay, activateSearchField } =
    story.content;
  const { query } = useRouter();
  const initialValue = typeof query.q === "string" ? query.q : undefined;
  const refinements = [
    { id: "eventType", attribute: "types" },
    { id: "product", attribute: "categories" },
    { id: "solution", attribute: "solutions" },
    { id: "other", attribute: "otherTags" },
  ];

  const featuredArticleStory = featuredArticle
    ? getRelation(featuredArticle)
    : latestArticle;

  return (
    <InstantSearch indexName={indecies[locale]} searchClient={searchClient}>
      <Configure
        hitsPerPage={9}
        attributesToSnippet={["title:40", "content:40", "metaDescription:40"]}
        query={initialValue}
        filters={"storyType:articlePage"}
      />
      {featuredArticleStory && (
        <FeaturedHero
          title={featuredArticleStory.content.title}
          preamble={featuredArticleStory.content.description}
          byLabel={t("by")}
          author={featuredArticleStory.content.author}
          date={resolveDate(featuredArticleStory.content.createdAt)}
          readTime={`${featuredArticleStory.content.readTime} ${t(
            "minutesShort"
          )}`}
          buttons={
            <Button
              variant="inverted"
              href={resolveSlug(featuredArticleStory.full_slug)}
            >
              {t("continue-reading")}
            </Button>
          }
          backgroundImage={
            <ImageVaultImage
              sizes="100vw"
              image={resolveStoryImage(featuredArticleStory.content)}
              layout="fill"
            />
          }
          noOverlay={noHeroOverlay}
        />
      )}
      <StoryFinder
        value={initialValue}
        activateSearchField={activateSearchField}
        refinements={refinements}
      />
    </InstantSearch>
  );
};

export default ArticleFinderPage;
