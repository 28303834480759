import axios from "axios";
import { format } from "date-fns";
import { useTranslation } from "next-i18next";
import React, { useEffect, useState } from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import ShareTickerUi from "../../ui/ContentGrid/components/ShareTicker";
import { IconType } from "../../ui/Icon/Icon";

type TickerData = {
  ISIN: string;
  MarketPlace: string;
  TickerId: number;
  TickerName: string;
  TickerSymbol: string;
  TickerType: string;
  TradeCurrency: string;
  Quotes: {
    Delta: number;
    DeltaPercentage: number;
    Price: number;
    QuoteTime: string;
  }[];
};

const ShareTicker: StoryblokBlockComponent = () => {
  const { t } = useTranslation();
  const [tickerData, setTickerData] = useState<TickerData>();

  useEffect(() => {
    const getShareTickerData = async () => {
      const { data } = await axios.get(
        "https://publish.ne.cision.com/papi/ticker/BE2939AE85F2447AAD457A071DC5BFCF?format=json"
      );

      setTickerData(data.Instruments[0]);
    };

    getShareTickerData();

    // Update every 5 min
    setInterval(getShareTickerData, 300000);
  }, []);

  const resolveIcon = (delta) => {
    if (delta === 0) {
      return "arrow-right";
    }
    if (delta > 0) {
      return "arrow-up";
    }
    if (delta < 0) {
      return "arrow-down";
    }
  };

  return tickerData ? (
    <ShareTickerUi
      icon={resolveIcon(tickerData.Quotes[0].Delta) as IconType}
      price={tickerData.Quotes[0].Price}
      name={tickerData.TickerName}
      currency={tickerData.TradeCurrency}
      deltaPercentage={(tickerData.Quotes[0].DeltaPercentage * 100).toFixed(2)}
      delta={tickerData.Quotes[0].Delta.toString()}
      quoteTimeLabel={t("latest-update")}
      quoteTime={format(
        new Date(tickerData.Quotes[0].QuoteTime),
        "yyyy-MM-dd HH:mm OOOO"
      )}
    />
  ) : (
    <></>
  );
};

export default ShareTicker;
