import React, { useEffect, useMemo, useState } from "react";
import { useDatablockWidget } from "../../../hooks/mfn/useDatablockWidget";
import { Tabs, Tab } from "../../Common/Tabs";
import Button from "../../Button";
import cn from "classnames";
import c from "../../../styles/container.module.scss";
import s from "./Datablock.KeyFigures.module.scss";
import ss from "./Datablock.SharedStyles.module.scss";
import tokens from "../tokens.json";

const handleDownload = async (locale: string) => {
  try {
    const response = await fetch(
      `https://widget.datablocks.se/api/rose/widgets/kpi-export-v2?token=${tokens.kpiExportv2}&lang=${locale}`
    );
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "tobii-export.xls";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};

const GridLayout = ({ group, locale }) => (
  <div className={cn(s.keyFiguresGrid)}>
    {group.map((widget) => (
      <div
        key={widget.widgetId}
        className={cn(s.keyFiguresGridItem, ss.contentWrapper)}
      >
        <span className={s.title}>{widget.label}</span>
        <div id={`${widget.widgetId}-${locale}`} className={s.graphs} />
      </div>
    ))}
  </div>
);

const TabbedLayout = ({ group, locale, activeTabIndex, onTabClick }) => (
  <div className={s.keyFiguresWrapper}>
    <Tabs activeIndex={activeTabIndex} alignment="alignLeft">
      {group.map((tab, i) => (
        <Tab
          key={tab.widgetId}
          index={i}
          title={tab.label}
          isActive={activeTabIndex === i}
          onClick={() => onTabClick(i)}
        />
      ))}
    </Tabs>
    <div className={cn(s.keyFiguresTabContent, ss.tabsWrapper)}>
      <div className={s.keyFiguresTabHeader}>
        <span className={s.title}>{group[activeTabIndex].label}</span>
        <Button
          variant="cta"
          onClick={() => handleDownload(locale)}
          size="medium"
        >
          {locale === "en" ? "Download excel" : "Ladda ner excel"}
        </Button>
      </div>

      {group.map((tab, j) => (
        <div
          key={tab.widgetId}
          className={cn(ss.tab, ss.contentWrapper, {
            [ss.activeTab]: activeTabIndex === j,
          })}
        >
          <div
            id={`${tab.widgetId}-${locale}`}
            className={cn(c.xWide, s.keyfigures)}
          />
        </div>
      ))}
    </div>
  </div>
);

const StandaloneLayout = ({ widget, locale, quarterText }) => (
  <div className={cn(s.kpiFiguresQuarter)}>
    <h2 className={s.title}>
      {quarterText || (locale === "en" ? "Loading..." : "Laddar...")}
    </h2>
    <div id={`${widget.widgetId}-${locale}`} className={c.fluid} />
  </div>
);

type Props = {
  locale: string;
  demo: boolean;
  type: string;
};

const KeyFigures: React.FC<Props> = ({ locale, demo, type }) => {
  const [quarterText, setQuarterText] = useState("");
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const { initializeWidgets, cleanupWidgets } = useDatablockWidget();

  const groups = useMemo(
    () => ({
      groupOne: [
        {
          widget: "kpi-graph",
          widgetId: "kpi-graph-v5",
          token: tokens.kpiGraphv5,
          label: locale === "en" ? "Net sales" : "Nettoomsättning",
        },
        {
          widget: "kpi-graph",
          widgetId: "kpi-graph",
          token: tokens.kpiGraph,
          label:
            locale === "en" ? "Operating result (EBIT)" : "Rörelseresultat",
        },
        {
          widget: "kpi-donut",
          widgetId: "kpi-donut",
          token: tokens.kpiDonut,
          label:
            locale === "en"
              ? "Net sales by segment"
              : "Nettoomsättning per segment",
        },
      ],
      groupTwo: [
        {
          widget: "kpi-graph",
          widgetId: "kpi-graph-v4",
          token: tokens.kpiGraphv4,
          label:
            locale === "en" ? "Products & Solutions" : "Products & Solutions",
        },
        {
          widget: "kpi-graph",
          widgetId: "kpi-graph-v3",
          token: tokens.kpiGraphv3,
          label: locale === "en" ? "Integrations" : "Integrations",
        },
        {
          widget: "kpi-graph",
          widgetId: "kpi-graph-v2",
          token: tokens.kpiGraphv2,
          label: locale === "en" ? "Autosense" : "Autosense",
        },
      ],
      groupThree: [
        {
          widget: "kpi-table-v2",
          widgetId: "kpi-table-v2-v2",
          token: tokens.kpiTablev2v2,
          label: locale === "en" ? "Income statement" : "Resultaträkning",
        },
        {
          widget: "kpi-table-v2",
          widgetId: "kpi-table-v2-v1",
          token: tokens.kpiTablev2v1,
          label: locale === "en" ? "Other key figures" : "Övriga nyckeltal",
        },
      ],
      groupFour: [
        {
          widget: "kpi-dashboard",
          widgetId: "kpi-dashboard",
          token: tokens.kpiDashboard,
          label: locale === "en" ? "Overview" : "Översikt",
        },
      ],
    }),
    [locale]
  );

  useEffect(() => {
    let activeGroup;
    switch (type) {
      case "overview":
        activeGroup = groups.groupOne;
        break;
      case "segments":
        activeGroup = groups.groupTwo;
        break;
      case "performanceMetrics":
        activeGroup = groups.groupThree;
        break;
      case "quarter":
        activeGroup = groups.groupFour;
        const fetchQuarterInfo = async () => {
          try {
            const response = await fetch(
              `https://widget.datablocks.se/api/rose/widgets/kpi-table-v2?token=${tokens.kpiDashboardTitle}&c=f6f46e10-503a-4f64-84b9-1b48eb19b5ba&lang=${locale}`
            );

            if (!response.ok) {
              throw new Error("Failed to fetch quarter info");
            }

            const text = await response.text();
            const quarterText = text.split("<style>")[0].trim();
            setQuarterText(quarterText);
          } catch (error) {
            console.error("Error fetching quarter info:", error);
          }
        };

        fetchQuarterInfo();
        break;
        break;
      default:
        return;
    }

    initializeWidgets(activeGroup, locale, demo);
    return () => cleanupWidgets(activeGroup, locale);
  }, [type, locale, demo, groups, initializeWidgets, cleanupWidgets]);

  switch (type) {
    case "overview":
      return <GridLayout group={groups.groupOne} locale={locale} />;
    case "segments":
      return <GridLayout group={groups.groupTwo} locale={locale} />;
    case "performanceMetrics":
      return (
        <TabbedLayout
          group={groups.groupThree}
          locale={locale}
          activeTabIndex={activeTabIndex}
          onTabClick={setActiveTabIndex}
        />
      );
    case "quarter":
      return (
        <StandaloneLayout
          widget={groups.groupFour[0]}
          locale={locale}
          quarterText={quarterText}
        />
      );
    default:
      return null;
  }
};

export default KeyFigures;
