import { Block } from "../story";

export const pageSectionDividerBlockName = "pageSectionDivider" as const;

export type PageSectionDividerBlock = Block<
  typeof pageSectionDividerBlockName
> & {
  title: string;
  id: string;
};
