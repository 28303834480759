import { useCallback } from "react";

export const useSubscriptionWidget = () => {
  const initializeSubscriptionWidget = useCallback(
    (token: string, locale: string, demo: boolean) => {
      const DB_URL = "https://widget.datablocks.se/api/rose";

      const existingMF = window._MF;

      window._MF = {
        widget: "subscribe-v2",
        token: token,
        data: [],
        url: DB_URL,
        ready: false,
        render: function () {
          this.ready = true;

          const subscriptionElement =
            document.getElementById("subscribe-module");
          if (subscriptionElement) {
            subscriptionElement.style.display = "none";
            subscriptionElement.offsetHeight;
            subscriptionElement.style.display = "";
          }
        },
        push: function (conf) {
          this.data.push(conf);
        },
      };

      window._MF.push({
        query: "#subscribe-module",
        widget: "subscribe-v2",
        locale: locale,
        token: token,
        demo: demo,
      });

      if (
        !document.querySelector(
          `script[src="${DB_URL}/assets/js/loader-v4.js"]`
        )
      ) {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.src = `${DB_URL}/assets/js/loader-v4.js`;
        document.body.appendChild(script);
      }

      return { existingMF };
    },
    []
  );

  const cleanupSubscriptionWidget = useCallback(
    (existingMF: typeof window._MF | undefined) => {
      if (existingMF) {
        window._MF = existingMF;
      } else {
        delete window._MF;
      }
    },
    []
  );

  return { initializeSubscriptionWidget, cleanupSubscriptionWidget };
};
