import React, { useEffect } from "react";
import { useSubscriptionWidget } from "../../../hooks/mfn/useSubscriptionWidget";
import cn from "classnames";
import c from "../../../styles/container.module.scss";
import s from "./Datablock.Subscription.module.scss";
import tokens from "../tokens.json";

type Props = {
  locale: string;
  demo: boolean;
};

const Subscription: React.FC<Props> = ({ locale, demo }) => {
  const { initializeSubscriptionWidget, cleanupSubscriptionWidget } =
    useSubscriptionWidget();

  useEffect(() => {
    // Initialize and store the previous state
    const { existingMF } = initializeSubscriptionWidget(
      tokens.subscription,
      locale,
      demo
    );

    // Cleanup function
    return () => {
      cleanupSubscriptionWidget(existingMF);
    };
  }, [locale, demo, initializeSubscriptionWidget, cleanupSubscriptionWidget]);

  return <div id="subscribe-module" className={cn(c.narrow, s.subscription)} />;
};

export default Subscription;
