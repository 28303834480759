import React from "react";
import VideoUi from "../../ui/Video";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import ImageVaultImage from "../ImageVaultImage";
import { TwentyThreeVideoBlock } from "../../lib/storyblok/types/blocks/TwentyThreeVideoBlock";
import { imageSizes } from "../../lib/utils/imageSizes";
import { useViewmodel } from "../../lib/storyblok/ViewmodelContext";

import {
  getSchemaImageTwentyThree,
  getSchemaDuration,
  getSchemaTitle,
  getSchemaDescription,
} from "../../lib/utils/schema/video";

const TwentyThreeVideo: StoryblokBlockComponent<TwentyThreeVideoBlock> = ({
  url,
  thumbnail,
  thumbnailOverlay,
  startTime,
  title,
  description,
  duration,
  autoplay,
  component,
  schemaImage,
  schemaTitle,
  schemaDescription,
  schemaDate,
  schemaDuration,
}) => {
  const { locale, site } = useViewmodel();
  const getUrlParams = () => {
    const newUrl = new URL(url.replace("http:", "https:"));

    if (thumbnail?.item || autoplay) {
      newUrl.searchParams.append("autoplay", "1");
      newUrl.searchParams.append("mute", "1");
      newUrl.searchParams.append("mutedAutoPlay", "1");
      newUrl.searchParams.append("autoMute", "1");
      newUrl.searchParams.append("ambient", "1");
      newUrl.searchParams.append("showTray", "1");
    }
    if (startTime) {
      newUrl.searchParams.append("start", startTime);
    }

    return newUrl.toString();
  };

  return (
    <VideoUi
      url={url && getUrlParams()}
      thumbnailImage={
        thumbnail?.item && (
          <ImageVaultImage
            sizes={imageSizes("100vw", { xl: "1200px" })}
            image={thumbnail}
            layout="fill"
          />
        )
      }
      thumbnailOverlay={thumbnailOverlay}
      title={title}
      description={description}
      duration={duration}
      autoplay={autoplay}
      startTime={startTime}
      provider={component}
      schemaImage={getSchemaImageTwentyThree(
        thumbnail,
        schemaImage,
        site,
        locale
      )}
      schemaTitle={getSchemaTitle(title, schemaTitle)}
      schemaDescription={getSchemaDescription(description, schemaDescription)}
      schemaDate={schemaDate}
      schemaDuration={getSchemaDuration(schemaDuration)}
    />
  );
};

export default TwentyThreeVideo;
