import React from "react";
import cn from "classnames";
import s from "./FeaturedContentGrid.module.scss";
import c from "../../styles/container.module.scss";

const FeatureContentGrid = ({ children }) => (
  <section className={cn(s.section, c.xWide)}>{children}</section>
);

export default FeatureContentGrid;
