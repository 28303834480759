const tagUrls = {
  "Pro Spectrum": "/eye-trackers/screen-based/tobii-pro-spectrum",
  "Tobii Pro Spectrum": "/eye-trackers/screen-based/tobii-pro-spectrum",
  "Pro Spark": "/eye-trackers/screen-based/tobii-pro-spark",
  "Tobii Pro Spark": "/eye-trackers/screen-based/tobii-pro-spark",
  "Pro Glasses 3": "/eye-trackers/wearables/tobii-pro-glasses-3",
  "Tobii Pro Glasses 3": "/eye-trackers/wearables/tobii-pro-glasses-3",
  "Pro Glasses 2": "/eye-trackers/wearables/tobii-pro-glasses-3",
  "Tobii Pro Glasses 2": "/eye-trackers/wearables/tobii-pro-glasses-3",
  "Pro Glasses": "/eye-trackers/wearables/tobii-pro-glasses-3",
  "Tobii Pro Glasses": "/eye-trackers/wearables/tobii-pro-glasses-3",
  "Pro Fusion": "/eye-trackers/screen-based/tobii-pro-fusion",
  "Tobii Pro Fusion": "/eye-trackers/screen-based/tobii-pro-fusion",
  "Tobii Pro Lab": "/software/behavior-research-software/tobii-pro-lab",
  "Tobii Pro X2-60/X2-30": "/eye-trackers/screen-based/tobii-pro-fusion",
  "Tobii Pro X2-60 / X2-30": "/eye-trackers/screen-based/tobii-pro-fusion",
  "Tobii Pro X1 light": "/eye-trackers/screen-based/tobii-pro-fusion",
  "Tobii Pro X50": "/eye-trackers/screen-based/tobii-pro-spectrum",
  "Tobii Pro Nano": "/eye-trackers/screen-based/tobii-pro-spark",
  "Tobii Pro X3-120": "/eye-trackers/screen-based/tobii-pro-fusion",
  "Tobii Pro X60/X120 / T60/T120":
    "/eye-trackers/screen-based/tobii-pro-spectrum",
  "Tobii Pro T60 XL": "/eye-trackers/screen-based/tobii-pro-spectrum",
  "Tobii Pro TX-300": "/eye-trackers/screen-based/tobii-pro-spectrum",
  "Tobii ClearView": "/software/behavior-research-software/tobii-pro-lab",
};

const baseUrl = "https://www.tobii.com/products";

export const getTagUrl = (tagName) => {
  const trimmedTagName = tagName.trim();
  const urlSuffix = tagUrls[trimmedTagName];
  return urlSuffix ? `${baseUrl}${urlSuffix}` : null;
};
