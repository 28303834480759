/* eslint-disable */
import React, { useState } from "react";
import { useCurrentRefinements, useInfiniteHits } from "react-instantsearch";
import { DynamicBlocks } from "../DynamicBlock";
import FilterPageGrid from "../../ui/PageTemplates/FilterPage";
import SearchBox from "./SearchBox";
import { StorySortMethod } from "./StorySort";
import Button from "../../ui/Button";
import { useTranslation } from "next-i18next";
import InfiniteStoryHits from "./InfiniteStoryHits";
import { Block } from "../../lib/storyblok/types/story";
import StoryRefinementList from "./StoryRefinementList";
import { AnchorMenuContextProvider } from "../Layout/AnchorMenu/AnchorMenuContext";
import AnchorMenuSection from "../Layout/AnchorMenu/AnchorMenuSection";
import { splitIntoSections } from "../Layout/AnchorMenu/splitIntoSections";

const StorySearcher = ({
  story,
  value,
  preamble,
  activateSearchField,
  refinements,
  renderImages = true,
  renderDate = true,
  hiddenFacets = [],
}: {
  story: any;
  value?: string;
  preamble?: Block[];
  activateSearchField?: boolean;
  refinements?: unknown[];
  renderImages?: boolean;
  renderDate?: boolean;
  hiddenFacets?: string[];
}) => {
  const { t } = useTranslation("searchpage");
  const { results } = useInfiniteHits();
  const { items } = useCurrentRefinements();

  const [showFilter, setShowFilter] = useState(false);
  const [sortMethod] = useState<StorySortMethod>();

  const total = results?.nbHits ?? 0;
  const toggleFilter = () => {
    setShowFilter((currentShowFilter) => !currentShowFilter);
  };

  const noActiveFilters = items.length < 1;
  const { body } = story.content;

  const sections = splitIntoSections(body);

  return (
    <>
      <FilterPageGrid>
        {preamble && (
          <FilterPageGrid.Preamble>
            <DynamicBlocks blocks={preamble} />
          </FilterPageGrid.Preamble>
        )}
        <FilterPageGrid.Toolbar>
          {activateSearchField && (
            <FilterPageGrid.Input>
              <SearchBox defaultValue={value} type="small" />
            </FilterPageGrid.Input>
          )}
          <FilterPageGrid.Toggle>
            <Button
              variant="cta"
              size="small"
              icon={noActiveFilters ? "filter" : undefined}
              pill={noActiveFilters ? undefined : items.length}
              onClick={toggleFilter}
            >
              Filter
            </Button>
          </FilterPageGrid.Toggle>
        </FilterPageGrid.Toolbar>
        <FilterPageGrid.Filter visible={showFilter}>
          <StoryRefinementList
            refinementAttributes={refinements}
            hiddenFacets={hiddenFacets}
          />
        </FilterPageGrid.Filter>
        <FilterPageGrid.NumberOfHits>
          {t("number-of-hits", { count: total })}
        </FilterPageGrid.NumberOfHits>
        <FilterPageGrid.Body>
          <InfiniteStoryHits
            sortMethod={sortMethod}
            renderImages={renderImages}
            renderDate={renderDate}
          />
        </FilterPageGrid.Body>
      </FilterPageGrid>
      <AnchorMenuContextProvider>
        {sections?.map((section) => (
          <>
            {" "}
            <AnchorMenuSection
              key={`${section.href ? section.href : "index"}-${story.uuid}`}
              section={section}
            />
          </>
        ))}
      </AnchorMenuContextProvider>
    </>
  );
};

export default StorySearcher;
