import s from "../ShortFeatures.module.scss";
import { ReactNode } from "react";
import Markdown from "../../Markdown";
import cn from "classnames";

type Props = {
  text: string;
  description: string;
  showLabel?: boolean;
  label: string;
  labelPrefix: string;
  labelSuffix: string;
  button?: ReactNode;
  children: ReactNode;
};

const Feature = ({
  text,
  description,
  button,
  showLabel,
  label,
  labelPrefix,
  labelSuffix,
  children,
}: Props) => (
  <li className={cn(s.feature)}>
    <div className={s.visual}>
      {showLabel && (
        <div className={s.labelWrapper}>
          {labelPrefix && <span className={s.prefix}>{labelPrefix}</span>}
          {label && <span className={s.label}>{label}</span>}
          {labelSuffix && <span className={s.suffix}>{labelSuffix}</span>}
        </div>
      )}

      {!showLabel && (
        <figure className={s.icon}>
          {children}
          <figcaption hidden>{text}</figcaption>
        </figure>
      )}
    </div>

    <div className={s.content}>
      {text && description ? (
        <p className={s.title}>
          <strong>{text}</strong>
        </p>
      ) : (
        <p className={s.title}>{text}</p>
      )}
      {description && (
        <div className={s.description}>
          <Markdown>{description}</Markdown>
        </div>
      )}
      {button && <div className={s.featureButtons}>{button}</div>}
    </div>
  </li>
);

export default Feature;
