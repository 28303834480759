import { ReactNode } from "react";
import Icon from "../Icon/Icon";
import s from "./Contact.module.scss";

type Props = {
  heading: string;
  image: ReactNode;
  name: string;
  title: string;
  email?: string;
  phone?: string;
  phoneDisplay?: string;
};

const Contact = ({ heading, image, name, title, email, phone }: Props) => (
  <section className={s.contact}>
    <h2 className="label">{heading}</h2>
    <div className={s.imageWrapper}>
      <div className={s.imageContainer}>{image}</div>
      <div>
        <h3>{name}</h3>
        <p className={s.title}>{title}</p>
      </div>
    </div>
    <div className={s.linksWrapper}>
      {email && (
        <a href={`mailto:${email}`}>
          <Icon icon="mail" width={16} />
          {email}
        </a>
      )}
      {phone && (
        <p>
          <Icon icon="phone" width={16} />
          {phone}
        </p>
      )}
    </div>
  </section>
);

export default Contact;
