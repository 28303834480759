import { kebabCase } from "./../../../lib/utils/kebabcase";
import {
  pageSectionDividerBlockName,
  PageSectionDividerBlock,
} from "../../../lib/storyblok/types/blocks/PageSectionDividerBlock";
import { Block } from "../../../lib/storyblok/types/story";

// splits the body into sections based on the "pageSectionDivider" block
export const splitIntoSections = (body: Block[]) => {
  const initialSection =
    body?.[0]?.component === pageSectionDividerBlockName
      ? []
      : [{ title: "", href: "", blocks: [] }];

  return body?.reduce(appendBlockToLastSectionOrAddNewSection, initialSection);
};

const appendBlockToLastSectionOrAddNewSection = (
  sections: Section[],
  block: Block<string>
): Section[] => {
  if (block.component === pageSectionDividerBlockName) {
    const divider = block as PageSectionDividerBlock;
    return [
      ...sections,
      {
        title: divider.title,
        href: divider.id
          ? divider.id.toString().replace(/\s+/g, "-").trim()
          : kebabCase(divider.title).toString().replace(/\s+/g, "-").trim(),

        blocks: [],
      },
    ];
  } else {
    return [
      ...sections.slice(0, -1),
      {
        ...sections[sections.length - 1],
        blocks: [...sections[sections.length - 1].blocks, block],
      },
    ];
  }
};

export type Section = { title: string; href: string; blocks: Block[] };
