import React from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import LogoListUi from "../../ui/LogoList";
import { LogoListBlock } from "../../lib/storyblok/types/blocks/LogoListBlock";
import ImageVaultImage from "../ImageVaultImage";
import useMediaQuery from "../../hooks/useMatchMedia";

const { List, Logo } = LogoListUi;

const Agenda: StoryblokBlockComponent<LogoListBlock> = ({
  title,
  logos,
  scrollOnDesktop,
  featured,
  spacing,
}) => {
  const isDesktop = useMediaQuery("(max-width: 1024px)");

  return (
    <LogoListUi title={title} featured={featured} spacing={spacing}>
      <List shouldScroll={isDesktop || scrollOnDesktop} featured={featured}>
        {logos?.map((logo) => (
          <Logo
            key={logo._uid}
            logo={<ImageVaultImage image={logo.logo} layout="intrinsic" />}
          />
        ))}
      </List>
    </LogoListUi>
  );
};

export default Agenda;
