import React, { ElementType, useRef } from "react";
import cn from "classnames";
import { FilterListContext } from "./FilterListContext";
import Filter from "./components/Filter";
import Heading from "./components/Heading";
import Icon from "../Icon";
import s from "./FilterList.module.scss";

type FilterListProps = {
  label?: string;
  children: JSX.Element | JSX.Element[];
  /* If you should be able to expand and collapse the filters */
  layout?: "static" | "expandable";
  /* For changing the element thats rendered as the label */
  labelAs?: ElementType;
  className?: string;
};

const subComponents = { Filter, Heading };

const FilterList: CWS<FilterListProps, typeof subComponents> = ({
  label,
  children,
  labelAs = "h3",
  layout = "expandable",
  className,
}) => {
  const childrenRef = useRef<HTMLFieldSetElement | null>(null);
  const Heading = labelAs;
  const name = label ? label.replace(/\s+/g, "-").toLowerCase() : "";

  return (
    <FilterListContext.Provider value={{ name }}>
      <details
        className={cn(className, s.filterList, {
          [s.filterListStatic]: layout === "static",
        })}
        open
      >
        <summary className={s.heading}>
          <figure className={s.caret}>
            <Icon icon="caret-down" width={15} />
          </figure>
          <Heading className={s.label}>{label}</Heading>
        </summary>
        <fieldset ref={childrenRef} className={s.children}>
          {children}
        </fieldset>
      </details>
    </FilterListContext.Provider>
  );
};

FilterList.Filter = Filter;
FilterList.Heading = Heading;

export default FilterList;
