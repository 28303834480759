import React, { ReactNode, useEffect, useRef, useState } from "react";
import cn from "classnames";
import s from "./ContentGridSlider.module.scss";
import c from "../../styles/container.module.scss";
import Item from "./components/Item";
import Markdown from "../Markdown";
import ProgressBar from "./components/ProgressBar";
import SliderButton from "../Common/SliderButton/SliderButton";
import useMediaQuery from "../../hooks/useMatchMedia";
import useIsInViewport from "use-is-in-viewport";
import { useTranslation } from "next-i18next";

type Props = {
  title: string;
  description?: string;
  spacing?: string;
  isStartpage?: boolean;
  children: ReactNode;
};

const subComponents = { Item };
type SubComponents = typeof subComponents;

const ContentGridSlider: CWS<Props, SubComponents> = ({
  title,
  description,
  spacing,
  isStartpage,
  children,
}) => {
  const [scrolled, setScrolled] = useState(0);
  const [totalSlideItems, setTotalSlideItems] = useState(0);
  const contentGridRef = useRef<HTMLDivElement>(null);
  const isXL = useMediaQuery("(min-width: 1401px)");
  const isTablet = useMediaQuery("(min-width: 481px)");
  const isLaptop = useMediaQuery("(min-width: 769px)");
  const [isInViewport, targetRef] = useIsInViewport({ threshold: 50 });
  const [hasBeenInViewport, setHasBeenInViewport] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (isInViewport && !hasBeenInViewport) {
      setTimeout(() => {
        setHasBeenInViewport(true);
      }, 1000);
    }
  }, [isInViewport, hasBeenInViewport]);

  useEffect(() => {
    if (contentGridRef.current)
      setTotalSlideItems(contentGridRef.current.childElementCount);
  }, []);

  const onScroll = () => {
    if (contentGridRef.current) {
      const left = contentGridRef.current.scrollLeft;
      const width =
        contentGridRef.current.scrollWidth - contentGridRef.current.clientWidth;
      const scrolled = (left / width) * 100;
      setScrolled(Math.round(scrolled));
    }
  };

  useEffect(() => {
    const currentRef = contentGridRef.current;
    if (currentRef) {
      currentRef.addEventListener("scroll", onScroll);
      return () => currentRef.removeEventListener("scroll", onScroll);
    }
  });

  const scroll = (scrollOffset: number) => {
    let itemCount = totalSlideItems;
    if (isTablet) itemCount = Math.ceil(itemCount / 2);
    if (isXL) itemCount = Math.ceil(totalSlideItems);
    const scrollStep = 1 / itemCount;
    if (contentGridRef.current)
      contentGridRef.current.scrollBy({
        left: scrollOffset * scrollStep * contentGridRef.current.scrollWidth,
        behavior: "smooth",
      });
  };

  const getDisplayTotal = (): number => {
    let displayTotal = totalSlideItems;
    if (isTablet) displayTotal = totalSlideItems / 2;

    return displayTotal;
  };

  const getCurrentSlide = (totalSlides: number, percent: number): number => {
    const itemsPerSlide = getDisplayTotal();

    if (totalSlides % 2) {
      //If odd number of slides.
      //89% to offset the -10vw in the min-width
      const currentSlide =
        Math.floor((percent / 100) * itemsPerSlide * 0.89) + 1;

      if (currentSlide == 0) {
        return 1;
      }
      return currentSlide > totalSlides ? totalSlides : currentSlide;
    } else {
      //If even number of slides.
      const currentSlide = Math.floor((percent / 100) * itemsPerSlide) + 1;
      if (currentSlide == 0) {
        return 1;
      }
      return currentSlide > itemsPerSlide ? itemsPerSlide : currentSlide;
    }
  };

  const shouldShow = () => {
    if (totalSlideItems < 4 && isXL) return false;
    else return true;
  };

  return (
    <section
      className={cn(s.section, {
        [c.xWide]: isLaptop,
        [s.noTopSpacing]: spacing === "noTop",
        [s.noBottomSpacing]: spacing === "noBottom",
        [s.noSpacing]: spacing === "noSpacing",
      })}
    >
      <div
        ref={targetRef}
        className={cn(s.wrapper, {
          [s.startpage]: isStartpage,
          [s.inViewport]: isInViewport || hasBeenInViewport,
        })}
      >
        <div className={cn(s.top, c.xWide, { [s.noTitle]: !title })}>
          {title && <h2>{title}</h2>}
          {description && (
            <div className={s.description}>
              <Markdown>{description}</Markdown>
            </div>
          )}
          {shouldShow() && (
            <ProgressBar
              totalSlides={getDisplayTotal()}
              percent={scrolled}
              currentSlide={getCurrentSlide(getDisplayTotal(), scrolled)}
            />
          )}
        </div>
        <div
          className={cn(s.contentGrid, { [c.xWide]: isXL })}
          ref={contentGridRef}
        >
          {children}
        </div>
        <div className={cn({ [c.xWide]: !isXL })}>
          <ProgressBar
            totalSlides={getDisplayTotal()}
            percent={scrolled}
            currentSlide={getCurrentSlide(getDisplayTotal(), scrolled)}
          />
        </div>
        <div className={cn(c.xWide)}>
          {scrolled > 0 && shouldShow() && (
            <SliderButton
              onClick={() => scroll(-1)}
              align="prev"
              label={t("previous")}
              type="floating"
              style={"white"}
            />
          )}
          {scrolled < 100 && shouldShow() && (
            <SliderButton
              onClick={() => scroll(1)}
              align="next"
              label={t("next")}
              type="floating"
              style={"white"}
            />
          )}
        </div>
      </div>
    </section>
  );
};

ContentGridSlider.Item = Item;

export default ContentGridSlider;
