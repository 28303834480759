import Head from "next/head";
import parse from "html-react-parser";
import React from "react";
import { ViewModel } from "../lib/viewModel/createViewModel";
import GoogleTagManager from "./GoogleTagManager";
import PardotTracking from "./PardotTracking";
import HellobarTracking from "./HellobarTracking";
import BaiduTracking from "./BaiduTracking";
import ConvertExperiences from "./ConvertExperiences";
import { imageVaultLoader } from "./ImageVaultImage";
import { resolveSlug } from "../lib/utils/resolveSlug";
import {
  deduceDomain,
  deduceDomainFromStorybookSlug,
} from "../lib/utils/deduceDomain";
import BreadCrumbsMeta from "./Layout/BreadcrumbsMeta";
import FavIconMeta from "./Layout/FavIconMeta";

type Props = {
  viewModel: ViewModel;
};

const MetaTags = ({ viewModel }: Props) => {
  const {
    seoImage,
    seoMeta,
    seoKeywords,
    title,
    description,
    noIndex,
    noFollow,
    canonicalUrl,
  } = viewModel.story.content;
  const { locale, site } = viewModel;

  const finalTitle = seoMeta?.title || title || viewModel.story.name;
  const finalDescription = seoMeta?.description || description;
  const seoImageUrl = seoImage?.item?.MediaConversions?.[0].Url.split("/")
    .slice(3)
    .join("/");
  let currentUrl =
    deduceDomain(locale, site) + resolveSlug(viewModel.story.full_slug);
  if (locale === "zh")
    currentUrl = currentUrl.replace(/(https:\/\/)(tobii\.cn.?)/, "$1www.$2");

  // Fix ZH indexing issue =>  Change all canonical links on ZH pages to use .cn instead
  const metaCurrentUrl = currentUrl.replace(/\/zh/, "");

  const seoFallbackImageUrl =
    deduceDomain(locale, site) + "/images/tobii-seo-fallback.jpg";

  // Organization schema
  // Source: https://developers.google.com/search/docs/appearance/structured-data/organization
  const getLocalizedValues = (locale) => {
    switch (locale) {
      case "ja":
        return {
          address: {
            "@type": "PostalAddress",
            streetAddress: "3F SG Square, 7-7-7 Nishigotanda",
            addressLocality: "Shinagawa-ku",
            addressCountry: "JP",
            addressRegion: "Tokyo",
            postalCode: "141-0031",
          },
          email: "sales.jp@tobii.com",
          description:
            "トビーはアイトラッキング（視線計測装置・視線追跡・視線カメラ）技術の世界最大手で、アテンション・コンピューティングのパイオニアです。学術分野では、心理学、医学、工学部で評価され、また消費者行動、技能伝承/自動化で活用されています。さらにXR/ゲーム/自動車/医療機器などに組込も可能で、次世代インターフェースの開発が実現できます。",
          name: "Tobii Technology K. K.",
          telephone: "+81-3-6420-3990",
        };
      case "zh":
        return {
          address: {
            "@type": "PostalAddress",
            streetAddress:
              "29G, Shanghai Industrial Investment Building 18 Caoxi North Road",
            addressLocality: "Caoxi North Road",
            addressCountry: "CN",
            addressRegion: "Shanghai",
            postalCode: "",
          },
          email: "sales.cn@tobii.com",
          description:
            "Tobii肩负着通过能够理解人的注意和意图的眼动追踪与注意力计算技术来改善世界的使命。",
          name: "Tobii Shanghai",
          telephone: "+86 180 1558 6966",
        };
      default:
        return {
          address: {
            "@type": "PostalAddress",
            streetAddress: "Karlsrovägen 2D Box 743",
            addressLocality: "Danderyd",
            addressCountry: "SE",
            addressRegion: "Stockholm",
            postalCode: "S-182 17",
          },
          email: "",
          description:
            "Tobii is on a mission to improve the world with our eye tracking and attention computing technology that understands human attention and intent.",
          name: "Tobii AB",
          telephone: "+46 8 663 69 90",
        };
    }
  };
  const localizedValues = getLocalizedValues(locale);

  const organizationSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    image: seoFallbackImageUrl,
    url: deduceDomain(locale, site),
    logo: deduceDomain(locale, site) + "/images/tobii-logo.png",
    name: localizedValues.name,
    description: localizedValues.description,
    address: localizedValues.address,
    contactPoint: {
      "@type": "ContactPoint",
      telephone: localizedValues.telephone,
      email: localizedValues.email,
    },
  };

  return (
    <>
      <Head>
        {parse(viewModel.headHtml ?? "")}
        <title>{`${finalTitle} - Tobii`}</title>
        <meta name="description" content={finalDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={finalTitle} />
        <meta property="og:locale" content={viewModel.locale} />
        {seoImageUrl ? (
          <meta
            property="og:image"
            content={
              deduceDomain(locale, site) +
              imageVaultLoader({
                src: "/" + seoImageUrl,
                width: 1200,
                quality: 80,
              })
            }
          />
        ) : (
          <meta property="og:image" content={seoFallbackImageUrl} />
        )}
        {finalDescription && (
          <meta property="og:description" content={finalDescription} />
        )}

        {seoKeywords && <meta name="keywords" content={seoKeywords} />}

        {seoImageUrl ? (
          <meta
            property="twitter:image"
            content={
              deduceDomain(locale, site) +
              imageVaultLoader({
                src: "/" + seoImageUrl,
                width: 1200,
                quality: 80,
              })
            }
          />
        ) : (
          <meta name="twitter:image" content={seoFallbackImageUrl} />
        )}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={finalTitle} />
        <meta name="twitter:description" content={finalDescription} />
        <meta name="twitter:image:alt" content={finalTitle} />
        <meta name="twitter:site" content="@TobiiTechnology" />

        <meta
          property="article:publisher"
          content="https://www.facebook.com/TobiiAB/"
        />

        {(noIndex || noFollow) && (
          <meta
            name="robots"
            content={[noIndex && "noindex", noFollow && "nofollow"]
              .filter(Boolean)
              .join(", ")}
          />
        )}

        {viewModel.googleSiteVerificationID && (
          <meta
            name="google-site-verification"
            content={viewModel.googleSiteVerificationID}
          />
        )}

        {viewModel.baiduSiteVerificationID && (
          <meta
            name="baidu-site-verification"
            content={viewModel.baiduSiteVerificationID}
          />
        )}

        {viewModel.bingSiteVerificationID && (
          <meta
            name="msvalidate.01"
            content={viewModel.bingSiteVerificationID}
          />
        )}
        {viewModel.facebookSiteVerificationID && (
          <meta
            name="facebook-domain-verification"
            content={viewModel.facebookSiteVerificationID}
          />
        )}

        {viewModel.story.alternates.map((alternate) => (
          <link
            key={alternate.id}
            rel="alternate"
            href={
              deduceDomainFromStorybookSlug(alternate.full_slug) +
              resolveSlug(alternate.full_slug)
            }
            hrefLang={alternate.full_slug.split("/")[0]}
          />
        ))}

        <link rel="canonical" href={canonicalUrl || metaCurrentUrl} />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(organizationSchema),
          }}
        />
      </Head>
      <FavIconMeta />
      <BreadCrumbsMeta {...viewModel.breadcrumbs} />
      <PardotTracking id={viewModel.pardotTrackingID} />
      <BaiduTracking id={viewModel.baiduTrackingIDOne} />
      <BaiduTracking id={viewModel.baiduTrackingIDTwo} />
      <GoogleTagManager id={viewModel.googleTagManagerId} />
      <HellobarTracking id={viewModel.hellobarID} />
      <ConvertExperiences id={viewModel.convertExperiencesID} />
    </>
  );
};

export default MetaTags;
