import { ReactNode, Children, useRef, useState, useEffect } from "react";
import cn from "classnames";
import s from "./Testimonials.module.scss";
import c from "../../styles/container.module.scss";
import useMediaQuery from "../../hooks/useMatchMedia";
import { useTranslation } from "next-i18next";
import { ProgressBar, SliderButton } from "../ContentGridSlider";

import Testimonial from "./components/Testimonial";

type Props = {
  title: string;
  spacing: "onlyTop" | "onlyBottom" | "both" | "none";
  children: ReactNode;
};

const subComponents = { Testimonial };
type SubComponents = typeof subComponents;

const Testimonials: CWS<Props, SubComponents> = ({
  title,
  spacing,
  children,
}) => {
  const [scrolled, setScrolled] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);
  const listRef = useRef<HTMLUListElement>(null);
  const isLaptop = useMediaQuery("(min-width: 769px)");
  const { t } = useTranslation();

  useEffect(() => {
    setTotalSlides(Children.count(children));
  }, [children]);

  const onScroll = () => {
    if (listRef.current) {
      const left = listRef.current.scrollLeft;
      const width = listRef.current.scrollWidth - listRef.current.clientWidth;
      const scrolled = (left / width) * 100;
      setScrolled(Math.round(scrolled));
    }
  };

  useEffect(() => {
    const currentRef = listRef.current;
    if (currentRef) {
      currentRef.addEventListener("scroll", onScroll);
      return () => currentRef.removeEventListener("scroll", onScroll);
    }
  });

  const scroll = (scrollOffset: number) => {
    let itemCount = totalSlides;
    if (isLaptop) itemCount = Math.ceil(itemCount / 2);
    const scrollStep = 1 / itemCount;
    if (listRef.current)
      listRef.current.scrollBy({
        left: scrollOffset * scrollStep * listRef.current.scrollWidth,
        behavior: "smooth",
      });
  };

  const getCurrentSlide = (totalSlides: number, percent: number): number => {
    const itemsPerSlide = totalSlides;

    if (totalSlides % 2) {
      //If odd number of slides.
      //89% to offset the -10vw in the min-width
      const currentSlide =
        Math.floor((percent / 100) * itemsPerSlide * 0.89) + 1;

      if (currentSlide == 0) {
        return 1;
      }
      return currentSlide > totalSlides ? totalSlides : currentSlide;
    } else {
      //If even number of slides.
      const currentSlide = Math.floor((percent / 100) * itemsPerSlide) + 1;
      if (currentSlide == 0) {
        return 1;
      }
      return currentSlide > itemsPerSlide ? itemsPerSlide : currentSlide;
    }
  };

  return (
    <section
      className={cn(
        s.testimonials,
        s[spacing],
        totalSlides === 1 ? c.medium : c.xWide
      )}
    >
      <div className={s.titleWrapper}>
        {title && <h2 className={s.title}>{title}</h2>}
        {isLaptop && totalSlides > 2 && (
          <ProgressBar
            totalSlides={totalSlides}
            percent={scrolled}
            currentSlide={1}
            className={s.progressBarTop}
          />
        )}
      </div>
      <ul ref={listRef} className={cn(s.slider)}>
        {children}
      </ul>
      {totalSlides > 1 && (
        <>
          {scrolled > 0 && totalSlides > 2 && (
            <SliderButton
              onClick={() => scroll(-1)}
              align="prev"
              label={t("previous")}
              style={"white"}
              type="floating"
            />
          )}
          {scrolled < 100 && totalSlides > 2 && (
            <SliderButton
              onClick={() => scroll(+1)}
              align="next"
              label={t("next")}
              style={"white"}
              type="floating"
            />
          )}
        </>
      )}
      {!isLaptop && totalSlides > 2 && (
        <div className={s.progressBarBottom}>
          <ProgressBar
            totalSlides={totalSlides}
            percent={scrolled}
            currentSlide={getCurrentSlide(totalSlides, scrolled)}
          />
        </div>
      )}
    </section>
  );
};

Testimonials.Testimonial = Testimonial;

export default Testimonials;
