import { ReactNode } from "react";
import c from "../../../styles/container.module.scss";
import cn from "classnames";
import s from "./EditorialCopy.module.scss";

type Props = {
  className?: string;
  children: ReactNode;
};

const EditorialCopy = ({ className, children }: Props) => (
  <div className={cn(c.narrow, s.copy, className)}>{children}</div>
);

export default EditorialCopy;
