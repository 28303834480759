import cn from "classnames";

import s from "../FeaturedTabs.module.scss";

type Props = {
  text: string;
  active: boolean;
  onClick: () => void;
  isMobileTab?: boolean;
};

const Tab = ({ text, active, onClick, isMobileTab = false }: Props) => (
  <li className={s.tab}>
    <button
      className={cn("label", {
        [s.active]: active,
        [s.mobileTab]: isMobileTab,
      })}
      onClick={onClick}
    >
      {text}
    </button>
  </li>
);

export default Tab;
