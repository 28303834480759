// eslint-disable-next-line import/no-named-as-default
import ReCAPTCHA from "react-google-recaptcha";
import cn from "classnames";

import s from "./SubscriptionForm.module.scss";
import c from "../../styles/container.module.scss";

import Checkbox from "../Checkbox";
import Input from "../Input";
import Button from "../Button";
import axios from "axios";
import { useState } from "react";
import Markdown from "../Markdown";

type Props = {
  title: string;
  preamble: string;
  spacing: "onlyTop" | "onlyBottom" | "both" | "none";
  thankYouMarkdown: string;
  languageLabel: string;
  englishLabel: string;
  swedishLabel: string;
  infoTypeLabel: string;
  annualReportsLabel: string;
  interimReportsLabel: string;
  pressReleasesLabel: string;
  newsLetterLabel: string;
  nameLabel: string;
  emailLabel: string;
  smsLabel: string;
  submitLabel: string;
  subscriptionUniqueIdentifier: string;
  recaptchaSitekey: string;
};

const SubscriptionForm = ({
  title,
  preamble,
  spacing,
  thankYouMarkdown,
  languageLabel,
  englishLabel,
  swedishLabel,
  infoTypeLabel,
  annualReportsLabel,
  interimReportsLabel,
  pressReleasesLabel,
  newsLetterLabel,
  nameLabel,
  emailLabel,
  smsLabel,
  submitLabel,
  subscriptionUniqueIdentifier,
  recaptchaSitekey,
}: Props) => {
  const [hasSubscribed, setHasSubscribed] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);

    const { data } = await axios({
      method: "post",
      url: "https://publish.ne.cision.com/Subscription/SubscribeWithCaptcha",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (data === "Success") {
      setHasSubscribed(true);
    }
  };

  return (
    <section className={cn(s.subscriptionForm, c.xWide, s[spacing])}>
      {(title || preamble) && (
        <div className={cn(s.intro)}>
          {title && <h2>{title}</h2>}
          {preamble && <p className={cn(s.description)}>{preamble}</p>}
        </div>
      )}
      {hasSubscribed ? (
        <>{thankYouMarkdown && <Markdown>{thankYouMarkdown}</Markdown>}</>
      ) : (
        <>
          <form
            className={s.form}
            onSubmit={onSubmit}
            action="https://publish.ne.cision.com/Subscription/SubscribeWithCaptcha"
          >
            <input
              type="hidden"
              name="subscriptionUniqueIdentifier"
              value={subscriptionUniqueIdentifier}
            />
            <input type="hidden" name="Replylanguage" value="en" />
            <div className={s.checkboxes}>
              <Checkbox
                label={languageLabel}
                name={languageLabel}
                items={[
                  { id: "0", label: englishLabel, value: "en" },
                  { id: "1", label: swedishLabel, value: "sv" },
                ]}
              />
              <Checkbox
                label={infoTypeLabel}
                name={"informationtype"}
                items={[
                  { id: "2", label: annualReportsLabel, value: "rdv" },
                  { id: "3", label: interimReportsLabel, value: "rpt" },
                  { id: "4", label: pressReleasesLabel, value: "prm" },
                  { id: "5", label: newsLetterLabel, value: "nbr" },
                ]}
              />
            </div>
            <div className={s.inputs}>
              <Input name="Name" label={nameLabel} required />
              <Input name="Email" label={emailLabel} required />
              <Input
                name="cellphone"
                type="tel"
                label={smsLabel}
                placeholder="+46709123456"
                pattern="(00|\+)[0-9\-]{7,}"
              />
            </div>
            <ReCAPTCHA sitekey={recaptchaSitekey} />
            <Button variant="cta">{submitLabel}</Button>
          </form>
        </>
      )}
    </section>
  );
};

export default SubscriptionForm;
