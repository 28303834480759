import { useTranslation } from "next-i18next";
import { ContentGridTobiiGroupShareBlock } from "../../lib/storyblok/types/blocks/ContentGridTobiiGroupShareBlock";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { useLinks } from "../../lib/storyblok/useLinks";
import { useViewmodel } from "../../lib/storyblok/ViewmodelContext";
import { resolveDate } from "../../lib/utils/resolveDate";
import { resolveSlug } from "../../lib/utils/resolveSlug";
import ContentGridUi from "../../ui/ContentGrid";
import List from "../../ui/ContentGrid/components/List";
import ListItem from "../../ui/ContentGrid/components/ListItem";
import ListEventItem from "../../ui/ContentGrid/components/ListEventItem";
import ShareTicker from "./ShareTicker";

const ContentGridTobiiGroupShare: StoryblokBlockComponent<
  ContentGridTobiiGroupShareBlock
> = ({ title, description, spacing, events, pressReleases }) => {
  const { t } = useTranslation();
  const { globalLinks } = useViewmodel();
  const resolveLink = useLinks();
  const { locale } = useViewmodel();

  return (
    <ContentGridUi title={title} description={description} spacing={spacing}>
      <ShareTicker _uid={""} component={""} _editable={""} />
      <List
        title={t("upcoming-events")}
        browseAllHref={
          globalLinks.investorEventFilterPage &&
          resolveLink(globalLinks.investorEventFilterPage)
        }
        browseAllLabel={t("browse-all")}
      >
        {events &&
          events.map((event) => {
            // We have to conditionally collect day, month and year as we have two different date format: "sv" format is d MMMM, yyyy and "default" format is MMMM d, yyyy
            const dateObject = resolveDate(event.content.eventDate, locale);
            const dateParts = dateObject.split(", ")[0].split(" ");
            const day = locale === "sv" ? dateParts[0] : dateParts[1];
            const month = locale === "sv" ? dateParts[1] : dateParts[0];

            return (
              <ListEventItem
                key={event.uuid}
                title={event.name}
                eventLink={resolveSlug(event.full_slug)}
                dateTime={event.content.eventDate}
                day={day}
                month={month}
                tags={event.content.eventType}
                location={event.content.eventLocation}
              />
            );
          })}
      </List>
      <List
        title={t("latest-press-releases")}
        browseAllHref={
          globalLinks.pressreleaseFilterPage &&
          resolveLink(globalLinks.pressreleaseFilterPage)
        }
        browseAllLabel={t("browse-all")}
      >
        {pressReleases?.map((pressRelease) => (
          <ListItem
            key={pressRelease.uuid}
            title={pressRelease.name}
            href={pressRelease.full_slug}
            date={resolveDate(pressRelease.content.publishDate, locale)}
            dateTime={resolveDate(pressRelease.content.publishDate)}
          />
        ))}
      </List>
    </ContentGridUi>
  );
};

export default ContentGridTobiiGroupShare;
