import { useTranslation } from "next-i18next";
import React, { useCallback } from "react";
import {
  useClearRefinements,
  useCurrentRefinements,
  useRefinementList,
} from "react-instantsearch";
import FilterList from "../../ui/FilterList";

type RefinementListItem = {
  value: string;
  label: string;
  highlighted?: string;
  count: number;
  isRefined: boolean;
};

const RefinementList = () => {
  const { t } = useTranslation("searchpage");

  const translateLabel = useCallback(
    (items: RefinementListItem[]) =>
      items.map((item) => ({ ...item, label: t(`pages.${item.value}`) })),
    [t]
  );
  const { items, refine } = useRefinementList({
    limit: 99,
    attribute: "storyType",
    sortBy: ["name:asc"],
    transformItems: translateLabel,
  });
  const { refine: clearRefinements } = useClearRefinements();
  const { items: currentRefinements } = useCurrentRefinements();

  const onClearClick = () => {
    clearRefinements();
  };
  const handleFilterChange = (value: string) => () => refine(value);

  return (
    <>
      <FilterList.Heading
        clearLabel={currentRefinements.length > 0 ? t("clear-all") : ""}
        onClearClick={onClearClick}
      >
        {t("filter-by")}
      </FilterList.Heading>
      <FilterList layout="static">
        {items.map(({ label, count, value, isRefined }) => (
          <FilterList.Filter
            key={label}
            amount={count}
            value={value}
            checked={isRefined}
            onChange={handleFilterChange(value)}
          >
            {label}
          </FilterList.Filter>
        ))}
      </FilterList>
    </>
  );
};

export default RefinementList;
