import React, { ReactNode } from "react";
import cn from "classnames";
import s from "./MobileMenu.module.scss";

type Props = {
  isOpen: boolean;
  children: ReactNode;
  isTobiiGroup?: boolean;
};

export const MobileMenu = ({ isOpen, children, isTobiiGroup }: Props) => {
  return (
    <div
      className={cn(s.mobileMenu, {
        [s.isOpen]: isOpen,
        [s.tobiiGroup]: isTobiiGroup,
      })}
    >
      {children}
    </div>
  );
};
