import React, { ReactNode, HTMLProps, forwardRef } from "react";
import cn from "classnames";
import s from "./DesktopMenu.module.scss";

type Props = {
  children: ReactNode;
} & HTMLProps<HTMLDivElement>;

export const DesktopSubNavigationTab = forwardRef<HTMLDivElement, Props>(
  ({ children }, ref) => {
    return (
      <div
        className={cn(s.DesktopSubNavigationTab, s.DesktopSubNavigation)}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);

DesktopSubNavigationTab.displayName = "DesktopSubNavigationTab";
