import React, { HTMLProps } from "react";
import s from "../FilterPage.module.scss";
import cn from "classnames";

type FilterProps = HTMLProps<HTMLElement> & {
  visible?: boolean;
};

const Filter = ({ className, visible, ...props }: FilterProps) => (
  <aside
    id="filter"
    {...props}
    className={cn(className, s.filter, { [s.filter_visible]: visible })}
  />
);

export default Filter;
