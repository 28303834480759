import React from "react";
import s from "./ImagevaultMigration.module.scss";

const ImagevaultMigration = ({
  hero,
  editorial,
  content,
  logo,
  image1,
  image2,
  image3,
  image4,
  image5,
}) => {
  return (
    <>
      <section className={s.section}>
        <h1>Hero</h1>
        <div className={s.imageContainer}>{hero}</div>
      </section>
      <section className={s.section}>
        <h1>Editorial</h1>
        <div className={s.imageContainer}>{editorial}</div>
      </section>
      <section className={s.section}>
        <h1>Content</h1>
        <div className={s.imageContainer}>{content}</div>
      </section>
      <section className={s.section}>
        <h1>Logo</h1>
        <div className={s.imageContainer}>{logo}</div>
      </section>
      <section className={s.section}>
        <h1>Image1</h1>
        <div className={s.imageContainer}>{image1}</div>
      </section>
      <section className={s.section}>
        <h1>Image2</h1>
        <div className={s.imageContainer}>{image2}</div>
      </section>
      <section className={s.section}>
        <h1>Image3</h1>
        <div className={s.imageContainer}>{image3}</div>
      </section>
      <section className={s.section}>
        <h1>Image4</h1>
        <div className={s.imageContainer}>{image4}</div>
      </section>
      <section className={s.section}>
        <h1>Image5</h1>
        <div className={s.imageContainer}>{image5}</div>
      </section>
    </>
  );
};

export default ImagevaultMigration;
