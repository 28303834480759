import { useTranslation } from "next-i18next";
import { useRelations } from "../../lib/storyblok/useRelations";
import { ContentGridBlock } from "../../lib/storyblok/types/blocks/ContentGridBlock";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { resolveStoryImage } from "../../lib/utils/resolveStoryImage";
import { resolveSlug } from "../../lib/utils/resolveSlug";
import Button from "../../ui/Button";
import ContentGridUi from "../../ui/ContentGrid";
import ImageVaultImage from "../ImageVaultImage";
import { imageSizes } from "../../lib/utils/imageSizes";

const ContentGrid: StoryblokBlockComponent<ContentGridBlock> = ({
  title,
  description,
  items,
  twoColumns,
  verticalSpacing,
  isStartpage,
}) => {
  const { getRelation } = useRelations();
  const { t } = useTranslation();

  return (
    <ContentGridUi
      title={title}
      description={description}
      twoColumns={twoColumns}
      spacing={verticalSpacing}
      isStartpage={isStartpage}
    >
      {items.map((item) => {
        const story = getRelation(item);
        if (story) {
          const { uuid, content, name, full_slug } = story;
          const category = full_slug.split("/").reverse()[1];
          const slug = resolveSlug(full_slug);
          return (
            <ContentGridUi.Item
              key={uuid}
              href={slug}
              image={
                <ImageVaultImage
                  sizes={imageSizes("100vw", { xl: "750px", laptop: "33vw" })}
                  image={resolveStoryImage(content)}
                  layout="fill"
                />
              }
              category={
                !isStartpage
                  ? content.category || t(category, { ns: "tags" })
                  : ""
              }
              title={content.title || name}
              text={content.description || ""}
              link={
                isStartpage ? (
                  <Button
                    variant="default"
                    href={slug}
                    title={content.title || name}
                    icon="arrow-right"
                  >
                    {t("learn-more")}
                  </Button>
                ) : null
              }
            />
          );
        }
      })}
    </ContentGridUi>
  );
};

export default ContentGrid;
