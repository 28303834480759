import React from "react";
import { styled } from "@mui/material/styles";
import cn from "classnames";
import s from "../Accordion.module.scss";
import s2 from "../../Editorial/components/EditorialCopy.module.scss";
import Markdown from "../../Markdown";
import Icon from "../../Icon";

import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

type Props = {
  title: string;
  description: string;
  style: string;
  index: number;
  expanded: boolean;
  onChange: (event: React.SyntheticEvent, isExpanded: boolean) => void;
};

const Item = ({
  title,
  description,
  style,
  index,
  expanded,
  onChange,
}: Props) => {
  const introStyle =
    style !== "white" &&
    style !== "gray" &&
    style !== "none" &&
    style !== undefined
      ? s.inverted
      : s.default;

  const getBorderColor = () => {
    switch (style) {
      case "white":
        return "rgb(45 55 62 / 76%)";
      case "gray":
        return "rgb(45 55 62 / 76%)";
      case "none":
        return "rgb(45 55 62 / 76%)";
      case "":
        return "rgb(45 55 62 / 76%)";
      case "default":
      default:
        return "rgb(175 181 201 / 55%)";
    }
  };

  const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(() => ({
    borderBottom: `2px solid ${getBorderColor()}`,
    backgroundColor: "transparent",
    "&:first-child": {
      borderTop: `2px solid ${getBorderColor()}`,
    },
    "&::before": {
      display: "none",
    },
    "&.Mui-expanded": {
      borderBottom: "none",
    },
  }));

  const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={props.expandIcon} {...props} />
  ))(() => ({
    backgroundColor: "transparent",
    borderBottom: "none",
    padding: "0",
    "& .MuiAccordionSummary-content": {
      marginLeft: 0,
      padding: "8px 16px",
    },
    "& .MuiAccordionSummary-expandIconWrapper": {
      padding: "0 16px",
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(() => ({
    padding: "0 16px 32px",
    borderBottom: `2px solid ${getBorderColor()}`,
  }));

  return (
    <Accordion
      className={cn(s.item, introStyle, s2.copy)}
      expanded={expanded}
      onChange={onChange}
    >
      <AccordionSummary
        aria-controls={`panel${index}-content`}
        id={`panel${index}-header`}
        expandIcon={
          <Icon icon="caret-down" width={16} className={cn(s.icon)} />
        }
      >
        {title && <h3 className={s.title}>{title}</h3>}
      </AccordionSummary>
      <AccordionDetails>
        <div className={cn(s.description)}>
          <Markdown>{description}</Markdown>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default Item;
