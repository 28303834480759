/* eslint-disable */
import React from "react";
import Image from "next/image";
import { useLinks } from "../../../lib/storyblok/useLinks";
type TableCellWithLinkProps = {
  link: any;
  iconSrc: string;
};

const TableCellWithLink: React.FC<TableCellWithLinkProps> = ({
  link,
  iconSrc,
}) => {
  const resolveLink = useLinks();

  return (
    <td>
      {(link.filename || link.id || link.cached_url) && (
        <a
          href={link.filename ? link.filename : resolveLink(link)}
          target="_blank"
          rel="noreferrer"
        >
          <Image src={iconSrc} width={24} height={24} alt="" />
        </a>
      )}
    </td>
  );
};

export default TableCellWithLink;
