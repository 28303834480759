import Image from "next/image";
import { ReactNode } from "react";
import Icon, { IconType } from "../../Icon/Icon";
import Modal from "../../Modal";
import Markdown from "../../Markdown";
import ModalPortal from "../../ModalPortal/ModalPortal";
import s from "../Downloads.module.scss";

type Props = {
  modalRef?: React.RefObject<HTMLDivElement>;
  icon: IconType;
  title: string;
  subtitle: string;
  form: ReactNode;
  formTitle?: string;
  formText?: string;
  toggleModal: () => void;
  isModalOpen: boolean;
};

const GatedDownload = ({
  modalRef,
  icon,
  title,
  subtitle,
  form,
  formTitle,
  formText,
  toggleModal,
  isModalOpen,
}: Props) => {
  return (
    <>
      <li className={s.item}>
        <button onClick={toggleModal}>
          {icon && (
            <Image
              src={`/svg/icons/${icon}.svg`}
              width={24}
              height={24}
              alt=""
            />
          )}
          <div>
            <p className={s.title}>{title}</p>
            <p className={s.subtitle}>{subtitle}</p>
          </div>
        </button>
      </li>
      <ModalPortal show={isModalOpen}>
        <Modal className={s.modal} onCloseClick={toggleModal}>
          <div className={s.modalWrapper} ref={modalRef}>
            <button className={s.close} onClick={toggleModal}>
              <Icon icon="cancel" color="black" />
            </button>
            {formTitle && <h3 className={s.formTitle}>{formTitle}</h3>}
            {formText && (
              <div className={s.formText}>
                <Markdown>{formText}</Markdown>
              </div>
            )}
            <div className={s.form}>{form}</div>
          </div>
        </Modal>
      </ModalPortal>
    </>
  );
};

export default GatedDownload;
