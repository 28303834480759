import React, { ReactNode } from "react";
import cn from "classnames";
import s from "./PartOfEvent.module.scss";
import { useTranslation } from "next-i18next";

type Props = {
  blockTitle: string;
  image: ReactNode;
  eventType: string;
  title: string;
  date: string;
  location: string;
  link: ReactNode;
};

const PartOfEvent = ({
  blockTitle,
  image,
  eventType,
  title,
  date,
  location,
  link,
}: Props) => {
  const { t } = useTranslation();

  return (
    <section className={s.partOfEvent}>
      <h6 className={cn("label", s.label)}>{blockTitle}</h6>

      <div className={cn(s.card)}>
        {image && (
          <figure className={cn(s.imageContainer, s.figure)}> {image}</figure>
        )}
        {eventType && <p className={cn("label", s.label)}>{eventType}</p>}
        {title && <h5 className={s.title}>{title}</h5>}
        <ul className={s.details}>
          {date ? <li className={cn("date")}>{date}</li> : null}
          {location && (
            <li className={cn("location")}>{t(location, { ns: "tags" })}</li>
          )}
        </ul>
        {link && <div className={s.link}>{link}</div>}
      </div>
    </section>
  );
};

export default PartOfEvent;
