import { useRouter } from "next/router";
import React from "react";
import { Configure, InstantSearch } from "react-instantsearch";
import { PressreleaseFinderStory } from "../../lib/storyblok/types/stories/PressreleaseFinderStory";
import { StoryblokStoryComponent } from "../../lib/storyblok/types/StoryblokComponent";
import {
  initAlgoliaInvestorIndecies,
  initAlgoliaSearch,
} from "../../lib/utils/AlgoliaSearchHelpers";
import { ViewModel } from "../../lib/viewModel/createViewModel";
import { DynamicBlocks } from "../DynamicBlock";
import StoryFinder from "../Nestable/StoryFinder";
import { history } from "instantsearch.js/es/lib/routers";

type VM = ViewModel<PressreleaseFinderStory>;
const indecies = initAlgoliaInvestorIndecies();
const searchClient = initAlgoliaSearch();

const PressreleaseFinderPage: StoryblokStoryComponent<VM> = ({
  story,
  locale,
}) => {
  const { hero, body, activateSearchField } = story.content;
  const { query } = useRouter();
  const initialValue = typeof query.q === "string" ? query.q : undefined;
  const refinements = [
    { id: "pressreleaseCategory", attribute: "categories" },
    {
      id: "pressreleasePublishDateYear",
      attribute: "pressreleasePublishDateYear",
      sort: "reverse",
    },
  ];

  interface queryParameters {
    categories?: string[];
    pressreleasePublishDateYear?: string[];
  }

  const pressreleaseInstantSearchRouting = {
    router: history({
      createURL({ qsModule, routeState, location }) {
        const pageUrl =
          locale === "sv"
            ? "media/pressmeddelanden"
            : "newsroom/press-releases";
        const urlParts =
          locale === "sv"
            ? location.href.match(/^(.*?)\/media\/pressmeddelanden/)
            : location.href.match(/^(.*?)\/newsroom\/press-releases/);
        const baseUrl = `${urlParts ? urlParts[1] : ""}/`;

        const queryParameters: queryParameters = {};

        if (routeState.categories) {
          queryParameters.categories = (routeState.categories as string[]).map(
            encodeURIComponent
          );
        }
        if (routeState.pressreleasePublishDateYear) {
          queryParameters.pressreleasePublishDateYear = (
            routeState.pressreleasePublishDateYear as string[]
          ).map(encodeURIComponent);
        }

        const queryString = decodeURIComponent(
          qsModule.stringify(queryParameters, {
            addQueryPrefix: true,
            arrayFormat: "repeat",
          })
        );

        return `${baseUrl}${pageUrl}${queryString}`;
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      parseURL({ qsModule, location }) {
        const { categories = [], pressreleasePublishDateYear = [] } =
          qsModule.parse(location.search.slice(1));

        const allCategories = Array.isArray(categories)
          ? categories
          : [categories].filter(Boolean);

        const allPressreleasePublishDateYear = Array.isArray(
          pressreleasePublishDateYear
        )
          ? pressreleasePublishDateYear
          : [pressreleasePublishDateYear].filter(Boolean);

        const uiState: queryParameters = {
          categories: allCategories.map(decodeURIComponent),
          pressreleasePublishDateYear:
            allPressreleasePublishDateYear.map(decodeURIComponent),
        };

        return uiState;
      },
    }),

    stateMapping: {
      stateToRoute(uiState) {
        const indexUiState = uiState[indecies[locale]] || {};

        return {
          categories: indexUiState.refinementList?.categories,
          pressreleasePublishDateYear:
            indexUiState.refinementList?.pressreleasePublishDateYear,
        };
      },

      routeToState(routeState) {
        return {
          [indecies[locale]]: {
            refinementList: {
              categories: routeState.categories,
              pressreleasePublishDateYear:
                routeState.pressreleasePublishDateYear,
            },
          },
        };
      },
    },
  };

  return (
    <InstantSearch
      indexName={indecies[locale]}
      searchClient={searchClient}
      routing={pressreleaseInstantSearchRouting}
    >
      <Configure
        hitsPerPage={8}
        attributesToSnippet={["title:100", "content:40", "metaDescription:40"]}
        query={initialValue}
        filters={"storyType:pressreleasePage"}
      />
      <DynamicBlocks blocks={hero} />
      <StoryFinder
        pageType={"pressReleaseFinderPage"}
        renderImages={false}
        value={initialValue}
        preamble={body}
        activateSearchField={activateSearchField}
        refinements={refinements}
      />
    </InstantSearch>
  );
};

export default PressreleaseFinderPage;
