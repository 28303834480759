import React from "react";
import { useTranslation } from "next-i18next";
import cn from "classnames";
import s from "./Datablock.InvestorGrid.module.scss";
import { useUpcomingEvents } from "../../../hooks/mfn/useUpcomingEvents";
import tokens from "../tokens.json";

type Props = {
  locale: string;
  browseAllHref?: string;
};

const FinancialEvents: React.FC<Props> = ({ browseAllHref, locale }) => {
  const { t } = useTranslation();
  const { events, isLoading, error } = useUpcomingEvents(
    tokens.upcomingEvents,
    locale
  );

  if (error) {
    return (
      <div className={cn(s.item, s.calendarFeed)}>
        <div className={s.error}>Unable to load calendar data</div>
      </div>
    );
  }

  if (isLoading || !events.length) {
    return (
      <div className={cn(s.item, s.calendarFeed)}>
        <div className={s.loading}>{t("loading...")}</div>
      </div>
    );
  }

  const getMonthName = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString(locale, { month: "long" }).toUpperCase();
  };

  const getDay = (dateString: string) => {
    const date = new Date(dateString);
    return date.getDate();
  };

  return (
    <div className={cn(s.item, s.financialEvents)}>
      <div className={s.listHeading}>
        <h3>{t("upcoming-events")}</h3>
        {browseAllHref && (
          <a
            className={s.browseAll}
            href={browseAllHref}
            title={t("upcoming-events")}
          >
            {t("browse-all")}
          </a>
        )}
      </div>

      <ul className={s.list}>
        {events.map((event) => (
          <li key={event.id} className={s.listEventItem}>
            <time className={cn(s.date)} dateTime={event.starts}>
              <span className={s.day}>{getDay(event.starts)}</span>
              <span className="label">{getMonthName(event.starts)}</span>
            </time>
            <div className={s.wrapper}>
              <p className={s.title}>{event.title}</p>
              <p className={cn("label", s.location)}>{t("online")}</p>
              <ul className={s.calendarLinks}>
                {event.calendarLinks.google && (
                  <li>
                    <a
                      href={event.calendarLinks.google}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("google")}
                    </a>
                  </li>
                )}
                {event.calendarLinks.ical && (
                  <li>
                    <a
                      href={event.calendarLinks.ical}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("ical")}
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FinancialEvents;
