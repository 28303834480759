import React from "react";
import { HeaderNavigationItem } from "../../../../lib/storyblok/types/blocks/header/HeaderNavigationItem";
import { HeaderSubNavigationPanel } from "../../../../lib/storyblok/types/blocks/header/HeaderSubNavigationPanel";
import { HeaderSubNavigationTab } from "../../../../lib/storyblok/types/blocks/header/HeaderSubNavigationTab";
import HeaderSubNavigationPanelComponent from "../HeaderSubNavigationPanel";
import HeaderSubNavigationTabComponent from "../HeaderSubNavigationTab";
import MobileSubNavigationTabComponent from "../MobileSubNavigationTab";
import MobileSubNavigationPanelComponent from "../MobileSubNavigationPanel";

type SubNavigationProps = {
  subNavigation: HeaderNavigationItem["subNavigation"];
  view: "desktop" | "mobile";
  title?: string;
  href?: string;
  setIsMobileSubNavOpen?: (isOpen: boolean) => void;
};

const SubNavigation: React.FC<SubNavigationProps> = ({
  subNavigation,
  view,
  title,
  href,
  setIsMobileSubNavOpen,
}) => {
  if (view === "desktop") {
    return (
      <>
        {subNavigation.map((item) => {
          switch (item.component) {
            case "headerSubNavigationPanel":
              return (
                <HeaderSubNavigationPanelComponent
                  key={item._uid}
                  item={item as HeaderSubNavigationPanel}
                />
              );
            case "headerSubNavigationTab":
              return (
                <HeaderSubNavigationTabComponent
                  key={item._uid}
                  item={item as HeaderSubNavigationTab}
                />
              );
            default:
              return null;
          }
        })}
      </>
    );
  } else if (view === "mobile") {
    return (
      <>
        {subNavigation.map((item) => {
          switch (item.component) {
            case "headerSubNavigationPanel":
              return (
                <MobileSubNavigationPanelComponent
                  key={item._uid}
                  item={item as HeaderSubNavigationPanel}
                  title={title || ""}
                  href={href || "#"}
                  setIsMobileSubNavOpen={setIsMobileSubNavOpen!}
                />
              );
            case "headerSubNavigationTab":
              return (
                <MobileSubNavigationTabComponent
                  key={item._uid}
                  item={item as HeaderSubNavigationTab}
                  title={title || ""}
                  href={href || "#"}
                  setIsMobileSubNavOpen={setIsMobileSubNavOpen!}
                />
              );
            default:
              return null;
          }
        })}
      </>
    );
  }
  return null;
};

export default SubNavigation;
