import React from "react";
import cn from "classnames";
import s from "./SearchPage.module.scss";
import c from "../../../styles/container.module.scss";
import Filter from "./components/Filter";
import Body from "./components/Body";
import Input from "./components/Input";

type SearchPageProps = {
  children: JSX.Element | JSX.Element[];
};

const subComponents = { Filter, Body, Input };
type SubComponents = typeof subComponents;

const SearchPage: CWS<SearchPageProps, SubComponents> = ({ children }) => (
  <section className={cn(s.searchPage, c.xWide)}>{children}</section>
);

SearchPage.Filter = Filter;
SearchPage.Body = Body;
SearchPage.Input = Input;

export default SearchPage;
