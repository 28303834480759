import s from "../Tags.module.scss";
import b from "../../../ui/Button/Button.module.scss";
import cn from "classnames";

type Props = {
  tag: string;
  url?: string;
};

const Tag = ({ tag, url }: Props) => (
  <li>
    {url ? (
      <a href={url} title={tag} className={cn(b.button, b.tag)}>
        <span>{tag}</span>
      </a>
    ) : (
      <span className={s.tag}>{tag}</span>
    )}
  </li>
);

export default Tag;
