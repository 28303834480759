import cn from "classnames";
import s from "./LanguageSelect.module.scss";
import LanguageItem from "./components/LanguageItem";
import Button from "../Button";
import Modal from "../Modal";

type Props = {
  title: string;
  preamble: string;
  children: React.ReactElement[];
  closeButtonLabel: string;
  closeModal?: () => void;
};

const subComponents = {
  LanguageItem,
};

type SubComponents = typeof subComponents;

const LanguageSelectModal: CWS<Props, SubComponents> = ({
  title,
  preamble,
  closeButtonLabel,
  closeModal,
  children,
}: Props) => {
  return (
    <>
      <Modal onCloseClick={closeModal}>
        <h2>{title}</h2>
        <p>{preamble}</p>
        <div className={cn(s.langList)}>
          <ul>{children}</ul>
        </div>
        <Button onClick={closeModal}>{closeButtonLabel}</Button>
      </Modal>
    </>
  );
};

LanguageSelectModal.LanguageItem = LanguageItem;

export default LanguageSelectModal;
