import { ViewModel } from "../../lib/viewModel/createViewModel";
import { StoryblokStoryComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { DynamicBlocks } from "../DynamicBlock";

import { initAlgoliaIndecies } from "../../lib/utils/AlgoliaSearchHelpers";
import SearchPage from "../Nestable/SearchPage";
import { MainSearchPageStory } from "../../lib/storyblok/types/stories/MainSearchPageStory";

type VM = ViewModel<MainSearchPageStory>;

const MainSearchPage: StoryblokStoryComponent<VM> = ({ story, locale }) => {
  const { hero } = story.content;

  return (
    <SearchPage locale={locale} indices={initAlgoliaIndecies()}>
      <DynamicBlocks blocks={hero} />
    </SearchPage>
  );
};
export default MainSearchPage;
