import { useTranslation } from "next-i18next";
import { format } from "date-fns";
import { storyblokEditable } from "@storyblok/react";
import { ViewModel } from "../../lib/viewModel/createViewModel";
import { PressreleasePageStory } from "../../lib/storyblok/types/stories/PressreleasePageStory";
import { StoryblokStoryComponent } from "../../lib/storyblok/types/StoryblokComponent";
import WithSidebar from "../../ui/PageTemplates/WithSidebar";
import Share from "../../ui/Share";
import Tags from "../../ui/Tags";
import Tag from "../Tag";
import Details from "../../ui/Details";
import { DynamicBlocks } from "../DynamicBlock";
import { resolveDate } from "../../lib/utils/resolveDate";
import Editorial from "../../ui/Editorial";
import { useRelations } from "../../lib/storyblok/useRelations";
import { Contact as ContactStory } from "../../lib/storyblok/types/stories/Contact";
import MeasuredImage from "../../lib/storyblok/MeasuredImage";
import { imageSizes } from "../../lib/utils/imageSizes";
import { MediaQueryHider } from "../../ui/MediaQueryHider/MediaQueryHider";
import Video from "../../ui/Video";
import ShareItems from "../../ui/Share/components/ShareItems";
import Body from "../../ui/PageTemplates/WithSidebar/components/Body";
import Container from "../../ui/Container/Container";
import { useViewmodel } from "../../lib/storyblok/ViewmodelContext";
import DisclaimerBlock from "../../ui/DisclaimerBlock";
import { useState } from "react";
import { useLinks } from "../../lib/storyblok/useLinks";

type VM = ViewModel<PressreleasePageStory>;

const { Sidebar } = WithSidebar;

const PressreleasePage: StoryblokStoryComponent<VM> = ({ story }) => {
  const {
    hasDisclaimer,
    disclaimerTitle,
    disclaimerDescription,
    disclaimerOptionsList,
    disclaimerAuthorizedOptions,
    disclaimerSecondPageButtonText,
    disclaimerSecondPageSecondaryButtonText,
    disclaimerMainButtonText,
    disclaimerSecondaryButtonLink,
    disclaimerSecondaryButtonText,
    disclaimerRestrictedAccessTitle,
    disclaimerAuthorizedContentDescription,
    disclaimerRestrictedAccessContentDescription,
    disclaimerAuthorizedContentTitle,
    disclaimerHasSecondPage,
    disclaimerFirstDropDownTitle,
    pageTitle,
    files,
    contacts,
    intro,
    body,
    publishDate,
    categories,
    images,
    videos,
  } = story.content;

  const resolveLink = useLinks();
  const { globalLinks, locale } = useViewmodel();

  const [showPressRelease, setShowPressRelease] = useState(false);

  const { getRelation } = useRelations();
  const { t } = useTranslation(["tags", "pressreleasepage", "common"]);
  const formattedDate = publishDate
    ? format(new Date(publishDate), "MMMM dd, yyyy")
    : "";
  const fileEndingPattern = /[a-zA-Z0-9s_\\.\-():]+\.([A-Za-z]{3,4})$/i;

  // Do not show Financial Report categroies
  const categoriesToExclude = [
    "annual-report",
    "year-end-report",
    "interim-report",
  ];

  const filteredCategories = categories?.filter(
    (category) => !categoriesToExclude.includes(category.toLowerCase())
  );

  const hasImages = Array.isArray(images) && images?.length > 0;
  const hasVideos = Array.isArray(videos && videos?.length > 0);
  const documentationAndMedia = (
    <Editorial spacing="onlyTop">
      {(hasImages || hasVideos) && (
        <h2>{t("documentation-and-media", { ns: "pressreleasepage" })}</h2>
      )}
      {images?.map((image) => (
        <Editorial.Image
          key={image.id}
          keepProportions
          image={
            <MeasuredImage
              src={image.filename}
              alt={image.alt}
              sizes={imageSizes("100vw", { laptopLarge: "720px" })}
            />
          }
        />
      ))}
      {videos?.map((video) => (
        <Video
          key={video.id}
          url={video.filename}
          provider={"storyblok"}
          thumbnailOverlay={false}
        />
      ))}
    </Editorial>
  );
  return (
    <>
      {hasDisclaimer && !showPressRelease && (
        <DisclaimerBlock
          disclaimerTitle={disclaimerTitle}
          disclaimerDescription={disclaimerDescription}
          disclaimerOptionsList={disclaimerOptionsList}
          disclaimerAuthorizedOptions={disclaimerAuthorizedOptions}
          disclaimerAuthorizedContentDescription={
            disclaimerAuthorizedContentDescription
          }
          disclaimerAuthorizedContentTitle={disclaimerAuthorizedContentTitle}
          disclaimerRestrictedAccessTitle={disclaimerRestrictedAccessTitle}
          disclaimerRestrictedAccessContentDescription={
            disclaimerRestrictedAccessContentDescription
          }
          setShowContent={setShowPressRelease}
          disclaimerSecondaryButtonLink={disclaimerSecondaryButtonLink}
          disclaimerSecondaryButtonText={disclaimerSecondaryButtonText}
          disclaimerMainButtonText={disclaimerMainButtonText}
          disclaimerFirstDropDownTitle={disclaimerFirstDropDownTitle}
          disclaimerSecondPageSecondaryButtonText={
            disclaimerSecondPageSecondaryButtonText
          }
          disclaimerSecondPageButtonText={disclaimerSecondPageButtonText}
          disclaimerHasSecondPage={disclaimerHasSecondPage}
        />
      )}
      {(showPressRelease || !hasDisclaimer) && (
        <WithSidebar>
          {(body || pageTitle || intro) && (
            <Body>
              {publishDate && (
                <MediaQueryHider hideAboveDesktop>
                  <Container size="narrow">
                    <Details
                      title={t("publication-date", { ns: "pressreleasepage" })}
                      withoutHero
                    >
                      <div
                        key="publishDate"
                        {...storyblokEditable(story.content)}
                      >
                        <Details.Detail
                          icon="symbolicons-calendar-date"
                          title={resolveDate(formattedDate, locale)}
                        />
                      </div>
                    </Details>
                  </Container>
                </MediaQueryHider>
              )}
              {pageTitle && (
                <Editorial.Copy>
                  <h1 className="display--pr">{pageTitle}</h1>
                </Editorial.Copy>
              )}
              {intro && <DynamicBlocks blocks={intro} />}
              {body && <DynamicBlocks blocks={body} />}

              {(hasImages || hasVideos) && (
                <MediaQueryHider hideBelowDesktop>
                  <Container size="narrow">
                    <Editorial.Hr />
                    {documentationAndMedia}
                  </Container>
                </MediaQueryHider>
              )}
            </Body>
          )}

          <Sidebar>
            {publishDate && (
              <MediaQueryHider hideBelowDesktop>
                <Details
                  title={t("publication-date", { ns: "pressreleasepage" })}
                >
                  <Details.Detail
                    icon="symbolicons-calendar-date"
                    title={resolveDate(formattedDate, locale)}
                  />
                </Details>
              </MediaQueryHider>
            )}

            {files?.length > 0 && (
              <Details
                title={t("related-documents", { ns: "pressreleasepage" })}
                isRelatedDocuments
              >
                {files.map((f) => {
                  const fileEndingMatches = f.filename.match(fileEndingPattern);
                  const fileEnding = fileEndingMatches
                    ? "( " + fileEndingMatches[1].toUpperCase() + " )"
                    : "";
                  return (
                    <Details.Document
                      key={f.id}
                      icon="file-download"
                      title={f.title ? f.title : ""}
                      subtitle={fileEnding}
                      href={f.filename}
                    />
                  );
                })}
              </Details>
            )}

            {filteredCategories && (
              <Tags>
                {filteredCategories && filteredCategories.length > 0 && (
                  <Tags.TagList title={t("category", { ns: "common" })}>
                    {filteredCategories?.map((tag) => {
                      const baseUrl = globalLinks.pressreleaseFilterPage
                        ? resolveLink(globalLinks.pressreleaseFilterPage)
                        : "";
                      const url = `${baseUrl}?categories=${tag}`;
                      return (
                        <Tag key={tag} tag={t(tag, { ns: "tags" })} url={url} />
                      );
                    })}
                  </Tags.TagList>
                )}
              </Tags>
            )}

            {contacts?.map((c) => {
              const story = getRelation<ContactStory>(c);
              if (story) {
                return (
                  <>
                    <DynamicBlocks key={story.id} blocks={[story.content]} />
                  </>
                );
              }
            })}

            <Share title={t("share", { ns: "common" })}>
              <ShareItems />
            </Share>
            {(hasImages || hasVideos) && (
              <MediaQueryHider hideAboveDesktop>
                <Editorial.Hr />
                {documentationAndMedia}
              </MediaQueryHider>
            )}
          </Sidebar>
        </WithSidebar>
      )}
    </>
  );
};

export default PressreleasePage;
