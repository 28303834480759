import { ReactNode } from "react";
import cn from "classnames";
import s from "./EventCalendar.module.scss";
import c from "../../styles/container.module.scss";
import Event from "./components/Event";
import Button from "../Button";

type Props = {
  title: string;
  preamble?: string;
  buttons?: ReactNode[];
  viewAllLink?: string;
  viewAllLabel: string;
  shouldShowViewAll?: boolean;
  children: ReactNode;
};

const subComponents = { Event };
type SubComponents = typeof subComponents;

const EventCalendar: CWS<Props, SubComponents> = ({
  title,
  preamble,
  buttons,
  viewAllLink,
  viewAllLabel,
  shouldShowViewAll,
  children,
}) => (
  <section className={cn(s.eventCalendar, c.xWide)}>
    {title && <h2>{title}</h2>}
    {preamble && <p className={s.preamble}>{preamble}</p>}
    <ul className={s.list}>{children}</ul>
    {buttons && buttons.length > 0 ? (
      <div className={s.buttons}>{buttons}</div>
    ) : (
      shouldShowViewAll &&
      viewAllLink &&
      viewAllLabel && <Button href={viewAllLink}>{viewAllLabel}</Button>
    )}
  </section>
);

EventCalendar.Event = Event;

export default EventCalendar;
