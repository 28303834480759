import React, { ReactNode, HTMLProps } from "react";
import s from "../FinancialReportsTable.module.scss";

type Props = HTMLProps<HTMLTableRowElement> & {
  children: ReactNode;
};

const TableRow = ({ children, ...restProps }: Props) => (
  <tr className={s.tableRow} {...restProps}>
    {children}
  </tr>
);

export default TableRow;
