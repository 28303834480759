import React from "react";
import cn from "classnames";
import s from "../Slider.module.scss";
import c from "../../../styles/container.module.scss";
import Button from "../../Button";
import { useLinks } from "../../../lib/storyblok/useLinks";
import VideoPlayer from "./VideoPlayer";

const SlideItemVideo = ({
  label,
  titleType = "h3",
  title,
  description,
  buttons,
  inverted,
  overlay,
  video,
  playCount,
  setPlayCount,
  activeSlideId,
  paginationTitle, // eslint-disable-line
  id,
  contentPosition,
}) => {
  const resolveLink = useLinks();
  const TitleTag = titleType;

  const innerContainerClassName = cn(
    s.innerContainer,
    c.xWide,
    { [s.inverted]: inverted },
    { [s.contentLeft]: contentPosition === "left" },
    { [s.contentCenter]: contentPosition === "center" },
    "swiper-slide-content"
  );
  return (
    <>
      <div className={cn(s.slideItem, s.slideVideo)}>
        {video && (
          <div className={cn(s.videoContainer)}>
            {
              <VideoPlayer
                videoUrl={video.filename}
                playCount={playCount}
                setPlayCount={setPlayCount}
                activeSlideId={activeSlideId}
                id={id}
              />
            }
          </div>
        )}
        {overlay && <div className={cn(s.overlay, s[overlay])}></div>}

        <div className={innerContainerClassName}>
          {label && (
            <p
              className={cn(s.label)}
              data-swiper-parallax-y="-10"
              data-swiper-parallax-duration="300"
              data-swiper-parallax-opacity="0.25"
            >
              {label}
            </p>
          )}
          {title &&
            TitleTag &&
            React.createElement(
              TitleTag,
              {
                className: cn(s.title),
                "data-swiper-parallax-y": "-20",
                "data-swiper-parallax-duration": "400",
                "data-swiper-parallax-opacity": "1",
              },
              title
            )}
          {description && (
            <p
              className={cn(s.description)}
              data-swiper-parallax-y="-30"
              data-swiper-parallax-duration="500"
              data-swiper-parallax-opacity="0.5"
            >
              {description}
            </p>
          )}
          {buttons && buttons.length > 0 && (
            <div className={cn(s.buttons)}>
              {buttons.map((button) => (
                <Button
                  key={button._uid}
                  variant={button.buttonType ?? "default"}
                  href={resolveLink(button.buttonLink)}
                >
                  {button.buttonText}
                </Button>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SlideItemVideo;
