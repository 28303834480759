import { ReactNode } from "react";
import cn from "classnames";
import s from "../ContentGrid.module.scss";

type Props = {
  image: ReactNode;
  category: string;
  title: string;
  text: string;
  link: ReactNode;
  gray?: boolean;
  href?: string;
};

const Item = ({ image, category, title, link, text, gray, href }: Props) => {
  return (
    <article className={cn(s.item, { [s.gray]: gray })}>
      <a className={s.link} title={title} href={href}>
        <div className={s.imageContainer}>
          <div>
            <figure className={s.figure}> {image}</figure>
          </div>
        </div>
        <div className={s.content}>
          {!gray && <span className={s.label}>{category}</span>}
          <h3>{title}</h3>
          <p>{text}</p>
        </div>
      </a>
      {link}
    </article>
  );
};

export default Item;
