import { ViewModel } from "../../lib/viewModel/createViewModel";
import { StoryblokStoryComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { DynamicBlocks } from "../DynamicBlock";
import { initAlgoliaInvestorIndecies } from "../../lib/utils/AlgoliaSearchHelpers";
import { InvestorSearchPageStory } from "../../lib/storyblok/types/stories/InvestorSearchPageStory";
import SearchPage from "../Nestable/SearchPage";

type VM = ViewModel<InvestorSearchPageStory>;

const InvestorSearchPage: StoryblokStoryComponent<VM> = ({ story, locale }) => {
  const { hero } = story.content;

  return (
    <SearchPage locale={locale} indices={initAlgoliaInvestorIndecies()}>
      <DynamicBlocks blocks={hero} />
    </SearchPage>
  );
};

export default InvestorSearchPage;
