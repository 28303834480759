import { ReactNode } from "react";
import s from "../TagsAndShareWrapper.module.scss";

type Props = {
  title: string;
  weChat?: ReactNode;
  children: ReactNode;
};

const ShareWrapper = ({ title, weChat, children }: Props) => (
  <div className={s.shareWrapper} aria-label={title}>
    {weChat && <div className={s.qrCode}>{weChat}</div>}
    <div className={s.list}>{children}</div>
  </div>
);

export default ShareWrapper;
