import { createPortal } from "react-dom";
import FocusLock from "react-focus-lock";

type Props = {
  children: React.ReactElement | React.ReactElement[];
  show: boolean;
};

const ModalPortal = ({ show, children }: Props) => {
  // handle SSR, since document does not exist on SSR
  if (typeof window === "undefined") {
    return <></>;
  }
  return createPortal(
    <>{show && <FocusLock>{children}</FocusLock>}</>,
    document.body
  );
};

ModalPortal.displayName = "ModalPortal";

export default ModalPortal;
