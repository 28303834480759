import React from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import DetailsUi from "../../ui/Details";
import { DetailsBlock } from "../../lib/storyblok/types/blocks/DetailsBlock";

const Details: StoryblokBlockComponent<DetailsBlock> = ({ title, details }) => {
  const { Detail } = DetailsUi;

  return (
    <DetailsUi title={title}>
      {details?.map((detail) => (
        <Detail
          key={detail._uid}
          icon={detail.icon}
          title={detail.title}
          subtitle={detail.subtitle}
        />
      ))}
    </DetailsUi>
  );
};

export default Details;
