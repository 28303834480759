import React, { useRef } from "react";
import cn from "classnames";
import "swiper/css";
import "swiper/css/a11y";
import "swiper/css/effect-coverflow";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import s from "./VideoCarousel.module.scss";
import c from "../../../styles/container.module.scss";
import bs from "../../../styles/block.spacing.module.scss";
import CarouselItemVideo from "./components/CarouselItemVideo";
import SliderButton from "../../Common/SliderButton/SliderButton";
import BlockBackground from "../../Common/BlockBackground";
import BlockIntro from "../../Common/BlockIntro";
import { useTranslation } from "next-i18next";

const VideoCarousel = ({
  title,
  description,
  buttons,
  items,
  effect,
  style,
  spacing,
  outerSpacing,
  carouselWidth,
}) => {
  const { t } = useTranslation();
  const swiperRef = useRef<SwiperRef>(null);

  const slideNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const slidePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const getBreakpoints = (effect, carouselWidth) => {
    const coverFlowConfig = {};

    const slideDefaultConfig = {
      720: { slidesPerView: 2 },
      1200: { slidesPerView: 3 },
    };

    const slideFluidConfig = {
      720: { slidesPerView: 2 },
      1200: { slidesPerView: 3 },
      1600: { slidesPerView: 4 },
      1900: { slidesPerView: 5 },
    };

    if (effect === "slide") {
      return carouselWidth === "fluid" ? slideFluidConfig : slideDefaultConfig;
    } else {
      return coverFlowConfig;
    }
  };

  const getSlidesPerView = (effect) => {
    const coverFlowConfig = "auto";
    const slideConfig = 1;

    return effect === "slide" ? slideConfig : coverFlowConfig;
  };

  return (
    <section
      className={cn(s.carouselVideo, s[effect], bs[spacing], bs[outerSpacing])}
    >
      <BlockBackground style={style} opacity={100} />

      <BlockIntro
        alignment={"alignCenter"}
        style={style}
        title={title}
        description={description}
        buttons={buttons}
        featured={false}
        flexibleWidth={true}
      />

      <div className={cn(s.sliderWrapper, s[carouselWidth], c[carouselWidth])}>
        <Swiper
          effect={effect}
          centeredSlides={true}
          loop={true}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 200,
            modifier: 1,
            slideShadows: false,
          }}
          spaceBetween={32}
          autoplay={false}
          mousewheel={false}
          slidesPerView={getSlidesPerView(effect)}
          breakpoints={getBreakpoints(effect, carouselWidth)}
          ref={swiperRef}
        >
          {items &&
            items.map((item, index) => (
              <div className={s.swiperSlide} key={index}>
                <SwiperSlide key={index} className={s.slideItem}>
                  <CarouselItemVideo
                    title={item.title}
                    description={item.description}
                    video={item.video}
                    style={item.style}
                  />
                </SwiperSlide>
              </div>
            ))}
        </Swiper>

        <div className={s.navigation}>
          <SliderButton
            onClick={slidePrev}
            align="prev"
            label={t("previous")}
            style={style}
          />
          <SliderButton
            onClick={slideNext}
            align="next"
            label={t("next")}
            style={style}
          />
        </div>
      </div>
    </section>
  );
};

export default VideoCarousel;
