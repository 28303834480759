import { AnimatePresence, motion } from "framer-motion";
import { ReactNode } from "react";
import cn from "classnames";
import s from "./Modal.module.scss";

type Props = {
  onCloseClick?: () => void;
  className?: string;
  children: ReactNode;
};

const Modal = ({ onCloseClick, className, children }: Props) => (
  <>
    <AnimatePresence>
      <motion.div
        key={"modal"}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.15 }}
      >
        <div className={s.overlay} onClick={onCloseClick} aria-hidden="true" />
        <div className={cn(s.modal, className)}>{children}</div>
      </motion.div>
    </AnimatePresence>
  </>
);

export default Modal;
