import React, { forwardRef } from "react";
import Flag from "../../../Flag";
import { FlagKey } from "../../../Flag/Flag";
import cn from "classnames";
import s from "./MobileMenu.module.scss";

type Props = {
  language: string;
  href: string;
  country: FlagKey;
};

export const MobileLanguageItem = forwardRef<HTMLAnchorElement, Props>(
  ({ language, href, country }, ref) => (
    <li>
      <a
        ref={ref}
        href={href}
        className={cn(s.mobileMenuLangItem)}
        title={language}
      >
        <Flag country={country} width={16} />
        <span>{language}</span>
      </a>
    </li>
  )
);

MobileLanguageItem.displayName = "MobileLanguageItem";
