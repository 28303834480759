import React from "react";
import dynamic from "next/dynamic";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { ShareGraphBlock } from "../../lib/storyblok/types/blocks/ShareGraphBlock";

const DynamicShareGraph = dynamic(() => import("../../ui/ShareGraph"), {
  ssr: false,
});

const ShareGraph: StoryblokBlockComponent<ShareGraphBlock> = ({ language }) => {
  return <DynamicShareGraph language={language} />;
};

export default ShareGraph;
