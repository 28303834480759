import axios from "axios";

// let baseURL = process.env.PUBLICATION_URL;
const baseURL = "https://msr-web-api.azurewebsites.net/api";

if (typeof baseURL === "undefined")
  throw new Error("Publication URL environment variable missing");

export const publicationAxios = axios.create({
  baseURL,
});
