import React from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import Button from "../../ui/Button";
import CTABlockUi from "../../ui/CTABlock";
import { CtaBlockBlock } from "../../lib/storyblok/types/blocks/CtaBlockBlock";
import Link from "next/link";
import { useLinks } from "../../lib/storyblok/useLinks";

const CTABlock: StoryblokBlockComponent<CtaBlockBlock> = ({
  style,
  spacing,
  outerSpacing,
  width,
  text,
  title,
  buttonText,
  buttonLink,
  secondButtonText,
  secondButtonLink,
}) => {
  const resolveLink = useLinks();
  const href = resolveLink(buttonLink);
  const secondHref = resolveLink(secondButtonLink);
  return (
    <CTABlockUi
      style={style}
      title={title}
      text={text}
      spacing={spacing}
      outerSpacing={outerSpacing}
      width={width}
      button={
        buttonText &&
        href && (
          <Link href={href}>
            <Button href={href} variant={"inverted"}>
              {buttonText}
            </Button>
          </Link>
        )
      }
      secondButton={
        secondButtonText &&
        secondHref && (
          <Link href={secondHref}>
            <Button href={secondHref} variant={"inverted"}>
              {secondButtonText}
            </Button>
          </Link>
        )
      }
    />
  );
};

export default CTABlock;
