import Script from "next/script";

type Props = { id: string | null };

const ConvertExperienced = ({ id }: Props) =>
  id ? (
    <Script
      id={`convert-experiences-${id}`}
      strategy="beforeInteractive"
      src={`//cdn-4.convertexperiments.com/js/${id}.js`}
    ></Script>
  ) : null;

export default ConvertExperienced;
