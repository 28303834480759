import { ReactNode } from "react-markdown/lib/react-markdown";
import s from "../ArticleHeading.module.scss";

type Props = {
  children: ReactNode;
};

const Detail = ({ children }: Props) => {
  return <li className={s.detail}>{children}</li>;
};

export default Detail;
