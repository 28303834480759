import React, { forwardRef, ReactNode } from "react";
import { motion, HTMLMotionProps } from "framer-motion";
import Icon from "../../../Icon/Icon";
import s from "./DesktopMenu.module.scss";
import FlexibleLinkWrapper from "../../../../components/Nestable/LinkWrapper/FlexibleLinkWrapper";

type Props = {
  title: string;
  icon?: ReactNode;
  description?: string;
  link?: string;
  index: number;
  children: ReactNode;
  onGroupAnimationComplete?: () => void;
} & HTMLMotionProps<"div">;

export const DesktopSubNavigationGroup = forwardRef<HTMLDivElement, Props>(
  (
    {
      title,
      description,
      link,
      children,
      icon,
      index,
      onGroupAnimationComplete,
      ...props
    },
    ref
  ) => {
    const content = (
      <>
        {icon}
        <div>
          {title && <span className={s.title}>{title}</span>}
          {description && <p className={s.description}>{description}</p>}
        </div>
      </>
    );

    return (
      <motion.div
        initial={{ opacity: 0, x: -16 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.3, delay: index * 0.3 }}
        onAnimationComplete={onGroupAnimationComplete}
        className={s.DesktopSubNavigationGroup}
        key={index}
        ref={ref}
        role="group"
        aria-labelledby={title}
        {...props}
      >
        {link && link.trim() !== "/" ? (
          <FlexibleLinkWrapper
            href={link}
            title={title}
            className={s.header}
            aria-label={description ? `${title}: ${description}` : title}
          >
            {content}
            <Icon
              icon={"arrow-right"}
              color={s.iconColorSubGroupHeader}
              className={s.icon}
              width={12}
              aria-hidden="true"
            />
          </FlexibleLinkWrapper>
        ) : (
          <div title={title} className={s.header}>
            {content}
          </div>
        )}
        <ul className={s.list} role="menu">
          {children}
        </ul>
      </motion.div>
    );
  }
);

DesktopSubNavigationGroup.displayName = "DesktopSubNavigationGroup";
