import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import DownloadsUi from "../../ui/Downloads";
import { DownloadsBlock } from "../../lib/storyblok/types/blocks/DownloadsBlock";
import { DynamicBlocks } from "../DynamicBlock";

const Downloads: StoryblokBlockComponent<DownloadsBlock> = ({
  text,
  title,
  list,
}) => {
  return (
    <DownloadsUi title={title} text={text}>
      <DynamicBlocks blocks={list} />
    </DownloadsUi>
  );
};

export default Downloads;
