import { EditorialBlock } from "../../lib/storyblok/types/blocks/EditorialBlock";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import EditorialUi from "../../ui/Editorial/Editorial";
import { AnchorMenuContextProvider } from "../Layout/AnchorMenu/AnchorMenuContext";
import AnchorMenuSection from "../Layout/AnchorMenu/AnchorMenuSection";
import { splitIntoSections } from "../Layout/AnchorMenu/splitIntoSections";
import { v4 as uuidv4 } from "uuid";

const Editorial: StoryblokBlockComponent<EditorialBlock> = ({
  body,
  spacing,
}) => {
  const sections = splitIntoSections(body);
  return (
    <AnchorMenuContextProvider>
      <EditorialUi spacing={spacing}>
        {sections?.map((section) => (
          <AnchorMenuSection
            key={`${section.href ? section.href : "index"}-${uuidv4()}`}
            section={section}
          />
        ))}
      </EditorialUi>
    </AnchorMenuContextProvider>
  );
};

export default Editorial;
