import React, { ReactNode } from "react";
import s from "../TechSpec.module.scss";

type Props = {
  children: ReactNode;
};

const Tables = ({ children }: Props) => (
  <div className={s.tables}>{children}</div>
);

export default Tables;
