import React, { useMemo, useState } from "react";
import { StoryblokStoryComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { ViewModel } from "../../lib/viewModel/createViewModel";
import { DynamicBlocks } from "../DynamicBlock";
import FilterPageGrid from "../../ui/PageTemplates/FilterPage";
import Button from "../../ui/Button";
import { useTranslation } from "next-i18next";
import { ImagevaultGalleryPageStory } from "../../lib/storyblok/types/stories/ImagevaultGalleryPageStory";
import ImageGalleryCard from "../../ui/ImageGalleryCard";
import Tag from "../Tag";
import Image from "next/image";
import FilterList from "../../ui/FilterList";
import ContentGrid from "../../ui/ContentGrid";
import { ImageVaultGalleryPageViewModelExtension } from "../../lib/viewModel/handleImagevaultGalleryStory";
import { imageVaultLoader } from "../ImageVaultImage";
import useMediaQuery from "../../hooks/useMatchMedia";
import { imageSizes } from "../../lib/utils/imageSizes";

type VM = ViewModel<ImagevaultGalleryPageStory> &
  ImageVaultGalleryPageViewModelExtension;

const ImagevaultGalleryPage: StoryblokStoryComponent<VM> = ({
  story,
  galleryItems,
}) => {
  const { hero, body } = story.content;
  const { t } = useTranslation(["searchpage", "common"]);

  const isMobile = useMediaQuery("(max-width: 768px)");
  const [showFiltersMobile, setShowFiltersMobile] = useState(false);

  const [activeFilters, setActiveFilters] = useState<string[]>([]);
  const filteredGalleryItems = useMemo(
    () =>
      galleryItems.filter(
        (gi) =>
          activeFilters.length === 0 ||
          activeFilters?.every((filter) => gi.categories?.includes(filter))
      ),
    [activeFilters, galleryItems]
  );

  const allCategories = galleryItems
    .filter(
      (gi) =>
        activeFilters.length === 0 ||
        activeFilters.every((af) => gi.categories?.includes(af))
    )
    .flatMap((gi) => gi.categories ?? []);
  const categoriesSet = Array.from(new Set(allCategories));
  const categoriesWithCount = categoriesSet
    .map<[string, number]>((c) => [
      c,
      allCategories.filter((ac) => ac === c).length,
    ])
    .filter(
      ([category, count]) =>
        activeFilters.includes(category) || count < filteredGalleryItems.length
    )
    .sort(([catA]) => (activeFilters.includes(catA) ? 0 : 1))
    .sort(
      ([catA, countA], [catB, countB]) =>
        countB * 10 - countA * 10 + catA.localeCompare(catB)
    );

  return (
    <>
      {hero && <DynamicBlocks blocks={hero} />}
      <FilterPageGrid>
        <FilterPageGrid.Preamble>
          {body && <DynamicBlocks blocks={body} />}
        </FilterPageGrid.Preamble>
        <>
          {isMobile && !showFiltersMobile && (
            <Button
              variant="cta"
              icon={"filter"}
              onClick={() => setShowFiltersMobile(true)}
            >
              {t("filter-verb")}
            </Button>
          )}
        </>
        <FilterPageGrid.Filter visible={showFiltersMobile}>
          <FilterList.Heading
            clearLabel={activeFilters.length > 0 ? t("clear-all") : ""}
            onClearClick={() => setActiveFilters([])}
          >
            {t("filter-by")}
          </FilterList.Heading>
          <FilterList label={t(`category`)} layout="static">
            {categoriesWithCount.map(([category, count]) => {
              const checked = activeFilters.includes(category);
              return (
                <FilterList.Filter
                  key={category}
                  value={category}
                  amount={count}
                  checked={checked}
                  onChange={() =>
                    setActiveFilters((prev) =>
                      checked
                        ? prev.filter((c) => c !== category)
                        : [...prev, category]
                    )
                  }
                >
                  {category}
                </FilterList.Filter>
              );
            })}
          </FilterList>
          <>
            {isMobile && showFiltersMobile && (
              <Button color="black" onClick={() => setShowFiltersMobile(false)}>
                {t("close-filter")}
              </Button>
            )}
          </>
        </FilterPageGrid.Filter>
        <FilterPageGrid.NumberOfHits>
          {t("number-of-hits_other", { count: filteredGalleryItems.length })}
        </FilterPageGrid.NumberOfHits>
        <FilterPageGrid.Body>
          <ContentGrid spacing="noTop" container="fluid">
            {filteredGalleryItems.map(
              ({ name, imageUrl, categories, width, height, id }) => {
                return (
                  <ImageGalleryCard
                    key={id}
                    tags={categories?.map((c) => (
                      <Tag key={`${id}-${c}`} tag={c} url="" />
                    ))}
                    image={
                      <Image
                        loader={imageVaultLoader}
                        src={imageUrl}
                        layout="fill"
                        objectFit="contain"
                        sizes={imageSizes("100vw", {
                          laptop: "25vw",
                        })}
                        quality={85}
                        alt={name}
                      />
                    }
                    title={name}
                    details={[`${width} x ${height} px`]}
                    link={
                      <Button
                        href={"/imagevault" + imageUrl}
                        size="small"
                        openInNewTab
                        download
                      >
                        {t("download", { ns: "common" })}
                      </Button>
                    }
                  />
                );
              }
            )}
          </ContentGrid>
        </FilterPageGrid.Body>
      </FilterPageGrid>
    </>
  );
};

export default ImagevaultGalleryPage;
