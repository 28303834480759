import s from "../Agenda.module.scss";

type Props = {
  time: string;
  description: string;
};

const Item = ({ time, description }: Props) => (
  <li className={s.item}>
    <p className={s.time}>{time}</p>
    <p className={s.description}>{description}</p>
  </li>
);

export default Item;
