import React from "react";
import cn from "classnames";
import s from "../ContentGrid.module.scss";
import Icon, { IconType } from "../../Icon/Icon";

type Props = {
  icon: IconType;
  name: string;
  price: number;
  currency: string;
  deltaPercentage: string;
  delta: string;
  quoteTimeLabel: string;
  quoteTime: string;
};

const ShareTicker = ({
  icon,
  name,
  price,
  currency,
  deltaPercentage,
  delta,
  quoteTimeLabel,
  quoteTime,
}: Props) => {
  return (
    <article className={cn(s.item, s.shareTicker)}>
      <div className={s.data}>
        <Icon icon={icon} width={48} color={s.iconcolor} />
        <p>{`${name}: ${price} ${currency}`}</p>
        <p>{`${deltaPercentage}% (${delta} ${currency})`}</p>
        <p className={s.latestUpdate}>{`${quoteTimeLabel}: ${quoteTime}`}</p>
      </div>
    </article>
  );
};

ShareTicker.displayName = "ShareTicker";

export default ShareTicker;
