import { ReactNode } from "react";
import cn from "classnames";
import c from "../../../styles/container.module.scss";
import s from "../Video.module.scss";

type Props = {
  featured?: boolean;
  children: ReactNode;
  spacing: "onlyTop" | "onlyBottom" | "both" | "none";
};

const Container = ({ featured, children, spacing }: Props) => (
  <div
    className={cn(c.xWide, s.container, { [s.featured]: featured }, s[spacing])}
  >
    {children}
  </div>
);

export default Container;
