import Icon from "../../Icon/Icon";
import s from "./SliderButton.module.scss";
import cn from "classnames";

type Props = {
  onClick: () => void;
  align: "prev" | "next";
  type?: string;
  label: string;
  style?: string;
};

const SliderButton = ({ onClick, align, label, type, style }: Props) => (
  <button
    className={cn(s.sliderButton, {
      [s.floating]: type === "floating",
      [s.prevButton]: align === "prev",
      [s.nextButton]: align === "next",
      [s.inverted]: style !== "white" && style !== "gray",
    })}
    onClick={onClick}
    title={label}
  >
    <span>
      <Icon icon={align === "prev" ? "caret-left" : "caret-right"} width={24} />
    </span>
  </button>
);

export default SliderButton;
