import { ReactNode } from "react";
import s from "./Editorial.module.scss";

import EditorialCopy from "./components/EditorialCopy";
import EditorialImage from "./components/EditorialImage";
import EditorialQuote from "./components/EditorialQuote";
import EditorialVideo from "./components/EditorialVideo";
import WithoutSpacing from "./components/WithoutSpacing";
import Hr from "./components/Hr";
import cn from "classnames";

type Props = {
  children: ReactNode;
  spacing: "onlyTop" | "onlyBottom" | "both" | "none";
  withoutSectionPadding?: boolean;
};

const subComponents = {
  Image: EditorialImage,
  Quote: EditorialQuote,
  Copy: EditorialCopy,
  Video: EditorialVideo,
  Hr,
  WithoutSpacing,
};
type SubComponents = typeof subComponents;

const Editorial: CWS<Props, SubComponents> = ({
  children,
  spacing,
  withoutSectionPadding,
}) => (
  <div
    className={cn(s.editorial, s[spacing], {
      [s.withPadding]: !withoutSectionPadding,
    })}
  >
    {children}
  </div>
);

Editorial.Image = EditorialImage;
Editorial.Quote = EditorialQuote;
Editorial.Copy = EditorialCopy;
Editorial.Video = EditorialVideo;
Editorial.WithoutSpacing = WithoutSpacing;
Editorial.Hr = Hr;

export default Editorial;
