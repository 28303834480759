import React, { FormEvent } from "react";
import SearchInput from "../../ui/SearchInput";
import { useSearchBox } from "react-instantsearch";
import { useTranslation } from "next-i18next";

type SearchBoxProps = {
  defaultValue?: string;
  type?: "large" | "small";
};

const SearchBox = ({ defaultValue = "", type = "large" }: SearchBoxProps) => {
  const { t } = useTranslation("searchpage");

  const { refine } = useSearchBox();

  const onChange = (event: FormEvent<HTMLInputElement>) => {
    refine(event.currentTarget.value);
  };

  const onClear = () => {
    refine("");
  };

  return (
    <SearchInput
      label={t("input-label")}
      placeholder={t("input-placeholder")}
      clearLabel={t("input-clear")}
      onChange={onChange}
      defaultValue={defaultValue}
      onClearClick={onClear}
      type={type}
    />
  );
};

export default SearchBox;
