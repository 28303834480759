import Icon from "../../../ui/Icon";
import s from "./LinkWrapper.module.scss";
import { useIsExternalLink } from "../../../hooks/useIsExternalLink";
import { useEffect, useRef, useState } from "react";

type Props = {
  text?: React.ReactNode;
  href?: string;
  className?: string;
  variant?: "light" | "default" | "dark";
  style?: React.CSSProperties;
  color?: string;
  iconSize?: number;
};

const LinkWrapper = ({
  text,
  href,
  style,
  color,
  variant,
  iconSize,
  ...rest
}: Props) => {
  const [computedColor, setComputedColor] = useState("var(--medium)");
  const [computedIconSize, setComputedIconSize] = useState(12);
  const { isExternalLink, shouldNofollow } = useIsExternalLink(href);
  const ref = useRef<HTMLAnchorElement>(null);
  const titleText = typeof text === "string" ? text : undefined;

  useEffect(() => {
    const parentElement = ref.current?.parentElement;
    if (parentElement) {
      const parentStyle = getComputedStyle(parentElement);
      const colorMatches = (parentStyle.color.match(/255/g) || []).length === 3;
      setComputedColor(colorMatches ? parentStyle.color : "var(--medium)");

      const fontSize = parseFloat(parentStyle.fontSize) || 12;
      setComputedIconSize(fontSize * 0.7);
    }
  }, []);

  // Get color based on variant
  const variantColors: Record<"light" | "default" | "dark", string> = {
    light: "var(--white)",
    dark: "var(--black)",
    default: "var(--medium)",
  };

  const finalColor = variant ? variantColors[variant] : color || computedColor;

  if (isExternalLink) {
    const relValue = shouldNofollow
      ? "noopener noreferrer nofollow"
      : "noopener noreferrer";
    return (
      // eslint-disable-next-line
      <a
        href={href}
        target="_blank"
        rel={relValue}
        ref={ref}
        style={{ ...style, color: finalColor }}
        title={titleText}
        {...rest}
      >
        {text}
        <Icon
          icon="arrow-up-right"
          width={iconSize || computedIconSize}
          color={finalColor}
          className={s.externalIcon}
        />
      </a>
    );
  }
  return (
    <a
      href={href}
      target="_self"
      ref={ref}
      style={{ ...style, color: finalColor }}
      title={titleText}
      {...rest}
    >
      {text}
    </a>
  );
};
export default LinkWrapper;
