import React from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import CTAModalUi from "../../ui/CTAModal";
import { CtaModalBlock } from "../../lib/storyblok/types/blocks/CtaModalBlock";

const CtaModal: StoryblokBlockComponent<CtaModalBlock> = ({
  buttonText,
  iframeUrl,
  spacing,
}) => (
  <CTAModalUi buttonText={buttonText} iframeUrl={iframeUrl} spacing={spacing} />
);

export default CtaModal;
