import cn from "classnames";
import { CSSProperties, ElementType, HTMLProps } from "react";
import s from "./MediaQueryHider.module.scss";

type Props<T extends ElementType> = HTMLProps<T> & {
  display?: CSSProperties["display"];
  element?: T;
  hideBelowXl?: boolean;
  hideAboveXl?: boolean;
  hideBelowLaptop?: boolean;
  hideAboveLaptop?: boolean;
  hideBelowDesktop?: boolean;
  hideAboveDesktop?: boolean;
};

export const MediaQueryHider = ({
  element: Element = "div",
  display = "block",
  hideBelowXl,
  hideAboveXl,
  hideBelowLaptop,
  hideAboveLaptop,
  hideBelowDesktop,
  hideAboveDesktop,
  ...rest
}: Props<typeof Element>) => {
  return (
    <Element
      style={display ? { "--display": display } : undefined}
      className={cn({
        [s.hideAboveXl]: hideAboveXl,
        [s.hideBelowXl]: hideBelowXl,
        [s.hideAboveLaptop]: hideAboveLaptop,
        [s.hideBelowLaptop]: hideBelowLaptop,
        [s.hideAboveDesktop]: hideAboveDesktop,
        [s.hideBelowDesktop]: hideBelowDesktop,
      })}
      {...rest}
    />
  );
};
