import { ReactNode } from "react";
import s from "../ContentGridSlider.module.scss";

type Props = {
  image: ReactNode;
  label?: string;
  title: string;
  text: string;
  link: ReactNode;
  href: string;
};

const Item = ({ image, label, title, link, text, href }: Props) => (
  <article className={s.item}>
    <a className={s.link} title={title} href={href}>
      <div className={s.imageContainer}>
        <div>
          <figure className={s.figure}> {image}</figure>
        </div>
      </div>
      {label && <label>{label}</label>}

      <h3>{title}</h3>
      <p>{text}</p>
    </a>
    {link}
  </article>
);

export default Item;
