/* eslint-disable */
import React from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import CtaForm from "../../ui/CTAForm";
import { CtaFormBlock } from "../../lib/storyblok/types/blocks/CtaFormBlock";
import { imageSizes } from "../../lib/utils/imageSizes";
import ImageVaultImage from "../ImageVaultImage";

const CTAForm: StoryblokBlockComponent<CtaFormBlock> = ({
  text,
  title,
  copy,
  image,
  formUrl,
  spacing,
  style,
  divider,
}) => {
  return (
    <CtaForm
      title={title}
      text={text}
      copy={copy}
      image={
        <ImageVaultImage
          sizes={imageSizes("720px")}
          image={image}
          layout="intrinsic"
          contain
        />
      }
      formUrl={formUrl}
      spacing={spacing}
      style={style}
      divider={divider}
    />
  );
};

export default CTAForm;
