import React from "react";
import cn from "classnames";
import s from "./EditorialQuote.module.scss";
import c from "../../../styles/container.module.scss";
import Icon from "../../Icon";

export type Props = {
  quote: string;
  quotee: string;
};

const EditorialImage = ({ quote, quotee }: Props) => {
  return (
    <article className={cn(c.narrow, s.container)}>
      <div>
        <Icon width={24} icon="quote" />
      </div>
      <div>
        <blockquote className={s.quote}>{quote}</blockquote>
        <span className={s.quotee}>{quotee}</span>
      </div>
    </article>
  );
};

export default EditorialImage;
