import { AnimatePresence } from "framer-motion";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import useMediaQuery from "../../../hooks/useMatchMedia";
import UiAnchorMenu from "../../../ui/AnchorMenu";
import { useAnchorMenuContext } from "./AnchorMenuContext";
import { Section } from "./splitIntoSections";
import Link from "next/link";
import { ButtonBlock } from "../../../lib/storyblok/types/blocks/ButtonBlock";
import ConditionalWrapper from "../../ConditionalWrapper";
import { useLinks } from "../../../lib/storyblok/useLinks";
import { useTranslation } from "next-i18next";

type Props = {
  sections: Section[];
  button?: ButtonBlock;
};

const { List, Item, CTAItem, MobileMenu, ExpandButton } = UiAnchorMenu;

const extractHash = (url: string): string | undefined => url.split("#")[1];

const AnchorMenu = ({ sections, button }: Props) => {
  const resolveLink = useLinks();

  const { asPath, events } = useRouter();
  const { visibleSections = [] } = useAnchorMenuContext();
  const [hash, setHash] = useState(extractHash(asPath));

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isMobile = useMediaQuery("(max-width: 1024px)");

  const { t } = useTranslation();

  useEffect(() => {
    if (!isMobile) {
      setIsMenuOpen(false);
    }
  }, [isMobile]);

  useEffect(() => {
    if (visibleSections.length > 0) {
      setHash((currentHash) =>
        visibleSections.includes(currentHash ?? "") ? currentHash : undefined
      );
    }
  }, [visibleSections, setHash]);

  useEffect(() => {
    const onHashChangeStart = (url: string) => {
      setHash(extractHash(url));
    };
    events.on("hashChangeStart", onHashChangeStart);
    return () => {
      events.off("hashChangeStart", onHashChangeStart);
    };
  }, [events, setHash]);

  const isFirstSectionEmpty = sections[0].href === "";

  const activeSectionIndex = sections.findIndex((section) =>
    hash
      ? hash === section.href && visibleSections.includes(section.href)
      : visibleSections.includes(section.href)
  );

  const activeItemIndex = isFirstSectionEmpty
    ? activeSectionIndex - 1
    : activeSectionIndex;

  if (sections.length === 1 && isFirstSectionEmpty) return null;

  return (
    <>
      <UiAnchorMenu>
        <List>
          {sections
            .filter((f) => f.title)
            .map(({ href, title }, i) => (
              <Link key={href} href={"#" + href} passHref>
                <Item
                  text={title}
                  isActive={i === activeItemIndex}
                  style={
                    isMobile
                      ? {
                          transform: `translateY(-${activeItemIndex * 100}%)`,
                        }
                      : undefined
                  }
                />
              </Link>
            ))}
        </List>
        <ExpandButton
          onClick={() => setIsMenuOpen(true)}
          title={t("on-this-page")}
          hideOnDesktop
        />
        {button?.buttonLink && (
          <ConditionalWrapper
            wrapper={(children) => (
              <Link href={resolveLink(button?.buttonLink)} passHref>
                {children}
              </Link>
            )}
            // Next/Link doesn't support passing an anchor link
            shouldWrap={!resolveLink(button?.buttonLink).startsWith("#")}
          >
            <CTAItem
              text={button?.buttonText ?? ""}
              shortText={button?.buttonText ?? ""}
              href={resolveLink(button?.buttonLink)}
            />
          </ConditionalWrapper>
        )}
        <AnimatePresence>
          {isMenuOpen && (
            <MobileMenu
              title={t("on-this-page")}
              close={t("close")}
              onCloseClick={() => setIsMenuOpen(false)}
            >
              {sections
                .filter((f) => f.title)
                .map(({ href, title }, i) => (
                  <Link key={href} href={"#" + href} passHref>
                    <Item
                      text={title}
                      isActive={i === activeItemIndex}
                      isMobileItem={true}
                    />
                  </Link>
                ))}
            </MobileMenu>
          )}
        </AnimatePresence>
      </UiAnchorMenu>
    </>
  );
};

export default AnchorMenu;
