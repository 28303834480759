import React, { useRef, useState } from "react";
import Image, { ImageProps } from "next/image";
import useEventListener from "../utils/useEventListener";

type Dimensions = { width: number; height: number };

const MeasuredImage = ({ ...props }: ImageProps) => {
  const [wrapperHeight, setWrapperHeight] = useState(0);
  const [imageDimensions, setImageDimensions] = useState<Dimensions>({
    width: 100,
    height: 100,
  });

  const wrapperRef = useRef<HTMLDivElement>(null);

  const resizeWrapper = (imageDimensions: Dimensions) => {
    const wrapperWidth = wrapperRef.current?.clientWidth;
    if (wrapperWidth === undefined) throw new Error("wrapperWidth missing");
    setWrapperHeight(
      imageDimensions.height * (wrapperWidth / imageDimensions.width)
    );
  };

  useEventListener("resize", () => resizeWrapper(imageDimensions));

  return (
    <div
      ref={wrapperRef}
      style={{ width: "100%", position: "relative", height: wrapperHeight }}
    >
      <Image
        {...props}
        onLoadingComplete={({ naturalHeight, naturalWidth }) => {
          const dimensions = { width: naturalWidth, height: naturalHeight };
          setImageDimensions(dimensions);
          resizeWrapper(dimensions);
        }}
        layout="fill"
      />
    </div>
  );
};

export default MeasuredImage;
