const getPaginationConfig = (paginationOptions, slides) => {
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      let additionalClass = "";
      if (paginationOptions === "paginationEllipsis") {
        additionalClass = "swiper-pagination-paginationEllipsis";
      }

      const combinedClassName = `${className} ${additionalClass}`;

      switch (paginationOptions) {
        case "pagination":
          return `
              <div class="${combinedClassName}">
                <div class="progressWrapper">
                  <div class="progress" id="progress-${index}"></div>
                </div>
                <span class="copy">${slides[index]?.paginationTitle}</span>
              </div>
            `;
        case "paginationEllipsis":
          return `
              <div class="${combinedClassName} paginationEllipsis">
               
              </div>
            `;
        case "none":
          return `
              <div class="paginationNone ${combinedClassName}">
                <div id="progress-${index}"></div>
              </div>
            `;
        default:
          return "";
      }
    },
  };

  return pagination;
};

export default getPaginationConfig;
