import React from "react";
import s from "../SearchHits.module.scss";

interface TagsProps {
  children: JSX.Element | JSX.Element[];
}

const Tags = ({ children }: TagsProps) => {
  return <ul className={s.tags}>{children}</ul>;
};

export default Tags;
