import { useTranslation } from "next-i18next";
import { BreadCrumbsViewModel } from "../../lib/viewModel/createBreadcrumbsViewModel";
import Head from "next/head";

const BreadCrumbsMeta = ({ crumbs, domain }: BreadCrumbsViewModel) => {
  // SEO Breadcrumb meta data
  // https://developers.google.com/search/docs/advanced/structured-data/breadcrumb#json-ld
  const { t } = useTranslation();
  const meta = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: crumbs.map(({ name, href, isRoot }, i, orig) => ({
      "@type": "ListItem",
      position: i + 1,
      name: isRoot ? t("navigation-root") : name,
      item: i !== orig.length - 1 ? domain + href : domain + href || "",
    })),
  };

  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(meta) }}
      />
    </Head>
  );
};

export default BreadCrumbsMeta;
