import React, { ReactNode } from "react";
import cn from "classnames";
import s from "./CTABlock.module.scss";
import bs from "../../styles/block.spacing.module.scss";
import c from "../../styles/container.module.scss";

type Props = {
  style: "white" | "gray" | "signature" | "black";
  title: string;
  text: string;
  button?: ReactNode;
  secondButton?: ReactNode;
  spacing: "onlyTop" | "onlyBottom" | "both" | "none";
  outerSpacing: "outerOnlyTop" | "outerOnlyBottom" | "outerBoth" | "outerNone";
  width: "fluid" | "narrow" | "wide";
};

const CTABlock = ({
  style = "signature",
  title,
  text,
  button,
  secondButton,
  spacing,
  outerSpacing,
  width,
}: Props) => {
  return (
    <section>
      <div
        className={cn(
          s.cta,
          s[style],
          bs[spacing],
          bs[outerSpacing],
          width === "wide"
            ? c.xWide
            : width === "fluid"
            ? c.fluid
            : width === "narrow"
            ? c.narrow
            : ""
        )}
      >
        {title && (
          <h2 className={cn({ [s.marginBottom]: !text && button })}>{title}</h2>
        )}
        {text && <p className={cn({ [s.marginBottom]: button })}>{text}</p>}
        <div className={s.buttons}>
          {button && button}
          {secondButton && secondButton}
        </div>
      </div>
    </section>
  );
};

export default CTABlock;
