import React from "react";
import cn from "classnames";
import s from "../DisclaimerBlock.module.scss";
import b from "../../../ui/Button/Button.module.scss";
import Markdown from "../../../ui/Markdown";

type Props = {
  setShowContent: (show: boolean) => void;
  setDisclaimerState: (state: string) => void;
  disclaimerAuthorizedContentDescription: string;
  disclaimerAuthorizedContentTitle: string;
  disclaimerSecondPageButtonText: string;
  disclaimerSecondPageSecondaryButtonText: string;
};

const AuthorizedContent = ({
  disclaimerAuthorizedContentDescription,
  disclaimerAuthorizedContentTitle,
  disclaimerSecondPageButtonText,
  disclaimerSecondPageSecondaryButtonText,
  setShowContent,
  setDisclaimerState,
}: Props) => {
  const handleConfirmButton = () => {
    setShowContent(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className={s.authorizedContent}>
      <h2 className={s.title}>{disclaimerAuthorizedContentTitle}</h2>
      <Markdown>{disclaimerAuthorizedContentDescription}</Markdown>
      <div className={s.buttonsContainer}>
        <button className={cn(b.button, b.cta)} onClick={handleConfirmButton}>
          <span>{disclaimerSecondPageButtonText}</span>
        </button>
        <button
          className={cn(b.button, b.cta)}
          onClick={() => setDisclaimerState("restrictedAccess")}
        >
          <span>{disclaimerSecondPageSecondaryButtonText}</span>
        </button>
      </div>
    </div>
  );
};

export default AuthorizedContent;
