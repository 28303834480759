import React, { forwardRef, useState } from "react";
import { motion } from "framer-motion";
import cn from "classnames";
import Icon from "../../../Icon/Icon";
import s from "./MobileMenu.module.scss";
import { useIsExternalLink } from "../../../../hooks/useIsExternalLink";
import { MobileNavItemItemItem } from "./MobileNavItemItemItem";
import { useLinks } from "../../../../lib/storyblok/useLinks";
import { LinkFieldtype } from "../../../../lib/storyblok/types/fieldtypes/linkFieldtype";
import { useTranslation } from "next-i18next";
import FlexibleLinkWrapper from "../../../../components/Nestable/LinkWrapper/FlexibleLinkWrapper";

type SubItem = {
  component: string;
  description: string;
  _uid: string;
  title: string;
  link: LinkFieldtype;
};

type Props = {
  text: string;
  href: string;
  directLink: boolean;
  subItems: SubItem[];
  index: number;
};

export const MobileNavItemItem = forwardRef<HTMLAnchorElement, Props>(
  ({ text, href, directLink, subItems, index }, ref) => {
    const resolveLink = useLinks();
    const { t } = useTranslation();
    const { isExternalLink } = useIsExternalLink(href);
    const [showAll, setShowAll] = useState(false);

    return (
      <motion.li
        initial={{
          opacity: 0,
          x: 30,
        }}
        animate={{ opacity: 1, x: 0 }}
        transition={{
          duration: 0.3,
          delay: index * 0.06,
        }}
        className={cn(s.navItemItem, {
          [s.hasSubItems]: subItems && subItems.length > 0,
          [s.hasDirectLink]: directLink,
        })}
      >
        {directLink ? (
          <FlexibleLinkWrapper
            ref={ref}
            href={href}
            title={text}
            aria-label={text}
            tabIndex={index}
          >
            {text && <span>{text}</span>}
            {isExternalLink ? (
              <Icon icon="arrow-up-right" color={s.icon} width={14} />
            ) : (
              <Icon icon="arrow-right" color={s.icon} width={14} />
            )}
          </FlexibleLinkWrapper>
        ) : (
          text && (
            <div tabIndex={index}>
              <span>{text}</span>
            </div>
          )
        )}
        {subItems && subItems.length > 0 && (
          <ul>
            {subItems
              .slice(0, showAll ? subItems.length : 3)
              .map((subNavItem, index) => (
                <MobileNavItemItemItem
                  key={subNavItem._uid}
                  text={subNavItem.title}
                  href={resolveLink(subNavItem.link)}
                  index={index}
                />
              ))}
            {subItems.length > 3 && (
              <motion.li
                initial={{
                  opacity: 0,
                  x: 30,
                }}
                animate={{ opacity: 1, x: 0 }}
                transition={{
                  duration: 0.3,
                  delay: 3 * 0.06,
                }}
                className={cn(s.navItemItemItem)}
              >
                <button
                  onClick={() => setShowAll((prev) => !prev)}
                  className={s.viewAllButton}
                  aria-expanded={showAll}
                  aria-label={
                    showAll ? t("show-less-items") : t("show-more-items")
                  }
                >
                  <span>{showAll ? t("show-less") : t("show-more")}</span>
                  <Icon
                    icon={showAll ? "minus" : "add"}
                    width={12}
                    className={cn(s.icon, {
                      [s.open]: showAll,
                    })}
                  />
                </button>
              </motion.li>
            )}
          </ul>
        )}
      </motion.li>
    );
  }
);

MobileNavItemItem.displayName = "NavItemItem";
