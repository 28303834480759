import React, { ReactNode, useRef } from "react";
import { useTranslation } from "next-i18next";
import cn from "classnames";
import { motion, useInView } from "framer-motion";
import RichText from "../../Common/RichText";
import s from "../InteractiveGrid.module.scss";
import Button from "../../Button";
import { StoryblokRichTextFieldtype } from "../../../lib/storyblok/types/fieldtypes/richText";
import BlockBackground from "../../Common/BlockBackground/BlockBackground";

interface ItemTouchProps {
  index: number;
  style?: string;
  title: string;
  description: StoryblokRichTextFieldtype;
  link?: string;
  image?: ReactNode;
}

const ItemTouch: React.FC<ItemTouchProps> = ({
  index,
  style = "tobii-violet",
  title,
  description,
  link,
  image,
}) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const isInView = useInView(ref, {
    once: true,
  });

  const contentInverted =
    style !== "white" &&
    style !== "gray" &&
    style !== "none" &&
    style !== "" &&
    style !== undefined
      ? true
      : false;

  return (
    <motion.div
      ref={ref}
      className={cn(s.card, s.cardTouch, { [s.inverted]: !contentInverted })}
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{
        opacity: isInView ? 1 : 0,
        scale: isInView ? 1 : 0.8,
      }}
      transition={{
        duration: 0.3,
        delay: index * 0.05,
        ease: [0.2, 0, 0.1, 1],
      }}
    >
      <div className={cn(s.visualContainer, s.imageContainer)}>{image}</div>
      <BlockBackground style={style} opacity={75} />
      <div className={s.contentContainer}>
        <span className={s.title}>{title}</span>
        {description && (
          <div className={s.description}>
            <RichText
              content={description}
              {...(contentInverted && { inverted: true })}
            />
          </div>
        )}
        {link && link !== "/" && link.trim() !== "" && (
          <Button
            variant={contentInverted ? "inverted" : "default"}
            size={"medium"}
            href={link}
            title={title}
            icon="arrow-right"
          >
            {t("learn-more")}
          </Button>
        )}
      </div>
    </motion.div>
  );
};

export default ItemTouch;
