import React from "react";
import Button from "../../Button";
import s from "../FilterList.module.scss";

type FilterProps = {
  children?: string;
  clearLabel?: string;
  onClearClick?: () => void;
};

const Heading = ({ children, clearLabel, onClearClick }: FilterProps) => {
  return (
    <div className={s.topHeading}>
      <h3 className="label">{children}</h3>
      {clearLabel && onClearClick && (
        <Button icon="cancel" onClick={onClearClick} variant="text">
          {clearLabel}
        </Button>
      )}
    </div>
  );
};

export default Heading;
