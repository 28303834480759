import { ReactNode, useEffect, useState } from "react";
import useIsInViewport from "use-is-in-viewport";

import cn from "classnames";
import s from "./ParallaxCTABlock.module.scss";
import c from "../../styles/container.module.scss";

type Props = {
  title: string;
  text: string;
  button: ReactNode;
  video: ReactNode;
  featured?: boolean;
  divider?: ReactNode;
};

const chars = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "+",
  "/",
  "|",
  "_",
  "&",
  "%",
  "?",
  "¿",
  "=",
  "#",
  "@",
];

const ParallaxCTABLock = ({
  title,
  text,
  button,
  video,
  featured = false,
  divider,
}: Props) => {
  const [isInViewport, targetRef] = useIsInViewport({ threshold: 50 });
  const [titleToDisplay, setTitleToDisplay] = useState("");

  useEffect(() => {
    const changeTitle = () => {
      let i = 0;

      const insterval = setInterval(() => {
        if (i < 10) {
          const newTitle = title.split("").map((letter) => {
            if (letter === " ") return " ";
            return chars[Math.floor(Math.random() * chars.length)];
          });
          setTitleToDisplay(newTitle.join(""));
          i++;
        } else {
          clearInterval(insterval);
          setTitleToDisplay(title);
        }
      }, 30);
    };

    if (isInViewport && featured) {
      setTimeout(() => {
        changeTitle();
      }, 200);
    } else {
      setTimeout(() => {
        setTitleToDisplay("");
      }, 500);
    }
  }, [isInViewport, title, featured]);

  return (
    <>
      {divider && divider}
      <section
        className={cn(s.parallaxCTABlock, c.fluid, {
          [s.featured]: featured,
          [s.withDivider]: divider,
        })}
      >
        {video && <div className={s.video}>{video}</div>}
        {(title || text) && (
          <div
            ref={targetRef}
            className={cn(s.wrapper, { [s.inViewport]: isInViewport })}
          >
            {title && (
              <h2 className={cn(s.title, c.medium)}>
                {featured ? titleToDisplay : title}
              </h2>
            )}
            {text && <p className={cn(s.text, c.medium)}>{text}</p>}
            {button && button}
          </div>
        )}
      </section>
    </>
  );
};

export default ParallaxCTABLock;
