import React, { ReactNode, useEffect, useRef, useState } from "react";
import useMediaQuery from "../../hooks/useMatchMedia";
import cn from "classnames";
import s from "./Speakers.module.scss";
import c from "../../styles/container.module.scss";

import Speaker from "./components/Speaker";
import ExpandButton from "./components/ExpandButton";
import ProgressBar from "../ContentGridSlider/components/ProgressBar";
import { useDeviceDetect } from "../../hooks/useTouchDevice";

type Props = {
  title: string;
  children: ReactNode;
};

const subComponents = { Speaker, ExpandButton };
type SubComponents = typeof subComponents;

const Speakers: CWS<Props, SubComponents> = ({ title, children }: Props) => {
  const noOfSpeakers = React.Children.count(children);
  const [scrolled, setScrolled] = useState(0);
  const listRef = useRef<HTMLUListElement | null>(null);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { isTouchDevice } = useDeviceDetect();

  const onScroll = () => {
    const list = listRef.current;
    if (list) {
      const left = list.scrollLeft;
      const width = list.scrollWidth - list.clientWidth;
      const scrolled = (left / width) * 100;
      setScrolled(scrolled);
    }
  };

  useEffect(() => {
    if (listRef.current) listRef.current.style.scrollSnapType = "x mandatory";
  }, []);

  useEffect(() => {
    const list = listRef.current;
    if (list) {
      list.addEventListener("scroll", onScroll);
      return () => list.removeEventListener("scroll", onScroll);
    }
  });

  const getCurrentSlide = (totalSlides: number, percent: number): number => {
    if (totalSlides % 2) {
      //If odd number of slides.
      //89% to offset the -10vw in the min-width
      const currentSlide =
        Math.floor((percent / 100) * noOfSpeakers * 0.89) + 1;

      if (currentSlide == 0) {
        return 1;
      }
      return currentSlide > totalSlides ? totalSlides : currentSlide;
    } else {
      //If even number of slides.
      const currentSlide = Math.floor((percent / 100) * noOfSpeakers) + 1;
      if (currentSlide == 0) {
        return 1;
      }
      return currentSlide > noOfSpeakers ? noOfSpeakers : currentSlide;
    }
  };

  return (
    <section className={cn(c.fluid, s.speakers)}>
      <h2 className={c.xWide}>{title}</h2>
      <ul
        ref={listRef}
        style={
          isTouchDevice && isMobile
            ? {
                gridTemplateColumns: `repeat(${noOfSpeakers}, 1fr)`,
              }
            : {}
        }
        className={cn(c.xWide, s.speakerList)}
      >
        {children}
      </ul>
      {isTouchDevice && isMobile && (
        <div className={cn(c.xWide, s.progressBar)}>
          <ProgressBar
            totalSlides={noOfSpeakers}
            percent={scrolled}
            currentSlide={getCurrentSlide(noOfSpeakers, scrolled)}
          />
        </div>
      )}
    </section>
  );
};

Speakers.Speaker = Speaker;
Speakers.ExpandButton = ExpandButton;

export default Speakers;
