import Script from "next/script";

type Props = { id: string | null };

const GoogleTagManager = ({ id }: Props) =>
  id ? (
    <>
      <Script
        id="google-tag-manager-head"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${id}')`,
        }}
      />
      <noscript>
        <iframe
          title="gtm-noscript"
          src={`https://www.googletagmanager.com/ns.html?id=${id}"`}
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>
    </>
  ) : null;

export default GoogleTagManager;
