import { useRouter } from "next/router";
import React from "react";
import { Configure, InstantSearch } from "react-instantsearch";
import { ProductFinderStory } from "../../lib/storyblok/types/stories/ProductFinderStory";
import { StoryblokStoryComponent } from "../../lib/storyblok/types/StoryblokComponent";
import {
  initAlgoliaIndecies,
  initAlgoliaSearch,
} from "../../lib/utils/AlgoliaSearchHelpers";
import { ViewModel } from "../../lib/viewModel/createViewModel";
import { DynamicBlocks } from "../DynamicBlock";
import StoryFinder from "../Nestable/StoryFinder";
import { history } from "instantsearch.js/es/lib/routers";

type VM = ViewModel<ProductFinderStory>;
const indecies = initAlgoliaIndecies();
const searchClient = initAlgoliaSearch();

const ProductFinderPage: StoryblokStoryComponent<VM> = ({ story, locale }) => {
  const { hero, body, activateSearchField } = story.content;
  const { query } = useRouter();
  const initialValue = typeof query.q === "string" ? query.q : undefined;
  const refinements = [
    { id: "product", attribute: "categories" },
    { id: "solution", attribute: "solutions" },
  ];

  interface queryParameters {
    categories?: string[];
    solutions?: string[];
  }

  const productnstantSearchRouting = {
    router: history({
      createURL({ qsModule, routeState, location }) {
        const pageUrl =
          locale === "zh" ? "product-finder" : "products/product-finder";
        const urlParts =
          locale !== "zh"
            ? location.href.match(/^(.*?)\/products\/product-finder/)
            : location.href.match(/^(.*?)\/product-finder/);

        const baseUrl = `${urlParts ? urlParts[1] : ""}/`;

        const queryParameters: queryParameters = {};

        if (routeState.categories) {
          queryParameters.categories = (routeState.categories as string[]).map(
            encodeURIComponent
          );
        }

        if (routeState.solutions) {
          queryParameters.solutions = (routeState.solutions as string[]).map(
            encodeURIComponent
          );
        }

        const queryString = decodeURIComponent(
          qsModule.stringify(queryParameters, {
            addQueryPrefix: true,
            arrayFormat: "repeat",
          })
        );

        return `${baseUrl}${pageUrl}${queryString}`;
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      parseURL({ qsModule, location }) {
        const { categories = [], solutions = [] } = qsModule.parse(
          location.search.slice(1)
        );

        const allCategories = Array.isArray(categories)
          ? categories
          : [categories].filter(Boolean);

        const allSolutions = Array.isArray(solutions)
          ? solutions
          : [solutions].filter(Boolean);

        const uiState: queryParameters = {
          categories: allCategories.map(decodeURIComponent),
          solutions: allSolutions.map(decodeURIComponent),
        };

        return uiState;
      },
    }),

    stateMapping: {
      stateToRoute(uiState) {
        const indexUiState = uiState[indecies[locale]] || {};

        return {
          categories: indexUiState.refinementList?.categories,
          solutions: indexUiState.refinementList?.solutions,
        };
      },

      routeToState(routeState) {
        return {
          [indecies[locale]]: {
            refinementList: {
              categories: routeState.categories,
              solutions: routeState.solutions,
            },
          },
        };
      },
    },
  };
  return (
    <InstantSearch
      indexName={indecies[locale]}
      searchClient={searchClient}
      routing={productnstantSearchRouting}
    >
      <Configure
        hitsPerPage={9}
        attributesToSnippet={["title:40", "content:40", "metaDescription:40"]}
        query={initialValue}
        filters={"storyType:productPage"}
      />
      <DynamicBlocks blocks={hero} />
      <StoryFinder
        value={initialValue}
        preamble={body}
        activateSearchField={activateSearchField}
        refinements={refinements}
        renderDate={false}
      />
    </InstantSearch>
  );
};

export default ProductFinderPage;
