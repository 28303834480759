import React, { ReactNode } from "react";
import cn from "classnames";
import s from "./EditorialImage.module.scss";
import c from "../../../styles/container.module.scss";

type Props = {
  image?: ReactNode;
  caption?: string;
  wide?: boolean;
  narrow?: boolean;
  keepProportions?: boolean;
};

const EditorialImage = ({
  image,
  caption,
  wide,
  keepProportions,
  narrow,
}: Props) => {
  return (
    <figure className={cn(s.container, c.fluid)}>
      <div
        className={cn({
          [c.wide]: wide && !narrow,
          [c.noGutter]: wide && !keepProportions,
          [c.narrow]: narrow,
        })}
      >
        <div
          className={cn(s.imgContainer, {
            [s.narrow]: !wide,
            [s.keepProportions]: keepProportions,
          })}
        >
          {image}
        </div>
      </div>
      <div className={c.narrow}>
        {caption && <figcaption>{caption}</figcaption>}
      </div>
    </figure>
  );
};

export default EditorialImage;
