/* eslint-disable */
import React, { ReactNode, useEffect, useState, useRef } from "react";
import cn from "classnames";
import Image from "next/image";
import Markdown from "../Markdown";
import s from "./CTAForm.module.scss";
import c from "../../styles/container.module.scss";
import s2 from "../Editorial/components/EditorialCopy.module.scss";
import swooshBlueTop from "../../public/images/divider-block-swoosh-blue-top.png";
import swooshBlueBottom from "../../public/images/divider-block-swoosh-blue-bottom.png";

type Props = {
  title: string;
  text?: string;
  copy?: string;
  image?: ReactNode;
  formUrl: string;
  spacing: "onlyTop" | "onlyBottom" | "both" | "none";
  style: "white" | "gray" | "signature" | "black";
  divider: "none" | "swooshBlueTop" | "swooshBlueBottom" | "swooshBlueBoth";
};

const CTAForm = ({
  title,
  text,
  copy,
  image,
  spacing,
  style,
  divider,
  formUrl,
}: Props) => {
  const iframeId = "ctaFormIFrame";

  const ref = useRef<HTMLIFrameElement>(null);
  let originUrl = "";
  if (formUrl) {
    try {
      originUrl = new URL(formUrl).origin;
    } catch (error) {
      console.error("Invalid formUrl:", formUrl);
    }
  }

  useEffect(() => {
    window.addEventListener("message", (message) => {
      if (
        (message.data.type === "ctaFormResize-" + formUrl &&
          message.data.height &&
          message.origin === originUrl) ||
        (message.data.type === "pardotFormResize-" + formUrl &&
          message.data.height &&
          message.origin === originUrl)
      ) {
        setTimeout(() => {
          setHeight(message.data.height + 2);
        }, 0);
      }
    });
  }, [originUrl, formUrl]);

  const [height, setHeight] = useState(1000);

  return (
    <section className={cn(s.ctaForm, s[spacing], s[style])}>
      {divider === "swooshBlueTop" || divider === "swooshBlueBoth" ? (
        <div className={s.topImage}>
          <Image
            src={swooshBlueTop}
            alt="Swoosh Blue Top"
            objectFit="contain"
            layout="intrinsic"
            quality="100"
          />
        </div>
      ) : null}

      <div className={cn(c.wide, s.wrapper)}>
        <div className={cn(s.content)}>
          {title && <h2>{title}</h2>}
          {text && (
            <div className={s.intro}>
              <Markdown>{text}</Markdown>
            </div>
          )}
          {copy && (
            <div className={cn(s.copy, s2.copy)}>
              <Markdown>{copy}</Markdown>
            </div>
          )}
          {image && <div className={cn(s.imageContainer)}>{image}</div>}
        </div>

        {formUrl && (
          <div className={cn(s.form)}>
            <div>
              <iframe
                title={formUrl}
                ref={ref}
                id={iframeId}
                width="100%"
                height={height}
                src={formUrl}
              />
            </div>
          </div>
        )}
      </div>

      {divider === "swooshBlueBottom" || divider === "swooshBlueBoth" ? (
        <div className={s.bottomImage}>
          <Image
            src={swooshBlueBottom}
            alt="Swoosh Blue Bottom"
            objectFit="contain"
            layout="intrinsic"
            quality="100"
          />
        </div>
      ) : null}
    </section>
  );
};

export default CTAForm;
