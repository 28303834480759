import { ReactNode } from "react";
import s from "../TagsAndShareWrapper.module.scss";

type Props = {
  children: ReactNode;
};

const TagsWrapper = ({ children }: Props) => (
  <ul className={s.tagsWrapper}>{children}</ul>
);

export default TagsWrapper;
