import React, { ReactNode } from "react";
import cn from "classnames";
import s from "../FeaturedContentGrid.module.scss";

type Props = {
  productName: string;
  title: string;
  preamble: string;
  buttons: ReactNode;
  foregroundImage?: ReactNode;
  backgroundImage: ReactNode;

  inverted?: boolean;
  overlay?: boolean;
  fullWidth?: boolean;
  alignRight?: boolean;
};

const FeaturedContentItem = ({
  productName,
  title,
  preamble,
  buttons,
  foregroundImage,
  backgroundImage,
  inverted,
  overlay,
  fullWidth,
  alignRight,
}: Props) => (
  <article
    className={cn(s.article, {
      [s.inverted]: inverted,
      [s.fullWidth]: fullWidth,
      [s.alignRight]: !fullWidth && alignRight,
    })}
  >
    {!foregroundImage && backgroundImage && (
      <>
        <div className={s.background}>{backgroundImage}</div>
      </>
    )}

    <div className={s.container}>
      <div className={s.top}>
        <span>{productName}</span>
      </div>
      <h3 className="display">{title}</h3>
      <p>{preamble}</p>
      <div className={s.buttons}>{buttons}</div>
    </div>

    {foregroundImage && (
      <div className={s.foregroundImage}>{foregroundImage}</div>
    )}
    {overlay && <div className={s.overlay} />}
  </article>
);

export default FeaturedContentItem;
