import { DownloadsLinkItemListBlock } from "../../lib/storyblok/types/blocks/DownloadsLinkItemListBlock";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import Downloads from "../../ui/Downloads/Downloads";
import { DynamicBlocks } from "../DynamicBlock";

const { List } = Downloads;

const DownloadsLinkItemList: StoryblokBlockComponent<
  DownloadsLinkItemListBlock
> = ({ items }) => (
  <List>
    <DynamicBlocks blocks={items} />
  </List>
);

export default DownloadsLinkItemList;
