import TobiiLogo from "../../Logos/TobiiLogo";
import Icon from "../../Icon/Icon";
import cn from "classnames";
import s from "../Footer.module.scss";
import b from "../../../ui/Button/Button.module.scss";
import { useTranslation } from "next-i18next";
import FlexibleLinkWrapper from "../../../components/Nestable/LinkWrapper/FlexibleLinkWrapper";

type Props = {
  search: string;
  site: string;
  onLangClick: () => void;
};

const FooterHeader = ({ search, site, onLangClick }: Props) => {
  const logoWidth = "4.5rem";
  const { t } = useTranslation();

  return (
    <div className={cn(s.footerHeader)}>
      <TobiiLogo width={logoWidth} color={s.white} />

      <ul>
        {onLangClick && (
          <li>
            <button className={s.langPicker} onClick={onLangClick}>
              <Icon icon="world" width={20} color={s.white} />
            </button>
          </li>
        )}
        {search && (
          <li>
            <FlexibleLinkWrapper href={search} title={t("search")}>
              <Icon icon="search" color={s.white} width={20} />
            </FlexibleLinkWrapper>
          </li>
        )}
        {site === "investor" ? (
          <li>
            <FlexibleLinkWrapper
              className={cn(s.button, b.button, b.small)}
              href="https://www.tobii.com/"
              title={t("tobii-com")}
            >
              Tobii.com
              <Icon icon="arrow-up-right" color={s.white} width={12} />
            </FlexibleLinkWrapper>
          </li>
        ) : (
          <li>
            <FlexibleLinkWrapper
              className={cn(s.button, b.button, b.small)}
              href="https://corporate.tobii.com/"
              title={t("tobii-corporate")}
            >
              Tobii Corporate
              <Icon icon="arrow-up-right" color={s.white} width={12} />
            </FlexibleLinkWrapper>
          </li>
        )}
      </ul>
    </div>
  );
};
export default FooterHeader;
