import React, { useEffect, useState } from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import GlobalAlertUi from "../../ui/GlobalAlert";
import Image from "next/image";
import Button from "../../ui/Button";
import { GlobalAlertBlock } from "../../lib/storyblok/types/blocks/GlobalAlertBlock";
import { useTranslation } from "next-i18next";
import { useLinks } from "../../lib/storyblok/useLinks";

const GlobalAlert: StoryblokBlockComponent<GlobalAlertBlock> = ({
  _uid,
  style,
  icon,
  title,
  text,
  button,
  expirationTime,
}) => {
  const [isClosed, setIsClosed] = useState(true);
  const { t } = useTranslation();

  const resolveLink = useLinks();

  useEffect(() => {
    const alerts =
      typeof window !== "undefined" ? localStorage.getItem("alerts") : null;

    try {
      const parsedAlerts = alerts ? JSON.parse(alerts) : [];

      if (parsedAlerts.length > 0) {
        const thisAlert = parsedAlerts.find((alert) => alert.id === _uid);

        if (thisAlert) {
          const now = new Date().getTime();

          if (now > thisAlert.expirationTime) {
            setIsClosed(false);

            localStorage.setItem(
              "alerts",
              parsedAlerts.filter((alert) => alert.id !== _uid)
            );
          }
        } else {
          setIsClosed(false);
        }
      } else {
        setIsClosed(false);
      }
    } catch {
      setIsClosed(false);
    }
  }, [_uid]);

  const onCloseClick = () => {
    const alertsData = {
      id: _uid,
      status: "seen",
      timeOfAcception: new Date().getTime(),
      expirationTime: Math.floor(new Date().getTime() + expirationTime * 1000),
    };

    let alerts: [];
    try {
      alerts = JSON.parse(localStorage.getItem("alerts") || "[]");
    } catch {
      alerts = [];
    }

    localStorage.setItem("alerts", JSON.stringify([...alerts, alertsData]));
    setIsClosed(true);
  };

  if (isClosed) {
    return null;
  }

  return (
    <GlobalAlertUi
      onCloseClick={onCloseClick}
      style={style}
      icon={
        icon && (
          <Image
            src={`/svg/icons/${icon}.svg`}
            alt={`icon-${icon}`}
            width={18}
            height={18}
          />
        )
      }
      title={title}
      text={text}
      close={t("close")}
      button={
        button[0] && (
          <Button
            href={button[0]?.buttonLink && resolveLink(button[0]?.buttonLink)}
            variant={button[0].buttonType}
          >
            {button[0].buttonText}
          </Button>
        )
      }
      showMoreLabel={t("show-more")}
      showLessLabel={t("show-less")}
    />
  );
};

export default GlobalAlert;
