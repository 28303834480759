import { storyblokEditable } from "@storyblok/react";
import { Block } from "../lib/storyblok/types/story";
import { StoryblokBlockComponent } from "../lib/storyblok/types/StoryblokComponent";
import Markdown from "./Nestable/Markdown";
import TextAndImage from "./Nestable/TextAndImage";
import ImageCarousel from "./Nestable/ImageCarousel";
import VideoCarousel from "./Nestable/VideoCarousel";
import DualMediaBlock from "./Nestable/DualMediaBlock";
import CTABlock from "./Nestable/CtaBlock";
import CTAForm from "./Nestable/CtaForm";
import TechSpec from "./Nestable/TechSpec";
import FinancialReportsTable from "./Nestable/FinancialReportsTable";
import Editorial from "./Nestable/Editorial";
import EditorialImage from "./Nestable/EditorialImage";
import EditorialImageKeepProportions from "./Nestable/EditorialImageKeepProportions";
import EditorialQuote from "./Nestable/EditorialQuote";
import EditorialVideo from "./Nestable/EditorialVideo";
import EditorialMarkdown from "./Nestable/EditorialMarkdown";
import Embed from "./Nestable/EmbedBlock";
import ShortFeatures from "./Nestable/ShortFeatures";
import HeroBlock from "./Nestable/Hero";
import ColumnGrid from "./Nestable/ColumnGrid";
import ColumnGridVideoItem from "./Nestable/ColumnGridVideoItem";
import ColumnGridImageItem from "./Nestable/ColumnGridImageItem";
import ColumnGridTextItem from "./Nestable/ColumnGridTextItem";
import DownloadsBlock from "./Nestable/Downloads";
import DownloadsExpandableItemList from "./Nestable/DownloadsExpandableItemList";
import DownloadsLinkItemList from "./Nestable/DownloadsLinkItemList";
import DownloadsLinkItem from "./Nestable/DownloadsLinkItem";
import DownloadsGatedDownloadItem from "./Nestable/DownloadsGatedDownloadItem";
import Speakers from "./Nestable/Speakers";
import Details from "./Nestable/Details";
import Agenda from "./Nestable/Agenda";
import Share from "./Nestable/Share";
import Contact from "./Nestable/Contact";
import Video from "./Nestable/Video";
import YouTubeVideo from "./Nestable/YouTubeVideo";
import TwentyThreeVideo from "./Nestable/TwentyThreeVideo";
import TencentVideo from "./Nestable/TencentVideo";
import TextAndVideo from "./Nestable/TextAndVideo";
import FeaturedContentGrid from "./Nestable/FeaturedContentGrid";
import ContentGrid from "./Nestable/ContentGrid";
import ContentGridSlider from "./Nestable/ContentGridSlider";
import VideoPlayer from "./Nestable/VideoPlayer";
import Preamble from "./Nestable/Preamble";
import Testimonials from "./Nestable/Testimonials";
import EventCalendar from "./Nestable/EventCalendar";
import LogoList from "./Nestable/LogoList";
import GatedForm from "./Nestable/GatedForm";
import ShareGraph from "./Nestable/ShareGraph";
import ShareTicker from "./Nestable/ShareTicker";
import ContentGridTobiiGroupShare from "./Nestable/ContentGridTobiiGroupShare";
import ParallaxCTABlock from "./Nestable/ParallaxCTABlock";
import FeaturedTabs from "./Nestable/FeaturedTabs";
import TextAndImageParallax from "./Nestable/TextAndImageParallax";
import Button from "./Nestable/Button";
import GatedDownloads from "./Nestable/GatedDownloads";
import SubscriptionForm from "./Nestable/SubscriptionForm";
import LocalAlert from "./Nestable/LocalAlert";
import CtaModal from "./Nestable/CtaModal";
import MiscTable from "./Nestable/MiscTable";
import Slider from "./Nestable/Slider";
import OverlayBlock from "./Nestable/OverlayBlock";
import AccordionBlock from "./Nestable/AccordionBlock";
import DatablockBlock from "./Nestable/DatablockBlock";
import ComponentTest from "./Nestable/ComponentTest";
import FourOhFour from "../pages/404";
import TabsGrid from "./Nestable/TabsGrid";
import InteractiveGrid from "./Nestable/InteractiveGrid";
import ImagevaultMigration from "./Nestable/ImagevaultMigration";

const components = {
  markdown: Markdown,
  ctaBlock: CTABlock,
  ctaForm: CTAForm,
  textAndImage: TextAndImage,
  imageCarousel: ImageCarousel,
  videoCarousel: VideoCarousel,
  dualMediaBlock: DualMediaBlock,
  techSpec: TechSpec,
  financialReportsTable: FinancialReportsTable,
  miscTable: MiscTable,
  editorial: Editorial,
  editorialMarkdown: EditorialMarkdown,
  editorialImage: EditorialImage,
  editorialImageKeepProportions: EditorialImageKeepProportions,
  editorialVideo: EditorialVideo,
  editorialQuote: EditorialQuote,
  embed: Embed,
  heroSmall: HeroBlock,
  shortFeatures: ShortFeatures,
  columnGrid: ColumnGrid,
  columnGridVideoItem: ColumnGridVideoItem,
  columnGridImageItem: ColumnGridImageItem,
  columnGridTextOnly: ColumnGrid,
  columnGridTextItem: ColumnGridTextItem,
  downloads: DownloadsBlock,
  downloadsExpandableItemList: DownloadsExpandableItemList,
  downloadsLinkItemList: DownloadsLinkItemList,
  downloadsLinkItem: DownloadsLinkItem,
  downloadsGatedDownloadItem: DownloadsGatedDownloadItem,
  hero: HeroBlock,
  speakers: Speakers,
  details: Details,
  agenda: Agenda,
  share: Share,
  contact: Contact,
  video: Video,
  youtubeVideo: YouTubeVideo,
  youtubeVideoWithoutDescription: YouTubeVideo,
  twentyThreeVideo: TwentyThreeVideo,
  twentyThreeVideoWithoutDescription: TwentyThreeVideo,
  tencentVideo: TencentVideo,
  tencentVideoWithoutDescription: TencentVideo,
  textAndVideo: TextAndVideo,
  featuredContentGrid: FeaturedContentGrid,
  contentGrid: ContentGrid,
  contentGridSlider: ContentGridSlider,
  videoPlayer: VideoPlayer,
  preamble: Preamble,
  testimonials: Testimonials,
  eventCalendar: EventCalendar,
  logoList: LogoList,
  gatedForm: GatedForm,
  shareGraph: ShareGraph,
  contentGridTobiiGroupShare: ContentGridTobiiGroupShare,
  shareTicker: ShareTicker,
  parallaxCTABlock: ParallaxCTABlock,
  featuredTabs: FeaturedTabs,
  textAndImageParallax: TextAndImageParallax,
  button: Button,
  gatedDownloads: GatedDownloads,
  gatedDownloadsList: DownloadsLinkItemList,
  gatedDownloadsExpandableItemList: DownloadsExpandableItemList,
  subscriptionForm: SubscriptionForm,
  localAlert: LocalAlert,
  ctaModal: CtaModal,
  slider: Slider,
  overlayBlock: OverlayBlock,
  accordionBlock: AccordionBlock,
  datablock: DatablockBlock,
  componentTest: ComponentTest,
  fourOhFour: FourOhFour,
  tabsGrid: TabsGrid,
  interactiveGrid: InteractiveGrid,
  imagevaultMigration: ImagevaultMigration,
};

type Overrides = Partial<{
  [key in keyof typeof components]: StoryblokBlockComponent;
}>;

export const DynamicBlock = ({ content, overrides }) => {
  const Component =
    { ...components, ...overrides }[content.component] ?? NotCreated;

  return (
    <div
      style={{
        display: "contents",
      }}
      {...storyblokEditable(content)}
      key={content._uid}
    >
      <Component {...content} />
    </div>
  );
};

type MultipleProps = {
  blocks: Block[];
  overrides?: Overrides;
};

export const DynamicBlocks = ({ blocks, overrides }: MultipleProps) => (
  <>
    {Array.isArray(blocks) &&
      blocks?.map((content) => (
        <DynamicBlock
          key={content._uid}
          content={content}
          overrides={overrides}
        />
      ))}
  </>
);

const NotCreated: StoryblokBlockComponent = ({ component }) => (
  <p>
    The component <strong>{component}</strong> has not been created yet.
  </p>
);
