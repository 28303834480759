/* eslint-disable */
import React, { ReactNode, useRef } from "react";
import cn from "classnames";
import s from "./Overlay.module.scss";
import c from "../../styles/container.module.scss";
import bs from "../../styles/block.spacing.module.scss";
import BlockIntro from "../Common/BlockIntro";
import VideoPlayer from "./components/VideoPlayer";
import BlockBackground from "../Common/BlockBackground";

type Props = {
  title: string;
  description: string;
  buttons?: ReactNode;
  image?: ReactNode;
  video?: ReactNode;
  overlayStyle: string;
  overlayOpacity: number;
  featured?: boolean;
  contentAlignment: string;
  innerSpacing: string;
  outerSpacing: string;
  width: string;
};

const subComponents = { VideoPlayer };
type SubComponents = typeof subComponents;

const Overlay: CWS<Props, SubComponents> = ({
  title,
  description,
  buttons,
  image,
  video,
  overlayStyle,
  overlayOpacity,
  featured,
  contentAlignment,
  innerSpacing,
  outerSpacing,
  width,
}) => {
  return (
    <>
      <section
        className={cn(s.overlay, s[innerSpacing], bs[outerSpacing], c[width])}
      >
        <div className={cn(s.background)}>
          {video
            ? video
            : image && (
                <div className={cn(s.backgroundImage)}>
                  <div className={s.imageContainer}>{image}</div>
                </div>
              )}
        </div>

        <BlockBackground style={overlayStyle} opacity={overlayOpacity} />

        <BlockIntro
          alignment={contentAlignment}
          style={overlayStyle}
          title={title}
          description={description}
          buttons={buttons}
          featured={featured}
          flexibleWidth={true}
        />
      </section>
    </>
  );
};

Overlay.VideoPlayer = VideoPlayer;

export default Overlay;
