import React, { useEffect, useState } from "react";
import cn from "classnames";
import s from "./DisclaimerBlock.module.scss";
import c from "../../styles/container.module.scss";
import b from "../../ui/Button/Button.module.scss";
import Dropdown from "./components/Dropdown";
import AuthorizedContent from "./components/AuthorizedContent";
import RestrictedAccessComponent from "./components/RestrictedAccessComponent";
import Markdown from "../../ui/Markdown";
import { useLinks } from "../../lib/storyblok/useLinks";
import { LinkFieldtype } from "../../lib/storyblok/types/fieldtypes/linkFieldtype";

type Props = {
  disclaimerTitle: string;
  disclaimerRestrictedAccessTitle: string;
  disclaimerDescription: string;
  disclaimerFirstDropDownTitle: string;
  disclaimerSecondaryButtonText: string;
  disclaimerOptionsList: string;
  disclaimerSecondaryButtonLink: LinkFieldtype;
  disclaimerHasSecondPage: boolean;
  disclaimerSecondPageButtonText: string;
  disclaimerSecondPageSecondaryButtonText: string;
  disclaimerAuthorizedOptions: string;
  disclaimerAuthorizedContentDescription: string;
  disclaimerRestrictedAccessContentDescription: string;
  disclaimerAuthorizedContentTitle: string;
  disclaimerMainButtonText: string;
  setShowContent: (show: boolean) => void;
};

const DisclaimerBlock = ({
  disclaimerTitle,
  disclaimerMainButtonText,
  disclaimerSecondaryButtonLink,
  disclaimerFirstDropDownTitle,
  disclaimerSecondaryButtonText,
  disclaimerRestrictedAccessTitle,
  disclaimerDescription,
  disclaimerOptionsList,
  disclaimerHasSecondPage,
  disclaimerSecondPageButtonText,
  disclaimerSecondPageSecondaryButtonText,
  disclaimerAuthorizedOptions,
  disclaimerAuthorizedContentDescription,
  disclaimerAuthorizedContentTitle,
  disclaimerRestrictedAccessContentDescription,
  setShowContent,
}: Props) => {
  const resolveLink = useLinks();

  const [disclaimerState, setDisclaimerState] = useState("disclaimer");
  const [authorizedOptionsList, setAuthorizedOptionsList] = useState<string[]>(
    []
  );
  const [selectedOption, setSelectedOption] = useState("");

  const resolvedButtonLink = resolveLink(disclaimerSecondaryButtonLink);

  useEffect(() => {
    if (disclaimerAuthorizedOptions) {
      const OptionsArray = disclaimerAuthorizedOptions
        .split("|")
        .map((item) => item.trim());
      setAuthorizedOptionsList(OptionsArray);

      // Set setSelectedOption to the first item from OptionsArray since selectedOption is empty by default.
      if (OptionsArray.length > 0) {
        setSelectedOption(OptionsArray[0]);
      }
    }
  }, [disclaimerAuthorizedOptions]);

  const handleContinueButton = () => {
    const isValidCountry = authorizedOptionsList.includes(selectedOption);
    if (isValidCountry && !disclaimerHasSecondPage) {
      setShowContent(true);
      return;
    }
    const nextState = isValidCountry ? "authorizedContent" : "restrictedAccess";
    setDisclaimerState(nextState);
  };

  return (
    <>
      <section className={cn(s.disclaimerBlock, c.medium)}>
        {disclaimerState === "disclaimer" && (
          <>
            {disclaimerTitle && <h2 className={s.title}>{disclaimerTitle}</h2>}
            {disclaimerDescription && (
              <Markdown>{disclaimerDescription}</Markdown>
            )}

            {disclaimerFirstDropDownTitle && (
              <h5 className={s.disclaimerFirstDropDownTitle}>
                {disclaimerFirstDropDownTitle}
              </h5>
            )}
            {disclaimerOptionsList && (
              <div className={s.optionsDropdown}>
                <Dropdown
                  options={disclaimerOptionsList
                    .split("|")
                    .map((item) => item.trim())}
                  onChange={setSelectedOption}
                />
              </div>
            )}

            <div className={s.buttonsContainer}>
              {disclaimerMainButtonText && (
                <button
                  className={cn(b.button, b.cta)}
                  onClick={handleContinueButton}
                >
                  <span>{disclaimerMainButtonText}</span>
                </button>
              )}
              {disclaimerSecondaryButtonText && (
                <a className={cn(b.button, b.cta)} href={resolvedButtonLink}>
                  <span>{disclaimerSecondaryButtonText}</span>
                </a>
              )}
            </div>
          </>
        )}
        {disclaimerState === "authorizedContent" && (
          <AuthorizedContent
            setShowContent={setShowContent}
            setDisclaimerState={setDisclaimerState}
            disclaimerAuthorizedContentDescription={
              disclaimerAuthorizedContentDescription
            }
            disclaimerAuthorizedContentTitle={disclaimerAuthorizedContentTitle}
            disclaimerSecondPageButtonText={disclaimerSecondPageButtonText}
            disclaimerSecondPageSecondaryButtonText={
              disclaimerSecondPageSecondaryButtonText
            }
          />
        )}
        {disclaimerState === "restrictedAccess" && (
          <RestrictedAccessComponent
            disclaimerRestrictedAccessContentDescription={
              disclaimerRestrictedAccessContentDescription
            }
            disclaimerRestrictedAccessTitle={disclaimerRestrictedAccessTitle}
          />
        )}
      </section>
    </>
  );
};

export default DisclaimerBlock;
