import { ReactNode } from "react";
import { motion } from "framer-motion";
import cn from "classnames";
import s from "../AnchorMenu.module.scss";
import Icon from "../../Icon";

type Props = {
  title: string;
  close: string;
  onCloseClick: () => void;
  isFeaturedTabs?: boolean;
  children: ReactNode;
};

const MobileMenu = ({
  title,
  close,
  onCloseClick,
  isFeaturedTabs = false,
  children,
}: Props) => (
  <motion.nav
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.1 }}
    className={s.mobileMenu}
  >
    <div className={cn(s.header, { [s.featuredTabs]: isFeaturedTabs })}>
      <h3>{title}</h3>
      <button onClick={onCloseClick} title={close}>
        <Icon icon="cancel" color={s.iconcolor} width={24} />
      </button>
    </div>

    <ul>{children}</ul>
  </motion.nav>
);

export default MobileMenu;
