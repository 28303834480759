import React from "react";
import s from "../DisclaimerBlock.module.scss";
import Markdown from "../../../ui/Markdown";

const RestrictedAccessComponent = ({
  disclaimerRestrictedAccessContentDescription,
  disclaimerRestrictedAccessTitle,
}) => {
  return (
    <div className={s.restrictedAccess}>
      <h2 className={s.title}>{disclaimerRestrictedAccessTitle}</h2>
      <Markdown>{disclaimerRestrictedAccessContentDescription}</Markdown>
    </div>
  );
};

export default RestrictedAccessComponent;
