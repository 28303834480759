import React, { HTMLProps } from "react";
import Pill from "../../Pill";
import s from "../FilterList.module.scss";
import cn from "classnames";
import { useFilterListContext } from "../FilterListContext";

type FilterProps = {
  children: string;
  value: string | number;
  amount?: number;
  type?: "checkbox" | "radio";
} & HTMLProps<HTMLInputElement>;

const Filter = ({
  children,
  amount,
  className,
  type = "checkbox",
  ...props
}: FilterProps) => {
  const { name } = useFilterListContext();
  return (
    <label className={cn(className, s.filter)}>
      <input
        {...props}
        className={cn("visuallyHidden", s.input)}
        name={name}
        type={type}
      />
      <span className={s.filterContainer}>
        <span className={s.filterLabel}>{children}</span>
        {amount && <Pill>{amount}</Pill>}
      </span>
    </label>
  );
};

export default Filter;
