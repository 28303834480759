import { useTranslation } from "next-i18next";
import Tags from "../ui/Tags";

type Props = {
  tag: string;
  url: string;
};

const Tag = ({ tag, url }: Props) => {
  const { t } = useTranslation();

  return <Tags.Tag key={tag} tag={t(tag, { ns: "tags" })} url={url} />;
};

export default Tag;
