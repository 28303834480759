import { useCallback } from "react";
import type {
  WidgetConfig,
  DatablockConfig,
} from "../../lib/storyblok/types/blocks/DatablockBlock";

export const useDatablockWidget = () => {
  const initializeWidgets = useCallback(
    (widgetConfigs: WidgetConfig[], locale: string, demo: boolean) => {
      const DB_URL = "https://widget.datablocks.se/api/rose";

      if (!window._MF) {
        window._MF = {
          data: [],
          url: DB_URL,
          ready: false,
          render: () => {
            window._MF!.ready = true;
          },
          push: function (conf: DatablockConfig) {
            this.data.push(conf);
          },
        };
      }

      widgetConfigs.forEach((config) => {
        const elementId = `${config.widgetId}-${locale}`;

        window._MF!.push({
          query: `#${elementId}`,
          widget: config.widget,
          locale,
          token: config.token,
          demo,
        });
      });

      // Load script only if not already present
      const scriptSrc = `${DB_URL}/assets/js/loader-v4.js`;
      if (!document.querySelector(`script[src="${scriptSrc}"]`)) {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.src = scriptSrc;
        document.body.appendChild(script);
      }
    },
    []
  );

  const cleanupWidgets = useCallback(
    (widgetConfigs: WidgetConfig[], locale: string) => {
      if (window._MF?.data) {
        window._MF.data = window._MF.data.filter(
          (item) =>
            !widgetConfigs.some(
              (config) => item.query === `#${config.widgetId}-${locale}`
            )
        );
      }
    },
    []
  );

  return { initializeWidgets, cleanupWidgets };
};
