import React, { forwardRef, HTMLProps } from "react";
import { motion } from "framer-motion";
import { useIsExternalLink } from "../../../../hooks/useIsExternalLink";
import Icon from "../../../Icon/Icon";
import cn from "classnames";
import s from "./DesktopMenu.module.scss";
import FlexibleLinkWrapper from "../../../../components/Nestable/LinkWrapper/FlexibleLinkWrapper";

type Props = {
  title: string;
  link: string;
  index: number;
} & HTMLProps<HTMLAnchorElement>;

export const DesktopSubNavigationPanelLink = forwardRef<
  HTMLAnchorElement,
  Props
>(({ title, link, index, ...anchorProps }, ref) => {
  const { isExternalLink } = useIsExternalLink(link);

  return (
    <motion.li
      initial={{
        opacity: 0,
        y: -20,
      }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        duration: 0.2,
        delay: index * 0.03,
      }}
    >
      <FlexibleLinkWrapper
        className={cn(
          s.DesktopSubNavigationPanelItem,
          s.DesktopSubNavigationPanelLink,
          {
            [s.externalLink]: isExternalLink,
          }
        )}
        href={link}
        ref={ref}
        title={title}
        {...anchorProps}
      >
        {title && <span>{title}</span>}
        {isExternalLink ? (
          <Icon
            icon="arrow-up-right"
            width={12}
            className={(s.icon, s.iconExternal)}
            color={s.iconColorSubGroupHeader}
          />
        ) : (
          <Icon
            icon="arrow-right"
            width={12}
            className={s.icon}
            color={s.iconColorSubGroupHeader}
          />
        )}
      </FlexibleLinkWrapper>
    </motion.li>
  );
});

DesktopSubNavigationPanelLink.displayName = "DesktopSubNavigationPanelLink";
