import { useCallback, useState, useEffect } from "react";

export interface Content {
  title: string;
  slug: string;
  publish_date: string;
}

export interface NewsItem {
  news_id: string;
  content: Content;
}

interface NewsResponse {
  items: {
    news_id: string;
    content: Content;
  }[];
}

export const usePressReleasesFeed = (tokenIR: string, locale: string) => {
  const [newsItems, setNewsItems] = useState<NewsItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchNewsData = useCallback(async () => {
    try {
      const response = await fetch(
        `https://feed.mfn.se/v1/feed/${tokenIR}.json?lang=${locale}`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = (await response.json()) as NewsResponse;

      const irItems = data.items.map(({ news_id, content }) => ({
        news_id,
        content,
      }));

      const sortedItems = irItems
        .sort(
          (a, b) =>
            new Date(b.content.publish_date).getTime() -
            new Date(a.content.publish_date).getTime()
        )
        .slice(0, 3); // Show 3 releases

      setNewsItems(sortedItems);
      setError(null);
    } catch (err) {
      setError(
        err instanceof Error ? err.message : "Failed to fetch news data"
      );
      setNewsItems([]);
    } finally {
      setIsLoading(false);
    }
  }, [tokenIR, locale]);

  useEffect(() => {
    fetchNewsData();
    const intervalId = setInterval(fetchNewsData, 300000); // 5 minutes
    return () => clearInterval(intervalId);
  }, [fetchNewsData]);

  return {
    newsItems,
    isLoading,
    error,
  };
};
