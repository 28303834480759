const excludedFiltersTags = [
  "Tobii Pro TX-300",
  "Tobii Pro T60 XL",
  "Tobii Pro X60 / X120 / T60 / T120",
  "Tobii Pro X3-120",
  "Tobii Pro X2-60 / X2-30",
  "Tobii Pro X50",
  "Tobii Pro 1750 / 2150",
  "Tobii Pro X1 light",
  "Tobii eye tracker 5",
  "Tobii 4C",
  "EyeX",
  "Tobii Pro Studio",
  "Tobii ClearView",
  "Tobii Pro SDK / API",
  "Tobii VR",
  "Noldus",
  "Bitbrain",
  "Brain Products",
  "BiSigma",
  "Ergoneers",
  "Qualisys",
  "Shimmer GSR",
  "E-Prime",
  "iMotions",
  "Biopac",
  "Tobii Pro Glasses",
  "ISVs",
  "LSL",
  "Psychtoolbox",
  "Psychopy",
  "Timestudio",
  "OpenSesame",
  "Titta",
  "Translog",
  "EYE-EEG",
  "itrace",
  "GlassesViewer",
  "Flag",
  "Eye tracking competitor",
  "Dynavox",
  "Tobii Sticky",
  "Cites Tobii Publication",
];

export default excludedFiltersTags;
