import s from "./ComponentTest.module.scss";

type Props = {
  content: string;
  title: string;
};

const ComponentTest = ({ content, title }: Props) => (
  <section className={s.contact}>
    <h2>{title}</h2>
    <h2>{content}</h2>
  </section>
);

export default ComponentTest;
