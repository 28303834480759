import React, { ReactNode, HTMLProps, forwardRef } from "react";
import cn from "classnames";
import s from "./DesktopMenu.module.scss";

type Props = {
  children: ReactNode;
  onClick?: () => void;
  isSingleItem?: boolean;
} & HTMLProps<HTMLDivElement>;

export const DesktopSubNavigationPanel = forwardRef<HTMLDivElement, Props>(
  ({ children, onClick = () => {}, isSingleItem }, ref) => {
    return (
      <div
        className={cn(s.DesktopSubNavigationPanel, s.DesktopSubNavigation, {
          [s.singlePanel]: isSingleItem,
        })}
        onClick={onClick}
        ref={ref}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            onClick();
            e.preventDefault();
          }
        }}
      >
        {children}
      </div>
    );
  }
);

DesktopSubNavigationPanel.displayName = "DesktopSubNavigationPanel";
