import Script from "next/script";

type Props = { id: string | null };

const HellobarTracking = ({ id }: Props) =>
  id ? (
    <Script
      id={`hellobar-${id}`}
      strategy="lazyOnload"
      src={`https://my.hellobar.com/${id}.js`}
    />
  ) : null;

export default HellobarTracking;
