import { useCallback, useState, useEffect } from "react";

interface StockData {
  ath: number;
  currency: string;
  deltaToday: number;
  deltaYear: number;
  deltaYearDividend: number;
  latestPrice: number;
  listName: string;
  maxPriceYear: number;
  mcap: number;
  minPriceYear: number;
  stockIsin: string;
  stockIssueDate: string;
  stockSymbol: string;
}

interface StockResponse {
  stocks: StockData[];
}

export const useShareTicker = (token: string, locale: string) => {
  const [stockData, setStockData] = useState<StockData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [lastFetched, setLastFetched] = useState<string>("");

  const formatDateTime = () => {
    const now = new Date();
    const offset = -now.getTimezoneOffset();
    const sign = offset >= 0 ? "+" : "-";
    const hours = Math.floor(Math.abs(offset) / 60)
      .toString()
      .padStart(2, "0");
    const minutes = (Math.abs(offset) % 60).toString().padStart(2, "0");

    return `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(now.getDate()).padStart(2, "0")} ${String(
      now.getHours()
    ).padStart(2, "0")}:${String(now.getMinutes()).padStart(
      2,
      "0"
    )} GMT${sign}${hours}:${minutes}`;
  };

  const fetchStockData = useCallback(async () => {
    try {
      const response = await fetch(
        `https://widget.datablocks.se/api/rose/widgets/stock-table?lang=${locale}&type=json&token=${token}`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data: StockResponse = await response.json();
      setStockData(data.stocks[0]);
      setLastFetched(formatDateTime());
      setError(null);
    } catch (err) {
      setError(
        err instanceof Error ? err.message : "Failed to fetch stock data"
      );
      setStockData(null);
    } finally {
      setIsLoading(false);
    }
  }, [token, locale]);

  useEffect(() => {
    fetchStockData();

    // Refresh 5 minutes
    const intervalId = setInterval(fetchStockData, 300000);

    return () => clearInterval(intervalId);
  }, [fetchStockData]);

  return { stockData, isLoading, error, lastFetched };
};
