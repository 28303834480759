import React, { ReactNode } from "react";
import cn from "classnames";
import s from "./Footer.module.scss";
import c from "../../styles/container.module.scss";
import FooterHeader from "./components/FooterHeader";
import FooterFooter from "./components/FooterFooter";
import FooterList from "./components/FooterList";
import FooterLink from "./components/FooterLink";
import SocialList from "./components/SocialList";
import SocialItem from "./components/SocialItem";

const subComponents = {
  FooterHeader,
  FooterFooter,
  FooterList,
  FooterLink,
  SocialList,
  SocialItem,
};
type SubComponents = typeof subComponents;

type Props = {
  children: ReactNode;
};

const Footer: ((props: Props) => JSX.Element) & SubComponents = ({
  children,
}: Props) => (
  <footer className={cn(c.fluid, s.footer)}>
    <div className={cn(c.xWide, s.content)}>{children}</div>
  </footer>
);

Footer.FooterHeader = FooterHeader;
Footer.FooterFooter = FooterFooter;
Footer.FooterList = FooterList;
Footer.FooterLink = FooterLink;
Footer.SocialList = SocialList;
Footer.SocialItem = SocialItem;

export default Footer;
