import cn from "classnames";
import dynamic from "next/dynamic";
import { ReactNode, useRef } from "react";
import s from "./MediaAndImage.module.scss";
import b from "./../Button/Button.module.scss";
import c from "../../styles/container.module.scss";
import s2 from "../Editorial/components/EditorialCopy.module.scss";
import { resolveDate } from "../../lib/utils/resolveDate";
import { useViewmodel } from "../../lib/storyblok/ViewmodelContext";
import { useTranslation } from "next-i18next";

// Dynamic import for Typeform PopupButton
const PopupButton = dynamic(
  () => import("@typeform/embed-react").then((mod) => mod.PopupButton),
  { ssr: false }
);

type Props = {
  title: string;
  preamble: ReactNode;
  button?: ReactNode;
  secondButton?: ReactNode;
  typeformCopy: string;
  typeformID: string;
  typeformSize: "50" | "60" | "70" | "80" | "90" | "100";
  typeformButtonStyle: "default" | "cta" | "inverted";
  image?: ReactNode;
  video?: ReactNode;
  reversed?: boolean;
  featured?: boolean;
  dark?: boolean;
  parallaxVideo?: ReactNode;
  author?: string;
  publishedAt?: string;
  readTime?: string;
  imageKeepProportions: boolean;
  style: "white" | "gray" | "signature" | "black";
  outerSpacing: "outerOnlyTop" | "outerOnlyBottom" | "outerBoth" | "outerNone";
  mediaBackground:
    | "mediaBgWhite"
    | "mediaBgGray"
    | "mediaBgSignature"
    | "mediaBgBlack"
    | "mediaBgLitterbox";
  layout:
    | "layout11"
    | "layout1g1"
    | "layout12"
    | "layout1g2"
    | "layout21"
    | "layout2g1";
};

const MediaAndImage = ({
  title,
  preamble,
  button,
  secondButton,
  typeformCopy,
  typeformID,
  typeformSize,
  typeformButtonStyle,
  image,
  video,
  reversed,
  featured,
  style,
  outerSpacing,
  mediaBackground,
  imageKeepProportions,
  layout,
  parallaxVideo,
}: Props) => {
  const ref = useRef();
  return (
    <section
      className={cn(
        s.section,
        c.xWide,
        s.testClass,
        s[style],
        s[outerSpacing],
        s[layout],
        {
          [s.reversed]: reversed,
          [s.hasParallax]: parallaxVideo,
          [s.featured]: featured,
          [s[style]]: style,
        }
      )}
    >
      {parallaxVideo && <div className={s.parallax}>{parallaxVideo}</div>}
      <div
        className={cn(s.content, s2.copy, {
          [s.reversed]: reversed,
          [s.featured]: featured,
          [s.withParallax]: parallaxVideo,
        })}
      >
        {title && <h2 className={s.title}>{title}</h2>}
        {preamble && <div className={s.text}>{preamble}</div>}
        <div className={s.buttons}>
          {typeformID && typeformCopy && (
            <PopupButton
              id={typeformID}
              size={typeformSize}
              ref={ref}
              className={cn(b.button, b[typeformButtonStyle])}
            >
              {typeformCopy}
            </PopupButton>
          )}
          {!featured && (button || secondButton) && (
            <>
              {button && button}
              {secondButton && secondButton}
            </>
          )}
        </div>
      </div>
      {image && (
        <div
          className={cn(s.imageContainer, s.visual, {
            [s[style]]: style,
            [s.imageKeepProportions]: imageKeepProportions,
          })}
        >
          <div
            className={cn({
              [s[mediaBackground]]: mediaBackground,
            })}
          >
            {image}
          </div>
        </div>
      )}
      {video && (
        <div className={cn(s.videoContainer, s.visual)}>
          <div
            className={cn({
              [s[mediaBackground]]: mediaBackground,
            })}
          >
            {video}
          </div>
        </div>
      )}
    </section>
  );
};

export const MediaAndImageSmall = ({
  title,
  preamble,
  image,
  author,
  publishedAt,
  readTime,
  reversed,
  featured,
}: Props) => {
  const { locale } = useViewmodel();
  const { t } = useTranslation("articlepage");
  return (
    <section
      className={cn(s.smallSection, {
        [s.reversed]: reversed,
      })}
    >
      <div
        className={cn(s.content, s2.copy, s.smallContent, {
          [s.reversed]: reversed,
          [s.featured]: featured,
        })}
      >
        {title && <h2 className={s.title}>{title}</h2>}
        {preamble && <p className={s.textPreamble}>{preamble}</p>}

        <div className={s.details}>
          <p>
            <label className={cn("author", s.detailsItem)}>
              {`${author} `}
            </label>
          </p>

          <p className={cn("date", s.detailsItem)}>
            {publishedAt && `${resolveDate(publishedAt, locale)} `}
          </p>

          <p className={cn("date", s.detailsItem)}>
            {readTime && `${readTime}`} {t("minutesShort")}
          </p>
        </div>
      </div>
      {image && <div className={s.imageContainerSmall}>{image}</div>}
    </section>
  );
};

export default MediaAndImage;
