import { useEffect, useState } from "react";

export const useIsOverflow = (
  ref,
  direction: "horizontal" | "vertical",
  callback?
) => {
  const [isOverflow, setIsOverflow] = useState<boolean>();

  useEffect(() => {
    const { current } = ref;

    const trigger = () => {
      const hasOverflow =
        direction === "vertical"
          ? current.scrollHeight > current.clientHeight
          : current.scrollWidth > current.clientWidth;

      setIsOverflow(hasOverflow);

      if (callback) callback(hasOverflow);
    };

    if (current) {
      if ("ResizeObserver" in window) {
        new ResizeObserver(trigger).observe(current);
      }
      trigger();
    }
  }, [callback, ref, direction]);

  return isOverflow;
};
