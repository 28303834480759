import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import DownloadsUi from "../../ui/Downloads";
import { GatedDownloadsBlock } from "../../lib/storyblok/types/blocks/GatedDownloadsBlock";
import { DynamicBlocks } from "../DynamicBlock";
import { useCallback, useEffect, useState } from "react";

const GatedDownloads: StoryblokBlockComponent<GatedDownloadsBlock> = ({
  text,
  title,
  list,
}) => {
  const [idToDownload, setIdToDownload] = useState<string | null>(null);

  const downloadFile = useCallback(
    (id) => {
      const listBlock = list[0];
      const items =
        listBlock.component === "gatedDownloadsExpandableItemList"
          ? listBlock.items.flatMap((item) => item.items)
          : listBlock.items;

      const item = [...items].find((item) => item._uid === id);

      const filename = item?.externalFileUrl || item?.file?.filename;

      if (filename) {
        const link = document.createElement("a");
        link.id = id;
        link.href = filename;
        link.download = filename;
        link.target = "_blank";
        link.click();
      }
      setIdToDownload(null);
    },
    [list]
  );

  const onMessage = useCallback(
    (message) => {
      if (message.data.type === "pardotFormPreSubmit" && message.data.id) {
        if (!idToDownload) {
          setIdToDownload(message.data.id);
        }
      }

      if (message.data.type === "pardotFormSubmit" && idToDownload) {
        downloadFile(idToDownload);
        setIdToDownload(null);
      }
    },
    [downloadFile, idToDownload, setIdToDownload]
  );

  useEffect(() => {
    window.addEventListener("message", onMessage);

    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, [onMessage]);

  return (
    <DownloadsUi title={title} text={text}>
      <DynamicBlocks blocks={list} />
    </DownloadsUi>
  );
};

export default GatedDownloads;
