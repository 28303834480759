import React, { forwardRef } from "react";
import { motion } from "framer-motion";
import cn from "classnames";
import Icon from "../../../Icon/Icon";
import s from "./MobileMenu.module.scss";
import { useIsExternalLink } from "../../../../hooks/useIsExternalLink";
import FlexibleLinkWrapper from "../../../../components/Nestable/LinkWrapper/FlexibleLinkWrapper";

type Props = {
  text: string;
  href: string;
  index: number;
};

export const MobileNavItemItemItem = forwardRef<HTMLAnchorElement, Props>(
  ({ text, href, index }, ref) => {
    const { isExternalLink } = useIsExternalLink(href);

    return (
      <motion.li
        initial={{
          opacity: 0,
          x: 30,
        }}
        animate={{ opacity: 1, x: 0 }}
        transition={{
          duration: 0.3,
          delay: index * 0.06,
        }}
        className={cn(s.navItemItemItem)}
      >
        <FlexibleLinkWrapper
          ref={ref}
          href={href}
          title={text}
          aria-label={text}
          tabIndex={index}
        >
          {text && <span>{text}</span>}
          {isExternalLink && (
            <Icon icon="arrow-up-right" color={s.icon} width={12} />
          )}
        </FlexibleLinkWrapper>
      </motion.li>
    );
  }
);

MobileNavItemItemItem.displayName = "NavItemItemItem";
