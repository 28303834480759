import React, { useEffect } from "react";
import { useArchiveWidget } from "../../../hooks/mfn/useArchiveWidget";
import cn from "classnames";
import c from "../../../styles/container.module.scss";
import s from "./Datablock.Archive.module.scss";
import ss from "./Datablock.SharedStyles.module.scss";
import tokens from "../tokens.json";

type Props = {
  locale: string;
  demo: boolean;
};

const Archive: React.FC<Props> = ({ locale, demo }) => {
  const { initializeArchiveWidget, cleanupArchiveWidget } = useArchiveWidget();

  useEffect(() => {
    const { existingMF } = initializeArchiveWidget(
      tokens.archive,
      locale,
      demo
    );
    return () => {
      cleanupArchiveWidget(existingMF);
    };
  }, [locale, demo, initializeArchiveWidget, cleanupArchiveWidget]);

  return (
    <div
      id="archive-module"
      className={cn(c.xWide, s.archive, ss.contentWrapper)}
    />
  );
};

export default Archive;
