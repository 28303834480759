import React, { forwardRef } from "react";
import cn from "classnames";
import Icon, { IconType } from "../../../Icon/Icon";
import s from "./MobileMenu.module.scss";
import { useTranslation } from "next-i18next";
import FlexibleLinkWrapper from "../../../../components/Nestable/LinkWrapper/FlexibleLinkWrapper";

type Props = {
  text: string;
  siteTitle: string;
  href: string;
  iconPrefix?: IconType;
  iconSuffix?: IconType;
  isTobiiGroup?: boolean;
  onLangClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export const MobileFooterItem = forwardRef<HTMLAnchorElement, Props>(
  ({ text, href, iconPrefix, iconSuffix, onLangClick, isTobiiGroup }, ref) => {
    const { t } = useTranslation();

    return (
      <ul className={cn(s.mobileFooterItems)}>
        <li>
          <FlexibleLinkWrapper
            ref={ref}
            href={href}
            title={text}
            className={cn(s.mobileFooterItem)}
          >
            {iconPrefix && (
              <Icon icon={iconPrefix} width={14} color={cn(s.icon)} />
            )}
            {text}
            {iconSuffix && (
              <Icon icon={iconSuffix} width={14} color={cn(s.icon)} />
            )}
          </FlexibleLinkWrapper>
        </li>
        <li>
          <button
            className={s.mobileFooterItem}
            onClick={onLangClick}
            title={t("change-language")}
          >
            <span>{t("change-language")}</span>
            <Icon
              icon="world"
              width={18}
              color={isTobiiGroup ? s.tobiigroupiconcolor : s.iconColor}
            />
          </button>
        </li>
      </ul>
    );
  }
);

MobileFooterItem.displayName = "MobileFooterItem";
