import { EditorialMarkdownBlock } from "../../lib/storyblok/types/blocks/EditorialMarkdownBlock";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import Editorial from "../../ui/Editorial/Editorial";
import Markdown from "../../ui/Markdown";

const EditorialImage: StoryblokBlockComponent<EditorialMarkdownBlock> = ({
  markdown,
}) => (
  <Editorial.Copy>
    <Markdown>{markdown}</Markdown>
  </Editorial.Copy>
);

export default EditorialImage;
