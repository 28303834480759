import React, { ReactNode } from "react";
import cn from "classnames";
import s from "./TechSpec.module.scss";
import c from "../../styles/container.module.scss";
import Expandable from "./components/Expandable";
import ExpandButton from "./components/ExpandButton";
import Tables from "./components/Tables";
import Table from "./components/Table";
import TableRow from "./components/TableRow";
import TableNotes from "./components/TableNotes";
import TableNote from "./components/TableNote";
import Markdown from "../Markdown/Markdown";

type Props = {
  title: string;
  text: string;
  expandButton?: ReactNode;
  children: ReactNode;
  spacing: "onlyTop" | "onlyBottom" | "both" | "none";
};

const subComponents = {
  Expandable,
  ExpandButton,
  Tables,
  Table,
  TableRow,
  TableNotes,
  TableNote,
};
type SubComponents = typeof subComponents;

const TechSpec: CWS<Props, SubComponents> = ({
  title,
  text,
  expandButton,
  children,
  spacing,
}) => (
  <section className={cn(s.background, c.fluid)}>
    <div className={cn(s.techSpec, c.xWide, s[spacing])}>
      {title && <h3 className={cn("display", c.medium)}>{title}</h3>}
      {text && (
        <div className={s.wrapper}>
          <p className={s.text}>
            <Markdown>{text}</Markdown>
          </p>

          {expandButton}
        </div>
      )}
      {children}
    </div>
  </section>
);

TechSpec.Expandable = Expandable;
TechSpec.ExpandButton = ExpandButton;
TechSpec.Tables = Tables;
TechSpec.Table = Table;
TechSpec.TableRow = TableRow;
TechSpec.TableNotes = TableNotes;
TechSpec.TableNote = TableNote;

export default TechSpec;
