import { isSite, Site } from "../routing/site";
import { isLocale, Locale } from "../routing/locales";

export const deduceDomain = (locale: Locale, site: Site) => {
  if (locale === "zh") return process.env.NEXT_PUBLIC_CHINESE_DOMAIN;
  return site === "investor"
    ? process.env.NEXT_PUBLIC_INVESTOR_DOMAIN
    : process.env.NEXT_PUBLIC_MAIN_DOMAIN;
};

export const deduceDomainFromStorybookSlug = (slug: string) => {
  const [locale, site] = slug?.split("/");
  if (!isLocale(locale) || !isSite(site))
    throw new Error("Invalid alternate story slug");
  return deduceDomain(locale, site);
};
