/* eslint-disable */
import React, { useRef } from "react";
import cn from "classnames";
import s from "./EmbedBlock.module.scss";
import c from "../../styles/container.module.scss";
import Markdown from "../Markdown";

type Props = {
  title: string;
  text?: string;
  asset: {
    filename: string;
  };
  spacing: "onlyTop" | "onlyBottom" | "both" | "none";
  width: "fluid" | "narrow" | "wide";
  style: "white" | "gray" | "signature" | "black";
  height:
    | "400"
    | "500"
    | "600"
    | "700"
    | "800"
    | "900"
    | "1000"
    | "1100"
    | "1200";
};

const EmbedBlock = ({
  style = "signature",
  title,
  text,
  asset,
  spacing,
  width,
  height,
}: Props) => {
  const embedId = "embedIframe";

  const ref = useRef<HTMLIFrameElement>(null);

  let originUrl = "";
  if (asset?.filename) {
    try {
      originUrl = new URL(asset.filename).origin;
    } catch (error) {
      console.error("Invalid embedUrl:" + (asset?.filename || ""));
    }
  }

  return (
    <section className={cn(s.embed, s[style], s[spacing])}>
      <div className={cn(c.xWide)}>
        {title && <h2>{title}</h2>}
        {text && (
          <div className={s.text}>
            <Markdown>{text}</Markdown>
          </div>
        )}
      </div>
      <div className={cn(c[width])}>
        <div className={cn(s.embedWrapper)}>
          <iframe
            title={title}
            ref={ref}
            id={embedId}
            width="100%"
            height={height}
            src={asset?.filename || ""}
          />
        </div>
      </div>
    </section>
  );
};

export default EmbedBlock;
