import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import ColumnGrid from "../../ui/ColumnGrid";
import { ColumnGridTextItemBlock } from "../../lib/storyblok/types/blocks/ColumnGridTextItemBlock";
import { useLinks } from "../../lib/storyblok/useLinks";
import Button from "../../ui/Button";

const { Item } = ColumnGrid;

const ColumnGridTextItem: StoryblokBlockComponent<ColumnGridTextItemBlock> = ({
  _uid,
  title,
  markdown,
  buttonText,
  buttonLink,
  secondButtonText,
  secondButtonLink,
  style,
}) => {
  const resolveLink = useLinks();

  const isStyleInverted = style === "black" || style === "signature"; // Check if the style should be inverted

  return (
    <Item key={_uid} title={title} markdown={markdown}>
      {buttonText && buttonLink && (
        <Button
          href={resolveLink(buttonLink)}
          variant={isStyleInverted ? "inverted" : "default"} // Use the derived isStyleInverted value
          openInNewTab={buttonLink.linktype === "asset"}
        >
          {buttonText}
        </Button>
      )}
      {secondButtonText && secondButtonLink && (
        <Button
          href={resolveLink(secondButtonLink)}
          variant={isStyleInverted ? "inverted" : "default"} // Use the derived isStyleInverted value
          openInNewTab={secondButtonLink.linktype === "asset"}
        >
          {secondButtonText}
        </Button>
      )}
    </Item>
  );
};

export default ColumnGridTextItem;
