import React, { ReactNode, useState, useEffect, useRef } from "react";
import s from "./DesktopMenu.module.scss";

type Props = {
  children: ReactNode;
  activeItemIndex: number | null;
  navRef?: React.RefObject<HTMLElement>;
};

export const DesktopNav = ({ children, activeItemIndex, navRef }: Props) => {
  const [position, setPosition] = useState<number>(0);
  const [opacity, setOpacity] = useState<number>(0);
  const [isTransitioning, setIsTransitioning] = useState<boolean>(false);
  const internalNavRef = useRef<HTMLUListElement>(null);
  const prevActiveIndexRef = useRef<number | null>(null);

  useEffect(() => {
    const updateHighlight = () => {
      if (activeItemIndex !== null && internalNavRef.current) {
        const activeItem = internalNavRef.current.children[
          activeItemIndex
        ] as HTMLElement;
        if (activeItem) {
          const { offsetLeft, offsetWidth } = activeItem;
          const newPosition = offsetLeft + offsetWidth / 2;

          if (prevActiveIndexRef.current === null) {
            // First activation: fade in
            setPosition(newPosition);
            setOpacity(1);
          } else if (prevActiveIndexRef.current !== activeItemIndex) {
            // Transition between items
            setIsTransitioning(true);
            setPosition(newPosition);
          }

          prevActiveIndexRef.current = activeItemIndex;
        }
      } else if (prevActiveIndexRef.current !== null) {
        // Deactivation: fade out
        setOpacity(0);
        prevActiveIndexRef.current = null;
      }
    };

    updateHighlight();

    const handleResize = () => {
      if (activeItemIndex !== null && internalNavRef.current) {
        const activeItem = internalNavRef.current.children[
          activeItemIndex
        ] as HTMLElement;
        if (activeItem) {
          const { offsetLeft, offsetWidth } = activeItem;
          setPosition(offsetLeft + offsetWidth / 2);
        }
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [activeItemIndex]);

  const handleTransitionEnd = () => {
    setIsTransitioning(false);
  };

  const highlightStyle: React.CSSProperties = {
    left: `${position}px`,
    opacity: opacity,
    transition: isTransitioning ? "left 0.25s ease" : "opacity 0.25s ease",
  };

  return (
    <nav className={s.nav} ref={navRef}>
      <ul className={s.navList} ref={internalNavRef}>
        {children}
        <div
          className={s.highlight}
          style={highlightStyle}
          onTransitionEnd={handleTransitionEnd}
        />
      </ul>
    </nav>
  );
};
