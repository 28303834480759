import React from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { DualMediaBlockBlock } from "../../lib/storyblok/types/blocks/DualMediaBlockBlock";

import DualMediaBlockUi from "../../ui/DualMediaBlock";

const DualMediaBlock: StoryblokBlockComponent<DualMediaBlockBlock> = ({
  featured,
  reversed,
  latestArticle,
  featuredArticle,
  editorsPick,
  mostPopular,
  highlight,
  topArticles,
  style,
  outerSpacing,
  mediaBackground,
  imageKeepProportions,
  layout,
}) => {
  return (
    <DualMediaBlockUi
      style={style}
      outerSpacing={outerSpacing}
      mediaBackground={mediaBackground}
      imageKeepProportions={imageKeepProportions}
      layout={layout}
      featured={featured}
      reversed={reversed}
      latestArticle={latestArticle}
      featuredArticle={featuredArticle}
      topArticles={topArticles}
      editorsPick={editorsPick}
      mostPopular={mostPopular}
      highlight={highlight}
      typeformCopy={""}
      typeformID={""}
      typeformSize={"70"}
      typeformButtonStyle={"default"}
    />
  );
};

export default DualMediaBlock;
