/* eslint-disable */
import { useTranslation } from "next-i18next";
import { useCallback } from "react";
import { useRefinementList } from "react-instantsearch";
import FilterList from "../../ui/FilterList";

const RefinementFilterList = ({
  id,
  attribute,
  sort = "",
  hiddenFacets = [],
}) => {
  const { t } = useTranslation(["searchpage", "tags"]);
  const translateLabel = useCallback(
    (items) => {
      items = items.map((item) => ({
        ...item,
        label: t(`${item.value}`, { ns: "tags" }),
      }));
      if (sort === "reverse") return items.reverse();
      return items;
    },
    [sort]
  );

  const { items, refine } = useRefinementList({
    limit: 99,
    attribute,
    transformItems: translateLabel,
  });
  const handleChange = (refine: (value: string) => void, value: string) => () =>
    refine(value);
  if (items.length === 0) {
    return <></>;
  }
  return (
    <FilterList
      key={`filterList-${id}`}
      label={t(`categories.${id}`)}
      layout="expandable"
    >
      {items
        .filter((item) => !hiddenFacets.find((facet) => facet === item.value))
        .map(({ label, count, value, isRefined }) => (
          <FilterList.Filter
            key={label}
            amount={count}
            value={value}
            checked={isRefined}
            onChange={handleChange(refine, value)}
          >
            {label}
          </FilterList.Filter>
        ))}
    </FilterList>
  );
};
export default RefinementFilterList;
