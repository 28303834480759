import React, { ReactNode } from "react";
import cn from "classnames";
import s from "./MobileMenu.module.scss";

type Props = {
  isOpen: boolean;
  level: number;
  children: ReactNode;
};

export const MobileNavList = ({ isOpen, level, children }: Props) => {
  return (
    <ul
      className={cn(s.navList, {
        [s.isOpen]: isOpen,
        [s[`level-${level}`]]: true,
      })}
    >
      {children}
    </ul>
  );
};
