import cn from "classnames";
import { ReactNode } from "react";
import c from "../../styles/container.module.scss";

type Props = {
  size: "narrow" | "medium" | "wide" | "fluid";
  noGutter?: boolean;
  children: ReactNode;
};

const Container = ({ size, noGutter, children }: Props) => (
  <div className={cn(c[size], { [c.noGutter]: noGutter })}>{children}</div>
);

export default Container;
