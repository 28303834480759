import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { FinancialReportsTableBlock } from "../../lib/storyblok/types/blocks/FinancialReportsTableBlock";
import { default as FinancialReportsTableComponent } from "../../ui/FinancialReportsTable";
import React from "react";

const FinancialReportsTable: StoryblokBlockComponent<
  FinancialReportsTableBlock
> = ({ title, sections, spacing }) => {
  return (
    <FinancialReportsTableComponent
      title={title}
      spacing={spacing}
      sections={sections}
    />
  );
};

export default FinancialReportsTable;
