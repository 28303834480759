import cn from "classnames";
import { forwardRef } from "react";
import useMediaQuery from "../../../hooks/useMatchMedia";
import Button from "../../Button";

import s from "../AnchorMenu.module.scss";

type Props = {
  text: string;
  shortText: string;
  href: string;
};

const CTAItem = forwardRef<HTMLAnchorElement, Props>(
  ({ text, shortText, href }, ref) => {
    const isMobile = useMediaQuery("(max-width: 1024px)");

    return (
      <div className={cn(s.item, s.cta)}>
        <Button ref={ref} variant="cta" size="small" href={href} title={text}>
          {isMobile ? shortText : text}
        </Button>
      </div>
    );
  }
);

CTAItem.displayName = "CTAItem";

export default CTAItem;
