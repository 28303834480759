import cn from "classnames";
import Icon from "../../Icon";

import s from "../AnchorMenu.module.scss";

type Props = {
  onClick: () => void;
  hideOnDesktop?: boolean;
  className?: string;
  title: string;
};

const ExpandButton = ({
  onClick,
  hideOnDesktop = false,
  className,
  title,
}: Props) => (
  <button
    className={cn(s.expandButton, className, {
      [s.hideOnDesktop]: hideOnDesktop,
    })}
    onClick={onClick}
    title={title}
  >
    <Icon icon="ellipsis-filled" color={s.iconcolor} />
  </button>
);

export default ExpandButton;
