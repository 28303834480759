import { createContext, ReactNode, useContext } from "react";
import { ViewModel } from "../viewModel/createViewModel";

const viewModelContext = createContext<ViewModel>({} as ViewModel);

type Props = {
  viewModel: ViewModel;
  children: ReactNode;
};

export const ViewModelProvider = ({ children, viewModel }: Props) => {
  return (
    <viewModelContext.Provider value={viewModel}>
      {children}
    </viewModelContext.Provider>
  );
};

export const useViewmodel = () => useContext(viewModelContext);
