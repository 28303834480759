import React from "react";
import s from "./ProgressIndicator.module.scss";

type ProgressIndicatorProps = {
  current: number;
  total: number;
  children?: string;
};

const ProgressIndicator = ({
  current,
  total,
  children,
}: ProgressIndicatorProps) => {
  const value = String(Math.round((current / total) * 100));

  return (
    <div className={s.progressIndicator}>
      {children && (
        <label className={s.label} htmlFor="progress-indicator">
          {children}
        </label>
      )}
      <progress
        id="progress-indicator"
        className={s.progress}
        max="100"
        value={value}
      />
    </div>
  );
};

export default ProgressIndicator;
