import { ReactNode } from "react";
import cn from "classnames";

import s from "./LocalAlert.module.scss";
import c from "../../styles/container.module.scss";

type Props = {
  style: "white" | "red" | "black" | "signature";
  icon: ReactNode;
  title: string;
  text: string;
  button: ReactNode;
  padding: "topAndBottom" | "onlyTop" | "onlyBottom" | "none";
};

const LocalAlert = ({
  style,
  icon,
  title,
  text,
  button,
  padding = "topAndBottom",
}: Props) => {
  return (
    <div className={cn(s.localAlert, c.wide, s[style], s[padding])}>
      <div className={s.content}>
        {title && (
          <div className={s.titleWrapper}>
            <span className={s.icon}>{icon && icon}</span>
            <h3>{title}</h3>
          </div>
        )}
        {text && <p className={cn({ [s.marginBottom]: button })}>{text}</p>}
        {button && button}
      </div>
    </div>
  );
};

export default LocalAlert;
