import React, { useEffect, useMemo, useState } from "react";
import { useDatablockWidget } from "../../../hooks/mfn/useDatablockWidget";
import { Tabs, Tab } from "../../Common/Tabs";
import cn from "classnames";
import c from "../../../styles/container.module.scss";
import s from "./Datablock.InsiderTransactions.module.scss";
import ss from "./Datablock.SharedStyles.module.scss";
import tokens from "../tokens.json";

type Props = {
  locale: string;
  demo: boolean;
};

const InsidersTransactions: React.FC<Props> = ({ locale, demo }) => {
  const [activeTab, setActiveTab] = useState(0);
  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  const { initializeWidgets, cleanupWidgets } = useDatablockWidget();

  const widgetConfigs = useMemo(
    () => [
      {
        widget: "insider-positions",
        widgetId: "insider-positions",
        token: tokens.insiderPositions,
        label: locale === "en" ? "Positions" : "Positioner",
      },
      {
        widget: "insider-transactions",
        widgetId: "insider-transactions-buy-sell",
        token: tokens.insiderTransactionsBuySell,
        label: locale === "en" ? "Buy and sell" : "Köp och sälj",
      },
      {
        widget: "insider-transactions",
        widgetId: "insider-transactions-other",
        token: tokens.insiderTransactionsOther,
        label: locale === "en" ? "Other" : "Övrigt",
      },
    ],
    [locale]
  );

  useEffect(() => {
    initializeWidgets(widgetConfigs, locale, demo);
    return () => cleanupWidgets(widgetConfigs, locale);
  }, [locale, demo, widgetConfigs, initializeWidgets, cleanupWidgets]);

  return (
    <>
      <Tabs activeIndex={activeTab} alignment={"alignLeft"}>
        {widgetConfigs.map((tab, i) => (
          <Tab
            key={tab.widgetId}
            index={i}
            title={tab.label}
            isActive={activeTab === i}
            onClick={() => handleTabClick(i)}
          />
        ))}
      </Tabs>
      <div className={cn(ss.tabsWrapper, ss.contentWrapper)}>
        {widgetConfigs.map((tab, j) => (
          <div
            key={tab.widgetId}
            className={cn(ss.tab, { [ss.activeTab]: activeTab === j })}
          >
            <div
              id={`${tab.widgetId}-${locale}`}
              className={cn(c.xWide, s.insiderTransactions)}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default InsidersTransactions;
