import React from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { ButtonBlock } from "../../lib/storyblok/types/blocks/ButtonBlock";

import ButtonUi from "../../ui/Button";
import { useLinks } from "../../lib/storyblok/useLinks";

const Button: StoryblokBlockComponent<ButtonBlock> = ({
  buttonText,
  buttonLink,
  buttonType = "default",
}) => {
  const resolveLink = useLinks();

  return (
    <ButtonUi variant={buttonType} href={resolveLink(buttonLink)}>
      {buttonText}
    </ButtonUi>
  );
};

export default Button;
