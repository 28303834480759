import React, { forwardRef, HTMLProps, ReactNode } from "react";
import cn from "classnames";
import s from "./DesktopMenu.module.scss";

type Props = {
  children: ReactNode;
  activeStyle: React.CSSProperties;
} & HTMLProps<HTMLUListElement>;

export const DesktopSubNavigationPanelList = forwardRef<
  HTMLUListElement,
  Props
>(({ children, activeStyle, ...props }, ref) => {
  return (
    <ul className={cn(s.DesktopSubNavigationPanelList)} ref={ref} {...props}>
      {children}
      <div className={s.highlight} style={activeStyle} />
    </ul>
  );
});

DesktopSubNavigationPanelList.displayName = "DesktopSubNavigationPanelList";
