import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { SliderBlock } from "../../lib/storyblok/types/blocks/SliderBlock";
import SliderUi from "../../ui/Slider";

const Slider: StoryblokBlockComponent<SliderBlock> = ({
  slides,
  paginationOptions = "pagination",
  arrowNavigationVisibility = false,
  contentPosition = "left",
}) => {
  return (
    <SliderUi
      slides={slides}
      paginationOptions={paginationOptions}
      arrowNavigationVisibility={arrowNavigationVisibility}
      contentPosition={contentPosition}
    />
  );
};

export default Slider;
