import SearchPageGrid from "../../ui/PageTemplates/SearchPage";
import { Configure, InstantSearch } from "react-instantsearch";
import SearchBox from "../Nestable/SearchBox";
import InfiniteHits from "../Nestable/InfiniteHits";
import RefinementList from "../Nestable/RefinementList";
import { useRouter } from "next/router";
import { initAlgoliaSearch } from "../../lib/utils/AlgoliaSearchHelpers";
import { useMemo } from "react";

type Props = {
  indices;
  children;
  locale: string;
};

const SearchPage = ({ indices, children, locale }: Props) => {
  const searchClient = useMemo(() => initAlgoliaSearch(), []);
  const { query } = useRouter();
  const initialValue = typeof query.q === "string" ? query.q : undefined;
  return (
    <InstantSearch indexName={indices[locale]} searchClient={searchClient}>
      <Configure
        hitsPerPage={10}
        attributesToSnippet={["content:40", "metaDescription:40"]}
        query={initialValue}
      />
      {children}
      <SearchPageGrid>
        <SearchPageGrid.Input>
          <SearchBox defaultValue={initialValue} />
        </SearchPageGrid.Input>
        <SearchPageGrid.Filter>
          <RefinementList />
        </SearchPageGrid.Filter>
        <SearchPageGrid.Body>
          <InfiniteHits />
        </SearchPageGrid.Body>
      </SearchPageGrid>
    </InstantSearch>
  );
};

export default SearchPage;
