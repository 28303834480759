import { ReactNode } from "react";
import { Markdown } from "../../../lib/storyblok/types/blocks/MarkdownBlock";
import cn from "classnames";
import s from "../ColumnGrid.module.scss";
import c from "../../Editorial/components/EditorialCopy.module.scss";
import MarkdownUi from "../../Markdown";

type Props = {
  image?: ReactNode;
  video?: ReactNode;
  title: string;
  markdown: Markdown;
  children: ReactNode;
};

const Item = ({ image, video, title, markdown, children }: Props) => (
  <article className={s.item}>
    {(image || video) && (
      <div className={s.imageContainer}>
        {image && image}
        {video && video}
      </div>
    )}
    {title && (
      <h3 className={cn({ [s.textOnly]: !image && !video })}>{title}</h3>
    )}
    {markdown && (
      <div className={cn(s.markdown, c.copy)}>
        <MarkdownUi>{markdown}</MarkdownUi>
      </div>
    )}
    <div className={s.buttons}>{children}</div>
  </article>
);

export default Item;
