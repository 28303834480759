import React from "react";
import cn from "classnames";
import s from "./Loader.module.scss";

type LoaderColor = "black" | "white" | "peach" | "violet" | "blue" | "default";
type LoaderSize = "small" | "medium" | "large";

interface LoaderProps {
  isLoading?: boolean;
  size?: LoaderSize;
  variant?: LoaderColor;
}

const Loader: React.FC<LoaderProps> = ({
  isLoading = true,
  size = "medium",
  variant = "default",
}) => {
  if (!isLoading) return null;

  return (
    <div className={s.loader} role="status" aria-label="Loading">
      <div className={cn(s.ellipsis, s[`ellipsis-${size}`], s[variant])}>
        {[...Array(4)].map((_, index) => (
          <div key={index} />
        ))}
      </div>
    </div>
  );
};

export default React.memo(Loader);
