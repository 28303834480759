import cn from "classnames";
import s from "../ContentGrid.module.scss";
import { useTranslation } from "next-i18next";

type Props = {
  title: string;
  eventLink: string;
  dateTime: string;
  day: string;
  month: string;
  tags: string[];
  location: string;
};

const ListEventItem = ({
  title,
  eventLink,
  dateTime,
  day,
  month,
  tags,
  location,
}: Props) => {
  const { t } = useTranslation();
  return (
    <li className={s.listEventItem}>
      <time className={cn(s.date)} dateTime={dateTime}>
        <span className={s.day}>{day}</span>
        <span className="label">{month}</span>
      </time>
      <div className={s.wrapper}>
        <a href={eventLink} title={title}>
          {title}
        </a>
        <div className={s.details}>
          {tags?.length > 0 &&
            tags.map((tag, i) => (
              <span key={i} className={s.tag}>
                {t(tag, { ns: "tags" })}
              </span>
            ))}
        </div>
        {location && <p className={cn("label", s.location)}>{location}</p>}
      </div>
    </li>
  );
};

export default ListEventItem;
