import { DownloadsLinkItemBlock } from "../../lib/storyblok/types/blocks/DownloadsLinkItemBlock";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import Downloads from "../../ui/Downloads/Downloads";
import { useLinks } from "../../lib/storyblok/useLinks";

const { LinkItem } = Downloads;

const DownloadsLinkItem: StoryblokBlockComponent<DownloadsLinkItemBlock> = ({
  link,
  icon,
  title,
  subtitle,
}) => {
  const resolveLink = useLinks();

  return (
    <LinkItem
      href={resolveLink(link)}
      icon={icon}
      title={title}
      subtitle={subtitle}
    />
  );
};

export default DownloadsLinkItem;
