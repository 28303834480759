import React, { ReactNode } from "react";
import s from "./Tags.module.scss";

import TagList from "./components/TagList";
import Tag from "./components/Tag";

type Props = {
  children: ReactNode;
};

const subComponents = { TagList, Tag };
type SubComponents = typeof subComponents;

const Tags: CWS<Props, SubComponents> = ({ children }) => (
  <section className={s.tags}>
    <ul>{children}</ul>
  </section>
);

Tags.TagList = TagList;
Tags.Tag = Tag;

export default Tags;
