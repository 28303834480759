import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import { ColumnGridImageItemBlock } from "../../lib/storyblok/types/blocks/ColumnGridImageItemBlock";
import ColumnGrid from "../../ui/ColumnGrid";
import ImageVaultImage from "../ImageVaultImage";
import { useLinks } from "../../lib/storyblok/useLinks";
import { imageSizes } from "../../lib/utils/imageSizes";
import Button from "../../ui/Button";

const { Item } = ColumnGrid;

const ColumnGridImageItem: StoryblokBlockComponent<
  ColumnGridImageItemBlock
> = ({
  _uid,
  image,
  title,
  markdown,
  buttonText,
  buttonLink,
  secondButtonText,
  secondButtonLink,
  style,
}) => {
  const resolveLink = useLinks();

  const isStyleInverted = style === "black" || style === "signature"; // Check if the style should be inverted

  return (
    <Item
      key={_uid}
      image={
        image?.item && (
          <ImageVaultImage
            image={image}
            layout="fill"
            sizes={imageSizes("100vw", { tablet: "640px" })}
          />
        )
      }
      title={title}
      markdown={markdown}
    >
      {buttonText && buttonLink && (
        <Button
          href={resolveLink(buttonLink)}
          variant={isStyleInverted ? "inverted" : "default"} // Use the derived isStyleInverted value
        >
          {buttonText}
        </Button>
      )}
      {secondButtonText && secondButtonLink && (
        <Button
          href={resolveLink(secondButtonLink)}
          variant={isStyleInverted ? "inverted" : "default"} // Use the derived isStyleInverted value
        >
          {secondButtonText}
        </Button>
      )}
    </Item>
  );
};

export default ColumnGridImageItem;
