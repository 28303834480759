import React, { HTMLAttributes } from "react";
import cn from "classnames";

import s from "./Checkbox.module.scss";
import f from "../CTAForm/CTAForm.module.scss";

import Icon from "../Icon";

export type CheckboxItem = {
  label: string;
  value: string;
  id: string;
  disabled?: boolean;
  checked?: boolean;
};
type Props = HTMLAttributes<HTMLInputElement> & {
  label?: string;
  name: string;
  inverted?: boolean;
  disabled?: boolean;
  description?: string;
  columns?: "two";
  items: CheckboxItem[];
};

const Checkbox = ({
  label,
  name,
  inverted,
  description,
  columns,
  items,
}: Props) => {
  return (
    <fieldset
      className={cn(s.checkboxGroup, f.inputGroup, {
        [f.twoColumns]: columns === "two",
        [f.inverted]: inverted,
        [s.inverted]: inverted,
      })}
    >
      {(label || description) && (
        <div>
          {label && <span className={f.label}>{label}</span>}
          {description && <span className={f.description}>{description}</span>}
        </div>
      )}
      {items.map(({ disabled, label, checked, value, id }, index) => {
        return (
          <label
            key={index}
            className={cn(s.checkboxLabel, {
              [s.inverted]: inverted,
              [s.disabled]: disabled,
            })}
            htmlFor={id}
          >
            <input
              className={s.visuallyHidden}
              disabled={disabled}
              defaultChecked={checked}
              type="checkbox"
              name={name}
              value={value}
              id={id}
            />
            <figure
              className={cn(s.checkbox, {
                [s.inverted]: inverted,
                [s.disabled]: disabled,
              })}
            >
              <Icon
                className={cn(s.checkmark, { [s.inverted]: inverted })}
                icon="checkmark"
                width={18}
              />
            </figure>
            <span>{label}</span>
          </label>
        );
      })}
    </fieldset>
  );
};

export default Checkbox;
