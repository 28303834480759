import React from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import SubscriptionFormUI from "../../ui/SubsciptionForm";
import { SubscriptionFormBlock } from "../../lib/storyblok/types/blocks/SubscriptionFormBlock";
import { useTranslation } from "next-i18next";

const SubscriptionForm: StoryblokBlockComponent<SubscriptionFormBlock> = ({
  title,
  preamble,
  spacing,
  thankYouMarkdown,
  subscriptionUniqueIdentifier,
  recaptchaSitekey,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <SubscriptionFormUI
        title={title}
        preamble={preamble}
        spacing={spacing}
        thankYouMarkdown={thankYouMarkdown}
        subscriptionUniqueIdentifier={subscriptionUniqueIdentifier}
        recaptchaSitekey={recaptchaSitekey}
        languageLabel={t("language")}
        englishLabel={t("english")}
        swedishLabel={t("swedish")}
        infoTypeLabel={t("info-type")}
        annualReportsLabel={t("annual-reports")}
        interimReportsLabel={t("interim-reports")}
        pressReleasesLabel={t("press-releases")}
        newsLetterLabel={t("newsletter")}
        nameLabel={t("name")}
        emailLabel={t("email")}
        smsLabel={t("sms")}
        submitLabel={t("submit")}
      />
    </>
  );
};

export default SubscriptionForm;
