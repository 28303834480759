import React from "react";
import s from "./FilterSearch.module.scss";

const FilterSearch = ({ value, onChange }) => {
  return (
    <input
      type="search"
      placeholder="Search publications"
      value={value}
      onChange={onChange}
      className={s.searchInput}
    />
  );
};

export default FilterSearch;
