import cn from "classnames";
import React, { ReactNode, useRef } from "react";
import { useIsOverflow } from "../../../hooks/useIsOverflow";
import { ExpandButton } from "../../AnchorMenu";
import { useTranslation } from "next-i18next";
import s from "../FeaturedTabs.module.scss";

type Props = {
  mobileMenu: ReactNode;
  isMobileMenuOpen: boolean;
  setIsMobileMenuOpen: () => void;
  children: ReactNode;
};

const TabList = ({
  mobileMenu,
  isMobileMenuOpen,
  setIsMobileMenuOpen,
  children,
}: Props) => {
  const listRef = useRef<HTMLUListElement>(null);
  const isOverflow = useIsOverflow(listRef, "horizontal");
  const { t } = useTranslation();

  return (
    <div className={s.tabList}>
      <ul ref={listRef} className={cn({ [s.overflow]: isOverflow })}>
        {children}
      </ul>
      {isOverflow && (
        <ExpandButton
          title={t("unlock-the-feature-of")}
          onClick={setIsMobileMenuOpen}
          className={s.expandButton}
        />
      )}
      {isMobileMenuOpen && mobileMenu && mobileMenu}
    </div>
  );
};

export default TabList;
