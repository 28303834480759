import LinkWrapper from "../../../components/Nestable/LinkWrapper/LinkWrapper";
import cn from "classnames";
import s from "../Footer.module.scss";

type Props = {
  locale: string;
  privacyPolicyText: string;
  privacyPolicyLink: string;
  termsOfUseText: string;
  termsOfUseLink: string;
  copyright: string;
  allRightsReserved: string;
  icpLicense: string;
};

const getCurrentYear = (): number => {
  return new Date().getFullYear();
};

const FooterFooter = ({
  locale,
  privacyPolicyText,
  privacyPolicyLink,
  termsOfUseText,
  termsOfUseLink,
  copyright,
  allRightsReserved,
  icpLicense,
}: Props) => {
  return (
    <>
      <ul className={cn(s.footerFooter)}>
        <li>
          {privacyPolicyText && privacyPolicyLink && (
            <LinkWrapper href={privacyPolicyLink} text={privacyPolicyText} />
          )}
          {termsOfUseText && termsOfUseLink && (
            <LinkWrapper href={termsOfUseLink} text={termsOfUseText} />
          )}
        </li>
        <li>
          <span>
            {copyright} 2001-{getCurrentYear()} Tobii &bull; {allRightsReserved}
          </span>
          {locale == "zh" && <span> &bull; {icpLicense} </span>}
        </li>
      </ul>
    </>
  );
};

export default FooterFooter;
