import React from "react";
import { StoryblokBlockComponent } from "../../lib/storyblok/types/StoryblokComponent";
import PreambleUi from "../../ui/Preamble";
import { PreambleBlock } from "../../lib/storyblok/types/blocks/PreambleBlock";

const Preamble: StoryblokBlockComponent<PreambleBlock> = ({
  title,
  text,
  titleType,
  noTopPadding,
  noBottomPadding,
}) => {
  return (
    <PreambleUi
      title={title}
      labelAs={titleType || "h2"}
      text={text}
      noTopPadding={noTopPadding}
      noBottomPadding={noBottomPadding}
    />
  );
};

export default Preamble;
