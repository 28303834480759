import Image from "next/image";
import s from "../Details.module.scss";

type Props = {
  icon: string;
  title: string;
  subtitle?: string;
  eventDate?: string;
};

const Detail = ({ icon, title, subtitle }: Props) => {
  return (
    <li className={s.detail}>
      {icon && (
        <Image src={`/svg/icons/${icon}.svg`} width={24} height={24} alt="" />
      )}
      <div className={s.wrapper}>
        <p className={s.title}>{title}</p>
        {subtitle && <p className={s.subtitle}>{subtitle}</p>}
      </div>
    </li>
  );
};

export default Detail;
