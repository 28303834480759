import cn from "classnames";
import s from "../EventCalendar.module.scss";
import { useTranslation } from "next-i18next";

type Props = {
  dateTime: string;
  day: string;
  month: string;
  year: string;
  title: string;
  eventLink: string;
  description: string;
  tags: string[];
  location: string;
  isPassed: boolean;
};

const Event = ({
  dateTime,
  day,
  month,
  year,
  title,
  eventLink,
  description,
  tags,
  location,
  isPassed,
}: Props) => {
  const { t } = useTranslation();
  return (
    <li className={s.event}>
      <time
        className={cn(s.date, { [s.passedDate]: isPassed })}
        dateTime={dateTime}
      >
        <span className={s.day}>{day}</span>
        <span className="label">{month}</span>
        {isPassed && <span className="label">{year}</span>}
      </time>
      <div className={s.wrapper}>
        <a href={eventLink} className={s.title} title={title}>
          {title}
        </a>
        {description && <p className={s.description}>{description}</p>}
        <div className={s.details}>
          {tags?.length > 0 &&
            tags.map((tag, i) => (
              <span key={i} className={s.tag}>
                {t(tag, { ns: "tags" })}
              </span>
            ))}
        </div>
        {location && <p className={cn("label", s.location)}>{location}</p>}
      </div>
    </li>
  );
};

export default Event;
