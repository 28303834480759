import React from "react";
import { DesktopSubNavigationItem } from "../../../../ui/Header";
import { useLinks } from "../../../../lib/storyblok/useLinks";
import { LinkFieldtype } from "../../../../lib/storyblok/types/fieldtypes/linkFieldtype";
import { IconType } from "../../../../ui/Icon/Icon";

type SubNavigationItemListProps = {
  items: Array<{
    _uid: string;
    title: string;
    description: string;
    link: LinkFieldtype;
    icon?: IconType;
  }>;
};

const SubNavigationItemList: React.FC<SubNavigationItemListProps> = ({
  items,
}) => {
  const resolveLink = useLinks();

  return (
    <>
      {items.map((item) => (
        <DesktopSubNavigationItem
          key={item._uid}
          title={item.title}
          description={item.description}
          link={resolveLink(item.link)}
          icon={item.icon}
        />
      ))}
    </>
  );
};

export default SubNavigationItemList;
