import React from "react";
import { useTranslation } from "next-i18next";
import cn from "classnames";
import s from "./Datablock.InvestorGrid.module.scss";
import Icon from "../../Icon/Icon";
import { useShareTicker } from "../../../hooks/mfn/useShareTicker";
import tokens from "../tokens.json";

type Props = {
  locale: string;
};

const ShareTicker: React.FC<Props> = ({ locale }) => {
  const { t } = useTranslation();
  const { stockData, isLoading, error, lastFetched } = useShareTicker(
    tokens.shareTicker,
    locale
  );

  if (error) {
    return (
      <div className={cn(s.item, s.shareTicker)}>
        <div className={s.data}>
          <div className={s.error}>Unable to load stock data</div>
        </div>
      </div>
    );
  }

  if (isLoading || !stockData) {
    return (
      <div className={cn(s.item, s.shareTicker)}>
        <div className={s.loading}>{t("Loading...")}</div>
      </div>
    );
  }

  const getDirectionIcon = (delta: number) => {
    if (delta > 0) return "arrow-up";
    if (delta < 0) return "arrow-down";
    return "arrow-right";
  };

  return (
    <div className={cn(s.item, s.shareTicker)}>
      <Icon
        icon={getDirectionIcon(stockData.deltaToday)}
        color={s.iconcolor}
        className={s.icon}
      />
      <p>{stockData.stockSymbol}</p>
      <p>
        {stockData.latestPrice} {stockData.currency}:{" "}
        {(stockData.deltaToday * 100).toFixed(2)}%
      </p>
      <p className={s.latestUpdate}>
        {t("latest-update")}: {lastFetched}
      </p>
    </div>
  );
};

export default ShareTicker;
