/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo, useState } from "react";
import { ViewModel } from "../viewModel/createViewModel";
import { Story } from "./types/story";

declare global {
  interface Window {
    StoryblokBridge: any;
  }
}

export function useStoryblokBridge(originalVm: ViewModel) {
  const [storyFromBridge, setStoryFromBridge] = useState<Story | null>(null);

  const story = useMemo(() => {
    return storyFromBridge ?? (originalVm ? originalVm.story : null);
  }, [originalVm?.story, storyFromBridge]); // eslint-disable-line react-hooks/exhaustive-deps

  const initEventListeners = useCallback(() => {
    const { StoryblokBridge } = window;
    if (typeof StoryblokBridge !== "undefined") {
      const bridge = new StoryblokBridge({
        language: "default",
      });
      bridge.on(["change", "published"], () => location.reload());
      bridge.on("input", (event: any) => {
        if (event.story.uuid === story?.uuid) {
          setStoryFromBridge(event.story);
        }
      });
    }
  }, [story?.uuid]);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_PREVIEW_MODE?.toUpperCase() !== "TRUE") return;
    addBridge(initEventListeners);
  }, [initEventListeners]);

  return { ...originalVm, story };
}

// appends the bridge script tag to our document
// see https://www.storyblok.com/docs/guide/essentials/visual-editor#installing-the-storyblok-js-bridge
const addBridge = (callback: () => void) => {
  const existingScript = document.getElementById("storyblokBridge");
  if (!existingScript) {
    const script = document.createElement("script");
    script.src = "//app.storyblok.com/f/storyblok-v2-latest.js";
    script.id = "storyblokBridge";
    document.body.appendChild(script);
    script.onload = () => {
      callback();
    };
  } else {
    callback();
  }
};
