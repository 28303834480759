import React, { ReactNode } from "react";
import cn from "classnames";
import s from "./ShortFeatures.module.scss";
import c from "../../styles/container.module.scss";
import bs from "../../styles/block.spacing.module.scss";

import Feature from "./components/Feature";
import BlockBackground from "../Common/BlockBackground";
import BlockIntro from "../Common/BlockIntro";

type Props = {
  title: string;
  text: string;
  buttons?: ReactNode;
  children: ReactNode;
  style: string;
  featureStyle: string;
  featureBoxed?: boolean;
  spacing: string;
  outerSpacing: string;
  columns: string;
  contentAlignment: string;
  backgroundImage?: ReactNode;
  backgroundOpacity: number;
  width: string;
};

const subComponents = { Feature };
type SubComponents = typeof subComponents;

const ShortFeatures: CWS<Props, SubComponents> = ({
  title,
  text,
  buttons,
  children,
  style,
  featureStyle,
  featureBoxed,
  spacing,
  outerSpacing,
  columns,
  width,
  contentAlignment,
  backgroundImage,
  backgroundOpacity,
}) => {
  const featureVariant = (style) => {
    const invertedStyles = [
      "signature",
      "black",
      "blue",
      "oceanBlue",
      "peach",
      "violet",
      "gradientRadialTopBlueSignature",
      "gradientRadialCenterBlueSignature",
      "gradientLinearBlueSignature",
    ];
    if (invertedStyles.includes(style)) {
      return "inverted";
    }
    return "default";
  };

  const widthStyle =
    width === "fluid" || width === "featured" ? c.xWide : s.flexibleWidth;

  return (
    <>
      <section
        className={cn(s.shortFeatures, s[spacing], bs[outerSpacing], c[width])}
      >
        <div className={cn(s.background)}>
          {backgroundImage && (
            <div className={cn(s.backgroundImage)}>
              <div className={s.imageContainer}>{backgroundImage}</div>
            </div>
          )}
        </div>

        <BlockBackground style={style} opacity={backgroundOpacity} />

        <div className={cn(s.shortFeaturesInner)}>
          <BlockIntro
            alignment={contentAlignment}
            style={style}
            title={title}
            description={text}
            buttons={buttons}
            featured={""}
            flexibleWidth={""}
          />

          <div className={widthStyle}>
            <ul
              className={cn(
                s.features,
                s[columns],
                s[featureVariant(style)],
                s[featureStyle],
                {
                  [s.featureBoxed]: featureBoxed,
                }
              )}
            >
              {children}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

ShortFeatures.Feature = Feature;

export default ShortFeatures;
