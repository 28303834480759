import React, { ReactNode } from "react";
import s from "../TechSpec.module.scss";

type Props = {
  children: ReactNode;
};

const TableNote = ({ children }: Props) => (
  <span className={s.tableNote}>{children}</span>
);

export default TableNote;
