import { ReactNode } from "react";
import s from "../ContentGrid.module.scss";

type Props = {
  children: ReactNode;
};

const Container = ({ children }: Props) => (
  <div className={s.container}>{children}</div>
);

export default Container;
