import React, { useState } from "react";
import cn from "classnames";
import s from "../DisclaimerBlock.module.scss";
import sm from "../../../ui/Select/Select.module.scss";
import Icon from "../../Icon";

type DropdownProps = {
  options: string[];
  onChange: (value: string) => void;
};

const Dropdown: React.FC<DropdownProps> = ({ options, onChange }) => {
  const [selected, setSelected] = useState("");

  const handleChange = (e) => {
    setSelected(e.target.value);
    onChange(e.target.value);
  };
  return (
    <>
      <select
        className={cn(s.dropdown, sm.select, sm.medium)}
        value={selected}
        onChange={handleChange}
      >
        {options.map((option) => (
          <option key={option} value={option} className={s.option}>
            {option}
          </option>
        ))}
      </select>
      <Icon
        className={s.icon}
        icon="caret-down"
        color={"black"}
        width={undefined}
      />
    </>
  );
};

export default Dropdown;
