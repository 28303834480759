// Node Types
export type RichTextNodeType =
  | "doc"
  | "paragraph"
  | "heading"
  | "bullet_list"
  | "ordered_list"
  | "list_item"
  | "text"
  | "code_block"
  | "image"
  | "blockquote"
  | "horizontal_rule"
  | "hard_break";

// Mark Types
export type RichTextMarkType =
  | "bold"
  | "italic"
  | "underline"
  | "strike"
  | "code"
  | "link"
  | "styled"
  | "textStyle"
  | "highlight";

// Link Types
export type RichTextLinkType = "story" | "asset" | "url" | "email";

// Text Alignment
export type TextAlignment = "left" | "center" | "right" | "justify";

// Link Attributes
export interface RichTextLinkAttributes {
  href: string;
  target?: "_blank" | "_self";
  linktype: RichTextLinkType;
  uuid?: string;
  anchor?: string;
  rel?: string;
  title?: string;
  cached_url?: string;
}

// Style Attributes
export interface RichTextStyleAttributes {
  color?: string;
  backgroundColor?: string;
  textAlign?: TextAlignment;
  fontFamily?: string;
  fontSize?: string;
  fontWeight?: string | number;
  lineHeight?: string | number;
  letterSpacing?: string;
  textDecoration?: string;
  textTransform?: "none" | "capitalize" | "uppercase" | "lowercase";
}

// Image Attributes
export interface RichTextImageAttributes {
  src: string;
  alt?: string;
  title?: string;
  width?: number;
  height?: number;
  loading?: "lazy" | "eager";
  caption?: string;
}

// Heading Attributes
export interface RichTextHeadingAttributes {
  level: 1 | 2 | 3 | 4 | 5 | 6;
  id?: string;
  class?: string;
}

// Code Block Attributes
export interface RichTextCodeBlockAttributes {
  class?: string;
  language?: string;
}

// Base Node Attributes
export interface RichTextBaseNodeAttributes {
  id?: string;
  class?: string;
  dataAttribute?: Record<string, string>;
}

// Combined Mark Attributes
export interface RichTextMarkAttributes {
  link?: RichTextLinkAttributes;
  style?: RichTextStyleAttributes;
  heading?: RichTextHeadingAttributes;
  code?: RichTextCodeBlockAttributes;
  color?: string;
  backgroundColor?: string;
  textAlign?: TextAlignment;
}

// Base Node Type
export interface RichTextBaseNode {
  type: RichTextNodeType;
}

// Specific Node Types
export interface RichTextTextNode extends Omit<RichTextBaseNode, "type"> {
  type: "text";
  text: string;
  marks?: Array<{
    type: RichTextMarkType;
    attrs?: RichTextMarkAttributes;
  }>;
}

export interface RichTextImageNode extends Omit<RichTextBaseNode, "type"> {
  type: "image";
  attrs: RichTextImageAttributes & Partial<RichTextBaseNodeAttributes>;
}

export interface RichTextHeadingNode extends Omit<RichTextBaseNode, "type"> {
  type: "heading";
  attrs: RichTextHeadingAttributes & Partial<RichTextBaseNodeAttributes>;
}

export interface RichTextCodeBlockNode extends Omit<RichTextBaseNode, "type"> {
  type: "code_block";
  attrs: RichTextCodeBlockAttributes & Partial<RichTextBaseNodeAttributes>;
}

export interface RichTextContentNode extends Omit<RichTextBaseNode, "type"> {
  type: Exclude<RichTextNodeType, "text" | "image" | "heading" | "code_block">;
  attrs?: RichTextBaseNodeAttributes;
  content?: RichTextNode[];
}

// Main Rich Text Types
export type RichTextNode =
  | RichTextTextNode
  | RichTextImageNode
  | RichTextHeadingNode
  | RichTextCodeBlockNode
  | RichTextContentNode;

export interface StoryblokRichTextFieldtype {
  type: "doc";
  content: RichTextNode[];
}

// Helper type for component props
export type RichTextContent =
  | StoryblokRichTextFieldtype
  | string
  | null
  | undefined;

// Utility type for checking if content exists
export const isRichTextContent = (
  content: unknown
): content is StoryblokRichTextFieldtype => {
  return (
    typeof content === "object" &&
    content !== null &&
    "type" in content &&
    "content" in content &&
    Array.isArray((content as StoryblokRichTextFieldtype).content)
  );
};
