import { ReactNode } from "react";
import cn from "classnames";
import s from "./ImageGalleryCard.module.scss";

type Props = {
  image: ReactNode;
  tags?: ReactNode;
  title: string | ReactNode;
  details: string[];
  link: ReactNode;
  children?: ReactNode;
};

const FilterCard = ({ image, tags, title, details, link, children }: Props) => {
  return (
    <div className={s.filterCard}>
      {image && <div className={s.imageContainer}>{image}</div>}
      {tags && <ul className={s.tagList}>{tags}</ul>}
      <h3 className={s.title}>{title}</h3>
      {details && (
        <ul className={s.details}>
          {details.map((d, i) => (
            <li key={d} className={cn({ [s.divider]: i > 0 })}>
              {d}
            </li>
          ))}
        </ul>
      )}

      {children && <div className={s.body}>{children}</div>}
      <>{link}</>
    </div>
  );
};

export default FilterCard;
