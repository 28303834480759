import React, { forwardRef, ReactNode } from "react";
import { motion, HTMLMotionProps } from "framer-motion";
import cn from "classnames";
import s from "./DesktopMenu.module.scss";

type Props = {
  title: string;
  index: number;
  children: ReactNode;
  icon?: ReactNode;
  animateSidebar: boolean;
} & HTMLMotionProps<"div">;

export const DesktopSubNavigationSidebar = forwardRef<HTMLDivElement, Props>(
  ({ title, icon, children, animateSidebar, index, ...props }, ref) => {
    return (
      <motion.div
        initial={{ opacity: 0, x: -16 }}
        animate={animateSidebar ? { opacity: 1, x: 0 } : { opacity: 0, x: -16 }}
        transition={{ duration: 0.3, delay: 0.3 }}
        className={cn(s.DesktopSubNavigationSidebar)}
        key={index}
        ref={ref}
        role="navigation"
        aria-label={title}
        {...props}
      >
        <div className={cn(s.header)}>
          {icon}
          {title && <span className={s.title}>{title}</span>}
        </div>
        <ul className={cn(s.list)} role="menu">
          {children}
        </ul>
      </motion.div>
    );
  }
);

DesktopSubNavigationSidebar.displayName = "DesktopSubNavigationSidebar";
