import React, { forwardRef, HTMLProps, ReactNode } from "react";
import cn from "classnames";
import s from "./DesktopMenu.module.scss";

type Props = {
  children: ReactNode;
  hasSidebar: boolean;
} & HTMLProps<HTMLDivElement>;

export const DesktopSubNavigationPanelItemWrapperInner = forwardRef<
  HTMLDivElement,
  Props
>(({ children, hasSidebar }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(s.DesktopSubNavigationPanelWrapperInner, {
        [s.hasSidebar]: hasSidebar,
      })}
      role="region"
      aria-label="Sub-navigation panel"
    >
      {children}
    </div>
  );
});

DesktopSubNavigationPanelItemWrapperInner.displayName =
  "DesktopSubNavigationPanelItemWrapperInner";
