import { useCallback, useEffect, useRef } from "react";

const useExpandable = <T extends HTMLElement>(isExpanded: boolean) => {
  const contentRef = useRef<T>(null);

  const setMaxHeight = useCallback(() => {
    if (contentRef?.current) {
      contentRef.current.style.maxHeight = isExpanded
        ? contentRef.current.scrollHeight + "px"
        : "0";
      contentRef.current.style.opacity = isExpanded ? "1" : "0";
    }
  }, [isExpanded]);

  useEffect(() => {
    window.addEventListener("resize", setMaxHeight);
    return () => window.removeEventListener("resize", setMaxHeight);
  }, [setMaxHeight]);

  useEffect(() => {
    setMaxHeight();
  }, [isExpanded, setMaxHeight]);

  return contentRef;
};

export default useExpandable;
