import React, { forwardRef } from "react";
import { useIsExternalLink } from "../../../hooks/useIsExternalLink";

type Props = {
  href: string;
  children: React.ReactNode;
  className?: string;
  title?: string;
  ariaLabel?: string;
  tabIndex?: number;
};

const FlexibleLinkWrapper = forwardRef<HTMLAnchorElement, Props>(
  ({ href, children, className, title, ariaLabel, tabIndex, ...rest }, ref) => {
    const { isExternalLink, shouldNofollow } = useIsExternalLink(href);

    const commonProps = {
      ref,
      href,
      className,
      title: title,
      "aria-label": ariaLabel,
      tabIndex,
      ...rest,
    };

    switch (true) {
      case isExternalLink && shouldNofollow:
        return (
          <a
            {...commonProps}
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            {children}
          </a>
        );

      case isExternalLink && !shouldNofollow:
        return (
          <a {...commonProps} target="_blank" rel="noopener noreferrer">
            {children}
          </a>
        );

      default:
        return (
          <a {...commonProps} target="_self">
            {children}
          </a>
        );
    }
  }
);

FlexibleLinkWrapper.displayName = "FlexibleLinkWrapper";

export default FlexibleLinkWrapper;
