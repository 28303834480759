import React from "react";
import ShareTicker from "./ShareTicker";
import FinancialEvents from "./FinancialEvents";
import PressReleases from "./PressReleases";
import s from "./Datablock.InvestorGrid.module.scss";

type Props = {
  locale: string;
  eventsFilterPage?: string;
  pressReleaseFilterPage?: string;
};

const InvestorGrid: React.FC<Props> = ({
  locale,
  eventsFilterPage,
  pressReleaseFilterPage,
}) => {
  return (
    <div className={s.investorGridInner}>
      <ShareTicker locale={locale}></ShareTicker>
      <FinancialEvents
        locale={locale}
        browseAllHref={eventsFilterPage}
      ></FinancialEvents>
      <PressReleases locale={locale} browseAllHref={pressReleaseFilterPage} />
    </div>
  );
};

export default InvestorGrid;
