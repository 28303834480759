import { ReactNode } from "react";
import cn from "classnames";
import s from "./LogoList.module.scss";
import c from "../../styles/container.module.scss";

import List from "./components/List";
import Logo from "./components/Logo";

type Props = {
  title: string;
  featured: boolean;
  spacing: "onlyTop" | "onlyBottom" | "both" | "none";
  children: ReactNode;
};

const subComponents = { List, Logo };
type Subcomponents = typeof subComponents;

const LogoList: CWS<Props, Subcomponents> = ({
  title,
  featured,
  spacing,
  children,
}) => {
  return (
    <section className={cn(s.logoList, s[spacing], { [s.featured]: featured })}>
      {title && (
        <h2 className={cn(c.medium, s.title, { [s.featuredTitle]: featured })}>
          {title}
        </h2>
      )}
      {children}
    </section>
  );
};

LogoList.List = List;
LogoList.Logo = Logo;

export default LogoList;
