import { mediaqueries } from "../../styles/mediaqueries";

type Key = keyof typeof mediaqueries;
type ViewWidths = `${number}${"vw" | "px" | "em" | "rem"}`;
type Queries = Record<Key, ViewWidths>;
export type ImageSizes = string;

export const imageSizes = (
  maxSize: ViewWidths,
  queries?: Partial<Queries>
): ImageSizes =>
  [
    ...Object.entries(queries ?? {}).map(
      ([key, width]) => `${key ? mediaqueries[key] : ""} ${width}`
    ),
    `${maxSize}`,
  ].join(", ");
